import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import {
  IStudentQuizProgressType,
  IStudentQuizProgressTypeResponse,
} from "../../types/studentPanelTypes/studentQuizProgressType";
import { ViewDetailsButton } from "../styled/Button.styled";
import pageSize from "../../constants/pageSize";

type QuizProgressTabsPropsType = {
  data?: IStudentQuizProgressTypeResponse;
  currentPage: number;
  setCurrentPage: any;
};

function QuizProgressTabs({
  data,
  currentPage,
  setCurrentPage,
}: QuizProgressTabsPropsType) {
  const navigate = useNavigate();
  const columns: ColumnsType<IStudentQuizProgressType> = [
    {
      key: "name",
      dataIndex: "quizName",
    },
    {
      key: "date",
      dataIndex: "dateOfAppearing",
      render: (_, record) => (
        <Typography.Text code>{record.dateOfAppearing}</Typography.Text>
      ),
    },
    {
      key: "type",
      dataIndex: "quizMode",
      render: (_, record) => (
        <Typography.Text code>{record.quizMode}</Typography.Text>
      ),
    },
    {
      key: "questions",
      dataIndex: "totalQuestions",
      render: (_, record) => (
        <Typography.Text code>
          {record.totalQuestions} Questions
        </Typography.Text>
      ),
    },
    {
      key: "correct",
      dataIndex: "correctAnswerCount",
      render: (_, record) => (
        <Typography.Text code style={{ color: "green" }}>
          {record.correctAnswerCount} Correct
        </Typography.Text>
      ),
    },
    {
      key: "wrong",
      dataIndex: "wrongAnswerCount",
      render: (_, record) => (
        <Typography.Text code style={{ color: "red" }}>
          {record.wrongAnswerCount} Wrong
        </Typography.Text>
      ),
    },
    {
      key: "skip",
      dataIndex: "skippedAnswerCount",
      render: (_, record) => (
        <Typography.Text code>
          {record.skippedAnswerCount} Skipped
        </Typography.Text>
      ),
    },
    {
      key: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openDetails(record.id)} />
      ),
    },
  ];
  const openDetails = (id: number) => {
    navigate(`view-quiz-details/${id}`);
  };
  return (
    <>
      <Table<IStudentQuizProgressType>
        columns={columns}
        dataSource={data?.data?.content}
        pagination={{
          pageSize,
          current: currentPage,
          total: data?.data.totalElements,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
      />
    </>
  );
}

export default QuizProgressTabs;
