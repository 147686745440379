type VideoFileViewerProps = {
  uri: string;
};

const videoStyle = {
  width: "100%",
  height: "100%",
};
function VideoFileViewer(props: VideoFileViewerProps) {
  const { uri } = props;
  return (
    <>
      <video
        src={uri}
        controls={true}
        style={videoStyle}
        controlsList="nodownload"
        disablePictureInPicture
      />
    </>
  );
}

export default VideoFileViewer;
