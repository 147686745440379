import { mainApi } from "../../../app/services/mainApiSlice";
import {
  IStudent,
  IStudentByIdResponse,
  IStudentResponse,
} from "../../../types/studentType";

const commonUrl: string = "/tutor/student";

const tutorStudentsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStudentsByTutorId: builder.query<
      IStudentResponse,
      {
        currentPage: number;
        pageSize: number;
        idFromLocalStorage: string | null;
        studentName?: string;
        phoneNumber?: string;
        emailId?: string;
        studentType?: string;
        courseName?: string;
      }
    >({
      query: ({
        currentPage,
        pageSize,
        idFromLocalStorage,
        courseName,
        emailId,
        phoneNumber,
        studentName,
        studentType,
      }) => ({
        url: `${commonUrl}/get-all-student/${idFromLocalStorage}?courseName=${
          courseName || ""
        }&email=${emailId || ""}&page=${currentPage - 1}&phoneNumber=${
          phoneNumber || ""
        }&size=${pageSize}&studentName=${studentName || ""}&studentType=${
          studentType || ""
        }`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTORSTUDENT" }],
    }),
    getTutorStudentById: builder.query<
      IStudentByIdResponse,
      string | undefined
    >({
      query: (id) => ({
        url: `${commonUrl}/get-student/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTORSTUDENT" }],
    }),
    updateTutorStudentById: builder.mutation<
      IStudent,
      { id: number | undefined; post: Partial<IStudent> }
    >({
      query: ({ id, post }) => ({
        url: `${commonUrl}/update-student/${id}`,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "TUTORSTUDENT" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllStudentsByTutorIdQuery,
  useGetTutorStudentByIdQuery,
  useUpdateTutorStudentByIdMutation,
} = tutorStudentsApi;
