import { mainApi } from "../../../app/services/mainApiSlice";
import { IStudentAnalyticsResponse } from "../../../types/studentPanelTypes/studentAnalytics";
import {
  ITutorAnalyticsResponse,
  ITutorAnalyticsStudentWiseProgressResponse,
} from "../../../types/tutorPanelTypes/tutorAnalytics";

const commonUrl: string = "/tutor/analytics";

const tutorAnalyticsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    //get tutor time-sheet by tutor id
    getTutorAnalyticsTimesheet: builder.query<
      ITutorAnalyticsResponse,
      {
        currentPage: number;
        pageSize: number;
        idFromLocalStorage: string | undefined | null;
        endDate: string;
        startDate: string;
      }
    >({
      query: ({
        idFromLocalStorage,
        endDate,
        startDate,
        currentPage,
        pageSize,
      }) => ({
        url: `${commonUrl}/get-tutorAnalytic/${idFromLocalStorage}?endDate=${endDate}&page=${
          currentPage - 1
        }&size=${pageSize}&startDate=${startDate}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTORANALYTICS" }],
    }),
    getStudentTopicProgressAnalytics: builder.query<
      IStudentAnalyticsResponse,
      {
        courseId: number | undefined;
        studentId: string | undefined;
        endDate: string;
        startDate: string;
      }
    >({
      query: ({ courseId, studentId, endDate, startDate }) => ({
        url: `${commonUrl}/student-topic-analytic/${studentId}?courseId=${courseId}&endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTORANALYTICS" }],
    }),
    getGroupStudentList: builder.query<
      ITutorAnalyticsStudentWiseProgressResponse,
      string | undefined | null
    >({
      query: (id) => ({
        url: `${commonUrl}/groupStudent-list/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTORANALYTICS" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTutorAnalyticsTimesheetQuery,
  useGetStudentTopicProgressAnalyticsQuery,
  useGetGroupStudentListQuery,
} = tutorAnalyticsApi;
