import { Route, Routes } from "react-router-dom";

import Layout from "../../components/layout/Layout";
//ROLE-Tutor
import MyCourses from "../../pages/tutorPages/MyCourses/MyCourses";
import ViewMyCourse from "../../pages/tutorPages/MyCourses/ViewMyCourse";

//Tutor-Groups
import TutorGroups from "../../pages/tutorPages/TutorGroups/TutorGroups";
import ViewGroupDetails from "../../pages/tutorPages/TutorGroups/ViewGroupDetails";

// Tutor-Analytics
import TutorsAnalytics from "../../pages/tutorPages/Analytics/TutorsAnalytics";

import TimePunch from "../../pages/tutorPages/timePunch/TimePunch";
import TutorProfile from "../../pages/tutorPages/TutorProfile/TutorProfile";

// Tutor Dashboard.
import TutorDashboard from "../../pages/tutorPages/TutorDashboard";

// Tutor-quizzes.
import TutorQuizzes from "../../pages/tutorPages/Quizzes/TutorQuizzes";
import TutorQuizView from "../../pages/tutorPages/Quizzes/TutorQuizView";

//Tutor-students.
import TutorStudents from "../../pages/tutorPages/TutorStudents/TutorStudents";
import TutorStudentsDetails from "../../pages/tutorPages/TutorStudents/TutorStudentsDetails";
import { isAdmin, isTutor } from "../../constants/localStorage";
import TutorNotification from "../../pages/tutorPages/TutorNotification";
import WeeklyProgress from "../../pages/tutorPages/Analytics/WeeklyProgress";
import ViewAnalyticsQuizDetails from "../../pages/Analytics/ViewAnalyticsQuizDetails";
import Login from "../../pages/login/Login";
import CreateNewPassword from "../../pages/login/CreateNewPassword";
import ForgotPassword from "../../pages/login/ForgotPassword";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";

const TutorRoutes = () => {
  return (
    <Routes>
      <>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id" element={<CreateNewPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route path="" element={<PrivateRoute />}>
            <Route index element={<TutorDashboard />} />
            <Route path="tutor-profile" element={<TutorProfile />} />
            <Route path="my-courses" element={<MyCourses />} />
            <Route path="my-courses/view/:id" element={<ViewMyCourse />} />
            <Route path="time-punch" element={<TimePunch />} />

            <Route path="students">
              <Route index element={<TutorStudents />} />
              <Route path="view/:id" element={<TutorStudentsDetails />} />
            </Route>

            <Route path="groups" element={<TutorGroups />} />
            <Route path="groups/view/:id" element={<ViewGroupDetails />} />
            <Route path="analytics">
              <Route index element={<TutorsAnalytics />} />

              <Route
                path="weekly-progress/:studentId"
                element={<WeeklyProgress />}
              />
              <Route
                path="weekly-progress/:studentId/view-quiz-details/:quizId"
                element={<ViewAnalyticsQuizDetails />}
              />
            </Route>
            <Route path="quizzes">
              <Route index element={<TutorQuizzes />} />
              <Route path="quiz-details/:id" element={<TutorQuizView />} />
            </Route>

            <Route path="tutor-notification" element={<TutorNotification />} />
          </Route>
        </Route>
      </>
    </Routes>
  );
};

export default TutorRoutes;
