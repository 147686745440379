import { Form, Select } from "antd";
import { useAppDispatch } from "../../app/hooks";
import {
  useGetAllCoursesListQuery,
  useGetAllCoursesQuery,
} from "../../features/internalCourse/coursesICApiSlice";
import { setSelectedCourse } from "../../features/ui/uiSlice";
import { Rule } from "rc-field-form/lib/interface";

const { Option } = Select;

type ICourseOptionsProps = {
  label: string;
  name: string;
  rules?: Rule[];
};

function SelectCourseOptions(props: ICourseOptionsProps) {
  const { label, rules } = props;
  const { data } = useGetAllCoursesListQuery();

  const dispatch = useAppDispatch();

  return data ? (
    <Form.Item {...props}>
      <Select onChange={(e) => dispatch(setSelectedCourse(e))}>
        {data?.data.map((option) => (
          <Option key={option.courseId} value={option.courseId}>
            {option.courseName}
          </Option>
        ))}
      </Select>
    </Form.Item>
  ) : (
    <>
      <Form.Item label={label} name={"courseName"} rules={rules}>
        <Select loading />
      </Form.Item>
    </>
  );
}

export default SelectCourseOptions;
