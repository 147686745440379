import { Tabs, Form, Row, Col, notification, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../../components/styled/Container.styled";
import FormContainer from "../../../components/styled/FormContainer.styled";
import {
  useGetTutorStudentByIdQuery,
  useUpdateTutorStudentByIdMutation,
} from "../../../features/tutorPanelFeatures/TutorStudent/tutorStudentsApiSlice";
import AssignQuiz from "./AssignQuiz";
import PersonalDetails from "./PersonalDetails";
import Sections from "./Sections";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../../features/ui/uiSlice";
import Breadcrumbs from "../../../components/common/Breadcrumbs";

function TutorStudentsDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, isError } = useGetTutorStudentByIdQuery(id);
  const [updateTutorStudentById, { isLoading: isLoadingEdit }] = useUpdateTutorStudentByIdMutation();
  const [activeTabKey, setActiveTabKey] = useState<string>("1");
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [assignQuizzesId, setAssignQuizzesId] = useState<
    React.Key[] | undefined
  >(data?.data.quizzes || []);
  const dispatch = useDispatch();
  dispatch(setButtonDisabled(isLoadingEdit));

  const onClose = () => {
    navigate(-1);
  };
  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );
  // to enable assign button
  useEffect(() => {
    if (activeTabKey === "3") {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [activeTabKey]);

  useEffect(() => {
    setAssignQuizzesId(data?.data?.quizzes);
  }, [data]);

  const tabItems = [
    {
      label: "Personal Details",
      key: "1",
      children: (
        <WhiteContainer>
          <FormContainer>
            {!isLoading && !isError && data && <PersonalDetails data={data} />}
          </FormContainer>
        </WhiteContainer>
      ),
    },
    {
      label: "Section",
      key: "2",
      children: (
        <WhiteContainer>
          {!isLoading && !isError && data && <Sections data={data} />}
        </WhiteContainer>
      ),
    },
    {
      label: "Assign Quiz",
      key: "3",
      children: (
        <WhiteContainer>
          <AssignQuiz data={data} setAssignQuizzesId={setAssignQuizzesId} />
        </WhiteContainer>
      ),
    },
  ];
  return (
    <>
      <Breadcrumbs idName={!!id ? data?.data?.name : 'false'} isId={!!id ? true : false} />
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "assignForm") {
            const { personalDetailsForm } = forms;
            personalDetailsForm.submit();
            let personalData = { ...personalDetailsForm.getFieldsValue() };
            let obj = {
              id: data?.data?.id,
              ...personalData,
              imageUrl: data?.data?.imageUrl,
              assignCourse: data?.data?.assignCourse,
              quizzes: assignQuizzesId,
            };

            updateTutorStudentById({ id: data?.data.id, post: obj })
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully edited",
                  placement: "bottomLeft",
                });
                navigate(-1);
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <Form name="assignForm">
              <PrimaryButton
                htmlType="submit"
                className={
                  disableButton ? "hide-item-display" : "show-item-display"
                }
                loading={isButtonLoading}
              >
                Assign
              </PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>
        <LightGrayContainer>
          <Tabs
            defaultActiveKey="1"
            onChange={(e: string) => setActiveTabKey(e)}
            items={tabItems}
          />
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default TutorStudentsDetails;
