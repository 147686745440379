import { Select, Form } from "antd";
import { IValueLabel } from "../../types/common";
import { QuestionCategory } from "../../types/questionBank";

const options: IValueLabel[] = [
  {
    value: QuestionCategory.MCQ,
    label: "Multiple Choice Questions",
  },
  {
    value: QuestionCategory.RC,
    label: "Reading Comprehension",
  },
  {
    value: QuestionCategory.SR,
    label: "Student Response",
  },
];

function Category() {
  return (
    <Form.Item
      label="Category"
      name="questionCategory"
      rules={[{ required: true, message: "Please select category" }]}
    >
      <Select options={options} />
    </Form.Item>
  );
}

export default Category;
