import { Form, Select } from "antd";
import { useAppDispatch } from "../../../app/hooks";
import { setSelectedGroup } from "../../../features/ui/uiSlice";
import { Rule } from "rc-field-form/lib/interface";
import { useGetGroupListQuery } from "../../../features/tutorPanelFeatures/TimePunch/timePunchApiSlice";
import secureLocalStorage from "react-secure-storage";

type IGroupOptionsProps = {
  label: string;
  name: string | any;
  rules?: Rule[];
};

function SelectGroupOptions(props: IGroupOptionsProps) {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const tutorId = idFromLocalStorage;
  const { data } = useGetGroupListQuery(tutorId);

  const dispatch = useAppDispatch();
  return (
    <Form.Item {...props}>
      <Select onChange={(e) => dispatch(setSelectedGroup(e.split("-")[0]))}>
        {data?.data.groupDTO.map((option) => (
          <option key={option.id} value={`${option.id}-groupId`}>
            {option.name}
          </option>
        ))}
        {data?.data.studentDTO.map((option) => (
          <option key={option.id} value={`${option.id}-studentId`}>
            {option.name}
          </option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectGroupOptions;
