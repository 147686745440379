import { Card, Col, Progress, Row, Typography } from "antd";
import { commonStrokeColor } from "../../constants/commonColors";
import { ICourseMinimalDTOList } from "../../types/studentPanelTypes/studentDashboardType";
import clockIcon from "../../assets/customIcons/clockIcon.png";
import calendarIcon from "../../assets/customIcons/calendarIcon.png";
import { useDispatch } from "react-redux";
import { setCourseName } from "../../features/ui/uiSlice";
const cardBodyStyle = {
  padding: "10px",
};

type StudentDashboardCardProps = {
  courseCardData?: ICourseMinimalDTOList;
};

const { Title } = Typography;
function StudentDashboardCard(props: StudentDashboardCardProps) {
  const { courseCardData } = props;
  return (
    <>
      <Card
        hoverable
        bodyStyle={cardBodyStyle}
        className="student-dashboard-card-body"
      >
        <Row
          justify="space-between"
          className="student-dashboard-card-outer-row"
        >
          <Row
            justify="space-around"
            className="student-card-heading-row"
            align="middle"
          >
            <Col>
              <Title level={4} className="common-text-color m-0">
                {courseCardData?.courseName}
              </Title>
            </Col>
          </Row>
          <Row justify={"space-between"} className="course-row-divider">
            <Col span={10}>
              <Row className="inner-row-space-evenly">
                <Col>
                  <img src={calendarIcon} alt="calendar-icon" />
                </Col>
                <Col>
                  <span>{`${courseCardData?.startDate?.dayOfMonth}/${courseCardData?.startDate?.monthValue}/${courseCardData?.startDate?.year}`}</span>
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row className="inner-row-space-evenly">
                <Col>
                  <img src={clockIcon} alt="clock-icon" />
                </Col>
                <Col>
                  <span>{`${courseCardData?.courseDuration}`} hours</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Progress
              percent={courseCardData?.courseProgress}
              strokeColor={`${commonStrokeColor}`}
            />
          </Row>
        </Row>
      </Card>
    </>
  );
}

export default StudentDashboardCard;
