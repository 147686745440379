import { mainApi } from "../../../app/services/mainApiSlice";
import {
  IQuestionTypeBySectionIdResponse,
  IStudentQuestionTypeByIdResponse,
  IStudentQuestionTypeResponse,
} from "../../../types/questionType";
import { IQuizByIdInput, IQuizByIdResponse, IQuizResponse } from "../../../types/quizzes";
import { IBlackBookNotesResponse } from "../../../types/studentPanelTypes/blackBookType";
import { IStudentQuizProgressTypeResponse } from "../../../types/studentPanelTypes/studentQuizProgressType";
import { ITopicsByQuestionTypeIdResponse } from "../../../types/topic";

const commonUrl: string = "/student/quiz";
const lessonUrl: string = "student/course";

const studentQuizApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStudentQuiz: builder.query<
      IQuizResponse,
      {
        pageSize: number;
        id: string | undefined | null;
        courseId: string | undefined;
        currentPage: number;
      }
    >({
      query: ({ pageSize, id, courseId, currentPage }) => ({
        url: `${commonUrl}/get-all-quiz/${id}?courseId=${courseId}&page=${
          currentPage - 1
        }&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENT" }],
    }),

    getStudentQuizById: builder.query<
      IQuizByIdResponse,
      IQuizByIdInput
    >({
      query: ({id, studentId}) => ({
        url: `${commonUrl}/get-quiz/${id}?studentId=${studentId}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENT" }],
    }),

    addStudentQuiz: builder.mutation<
      IQuizByIdResponse,
      Partial<IQuizByIdResponse>
    >({
      query: (quizData: IQuizByIdResponse) => ({
        url: `${commonUrl}/save`,
        method: "POST",
        body: quizData,
      }),
      invalidatesTags: [
        { type: "Main", id: "STUDENT" },
        { type: "Main", id: "MYLEARNING" },
      ],
    }),

    // Get QuestionType By section ID
    getLessonBySectionId: builder.query<
      IQuestionTypeBySectionIdResponse,
      number | null
    >({
      query: (id) => ({
        url: `${lessonUrl}/lesson-list/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "QUESTIONTYPEIC" }],
    }),
    // Get Topic By Question ID
    getTopicByLessonId: builder.query<
      ITopicsByQuestionTypeIdResponse,
      number | null
    >({
      query: (id) => ({
        url: `${lessonUrl}/topic-list/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "QUESTIONTYPEIC" }],
    }),
    submitQuiz: builder.mutation<IQuizResponse, Partial<IQuizResponse>>({
      query: (quizData: IQuizResponse) => ({
        url: `${commonUrl}/save-submitQuiz`,
        method: "POST",
        body: quizData,
      }),
      invalidatesTags: [{ type: "Main", id: "STUDENT" }],
    }),
    viewQuiz: builder.query<IQuizByIdResponse, string | undefined | null>({
      query: (id) => ({
        url: `${commonUrl}/viewQuiz/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENT" }],
    }),

    getStudentQuestionList: builder.query<
      IStudentQuestionTypeResponse,
      {
        idFromLocalStorage: string | null;
        currentPage: number;
        pageSize: number;
        courseId: string | undefined;
      }
    >({
      query: ({ idFromLocalStorage, currentPage, pageSize, courseId }) => ({
        url: `${commonUrl}/get-QuestionForBlackBookF/${idFromLocalStorage}?courseId=${courseId}&page=${
          currentPage - 1
        }&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENTQUIZZES" }],
    }),
    getStudentQuestionById: builder.query<
      IStudentQuestionTypeByIdResponse,
      { idFromLocalStorage: string | null; questionId: string | undefined }
    >({
      query: ({ idFromLocalStorage, questionId }) => ({
        url: `${commonUrl}/get-question/${idFromLocalStorage}/?questionId=${questionId}`,
        method: "GET",
      }),
    }),
    getAllStudentViewQuiz: builder.query<
      IStudentQuizProgressTypeResponse,
      {
        idFromLocalStorage: string | null;
        currentPage: number;
        pageSize: number;
        courseId:string | undefined;
      }
    >({
      query: ({ idFromLocalStorage, currentPage, pageSize, courseId }) => ({
        url: `${commonUrl}/get-all-viewQuiz/${idFromLocalStorage}?courseId=${courseId}&page=${
          currentPage - 1
        }&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENTQUIZZES" }],
    }),
    getAllStudentNotes: builder.query<
      IBlackBookNotesResponse,
      {
        idFromLocalStorage: string | null;
        currentPage: number;
        pageSize: number;
        courseId: string | undefined;
      }
    >({
      query: ({ idFromLocalStorage, currentPage, pageSize, courseId }) => ({
        url: `${commonUrl}/get-Note/${idFromLocalStorage}?courseId=${courseId}&page=${
          currentPage - 1
        }&size=${pageSize}`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllStudentQuizQuery,
  useGetLessonBySectionIdQuery,
  useGetTopicByLessonIdQuery,
  useAddStudentQuizMutation,
  useGetStudentQuizByIdQuery,
  useSubmitQuizMutation,
  useViewQuizQuery,
  useGetStudentQuestionListQuery,
  useGetStudentQuestionByIdQuery,
  useGetAllStudentViewQuizQuery,
  useGetAllStudentNotesQuery,
} = studentQuizApi;
