import { Form, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Rule } from "rc-field-form/lib/interface";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useGetSectionByCourseIdQuery } from "../../features/internalCourse/sectionICApiSlice";
import {
  setSelectedCourse,
  setSelectedSection,
} from "../../features/ui/uiSlice";
import { RootState } from "../../app/store";

const { Option } = Select;

type ISectionOptionsProps = {
  label: string;
  name: string;
  rules?: Rule[];
  form: FormInstance;
};

function SelectSectionOptions(props: ISectionOptionsProps) {
  const { label, rules } = props;
  const { form } = props;
  const dispatch = useAppDispatch();

  const selectedCourse = useAppSelector(
    (state: RootState) => state.ui.selectedCourse
  );

  const { data } = useGetSectionByCourseIdQuery(
    selectedCourse || form?.getFieldValue("courseId")
  );

  useEffect(() => {
    if (selectedCourse) {
      form?.setFieldValue("sectionId", "");
    }
    return () => {
      dispatch(setSelectedCourse(""));
    };
  }, [form, selectedCourse, dispatch]);

  return data ? (
    <Form.Item {...props}>
      <Select onChange={(e) => dispatch(setSelectedSection(e))}>
        {data?.data.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  ) : (
    <>
      <Form.Item label={label} name={"sectionName"} rules={rules}>
        <Select loading />
      </Form.Item>
    </>
  );
}

export default SelectSectionOptions;
