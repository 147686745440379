import { mainApi } from "../../../app/services/mainApiSlice";
import {
  ICourse,
  ICourseResponse,
  ICourseByIdResponse,
} from "../../../types/course";

const commonUrl: string = "/tutor/course";

const myCoursesICApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Courses
    getAllMyCourses: builder.query<
      ICourseResponse,
      {
        currentPage: number;
        pageSize: number;
        idFromLocalStorage: string | null;
        course?: string;
        sectionName?: string;
        duration?: string;
        assignedStudents?: string;
      }
    >({
      query: ({
        currentPage,
        pageSize,
        idFromLocalStorage,
        course,
        sectionName,
        duration,
        assignedStudents,
      }) => ({
        url: `${commonUrl}/tutor-courses/${idFromLocalStorage}?assignedStudents=${
          assignedStudents || ""
        }&courseName=${course || ""}&duration=${duration}&page=${
          currentPage - 1
        }&sectionName=${sectionName || ""}&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "COURSESIC" }],
    }),
    // Get Course By ID
    getMyCourseById: builder.query<ICourseByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-course/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "COURSESIC" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllMyCoursesQuery, useGetMyCourseByIdQuery } =
  myCoursesICApi;
