import { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Row,
  Tabs,
  Table,
  notification,
  Modal,
  Col,
  Select,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { Link, useNavigate } from "react-router-dom";
import { quizzesTabs } from "../../constants/tabs";
import type { IQuestionBank } from "../../types/questionBank";
import {
  useDeleteQuestionByIdMutation,
  useGetAllQuestionsQuery,
} from "../../features/quizzes/questionApiSlice";
import pageSize from "../../constants/pageSize";
import {
  DeleteButton,
  PrimaryButton,
  ViewDetailsButton,
} from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import getStringFromHTML from "../../utils/getStringFromHTML";
import BulkUpload from "./BulkUpload";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../utils/useDebouncingValue";
import {
  categoryDropdown,
  difficultyDropdown,
} from "../../utils/questionBankDropdowns";

function QuestionBank() {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteQuestionById] = useDeleteQuestionByIdMutation();

  const [id, setId] = useState("");
  const [category, setCategory] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [course, setCourse] = useState("");
  const [section, setSection] = useState("");
  const [lesson, setLesson] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [passageText, setPassageText] = useState("");

  const [open, setOpen] = useState(false);

  const { debouncedValue: questionId, debouncing: idSearching } =
    useDebouncingValue(id);
  const { debouncedValue: question, debouncing: questionSearching } =
    useDebouncingValue(questionText);
  const { debouncedValue: categoryText, debouncing: categorySearching } =
    useDebouncingValue(category);
  const { debouncedValue: difficultyText, debouncing: difficultySearching } =
    useDebouncingValue(difficultyLevel);
  const { debouncedValue: courseText, debouncing: courseSearching } =
    useDebouncingValue(course);
  const { debouncedValue: sectionText, debouncing: sectionSearching } =
    useDebouncingValue(section);
  const { debouncedValue: lessonText, debouncing: lessonSearching } =
    useDebouncingValue(lesson);
  const { debouncedValue: passage, debouncing: passageSearching } =
    useDebouncingValue(passageText);

  const { data, isLoading, isFetching } = useGetAllQuestionsQuery({
    currentPage,
    pageSize,
    category: categoryText,
    difficulty: difficultyText,
    qId: questionId,
    course: courseText,
    lesson: lessonText,
    questionText: question,
    section: sectionText,
    passageText: passage,
  });

  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Quiz Question?",

      onOk() {
        onDelete(id);
      },
      onCancel() { },
    });
  };

  const columns: ColumnsType<IQuestionBank> = [
    {
      key: "questionNumberSeq",
      dataIndex: "questionNumberSeq",
      title: (
        <div>
          <Typography.Text>Q. ID</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setId(e.target.value);
              setCurrentPage(1);
            }}
            allowClear
            placeholder="Search here"
          />
        </div>
      ),
    },
    {
      key: "question",
      dataIndex: "question",
      ellipsis: true,
      title: (
        <div>
          <Typography.Text>Question</Typography.Text>
          <div>
            <Input.Search
              allowClear
              placeholder="Search here"
              onChange={(e) => {
                setQuestionText(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
      ),

      render: (_, record) => {
        return getStringFromHTML(record.questionText);
      },
    },
    {
      key: "passage",
      dataIndex: "passage",
      ellipsis: true,
      title: (
        <div>
          <Typography.Text>Passage</Typography.Text>
          <div>
            <Input.Search
              allowClear
              placeholder="Search here"
              onChange={(e) => {
                setPassageText(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
      ),

      render: (_, record) => {
        return getStringFromHTML(record.passage ? record.passage : "");
      },
    },
    {
      key: "questionCategory",
      dataIndex: "questionCategory",
      title: (
        <div>
          <Typography.Text>Category</Typography.Text>
          <Select
            placeholder="Select here"
            onChange={(value) => {
              setCategory(value);
              setCurrentPage(1);
            }}
            options={categoryDropdown}
            className="searchDropdownStyle"
          />
        </div>
      ),
    },
    {
      key: "difficultyLevel",
      dataIndex: "difficultyLevel",
      title: (
        <div>
          <Typography.Text>Difficulty Level</Typography.Text>
          <Select
            placeholder="Select here"
            onChange={(value) => {
              setDifficultyLevel(value);
              setCurrentPage(1);
            }}
            options={difficultyDropdown}
            className="searchDropdownStyle"
          />
        </div>
      ),
    },
    {
      key: "courseName",
      dataIndex: "courseName",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            placeholder="Search here"
            onChange={(e) => {
              setCourse(e.target.value);
              setCurrentPage(1);
            }}
            allowClear
          />
        </div>
      ),
    },
    {
      key: "sectionName",
      dataIndex: "sectionName",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
          <Input.Search
            placeholder="Search here"
            onChange={(e) => {
              setSection(e.target.value);
              setCurrentPage(1);
            }}
            allowClear
          />
        </div>
      ),
    },
    {
      key: "lessonName",
      dataIndex: "lessonName",
      title: (
        <div>
          <Typography.Text>Question Type</Typography.Text>
          <Input.Search
            placeholder="Search here"
            onChange={(e) => {
              setLesson(e.target.value);
              setCurrentPage(1);
            }}
            allowClear
          />
        </div>
      ),
    },
    {
      key: "viewDetails",
      fixed: "right",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
    {
      key: "deleteButton",
      fixed: "right",
      width: "5%",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];

  const openDetails = (id: number) => {
    navigate(`${id}`);
  };

  const tabChange = (key: string) => {
    navigate(`../${quizzesTabs[Number(key) - 1]}`);
  };

  const tabItems = [
    {
      label: "Quizzes",
      key: "1",
      children: "",
    },
    {
      label: "Question Bank",
      key: "2",
      children: (
        <WhiteContainer>
          <Table<IQuestionBank>
            rowKey="id"
            scroll={{ x: 1400 }}
            loading={
              isLoading ||
              idSearching ||
              categorySearching ||
              difficultySearching ||
              courseSearching ||
              isFetching ||
              questionSearching ||
              sectionSearching ||
              lessonSearching ||
              passageSearching
            }
            columns={columns}
            dataSource={data?.data.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: data?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </WhiteContainer>
      ),
    },
    {
      label: "Quiz Generator",
      key: "3",
      children: "",
    },
  ];

  const onDelete = (id: number) => {
    deleteQuestionById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        });
      });
  };

  return (
    <>
      <Row className="flex-start">
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="end" className="button-bottom-margin">
        <Col span={10}>
          <Row justify="space-between">
            <PrimaryButton className="mb-1" onClick={() => setOpen(true)}>
              Bulk Upload
            </PrimaryButton>
            <Link to="add">
              <PrimaryButton>Create New Question</PrimaryButton>
            </Link>
          </Row>
        </Col>
      </Row>
      <BulkUpload open={open} setOpen={setOpen} />
      <LightGrayContainer>
        <Tabs defaultActiveKey="2" onChange={tabChange} items={tabItems} />
      </LightGrayContainer>
    </>
  );
}

export default QuestionBank;
