import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AudioFileViewer from "../../../components/preview/AudioFileViewer";
import ImageFileViewer from "../../../components/preview/ImageFileViewer";
import PdfFileViewer from "../../../components/preview/PdfFileViewer";
import PptFileViewer from "../../../components/preview/PptFileViewer";
import TextViewer from "../../../components/preview/TextViewer";
import VideoFileViewer from "../../../components/preview/VideoFileViewer";
import { useGetResourceByIdQuery } from "../../../features/resources/resourcesApiSlice";
import { setTitlePreview } from "../../../features/ui/uiSlice";
import { GCP_URL } from "../../../constants/commonUtils";
import { useGetStudentResourceByIdQuery } from "../../../features/studentPanelFeatures/StudentMyLearning/myLearningApiSlice";
import { isAdmin, isStudent } from "../../../constants/localStorage";

function ResourceFileViewer(): JSX.Element {
  const { resourceId } = useParams();
  const { data } = useGetResourceByIdQuery(resourceId, { skip: !isAdmin });
  const { data: resourceData } = useGetStudentResourceByIdQuery(resourceId, {
    skip: !isStudent,
  });
  const dispatch = useDispatch();
  let dataSource = data ? data?.data : resourceData?.data;
  let gcsUrl = GCP_URL + dataSource?.path;

  useEffect(() => {
    dispatch(setTitlePreview(dataSource?.name));
  });

  switch (dataSource?.resourceType) {
    case "PDF":
      return <PdfFileViewer uri={gcsUrl} />;
    case "PPT":
      return <PptFileViewer uri={gcsUrl} />;
    case "MP4":
      return <VideoFileViewer uri={gcsUrl} />;
    case "TEXT":
      return <TextViewer textData={dataSource?.text || ""} />;
    case "MP3":
      return <AudioFileViewer uri={gcsUrl} />;
    case "IMAGE":
      return <ImageFileViewer uri={gcsUrl} />;
    default:
      return <></>;
  }
}

export default ResourceFileViewer;
