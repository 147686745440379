import { Col, Form, notification, Row, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import AssignStudents from "./AssignStudents";
import PersonalDetails from "./PersonalDetails";
import { useState } from "react";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../../components/styled/Container.styled";
import FormContainer from "../../../components/styled/FormContainer.styled";
import {
  useAddImageMutation,
  useAddTutorMutation,
} from "../../../features/tutor/tutorApiSlice";
import { useAppSelector } from "../../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../../features/ui/uiSlice";

function AddTutor() {
  const navigate = useNavigate();
  const onClose = () => {
    navigate(-1);
  };
  const dispatch = useDispatch();
  const [addImage] = useAddImageMutation();
  const [addTutor, { isLoading }] = useAddTutorMutation();
  const [assignCoursesId, setAssignCoursesId] = useState<React.Key[]>([]);
  const [qualification, setQualification] = useState<string[]>([]);
  const [profilePicUpload, setProfilePicUpload] = useState<FormData>(
    new FormData()
  );
  dispatch(setButtonDisabled(isLoading));

  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );
  return (
    <Form.Provider
      onFormFinish={(name, { forms }) => {
        if (name === "submitForm") {
          const { personalDetailsForm } = forms;
          personalDetailsForm.submit();
          let personalData = { ...personalDetailsForm.getFieldsValue() };
          let obj = {
            ...personalData,
            password: "string",
            qualification: qualification,
            tutorSectionDTO: assignCoursesId?.map((course) => ({
              course: [{ id: course }],
              groupCost: personalData[`groupCost-${course}`],
              individualCost: personalData[`individualCost-${course}`],
            })),
            onlineStatus: true,
            status: true,
          };
          addTutor(obj)
            .unwrap()
            .then((res: any) => {
              addImage({ id: res?.data?.id, formData: profilePicUpload });
              if (res.statusCode === 500) {
                notification.error({
                  message: "User already exist..!!",
                  description: res.message,
                  placement: "bottomLeft",
                });
              } else {
                notification.success({
                  message: "Successfully uploaded",
                  placement: "bottomLeft",
                });
                navigate(-1);
              }
            });
        }
      }}
    >
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <Form name="submitForm">
            <PrimaryButton htmlType="submit" loading={isButtonLoading}>Save</PrimaryButton>
          </Form>
        </Col>
        <Col>
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </Col>
      </Row>

      <LightGrayContainer>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Personal Details" key="1">
            <WhiteContainer>
              <FormContainer>
                <PersonalDetails
                  setAssignCoursesId={setAssignCoursesId}
                  qualification={qualification}
                  setQualification={setQualification}
                  setProfilePicUpload={setProfilePicUpload}
                />
              </FormContainer>
            </WhiteContainer>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Assigned Students" key="2">
            <WhiteContainer>
              <AssignStudents />
            </WhiteContainer>
          </Tabs.TabPane>
        </Tabs>
      </LightGrayContainer>
    </Form.Provider>
  );
}

export default AddTutor;
