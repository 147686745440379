import { Tree, Form, Row, Spin } from "antd";
import { Rule } from "rc-field-form/lib/interface";
import { ITopicSubtopicData } from "../../types/common";
import { useState } from "react";
import { FormInstance } from "antd/es/form";

type IProps = {
  label: string;
  name: string;
  rules?: Rule[];
  treeData: ITopicSubtopicData[] | undefined;
  isLoading: boolean;
  isFetching: boolean;
  form: FormInstance;
  disabled?: boolean;
};

const TreeDropdown = (props: IProps) => {
  const {
    treeData,
    label,
    name,
    rules,
    isLoading,
    isFetching,
    form,
    disabled,
  } = props;
  const [checkedKeys, setCheckedKeys] = useState(form.getFieldValue(name));
  const onTreeCheck = (checkedKeys: any) => {
    setCheckedKeys(checkedKeys);
    form.setFieldValue(name, checkedKeys.checked);
  };

  return (
    <>
      <Form.Item label={label}>
        <Row className="bordered-checkbox-container">
          {isLoading || isFetching ? (
            <Form.Item>
              <Spin />
            </Form.Item>
          ) : (
            <Form.Item name={name} rules={rules}>
              <Tree
                checkable
                checkStrictly
                treeData={treeData}
                checkedKeys={checkedKeys}
                onCheck={onTreeCheck}
                disabled={disabled}
              />
            </Form.Item>
          )}
        </Row>
      </Form.Item>
    </>
  );
};

export default TreeDropdown;
