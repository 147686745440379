import { mainApi } from "../../app/services/mainApiSlice";
import {
  IQuestionType,
  IQuestionTypeBySectionIdResponse,
  IQuestionTypeByIdResponse,
  IQuestionTypeResponse,
} from "../../types/questionType";

const commonUrl: string = "/admin/lesson";

const questionTypeICApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Question Types
    getAllQuestionTypes: builder.query<
      IQuestionTypeResponse,
      {
        currentPage: number;
        pageSize: number;
        name?: string;
        courseName?: string;
        sectionName?: string;
      }
    >({
      query: ({ currentPage, pageSize, name, courseName, sectionName }) => ({
        url: `${commonUrl}/get-all-lesson?courseName=${courseName || ""}&name=${
          name || ""
        }&page=${currentPage - 1}&sectionName=${
          sectionName || ""
        }&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "QUESTIONTYPEIC" }],
    }),
    // Get Question Type By Id
    getQuestionTypeById: builder.query<
      IQuestionTypeByIdResponse,
      string | undefined
    >({
      query: (id) => ({
        url: `${commonUrl}/get-lesson/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "QUESTIONTYPEIC" }],
    }),
    // Add Question Type
    addQuestionType: builder.mutation<IQuestionType, Partial<IQuestionType>>({
      query: (QuestionTypeData: IQuestionType) => ({
        url: `${commonUrl}/save`,
        method: "POST",
        body: QuestionTypeData,
      }),
      invalidatesTags: [{ type: "Main", id: "QUESTIONTYPEIC" }],
    }),
    // Update QuestionType By ID
    updateQuestionTypeById: builder.mutation<
      IQuestionType,
      { id: number | undefined; post: Partial<IQuestionType> }
    >({
      query: ({ id, post }) => ({
        url: `${commonUrl}/update-lesson/${id}`,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "QUESTIONTYPEIC" }],
    }),
    // Delete Question Type By ID
    deleteQuestionTypeById: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `${commonUrl}/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Main", id: "QUESTIONTYPEIC" }],
    }),
    // Get QuestionType By section ID
    getQuestionTypeBySectionId: builder.query<
      IQuestionTypeBySectionIdResponse,
      number | null
    >({
      query: (id) => ({
        url: `${commonUrl}/lesson-list/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "QUESTIONTYPEIC" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllQuestionTypesQuery,
  useGetQuestionTypeByIdQuery,
  useAddQuestionTypeMutation,
  useGetQuestionTypeBySectionIdQuery,
  useUpdateQuestionTypeByIdMutation,
  useDeleteQuestionTypeByIdMutation,
} = questionTypeICApi;
