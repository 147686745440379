import { Progress, Table } from "antd";
import { WhiteContainer } from "../styled/Container.styled";
import pageSize from "../../constants/pageSize";
import { ColumnsType } from "antd/es/table";
import { ITutorAnalyticsStudent } from "../../types/tutorPanelTypes/tutorAnalytics";
import { commonStrokeColor } from "../../constants/commonColors";
type ChildrenTableComponentPropsType = {
  data: ITutorAnalyticsStudent[] | undefined;
  groupCourseName?: string;
  tutorProgress?: number;
};
function ChildrenTableComponent({
  data,
  groupCourseName,
  tutorProgress,
}: ChildrenTableComponentPropsType) {
  const columns: ColumnsType<ITutorAnalyticsStudent> = [
    {
      key: "name",
      dataIndex: "name",
    },
    {
      key: "email",
      dataIndex: "email",
    },
    {
      key: "phoneNumber",
      dataIndex: "phoneNumber",
    },
    {
      key: "course",
      dataIndex: "courseName",
      render: (_, record) => (
        <>
          {groupCourseName ? (
            <span>{groupCourseName}</span>
          ) : (
            <span>{record?.courseName}</span>
          )}
        </>
      ),
    },
    {
      key: "progress",
      width: "10rem",
      render: (_, record) => (
        <>
          {tutorProgress ? (
            <Progress percent={tutorProgress} strokeColor={commonStrokeColor} />
          ) : (
            <Progress
              percent={Math.trunc(
                (record?.tutorProgress / record?.courseProgress) * 100
              )}
              strokeColor={commonStrokeColor}
            />
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <WhiteContainer>
        <Table<ITutorAnalyticsStudent>
          rowKey="id"
          dataSource={data}
          pagination={{ pageSize }}
          columns={columns}
        />
      </WhiteContainer>
    </>
  );
}

export default ChildrenTableComponent;
