import { useEffect, useState } from "react";
import { Input, Table, Typography, Row, Tabs, notification, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link, useNavigate } from "react-router-dom";
import { internalCoursesTabs } from "../../constants/tabs";
import type { ISection } from "../../types/section";
import {
  useGetAllSectionsQuery,
  useDeleteSectionByIdMutation,
} from "../../features/internalCourse/sectionICApiSlice";
import pageSize from "../../constants/pageSize";
import {
  PrimaryButton,
  DeleteButton,
  ViewDetailsButton,
} from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

function Section() {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteSectionById] = useDeleteSectionByIdMutation();

  const [searchText, setSearchText] = useState("");
  const [courseName, setCourseName] = useState("");
  const [duration, setDuration] = useState("");

  const { debouncedValue: sectionName, debouncing: sectionSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: course, debouncing: courseSearching } =
    useDebouncingValue(courseName);
  const { debouncedValue: courseDuration, debouncing: durationSearching } =
    useDebouncingValue(duration);

  const { data, isLoading, isFetching } = useGetAllSectionsQuery({
    currentPage,
    pageSize,
    sectionName,
    course,
    duration: courseDuration,
  });

  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Section?",

      onOk() {
        onDelete(id);
      },
      onCancel() {},
    });
  };

  const columns: ColumnsType<ISection> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Section Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourseName(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "courseName",
    },
    {
      key: "duration",
      title: (
        <div>
          <Typography.Text>Duration(in Hrs.)</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setDuration(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),

      dataIndex: "duration",
    },
    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openSectionDetails(record.id)} />
      ),
    },
    {
      key: "deleteButton",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];

  const openSectionDetails = (id: number) => {
    navigate(`${id}`);
  };

  const tabChange = (key: string) => {
    navigate(`../${internalCoursesTabs[Number(key) - 1]}`);
  };

  const tabItems = [
    {
      label: "Courses",
      key: "1",
      children: "",
    },
    {
      label: "Section",
      key: "2",
      children: (
        <WhiteContainer>
          <Table<ISection>
            rowKey="id"
            loading={
              isLoading ||
              isFetching ||
              sectionSearching ||
              courseSearching ||
              durationSearching
            }
            columns={columns}
            dataSource={data?.data.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: data?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </WhiteContainer>
      ),
    },
    {
      label: "Question Type",
      key: "3",
      children: "",
    },
    {
      label: "Topics",
      key: "4",
      children: "",
    },
  ];

  const onDelete = (id: number) => {
    deleteSectionById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        });
      });
  };

  return (
    <>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="end" className="button-bottom-margin">
        <Link to="add">
          <PrimaryButton>Add Section</PrimaryButton>
        </Link>
      </Row>

      <LightGrayContainer>
        <Tabs defaultActiveKey="2" onChange={tabChange} items={tabItems} />
      </LightGrayContainer>
    </>
  );
}

export default Section;
