import { Button, Col, Form, notification, Row, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import AddFile from "./AddFile";
import AddText from "./AddText";
import LinkCourses from "./LinkCourses";
import LinkSection from "./LinkSection";
import LinkQuestionType from "./LinkQuestionType";
import LinkTopic from "./LinkTopic";
import ContainerHeading from "../../components/styled/ContainerHeading.styled";
import { PrimaryButton } from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  DarkGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import { Tab } from "rc-tabs/lib/interface";
import { useState } from "react";
import { matchFileType } from "../../constants/fileTypes";
import { useAddResourceMutation } from "../../features/resources/resourcesApiSlice";
import FormContainer from "../../components/styled/FormContainer.styled";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../features/ui/uiSlice";


const AddResources = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState<React.Key[]>([]);
  const [sections, setSections] = useState<React.Key[]>([]);
  const [questionTypes, setQuestionTypes] = useState<React.Key[]>([]);
  const [topics, setTopics] = useState<React.Key[]>([]);
  const [blockSubmit, setBlockSubmit] = useState<boolean>(false);
  const [addResource,{isLoading}] = useAddResourceMutation();

  const dispatch = useDispatch();

  dispatch(setButtonDisabled(isLoading));

  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );

  const onClose = () => {
    navigate(-1);
  };

  const checkResourceLinking = (
    isCourse: boolean,
    isSection: boolean,
    isLesson: boolean,
    isTopic: boolean
  ) => {
    if (!isCourse && !isSection && !isLesson && !isTopic) {
      notification.info({
        message: "Alert",
        description: "Please select something to link",
        placement: "bottomLeft",
      });
      return false;
    } else {
      return true;
    }
  };
  const topTabItems: Tab[] = [
    {
      key: "1",
      label: "Add File",
      children: (
        <WhiteContainer>
          <FormContainer>
            <AddFile setBlockSubmit={setBlockSubmit} />
          </FormContainer>
        </WhiteContainer>
      ),
    },
    {
      key: "2",
      label: "Add Text",
      children: (
        <WhiteContainer>
          <AddText />
        </WhiteContainer>
      ),
    },
  ];

  const bottomTabItems: Tab[] = [
    {
      key: "1",
      label: "Courses",
      children: (
        <WhiteContainer>
          <LinkCourses setCourses={setCourses} />
        </WhiteContainer>
      ),
    },
    {
      key: "2",
      label: "Section",
      children: (
        <WhiteContainer>
          <LinkSection setSections={setSections} />
        </WhiteContainer>
      ),
    },
    {
      key: "3",
      label: "Question Type",
      children: (
        <WhiteContainer>
          <LinkQuestionType setQuestionTypes={setQuestionTypes} />
        </WhiteContainer>
      ),
    },
    {
      key: "4",
      label: "Topic",
      children: (
        <WhiteContainer>
          <LinkTopic setTopics={setTopics} />
        </WhiteContainer>
      ),
    },
  ];

  return (
    <Form.Provider
      onFormFinish={(name, { forms }) => {
        if (name === "submitForm") {
          const { addFileForm, addTextForm } = forms;

          const isCourse: boolean = !(courses.length === 0);
          const isSection: boolean = !(sections.length === 0);
          const isLesson: boolean = !(questionTypes.length === 0);
          const isTopic: boolean = !(topics.length === 0);

          const obj = {
            courses: isCourse ? courses : [""],
            sections: isSection ? sections : [""],
            lessons: isLesson ? questionTypes : [""],
            topics: isTopic ? topics : [""],
          };

          // adding file type resource
          if (
            addFileForm?.getFieldValue("fileForm")?.length !== 0 &&
            addFileForm?.getFieldValue("fileForm")?.length !== undefined
          ) {
            addFileForm.validateFields().then(({ fileForm }) => {
              if (blockSubmit) {
                return;
              }

              if (!fileForm || fileForm.length < 1) {
                notification.info({
                  message: "Alert",
                  description: "Please add at least one resource",
                  placement: "bottomLeft",
                });
                return;
              }

              if (
                checkResourceLinking(isCourse, isSection, isLesson, isTopic)
              ) {
                fileForm?.forEach((ele: any) => {
                  const formData = new FormData();

                  Object.keys(obj).forEach((key: string) => {
                    obj[key as keyof typeof obj].forEach(
                      (value: any, index) => {
                        formData.append(`${key}[${index}]`, value);
                      }
                    );
                  });

                  formData.append("pathFile", ele.document.file);
                  formData.append(
                    "resourceType",
                    matchFileType(ele.document.file.type)
                  );
                  formData.append("name", ele.name);

                  addResource({ formData })
                    .unwrap()
                    .then(() => {
                      notification.success({
                        message: "Success",
                        placement: "bottomLeft",
                      });
                      navigate("/resources");
                    })
                    .catch((error) =>
                      notification.error({
                        message: "error",
                        description: error.data.message,
                        placement: "bottomLeft",
                      })
                    );
                });
              }
            });
          }

          // adding text resource
          if (addTextForm?.getFieldsValue() !== undefined) {
            addTextForm.validateFields().then(({ name, text }) => {
              if (!isCourse && !isSection && !isLesson && !isTopic) {
                notification.info({
                  message: "Alert",
                  description: "Please select something to link",
                  placement: "bottomLeft",
                });
                return;
              }
              if (
                checkResourceLinking(isCourse, isSection, isLesson, isTopic)
              ) {
                const formData = new FormData();

                Object.keys(obj).forEach((key: string) => {
                  obj[key as keyof typeof obj].forEach((value: any, index) => {
                    formData.append(`${key}[${index}]`, value);
                  });
                });

                formData.append("resourceType", "TEXT");
                formData.append("name", name);
                formData.append("text", text);
                addResource({ formData })
                  .unwrap()
                  .then(() => {
                    notification.success({
                      message: "Success",
                      placement: "bottomLeft",
                    });
                    navigate("/resources");
                  })
                  .catch((error) =>
                    notification.error({
                      message: "error",
                      description: error.data.message,
                      placement: "bottomLeft",
                    })
                  );
              }
            });
          }
        }
      }}
    >
      <Row justify="end" gutter={20} className="mb-1">
        <Col>
          <Form name="submitForm">
            <PrimaryButton htmlType="submit" loading={isButtonLoading}>Save</PrimaryButton>
          </Form>
        </Col>
        <Col>
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </Col>
      </Row>

      <LightGrayContainer>
        <ContainerHeading>Add Resources</ContainerHeading>
        <DarkGrayContainer>
          <Tabs className="mb-2" items={topTabItems} />

          <Tabs
            className="mb-2"
            items={bottomTabItems}
            tabBarExtraContent={{
              left: (
                <Button type="ghost" disabled>
                  Select to Link
                </Button>
              ),
            }}
          />
        </DarkGrayContainer>
      </LightGrayContainer>
    </Form.Provider>
  );
};

export default AddResources;
