import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout, Row } from "antd";
import React, { useState } from "react";
import { ReactComponent as BrandLogo } from "../../assets/plusoldlogo.svg";
import Header from "./header/Header";
import SiderMenuItems from "./SiderMenuItems";
import styled from "styled-components";

const { Content, Sider } = Layout;

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1rem;
`;

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleSiderCollapse = () => setCollapsed((val) => !val);
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const index = pathName.indexOf("/courses");

  let test = true;
  if (index !== -1) {
    const check = pathName.substring(index + 8);
    if (check && !check.includes("edit") && !check.includes("add")) {
      test = false;
    }
  }

  const handleOnClick = () => {
    navigate("/");
  };

  return (
    <Layout style={{ minHeight: "100%" }}>
      {test && (
        <Sider
          collapsible
          collapsed={collapsed}
          theme="light"
          trigger={null}
          width="250"
        >
          <Row justify="center" align="middle">
            <BrandLogo className="cursor-dropdown" onClick={handleOnClick} />
          </Row>
          <SiderMenuItems toggleSiderCollapse={toggleSiderCollapse} />
        </Sider>
      )}
      <Layout>
        <Header />
        <Content>
          <OutletContainer>
            <Outlet />
          </OutletContainer>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
