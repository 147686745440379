import { Row, Typography } from "antd";

const { Title } = Typography;
function NotFoundPage() {
  return (
    <>
      <Row justify={"center"}>
        <Title level={1}>404</Title>
      </Row>
    </>
  );
}

export default NotFoundPage;
