import { Layout, Row } from "antd";
import Sider from "antd/es/layout/Sider";
import { NextButton, PrevButton } from "../styled/Button.styled";
import PreviewSideMenu from "./PreviewSideMenu";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import Title from "antd/es/typography/Title";
import { WhiteContainer } from "../styled/Container.styled";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPreviewHeader, setTitlePreview } from "../../features/ui/uiSlice";
import { useGetSectionByIdQuery } from "../../features/internalCourse/sectionICApiSlice";

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1rem 1rem 1rem;
`;

function LmsViewLayout() {
  const { sectionId, resourceId, quizId, courseIntroId, topicId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useGetSectionByIdQuery(sectionId);
  const title = useAppSelector((state) => state.ui.titlePreview);
  const itemsIdArray = useAppSelector((state) => state.ui.arrayPreview);
  const menuItemsIndexArray: any = [];

  const filteredObject = itemsIdArray?.map((item) => item);

  filteredObject?.map((item2: any) =>
    item2.map((item3: any) =>
      item3.map((item4: any) =>
        menuItemsIndexArray.push({
          id: `${item3[0] + "-" + item4?.id}`,
          type: item4?.name || "quizType",
        })
      )
    )
  );

  const filteredMenuItemsIndexArray = menuItemsIndexArray.filter(
    (item: any) => {
      return item?.id.split("-")[1] !== "undefined";
    }
  );

  const matchIdFromUrl = filteredMenuItemsIndexArray.find((item: any) => {
    if (item.id === topicId + "-" + resourceId) {
      return resourceId;
    } else if (item.id === topicId + "-" + quizId) {
      return quizId;
    } else if (item.id === `[object Object]-courseIntro/${courseIntroId}`) {
      return courseIntroId;
    }
  });
  let index = filteredMenuItemsIndexArray.indexOf(matchIdFromUrl);

  const prev = () => {
    if (filteredMenuItemsIndexArray.length !== 0) {
      let prevIndex = index - 1;

      if (prevIndex >= 0) {
        let typeOfId = filteredMenuItemsIndexArray[prevIndex]?.type;
        if (typeOfId === "quizType") {
          navigate(
            `topic/${filteredMenuItemsIndexArray[prevIndex]?.id?.split("-")[0]
            }/quiz/${filteredMenuItemsIndexArray[prevIndex]?.id?.split("-")[1]}`
          );
        } else if (typeOfId === "courseIntro") {
          navigate(
            `${filteredMenuItemsIndexArray[prevIndex]?.id?.split("-")[1]}`
          );
        } else {
          navigate(
            `topic/${filteredMenuItemsIndexArray[prevIndex]?.id?.split("-")[0]
            }/resource/${filteredMenuItemsIndexArray[prevIndex]?.id.split("-")[1]
            }`
          );
        }
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const next = () => {
    if (filteredMenuItemsIndexArray.length !== 0) {
      let nextIndex = index + 1;
      if (nextIndex <= filteredMenuItemsIndexArray.length - 1) {
        let typeOfId = filteredMenuItemsIndexArray[nextIndex]?.type;
        if (typeOfId === "quizType") {
          navigate(
            `topic/${filteredMenuItemsIndexArray[nextIndex]?.id?.split("-")[0]
            }/quiz/${filteredMenuItemsIndexArray[nextIndex]?.id?.split("-")[1]}`
          );
        } else if (typeOfId === "courseIntro") {
          navigate(
            `${filteredMenuItemsIndexArray[nextIndex]?.id?.split("-")[1]}`
          );
        } else {
          navigate(
            `topic/${filteredMenuItemsIndexArray[nextIndex]?.id?.split("-")[0]
            }/resource/${filteredMenuItemsIndexArray[nextIndex]?.id.split("-")[1]
            }`
          );
        }
      } else {
        return;
      }
    } else {
      return;
    }
  };

  let areButtonsDisabled = filteredMenuItemsIndexArray?.length === 0;
  let isPrevDisabled = index === 0;
  let isNextDisabled = index === filteredMenuItemsIndexArray?.length - 1;

  useEffect(() => {
    dispatch(setTitlePreview(""));
    dispatch(setPreviewHeader(data?.data?.name));
  }, [data]);

  return (
    <>
      <WhiteContainer className="lms-outer-container">
        <Layout className="main-layout-container">
          <Layout className="lms-content-viewer">
            <Row className="outer-styled-container lms-content-viewer-header">
              <Title level={4} className="m-0">
                {title}
              </Title>
            </Row>

            <OutletContainer>
              <Row className="lms-content-container">
                <Outlet />
              </Row>
            </OutletContainer>

            <Row className="lms-content-viewer-buttons-row">
              <PrevButton
                onClick={prev}
                disabled={areButtonsDisabled || isPrevDisabled}
              >
                Previous
              </PrevButton>
              <NextButton
                onClick={next}
                disabled={areButtonsDisabled || isNextDisabled}
              >
                Next
              </NextButton>
            </Row>
          </Layout>

          <Sider className="sider-menu" theme={"light"} width="250">
            <Row className="lms-content-viewer-header">Course Content</Row>
            <PreviewSideMenu />
          </Sider>
        </Layout>
      </WhiteContainer>
    </>
  );
}

export default LmsViewLayout;
