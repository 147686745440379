import { ConfigProvider, Menu, MenuProps } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetPreviewByCourseIdQuery } from "../../features/internalCourse/coursesICApiSlice";
import { setArrayPreview } from "../../features/ui/uiSlice";
import { ISection } from "../../types/section";
import FileTypeIcon from "../resource/FileTypeIcon";
import { AiFillLayout } from "react-icons/ai";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { IQuestionType } from "../../types/questionType";

const quizIconStyle = {
  fontSize: "1rem",
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
};
function PreviewSideMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { courseId, sectionId } = useParams();
  const { data, isLoading } = useGetPreviewByCourseIdQuery(courseId);

  const filteredData = data?.data?.course?.section?.find(
    (section: ISection) => section.id === Number(sectionId)
  );
  const onClick: MenuProps["onClick"] = (e) => {
    navigate(`${e.key}`);
  };

  const menuItemSelectedKey =
    pathname.split("/")[5] === "courseIntro"
      ? pathname.split("/")[5] + "/" + pathname.split("/")[6]
      : pathname.split("/")[5] +
        "/" +
        pathname.split("/")[6] +
        "/" +
        pathname.split("/")[7] +
        "/" +
        pathname.split("/")[8];

  type MenuItem = Required<MenuProps>["items"][number];

  useEffect(() => {
    if (!isLoading) {
      const menuItemsId = filteredData?.lesson?.map((lesson: IQuestionType) =>
        lesson?.topic?.map((topic: any) => [
          topic?.id,
          ...topic.resources,
          ...topic.quizzes,
        ])
      );
      const newMenuItems = menuItemsId;

      newMenuItems.unshift([
        [
          {
            id: `courseIntro/${data?.data?.course?.id}`,
            name: "courseIntro",
          },
        ],
      ]);
      dispatch(setArrayPreview(newMenuItems));
    }
  }, [data]);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }

  const items: MenuProps["items"] = [
    getItem("Introduction", -1, null, [
      getItem(
        `Introduction to ${data?.data?.course?.name}`,
        "courseIntro/" + data?.data?.course?.id,
        <BsFillFileEarmarkTextFill style={quizIconStyle} />
      ),
    ]),
    getItem(
      "",
      0,
      null,
      filteredData?.lesson.map((lesson: any) =>
        getItem(
          lesson?.name,
          lesson?.id + "-lesson",
          null,
          lesson.topic?.map((topic: any) =>
            getItem(
              topic?.name,
              topic?.id + "-topic",
              null,
              [...topic?.resources, ...topic?.quizzes].map((item: any) =>
                getItem(
                  item?.name || item?.quizName,
                  `topic/${topic?.id}/` +
                    (item?.quizName ? "quiz/" : "resource/") +
                    item?.id,
                  item?.name ? (
                    <FileTypeIcon resourceType={item?.resourceType} />
                  ) : (
                    <AiFillLayout style={quizIconStyle} />
                  )
                )
              )
            )
          )
        )
      )
    ),
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            colorItemBgSelected: "#eb4962",
          },
        },
      }}
    >
      <Menu
        mode="inline"
        onClick={onClick}
        items={items}
        defaultOpenKeys={["-1", "0"]}
        className="preview-sidemenu"
        inlineIndent={2}
        selectedKeys={[menuItemSelectedKey]}
        forceSubMenuRender={true}
      />
    </ConfigProvider>
  );
}

export default PreviewSideMenu;
