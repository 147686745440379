import { Col, Form, notification, Row, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import PersonalDetails from "./PersonalDetails";
import AssignCourses from "./AssignCourses";
import Quiz from "./Quiz";
import { useState } from "react";
import {
  useAddStudentImageMutation,
  useAddStudentMutation,
} from "../../../features/student/studentsApiSlice";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../../components/styled/Container.styled";
import FormContainer from "../../../components/styled/FormContainer.styled";
import AssignResources from "./AssignResources";
import AssignTutors from "./AssignTutors";
import { useAppSelector } from "../../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../../features/ui/uiSlice";

function AddStudents() {
  const navigate = useNavigate();
  const onClose = () => {
    navigate(-1);
  };
  const dispatch = useDispatch();
  const [addStudent, { isLoading }] = useAddStudentMutation();
  const [addStudentImage] = useAddStudentImageMutation();
  const [assignCoursesId, setAssignCoursesId] = useState<React.Key[]>([]);
  const [assignTutorsId, setAssignTutorsId] = useState<React.Key[]>([]);
  const [assignQuizzesId, setAssignQuizzesId] = useState<React.Key[]>([]);
  const [assignResourcesId, setAssignResourcesId] = useState<React.Key[]>([]);
  const [profilePicUpload, setProfilePicUpload] = useState<FormData>(
    new FormData()
  );
  dispatch(setButtonDisabled(isLoading));
  const isButtonLoading = useAppSelector((state) => state.ui.buttonDisabled);
  return (
    <Form.Provider
      onFormFinish={(name, { forms }) => {
        if (name === "submitForm") {
          const { personalDetailsForm } = forms;
          personalDetailsForm.submit();
          let personalData = { ...personalDetailsForm.getFieldsValue() };
          let obj = {
            ...personalData,
            assignCourse: assignCoursesId,
            password: "string",
            assignTutor: assignTutorsId,
            quizzes: assignQuizzesId,
            resources: assignResourcesId,
            status: true,
          };
          addStudent(obj)
            .unwrap()
            .then((res: any) => {
              addStudentImage({
                id: res?.data?.id,
                formData: profilePicUpload,
              });
              if (res.statusCode === 500) {
                notification.error({
                  message: res.message,
                  placement: "bottomLeft",
                });
              } else {
                notification.success({
                  message: "Successfully uploaded",
                  placement: "bottomLeft",
                });
                navigate(-1);
              }
            });
        }
      }}
    >
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <Form name="submitForm">
            <PrimaryButton htmlType="submit" loading={isButtonLoading}>
              Save
            </PrimaryButton>
          </Form>
        </Col>
        <Col>
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </Col>
      </Row>

      <LightGrayContainer>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Personal Details" key="1">
            <WhiteContainer>
              <FormContainer>
                <PersonalDetails setProfilePicUpload={setProfilePicUpload} />
              </FormContainer>
            </WhiteContainer>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Assign Courses" key="2">
            <WhiteContainer>
              <AssignCourses setAssignCoursesId={setAssignCoursesId} />
            </WhiteContainer>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Assign Tutors" key="3">
            <WhiteContainer>
              <AssignTutors setAssignTutorsId={setAssignTutorsId} />
            </WhiteContainer>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Resources" key="4">
            <WhiteContainer>
              <AssignResources setAssignResourcesId={setAssignResourcesId} />
            </WhiteContainer>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Quiz" key="5">
            <WhiteContainer>
              <Quiz setAssignQuizzesId={setAssignQuizzesId} />
            </WhiteContainer>
          </Tabs.TabPane>
        </Tabs>
      </LightGrayContainer>
    </Form.Provider>
  );
}

export default AddStudents;
