import { mainApi } from "../../../app/services/mainApiSlice";
import { ITutor, ITutorByIdResponse } from "../../../types/tutor";

const commonUrl: string = "/tutor/teacher";

const tutorProfileApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // get tutor profile
    getTutorProfile: builder.query<
      ITutorByIdResponse,
      string | undefined | null
    >({
      query: (id) => ({
        url: `${commonUrl}/tutor/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTOR" }],
    }),
    // update tutor profile
    updateTutorProfileById: builder.mutation<
      ITutor,
      { id: number | undefined; post: Partial<ITutor>; }
    >({
      query: ({ id, post }) => ({
        url: `${commonUrl}/update-tutor/${id}`,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "TUTOR" }],
    }),
    // update tutor profile image
    addTutorProfileImage: builder.mutation<
      FormData,
      { id: number | undefined; formData: FormData; }
    >({
      query: ({ id, formData }) => ({
        url: `${commonUrl}/save-image/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "TUTOR" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTutorProfileQuery,
  useUpdateTutorProfileByIdMutation,
  useAddTutorProfileImageMutation,
} = tutorProfileApi;
