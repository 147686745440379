import { Tabs, Row, Col } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { PrimaryButton } from "../../components/styled/Button.styled";
import {
  DarkGrayContainer,
  LightGrayContainer,
} from "../../components/styled/Container.styled";
import TimeSheet from "./TimeSheet";
import TutorGroupWiseProgress from "./TutorGroupWiseProgress";

function TutorAnalytics() {
  const [currentTab, setCurrentTab] = useState<string>();
  const { id } = useParams();
  const navigate = useNavigate();
  const onClose = () => {
    navigate(-1);
  };

  const tabItems = [
    {
      key: "1",
      label: "Time Sheet",
      children: <TimeSheet />,
    },
    {
      key: "2",
      label: "Studentwise progress",
      children: (
        <DarkGrayContainer>
          <TutorGroupWiseProgress />
        </DarkGrayContainer>
      ),
    },
  ];
  return (
    <>
      <Breadcrumbs idName={!id ? 'false' : currentTab === '2' ? 'student' : 'tutor'} isId={!!id ? true : false} />
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </Col>
      </Row>

      <LightGrayContainer>
        <Tabs defaultActiveKey="1" items={tabItems} onTabClick={(tab) => setCurrentTab(tab)} />
      </LightGrayContainer>
    </>
  );
}

export default TutorAnalytics;
