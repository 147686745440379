import { Col, Row, Tabs } from "antd";
import MultipleTopicForm from "./MultipleTopicForm";

function Add() {
  const tabItems = [
    {
      key: "1",
      label: "",
      children: <MultipleTopicForm />,
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Tabs items={tabItems} />
        </Col>
      </Row>
    </>
  );
}

export default Add;
