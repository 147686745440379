import { Select, Form } from "antd";
import { IValueLabel } from "../../types/common";

const options: IValueLabel[] = [
  {
    value: "EASY",
    label: "Easy",
  },
  {
    value: "AVERAGE",
    label: "Average",
  },
  {
    value: "HARD",
    label: "Hard",
  },
];

function DifficultyLevel() {
  return (
    <Form.Item
      label="Difficulty Level"
      name="difficultyLevel"
      rules={[{ required: true, message: "Please select difficulty level" }]}
    >
      <Select options={options} />
    </Form.Item>
  );
}

export default DifficultyLevel;
