import { useEffect, useState } from "react";

const DEBOUNCE_DELAY = 1000;

const useDebouncingValue = (value: string, delay?: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setLoading(false);
    }, delay || DEBOUNCE_DELAY);

    return () => {
      clearTimeout(handler);
      setLoading(true);
    };
  }, [value, delay]);
  return { debouncedValue, debouncing: loading };
};

export { useDebouncingValue };
