import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  padding: 1rem;
`;

const LightGrayContainer = styled(MainContainer)`
  background-color: #eee;
`;

const WhiteContainer = styled(MainContainer)`
  background-color: #fff;
`;

const DarkGrayContainer = styled(MainContainer)`
  background-color: #ddd;
`;

const StudentPanelWhiteContainer = styled(MainContainer)`
  background-color: #fff;
  border: none;
  min-height: 80vh;
  // justify-content: space-between;  TODO: need to check later after completing other screens
`;

const StudentFooter = styled.footer`
  background-color: white;
  padding: 1% 1%;
  margin: auto 0;
  position: sticky;
  bottom: 0rem;
`;

const AuthPagesContainer = styled(WhiteContainer)`
  border-radius: 0 0 3px 3px;
  background-color: #f9fafb;
  justify-content: space-evenly;
  align-items: center;
  height: 80vh;
  width: 95vw;
  margin: 0 auto 1rem auto;
`;
export {
  LightGrayContainer,
  WhiteContainer,
  DarkGrayContainer,
  StudentPanelWhiteContainer,
  StudentFooter,
  AuthPagesContainer,
};
