import styled from "styled-components";
import ReactQuill from "react-quill";
import { FaWindowMinimize } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { useEffect, useState } from "react";
import "./NotesQuill.css";
import { PrimaryButton } from "../styled/Button.styled";
import { Col, Row } from "antd";
import { useAppSelector } from "../../app/hooks";

const HeaderStrip = styled.div`
  background-color: #fff2c2;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1rem;
`;

const NotesContainer = styled.div`
  width: 40%;
  position: absolute;
  right: 10%;
  bottom: 2rem;
  background-color: white;
  border: 1px solid black;
  margin: 1rem;
`;

type NotesQuillPropsType = {
  readOnly: boolean;
  handleCloseNotes: any;
  handleSave?: any;
  onChange?: any;
  notes?: string;
};
function NotesQuill({
  readOnly,
  handleCloseNotes,
  handleSave,
  onChange,
  notes,
}: NotesQuillPropsType) {
  const [minimize, setMinimize] = useState(false);
  const isButtonLoading = useAppSelector((state) => state.ui.buttonDisabled);
  const [text,setText]=useState(notes);
  const handleMiniNotes = () => {
    setMinimize(!minimize);
  };
  useEffect(()=>{onChange(text)},[]);
  
  return (
    <NotesContainer className={minimize ? "mini" : ""}>
      <HeaderStrip>
        <Col>
          <Row>Notes</Row>
        </Col>
        <Col span={5}>
          <Row align={"middle"} justify={"end"}>
            <Col span={6}>
              <FaWindowMinimize
                onClick={handleMiniNotes}
                fontSize="1rem"
                cursor={"pointer"}
              />
            </Col>
            <Col span={6}>
              <GrClose
                onClick={handleCloseNotes}
                fontSize="1rem"
                cursor={"pointer"}
              />
            </Col>
          </Row>
        </Col>
      </HeaderStrip>
      <div className={minimize ? "hideNotesQuill" : ""}>
        <div className="notes-quill-container">
          {readOnly ? (
            <ReactQuill
              value={notes || ""}
              readOnly
              modules={{ toolbar: false }}
            />
          ) : (
            <ReactQuill
              onChange={(content) => {setText(content); onChange(content);}}
              readOnly={readOnly}
              value={text ||""}
            />
          )}
        </div>
        <Row justify={"center"}>
          {!readOnly && (
            <PrimaryButton onClick={handleSave} loading={isButtonLoading}>
              Save
            </PrimaryButton>
          )}
        </Row>
      </div>
    </NotesContainer>
  );
}

export default NotesQuill;
