import { Col, Row, Form, Input, notification } from "antd";
import type { ITopicByIdResponse } from "../../types/topic";
import SelectCourseOptions from "../selectFormOptions/SelectCourseOptions";
import SelectSectionOptions from "../selectFormOptions/SelectSectionOptions";
import SelectQuestionTypeOptions from "../selectFormOptions/SelectQuestionTypeOptions";
import { useUpdateTopicByIdMutation } from "../../features/internalCourse/topicICApiSlice";
import { useNavigate } from "react-router-dom";
import SelectTopicOptions from "../selectFormOptions/SelectTopicOptions";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../features/ui/uiSlice";

type EditTopicFormProps = {
  data?: ITopicByIdResponse;
};

function EditTopicForm(props: EditTopicFormProps) {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { data } = props;

  const [updateTopic, { isLoading }] = useUpdateTopicByIdMutation();
  const dispatch = useDispatch();
  dispatch(setButtonDisabled(isLoading));
  const onFinish = (values: any) => {
    updateTopic({
      id: data?.data.id,
      post: {
        lessonId: values.lessonId,
        name: values.name,
        parentTopicId: values.parentTopicId,
      },
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: "Successfully edited",
          placement: "bottomLeft",
        });
        navigate(-1);
      })
      .catch((error) =>
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        })
      );
  };

  const initialValues = {
    courseId: data?.data.courseId,
    courseName: data?.data.courseName,
    sectionId: data?.data.sectionId,
    sectionName: data?.data.sectionName,
    lessonId: data?.data.lessonId,
    lessonName: data?.data.lessonName,
    name: data?.data.name,
    parentTopicId: data?.data.parentTopicId,
    parentTopicName: data?.data.parentTopicName,
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="mainForm"
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <SelectCourseOptions
              label="Select Course"
              name="courseId"
              rules={[{ required: true, message: "Please select course" }]}
            />
          </Col>

          <Col span={12}>
            <SelectSectionOptions
              form={form}
              rules={[{ required: true, message: "Please select section" }]}
              label="Select Section"
              name="sectionId"
            />
          </Col>

          <Col span={12}>
            <SelectQuestionTypeOptions
              form={form}
              rules={[
                { required: true, message: "Please select question type" },
              ]}
              label="Select Question Type"
              name="lessonId"
            />
          </Col>

          <Col span={12}>
            <SelectTopicOptions
              form={form}
              name="parentTopicId"
              label="Parent Topic"
            />
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please enter topic name" }]}
              label="Topic Name"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default EditTopicForm;
