import { Button, Col, Form, notification, Row, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import AddFile from "./AddFile";
import LinkCourses from "./LinkCourses";
import LinkSection from "./LinkSection";
import LinkQuestionType from "./LinkQuestionType";
import LinkTopic from "./LinkTopic";
import ContainerHeading from "../../components/styled/ContainerHeading.styled";
import { Tab } from "rc-tabs/lib/interface";
import { PrimaryButton } from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  DarkGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import {
  useGetResourceByIdQuery,
  useUpdateResourceByIdMutation,
} from "../../features/resources/resourcesApiSlice";
import { useState, useEffect } from "react";
import AddText from "./AddText";
import { ICourse } from "../../types/course";
import { IQuestionType } from "../../types/questionType";
import { ISection } from "../../types/section";
import { ITopic } from "../../types/topic";
import FormContainer from "../../components/styled/FormContainer.styled";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../features/ui/uiSlice";
import Breadcrumbs from "../../components/common/Breadcrumbs";

function EditResource() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isError, isLoading } = useGetResourceByIdQuery(id);
  const [updateResourceById, { isLoading: isLoadingEdit }] = useUpdateResourceByIdMutation();
  const [courses, setCourses] = useState<React.Key[] | undefined | ICourse[]>(
    data?.data?.courses.map((ele) => ele["id"]) || []
  );
  const [sections, setSections] = useState<
    React.Key[] | undefined | ISection[]
  >(data?.data?.sections.map((ele) => ele["id"]) || []);
  const [questionTypes, setQuestionTypes] = useState<
    React.Key[] | IQuestionType[] | undefined
  >(data?.data?.lessons.map((ele) => ele["id"]) || []);
  const [topics, setTopics] = useState<React.Key[] | ITopic[] | undefined>(
    data?.data?.topics.map((ele) => ele["id"]) || []
  );

  const [blockSubmit, setBlockSubmit] = useState<boolean>(false);

  const onClose = () => {
    navigate(-1);
  };

  const dispatch = useDispatch();

  dispatch(setButtonDisabled(isLoadingEdit));

  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );
  useEffect(() => {
    setCourses(data?.data?.courses.map((ele) => ele["id"]));
    setSections(data?.data?.sections.map((ele) => ele["id"]));
    setQuestionTypes(data?.data?.lessons.map((ele) => ele["id"]));
    setTopics(data?.data?.topics.map((ele) => ele["id"]));
  }, [data]);

  const fileItemTab: Tab[] = [
    {
      key: "1",
      label: "File",
      children: (
        <WhiteContainer>
          <FormContainer>
            {!isError && !isLoading && data && (
              <AddFile setBlockSubmit={setBlockSubmit} resourceData={data} />
            )}
          </FormContainer>
        </WhiteContainer>
      ),
    },
  ];
  const textItemTab: Tab[] = [
    {
      key: "2",
      label: "Text",
      children: (
        <WhiteContainer>
          {!isError && !isLoading && data && <AddText resourceData={data} />}
        </WhiteContainer>
      ),
    },
  ];

  const bottomTabItems: Tab[] = [
    {
      key: "1",
      label: "Courses",
      children: (
        <WhiteContainer>
          {!isError && !isLoading && data && (
            <LinkCourses resourceData={data} setCourses={setCourses} />
          )}
        </WhiteContainer>
      ),
    },
    {
      key: "2",
      label: "Section",
      children: (
        <WhiteContainer>
          {!isError && !isLoading && data && (
            <LinkSection resourceData={data} setSections={setSections} />
          )}
        </WhiteContainer>
      ),
    },
    {
      key: "3",
      label: "Question Type",
      children: (
        <WhiteContainer>
          {!isError && !isLoading && data && (
            <LinkQuestionType
              resourceData={data}
              setQuestionTypes={setQuestionTypes}
            />
          )}
        </WhiteContainer>
      ),
    },
    {
      key: "4",
      label: "Topic",
      children: (
        <WhiteContainer>
          {!isError && !isLoading && data && (
            <LinkTopic resourceData={data} setTopics={setTopics} />
          )}
        </WhiteContainer>
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs idName={!!id ? data?.data?.name : 'false'} isId={!!id ? true : false} />
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { addTextForm, addFileForm } = forms;

            const isCourse: boolean = !(courses?.length === 0);
            const isSection: boolean = !(sections?.length === 0);
            const isLesson: boolean = !(questionTypes?.length === 0);
            const isTopic: boolean = !(topics?.length === 0);

            const obj = {
              courses: isCourse ? courses : [""],
              sections: isSection ? sections : [""],
              lessons: isLesson ? questionTypes : [""],
              topics: isTopic ? topics : [""],
            };

            if (addTextForm !== undefined) {
              addTextForm.validateFields().then(({ name, text }) => {
                const formData = new FormData();

                if (!isCourse && !isSection && !isLesson && !isTopic) {
                  notification.info({
                    message: "Alert",
                    description: "Please select something to link",
                    placement: "bottomLeft",
                  });
                  return;
                }

                Object.keys(obj).forEach((key: string) => {
                  obj[key as keyof typeof obj]?.forEach((value: any, index) => {
                    formData.append(`${key}[${index}]`, value);
                  });
                });

                formData.append("resourceType", "TEXT");
                formData.append("name", name);
                formData.append("text", text);

                updateResourceById({ id: data?.data?.id, formData })
                  .unwrap()
                  .then(() => {
                    notification.success({
                      message: "Successfully edited",
                      placement: "bottomLeft",
                    });
                    navigate("/resources");
                  })
                  .catch((error) =>
                    notification.error({
                      message: "error",
                      description: error.data.message,
                      placement: "bottomLeft",
                    })
                  );
              });
            }
            if (addFileForm !== undefined) {
              addFileForm.validateFields().then(() => {
                if (blockSubmit) {
                  return;
                }

                if (!isCourse && !isSection && !isLesson && !isTopic) {
                  notification.info({
                    message: "Alert",
                    description: "Please select something to link",
                    placement: "bottomLeft",
                  });
                  return;
                }
                const formData = new FormData();
                Object.keys(obj).forEach((key: string) => {
                  obj[key as keyof typeof obj]?.forEach((value: any, index) => {
                    formData.append(`${key}[${index}]`, value);
                  });
                });

                formData.append("resourceType", `${data?.data.resourceType}`);
                formData.append("name", `${data?.data.name}`);
                formData.append("path", `${data?.data.path}`);

                updateResourceById({ id: data?.data?.id, formData })
                  .unwrap()
                  .then(() => {
                    notification.success({
                      message: "Successfully edited",
                      placement: "bottomLeft",
                    });
                    navigate("/resources");
                  });
              });
            }
          }
        }}
      >
        <Row justify="end" gutter={20} className="mb-1">
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>Save</PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <ContainerHeading>Resources</ContainerHeading>
          <DarkGrayContainer>
            {data?.data.resourceType === "TEXT" ? (
              <Tabs className="mb-2" items={textItemTab} />
            ) : (
              <Tabs className="mb-2" items={fileItemTab} />
            )}

            <Tabs
              className="mb-2"
              items={bottomTabItems}
              tabBarExtraContent={{
                left: (
                  <Button type="ghost" disabled>
                    Select to Link
                  </Button>
                ),
              }}
            />
          </DarkGrayContainer>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default EditResource;
