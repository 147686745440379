import { Tabs } from "antd";
import { useState } from "react";
import QuizDetailsForm from "../../components/quizDetails/QuizDetailsForm";
import QuizQuestionForm from "../../components/quizDetails/QuizQuestionForm";
import { WhiteContainer } from "../../components/styled/Container.styled";
import FormContainer from "../../components/styled/FormContainer.styled";
import { IQuestionBankResponse } from "../../types/questionBank";

type QuizQuestionProps = {
  setQuestionId?: any;
  setDisable?: any;
  questionData?: IQuestionBankResponse;
};

function SubQuizGenerator(props: QuizQuestionProps) {
  const { setQuestionId, setDisable } = props;

  const [activeKey, setActiveKey] = useState("1");

  const onChange = (activeKey: string) => setActiveKey(activeKey);

  const tabItems = [
    {
      label: "Quiz Details",
      key: "1",
      children: (
        <WhiteContainer>
          <FormContainer>
            <QuizDetailsForm setActiveKey={setActiveKey} />
          </FormContainer>
        </WhiteContainer>
      ),
    },
    {
      label: "Quiz Questions",
      key: "2",
      activeKey: activeKey,
      children: (
        <WhiteContainer>
          <QuizQuestionForm
            setQuestionId={setQuestionId}
            setDisable={setDisable}
          />
        </WhiteContainer>
      ),
    },
  ];

  return <Tabs activeKey={activeKey} onChange={onChange} items={tabItems} />;
}
export default SubQuizGenerator;
