import { Form, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Rule } from "rc-field-form/lib/interface";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useGetQuestionTypeBySectionIdQuery } from "../../features/internalCourse/questionTypeICApiSlice";
import { RootState } from "../../app/store";
import {
  setSelectedSection,
  setSelectedQuestionType,
} from "../../features/ui/uiSlice";

const { Option } = Select;

type IQuestionTypeOptionsProps = {
  label: string;
  name: string;
  rules?: Rule[];
  form: FormInstance;
};

function SelectQuestionTypeOptions(props: IQuestionTypeOptionsProps) {
  const { form, name, label, rules } = props;
  const dispatch = useAppDispatch();

  const selectedSection = useAppSelector(
    (state: RootState) => state.ui.selectedSection
  );

  const { data } = useGetQuestionTypeBySectionIdQuery(
    selectedSection || form?.getFieldValue("sectionId")
  );

  useEffect(() => {
    if (selectedSection) {
      form?.setFieldValue(name, "");
    }

    return () => {
      dispatch(setSelectedSection(""));
    };
  }, [form, selectedSection, dispatch, name]);

  return data ? (
    <Form.Item {...props}>
      <Select onChange={(e) => dispatch(setSelectedQuestionType(e))}>
        {data?.data.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  ) : (
    <>
      <Form.Item label={label} name={"lessonName"} rules={rules}>
        <Select loading />
      </Form.Item>
    </>
  );
}

export default SelectQuestionTypeOptions;
