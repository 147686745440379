import { IStudentAnalytics } from "../../types/studentPanelTypes/studentAnalytics";
import ProgressItems from "./ProgressItems";

type DayProgressType = {
  data: IStudentAnalytics[];
  day: number;
};

function DayProgress(props: DayProgressType) {
  const { data, day } = props;
  const filterParticularDay = data?.filter((obj) => {
    return obj.day === day;
  });

  return (
    <>
      {filterParticularDay?.map((item1) =>
        item1?.analyticStudentDTO?.lessons?.map((item2, index) => (
          <ProgressItems key={index} item2={item2} />
        ))
      )}
    </>
  );
}

export default DayProgress;
