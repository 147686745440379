import { Col, Row, Form, notification } from "antd";
import { useNavigate } from "react-router-dom";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import ContainerHeading from "../../components/styled/ContainerHeading.styled";
import FormContainer from "../../components/styled/FormContainer.styled";
import { PrimaryButton } from "../../components/styled/Button.styled";
import TopicForm from "../../components/topic/TopicForm";
import { useAddTopicMutation } from "../../features/internalCourse/topicICApiSlice";
import { IAddTopic } from "../../types/topic";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../features/ui/uiSlice";

function AddTopic() {
  const navigate = useNavigate();
  const [addTopic, { isLoading }] = useAddTopicMutation();
  const dispatch = useDispatch();
  // set save button loading state
  dispatch(setButtonDisabled(isLoading));
  const onClose = () => {
    navigate(-1);
  };
  const isButtonLoading = useAppSelector((state) => state.ui.buttonDisabled);
  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { mainForm, listForm } = forms;

            mainForm.validateFields().then(({ lessonId }) => {
              listForm.validateFields().then(({ topics }) => {
                if (!topics || topics.length < 1) {
                  notification.error({
                    message: "Error!",
                    description: "Please add at least one topic",
                    placement: "bottomLeft",
                  });
                }

                const payload = topics.map(
                  (topic: { name: string; parentTopicId: number }) => ({
                    name: topic.name,
                    parentTopicId: topic.parentTopicId,
                    lessonId: lessonId,
                  })
                ) as IAddTopic;
                addTopic({ topic: payload })
                  .unwrap()
                  .then(() => {
                    notification.success({
                      message: "Successfully Added!",
                      placement: "bottomLeft",
                    });
                    navigate(-1);
                  })
                  .catch((error) =>
                    notification.error({
                      message: "Error!",
                      description: error.data.message,
                      placement: "bottomLeft",
                    })
                  );
              });
            });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>
                Save
              </PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <ContainerHeading>Add Topic</ContainerHeading>
          <WhiteContainer>
            <FormContainer>
              <TopicForm />
            </FormContainer>
          </WhiteContainer>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default AddTopic;
