import { useEffect, useState } from "react";
import { ReactComponent as BrandLogo } from "../../assets/plusoldlogo.svg";
import { Form, Input, Card, notification, Switch, Row, Col } from "antd";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSignInMutation } from "../../features/login/loginApiSlice";
import { PrimaryButton } from "../../components/styled/Button.styled";
import secureLocalStorage from "react-secure-storage";
import { useAppDispatch } from "../../app/hooks";
import { setToken, setUserTypeState } from "../../features/ui/uiSlice";

const Container = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  background-color: #e9f5f9;
`;

const loginScreenCardStyle = {
  width: 600,
  height: 600,
  backgroundColor: "#F7F7F7",
  boxShadow: "0px 0px 10px 2px #80d1eb",
};

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("ADMIN");
  const [signIn] = useSignInMutation();
  const dispatch = useAppDispatch();


  const handleLogin = () => {
    signIn({
      username: email,
      password: password,
      userType: userType,
    })
      .unwrap()
      .then((data) => {
        const { roles, id, accessToken, username } = data;

        secureLocalStorage.setItem("name", username);

        secureLocalStorage.setItem("role", roles.toString());

        secureLocalStorage.setItem("id", id.toString());

        secureLocalStorage.setItem("token", accessToken);

        if (userType == "ADMIN") {
          secureLocalStorage.setItem("admin", true);
        } else {
          secureLocalStorage.setItem("admin", false);
        }

        dispatch(setToken(accessToken));

        if (userType === "ADMIN") {
          dispatch(setUserTypeState(true));
        }

        navigate("/");

        notification.success({
          message: "Successfully logged in",
          placement: "bottomLeft",
        });
      })
      .catch((error) => {
        notification.error({
          message: "User does not exist",
          description: "Bad credentials",
          placement: "bottomLeft",
        });
      });
  };

  const onChange = (checked: boolean) => {
    if (checked === false) {
      dispatch(setUserTypeState(true));
      setUserType("ADMIN");
      secureLocalStorage.setItem("admin", true);
    } else {
      dispatch(setUserTypeState(false));
      setUserType("TUTOR");
      secureLocalStorage.setItem("admin", false);
    }
  };

  return (
    <Container>
      <BrandLogo />
      <Card bordered={false} style={loginScreenCardStyle}>
        <div className="welcome-text">
          <p>Welcome to 700+Club</p>
        </div>
        <div className="body-text">
          <p>Login via Registered Email</p>
        </div>
        <Row justify={"center"}>
          <Row justify={"space-around"} className="toggler">
            <Col span={3}>
              <span>Admin</span>
            </Col>
            <Col span={3}>
              <Switch
                onChange={onChange}
                style={{ backgroundColor: "#CCCCCC" }}
              />
            </Col>
            <Col span={3}>
              <span>Tutor</span>
            </Col>
          </Row>
        </Row>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <Form.Item
            label="Email address"
            name="username"
            rules={[
              { type: "email", message: "Input is not a valid email" },
              { required: true, message: "Please enter your email" },
            ]}
          >
            <Input
              placeholder="E-mail"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please enter your password" }]}
          >
            <Input.Password
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          <div className="forgot-password">
            <Form.Item>
              <Link className="login-form-forgot" to={"/forgot-password"}>
                Forgot password
              </Link>
            </Form.Item>
          </div>
          <div className="login-button">
            <Form.Item>
              <PrimaryButton htmlType="submit" onClick={handleLogin}>
                Log in
              </PrimaryButton>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </Container>
  );
}

export default Login;
