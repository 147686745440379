import { Col, Input, Row, Form } from "antd";
import "react-quill/dist/quill.snow.css";
import type { ICourseByIdResponse } from "../../../types/course";
import QuillContainer from "../../../components/styled/QuillContainer.styled";
import TinyMce from "../../../components/common/TinyMce";

type CourseFormProps = {
  data?: ICourseByIdResponse;
};

function CourseForm(props: CourseFormProps) {
  const { data } = props;

  return (
    <>
      <Form
        layout="vertical"
        initialValues={{
          name: data?.data.name,
          courseStatus: "INTERNAL",
          abbreviation: data?.data.abbreviation,
          duration: data?.data?.duration,
          assignStudents: data?.data.assignStudents,
          description: data?.data.description,
        }}
        name="mainForm"
        disabled
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <Form.Item
              label="Course Name"
              name="name"
              rules={[{ required: true, message: "Please Enter Course Name" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Duration(in Hrs.)" name="duration">
              <Input type="number" className="w-100" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Abbreviation"
              name="abbreviation"
              rules={[{ required: true, message: "Please Enter Abbreviation" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Assigned Students" name="assignStudents">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <p>Course Description</p>
            <QuillContainer>
              <TinyMce name="description" />
            </QuillContainer>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CourseForm;
