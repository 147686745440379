import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Form, Input, Radio, notification } from "antd";
import PhoneInput from "react-phone-number-input";
import { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import { useAddAdminImageByIdMutation } from "../../features/Profile/adminProfileApiSlice";
import { IAdminProfileByIdResponse } from "../../types/profileAdmin";
import { GCP_URL } from "../../constants/commonUtils";
import ProfileImageUploader from "../../components/uploader/ProfileImageUploader";

type AdminDetailsProps = {
  data?: IAdminProfileByIdResponse;
};

function AdminDetails(props: AdminDetailsProps) {
  const navigate = useNavigate();
  const { data } = props;
  const adminRole = data?.data.role.slice(17, 22);
  const [addAdminImageById] = useAddAdminImageByIdMutation();
  const [values, setValues] = useState({});
  const [profilePicForm, setProfilePicForm] = useState<FormData>(
    new FormData()
  );
  const handleValue = () => {
    setValues(values);
  };

  const [img, setImg] = useState({
    image_file: null,
    image_preview: "",
  });

  useEffect(() => {
    const imageUrl = GCP_URL + `${data?.data?.imageUrl}`;
    setImg({
      image_preview: imageUrl,
      image_file: null,
    });
  }, [data]);

  useEffect(() => {
    if (profilePicForm.has("image")) {
      addAdminImageById({ id: data?.data.id, formData: profilePicForm })
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully uploaded",
            placement: "bottomLeft",
          });
        });
    }
  }, [profilePicForm, addAdminImageById, data]);

  return (
    <>
      <Form
        layout="vertical"
        name="personalDetailsForm"
        initialValues={{
          name: data?.data.name,
          gender: data?.data.gender,
          phoneNumber: data?.data.phoneNumber,
          email: data?.data.email,
          address: data?.data.address,
          role: adminRole,
          image: data?.data.imageUrl,
        }}
      >
        <Row gutter={[32, 22]}>
          <Col span={12}>
            <Row gutter={[32, 22]}>
              <Col span={24}>
                <Row align="middle">
                  <Col span={24}>
                    <Row align="middle">
                      <ProfileImageUploader
                        src={img.image_preview}
                        type={"file"}
                        name={"image"}
                        id={"file"}
                        setImg={setImg}
                        setProfilePicForm={setProfilePicForm}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Form.Item label="Name" name="name">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="gender" label="Gender">
                  <Radio.Group>
                    <Radio value="MALE">Male</Radio>
                    <Radio value="FEMALE">Female</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[32, 22]}>
              <Col span={24}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  initialValue="abcxhc"
                >
                  <PhoneInput
                    placeholder="Enter phone number"
                    value="abcd"
                    onChange={handleValue}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="EmailID" name="email">
                  <Input disabled={true} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Role" name="role">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default AdminDetails;
