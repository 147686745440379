import styled from "styled-components";
import { LightGrayContainer } from "../styled/Container.styled";

const CardContainer = styled(LightGrayContainer)`
  width: 360px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span:first-child {
    font-size: 1.5rem;
  }
  span:last-child {
    font-size: 3rem;
    font-weight: 600;
  }
`;

type IProps = {
  text: string;
  sum: number | undefined;
};

function CustomCard({ text, sum }: IProps) {
  return (
    <CardContainer>
      <span>{text}</span>
      <span>{sum}</span>
    </CardContainer>
  );
}

export default CustomCard;
