import {
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Tag,
} from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  NextButton,
  PrevButton,
  WhiteCloseButton,
} from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  StudentFooter,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import { IStudentAnswers } from "../../types/questionType";
import { IStudentQuizByIdResponse } from "../../types/studentPanelTypes/studentQuizProgressType";
import { IStudentByIdResponse } from "../../types/studentType";
import QuestionTypesConstant from "../../utils/constants";
import TextViewer from "../preview/TextViewer";

const ParentContainer = styled(WhiteContainer)`
  padding: 0;
`;
const LightGreyHeader = styled(LightGrayContainer)`
  border: none;
  border-radius: 10px 10px 0 0;
  height: auto;
  flex-direction: row;
`;
const CustomWhiteContainer = styled(WhiteContainer)`
  border-radius: 0 0 10px 10px;
  border: none;
`;

type ViewAnalyticsQuizScreenPropsType = {
  data: IStudentQuizByIdResponse | undefined;
  studentData: IStudentByIdResponse | undefined;
};
function ViewAnalyticsQuizScreen({
  data,
  studentData,
}: ViewAnalyticsQuizScreenPropsType) {
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const [count, setCount] = useState(0);
  const totalQuestionsLength = data?.data?.studentAnswerDTO?.length || 0;
  let areButtonsDisabled = totalQuestionsLength === 0;
  let isPrevDisabled = count === 0;
  let isNextDisabled = count === totalQuestionsLength - 1;
  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };
  const handleDecrement = () => {
    setCount((prevCount) => prevCount - 1);
  };

  const handleClose = () => {
    navigate(-1);
  };
  const value = data?.data.studentAnswerDTO[count]?.studentAnswerOptions;

  return (
    <ParentContainer>
      <LightGreyHeader>
        <Row justify={"space-evenly"} gutter={32}>
          <Col>
            <span className="darkgrey-font-color">Name:</span>&nbsp;
            <span>{studentData?.data?.name}</span>
          </Col>
          <Col>
            <span className="darkgrey-font-color">Date:</span>&nbsp;
            <span>{data?.data?.dateOfAppearing}</span>
          </Col>
          <Col>
            <span className="darkgrey-font-color">Quiz Mode:</span>&nbsp;
            <span>{data?.data?.quizMode}</span>
          </Col>
          <Col>
            <span className="darkgrey-font-color">Course:</span>&nbsp;
            <span>{data?.data?.courseName}</span>
          </Col>
          <Col>
            <span className="darkgrey-font-color">Total Questions:</span>&nbsp;
            <span>{data?.data?.totalQuestions}</span>
          </Col>
        </Row>
      </LightGreyHeader>
      {data ? (
        <CustomWhiteContainer>
          <Row>
            <Col span={11}>
              Question {count + 1}/{data?.data?.totalQuestions}
            </Col>
            <Col span={12}>
              <Tag>{data?.data?.studentAnswerDTO[count]?.questionCategory}</Tag>
            </Col>
          </Row>

          {data?.data?.studentAnswerDTO[count]?.questionCategory ===
          `${QuestionTypesConstant.Rc}` ? (
            <Row>
              <Col span={12}>
                <br />
                <TextViewer
                  textData={data.data.studentAnswerDTO[count]?.passage}
                />
              </Col>
              <Col>
                <Divider type="vertical" style={{ height: "38rem" }} />
              </Col>
              <Col span={11}>
                <h3>Question</h3>
                <TextViewer
                  textData={
                    data?.data.studentAnswerDTO[count]?.questionText || " "
                  }
                />

                <br />
                <h3>Answer Options</h3>
                <br />
                {data?.data?.studentAnswerDTO[count]?.answers.map(
                  (item: IStudentAnswers) =>
                    item?.answerOptions.map((answer) => (
                      <Row className="mb-1" align={"middle"}>
                        <Col span={1}>{answer.option}</Col>
                        <Col>
                          <Checkbox
                            id={answer.value}
                            checked={value?.some(
                              (v) => v.value === answer.value
                            )}
                          />
                        </Col>
                        <Col span={15}>
                          <TextViewer textData={answer.value || " "} />
                        </Col>
                        <>
                          <span>
                            {answer.isCorrect === true ? (
                              <p className=" correct-ans"> Correct&nbsp;</p>
                            ) : (
                              ""
                            )}
                          </span>
                          <>
                            {data?.data.studentAnswerDTO[count].answerStatus ===
                              "WRONG" &&
                            value?.some((v) => v.value === answer.value) ? (
                              <p className=" wrong-ans">Incorrect</p>
                            ) : data?.data.studentAnswerDTO[count]
                                .answerStatus === "CORRECT" &&
                              value?.some((v) => v.value === answer.value) ? (
                              <p className=" correct-ans"> &nbsp;</p>
                            ) : data?.data.studentAnswerDTO[count]
                                .answerStatus === "SKIPPED" ? (
                              ""
                            ) : (
                              ""
                            )}
                          </>
                        </>
                      </Row>
                    ))
                )}
                <br />
                <Collapse ghost expandIconPosition="end">
                  <Panel header="Explanation" key="1">
                    <TextViewer
                      textData={
                        data?.data.studentAnswerDTO[count]?.explanation || " "
                      }
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          {data?.data?.studentAnswerDTO[count]?.questionCategory ===
          `${QuestionTypesConstant.Mcq}` ? (
            <>
              <div>
                <br />
                <TextViewer
                  textData={
                    data?.data.studentAnswerDTO[count]?.questionText || " "
                  }
                />
              </div>
              <br />
              <Row>
                <h3>Answer Options</h3>
              </Row>
              <br />

              {data?.data?.studentAnswerDTO[count]?.answers.map(
                (item: IStudentAnswers) =>
                  item?.answerOptions.map((answer) => (
                    <Row className="mb-1" align={"middle"}>
                      <Col span={1}>{answer.option}</Col>
                      <Col>
                        <Checkbox
                          id={answer.value}
                          checked={value?.some((v) => v.value === answer.value)}
                        />
                      </Col>
                      <Col span={15}>
                        <TextViewer textData={answer.value || " "} />
                      </Col>
                      <>
                        <span>
                          {answer.isCorrect === true ? (
                            <p className=" correct-ans"> Correct&nbsp;</p>
                          ) : (
                            ""
                          )}
                        </span>
                        <>
                          {data?.data.studentAnswerDTO[count].answerStatus ===
                            "WRONG" &&
                          value?.some((v) => v.value === answer.value) ? (
                            <p className=" wrong-ans">Incorrect</p>
                          ) : data?.data.studentAnswerDTO[count]
                              .answerStatus === "CORRECT" &&
                            value?.some((v) => v.value === answer.value) ? (
                            <p className=" correct-ans"> &nbsp;</p>
                          ) : data?.data.studentAnswerDTO[count]
                              .answerStatus === "SKIPPED" ? (
                            ""
                          ) : (
                            ""
                          )}
                        </>
                      </>
                    </Row>
                  ))
              )}
              <br />
              <Collapse ghost expandIconPosition="end">
                <Panel header="Explanation" key="1">
                  <TextViewer
                    textData={
                      data?.data.studentAnswerDTO[count]?.explanation || " "
                    }
                  />
                </Panel>
              </Collapse>
            </>
          ) : (
            <></>
          )}

          {data?.data.studentAnswerDTO[count]?.questionCategory ===
          `${QuestionTypesConstant.Numerical}` ? (
            <>
              <div>
                <br />
                <TextViewer
                  textData={
                    data?.data.studentAnswerDTO[count]?.questionText || " "
                  }
                />
              </div>
              <br />
              <Row align={"middle"} justify={"space-evenly"}>
                <Col span={4}>
                  <h5>Type your answer here</h5>
                </Col>
                <Col span={6}>
                  <Form.Item>
                    <Input
                      value={
                        data?.data?.studentAnswerDTO[count]
                          ?.studentAnswerOptions[0]?.value
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  {data?.data?.studentAnswerDTO[count]?.answerStatus ===
                    "WRONG" && <p className=" wrong-ans">Incorrect</p>}
                  {data?.data?.studentAnswerDTO[count]?.answerStatus ===
                    "CORRECT" && <p className=" correct-ans">Correct</p>}
                  {data?.data?.studentAnswerDTO[count]?.answerStatus ===
                    "SKIPPED" && <p>Skipped</p>}
                </Col>
              </Row>
              <br />
              <Collapse ghost expandIconPosition="end">
                <Panel header="Explanation" key="1">
                  <TextViewer
                    textData={
                      data?.data.studentAnswerDTO[count]?.explanation || " "
                    }
                  />
                </Panel>
              </Collapse>
            </>
          ) : (
            " "
          )}
          <StudentFooter>
            <Row>
              <Col span={12}>
                <Row justify={"start"}>
                  <PrevButton
                    onClick={handleDecrement}
                    disabled={areButtonsDisabled || isPrevDisabled}
                  >
                    Prev
                  </PrevButton>
                </Row>
              </Col>

              <Col span={12}>
                {count === totalQuestionsLength - 1 ? (
                  <Row justify={"end"}>
                    <Col>
                      <WhiteCloseButton onClick={handleClose}>
                        Close
                      </WhiteCloseButton>
                    </Col>
                  </Row>
                ) : (
                  <Row justify={"end"}>
                    <Col>
                      <NextButton
                        onClick={handleIncrement}
                        disabled={areButtonsDisabled || isNextDisabled}
                      >
                        Next
                      </NextButton>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </StudentFooter>
        </CustomWhiteContainer>
      ) : (
        <CustomWhiteContainer>
          <Spin />
        </CustomWhiteContainer>
      )}
    </ParentContainer>
  );
}

export default ViewAnalyticsQuizScreen;
