import { Form, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Rule } from "rc-field-form/lib/interface";
import secureLocalStorage from "react-secure-storage";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useGetCourseDetailsQuery } from "../../../features/tutorPanelFeatures/TimePunch/timePunchApiSlice";

const { Option } = Select;

type ISectionOptionsProps = {
  label: string;
  name: string | any;
  rules?: Rule[];
  form: FormInstance;
};

function SelectSectionOptions(props: ISectionOptionsProps) {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const tutorId = idFromLocalStorage;
  const id = useAppSelector((state: RootState) => state.ui.selectedGroup);

  const { data } = useGetCourseDetailsQuery({ id, tutorId });
  return (
    <Form.Item {...props}>
      <Select>
        {data?.data.map((option) =>
          option.sections.map((item) => (
            <Option key={item.sectionId} value={item.sectionId}>
              {item.sectionName}
            </Option>
          ))
        )}
      </Select>
    </Form.Item>
  );
}

export default SelectSectionOptions;
