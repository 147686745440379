import styled from "styled-components";
import { Button, Form, Input, Card, notification, Row, Typography } from "antd";
import AuthPagesHeader from "../../components/layout/studentPanelHeader/AuthPagesHeader";
import { AuthPagesContainer } from "../../components/styled/Container.styled";
import { useSendForgotMailMutation } from "../../features/forgotPassword/forgotPasswordApiSlice";
import { useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";

const ResetPasswordCard = styled(Card)`
  height: 60vh;
  max-height: 350px;
  width: 40vw;
  display: flex;
  text-align: center;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
`;
const cardBodyStyle = {
  width: "100%",
};
function ForgotPassword() {
  const [form] = Form.useForm();
  const [sendForgotMail] = useSendForgotMailMutation();

  const userType = useAppSelector((state) => state.ui.admin);

  const [forgotUserType, setForgotUserType] = useState<string>();

  const handleFormSubmit = (val: { email: string }) => {
    sendForgotMail({
      toAddresses: val.email,
      userType: forgotUserType,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: "Mail Sent Successfully",
          description: "Please check your email",
          placement: "bottomLeft",
        });
      })
      .then(() => {
        form.resetFields();
      })
      .catch(() => {
        notification.error({
          message: "User not found with email",
          placement: "bottomLeft",
        });
        form.resetFields();
      });
  };

  useEffect(() => {
    if (
      process.env.REACT_APP_IS_ADMIN === "true" &&
      (userType === true || userType === null)
    ) {
      setForgotUserType("ADMIN");
    } else if (
      process.env.REACT_APP_IS_ADMIN === "true" &&
      userType === false
    ) {
      setForgotUserType("TUTOR");
    } else if (process.env.REACT_APP_IS_ADMIN === "false") {
      setForgotUserType("STUDENT");
    }
  }, [userType]);

  return (
    <>
      <AuthPagesHeader />
      <AuthPagesContainer>
        <ResetPasswordCard bodyStyle={cardBodyStyle}>
          <Typography.Title level={2} className="mt-0">
            Forgot Password
          </Typography.Title>
          <Typography.Text className="p-1">
            Please share your registered e-mail, we will share password reset
            link to below mentioned email id
          </Typography.Text>

          <Form
            form={form}
            name="normal_login"
            className="login-form p-1"
            initialValues={{ remember: true }}
            layout="vertical"
            onFinish={(values) => handleFormSubmit(values)}
          >
            <Form.Item
              label="Email ID"
              name="email"
              rules={[
                { type: "email", message: "Input is not a valid email" },
                { required: true, message: "Please input your email" },
              ]}
            >
              <Input placeholder="E-mail" />
            </Form.Item>
            <Row justify={"center"} className="mt-2">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </ResetPasswordCard>
      </AuthPagesContainer>
    </>
  );
}

export default ForgotPassword;
