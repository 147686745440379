import { useParams } from "react-router-dom";
import { useGetPreviewByCourseIdQuery } from "../../../features/internalCourse/coursesICApiSlice";
import { useEffect } from "react";
import { setTitlePreview } from "../../../features/ui/uiSlice";
import { useDispatch } from "react-redux";
import { useGetAllMyLearningByCourseIdQuery } from "../../../features/studentPanelFeatures/StudentMyLearning/myLearningApiSlice";
import TextViewer from "../../../components/preview/TextViewer";
import secureLocalStorage from "react-secure-storage";
import { ROLE_ADMIN, ROLE_STUDENT } from "../../../constants/roles";

function CourseIntroViewer() {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const { courseIntroId } = useParams();

  const role = secureLocalStorage.getItem("role");
  const isStudent = role === ROLE_STUDENT;
  const isAdmin = role === ROLE_ADMIN;

  const { data } = useGetPreviewByCourseIdQuery(courseIntroId, {
    skip: !isAdmin,
  });

  const { data: courseIntro } = useGetAllMyLearningByCourseIdQuery(
    {
      id: idFromLocalStorage,
      courseId: courseIntroId,
    },
    { skip: !isStudent }
  );
  const dispatch = useDispatch();

  const textData = data
    ? data?.data?.course?.description || ""
    : courseIntro?.data[0]?.course?.description || "";

  useEffect(() => {
    dispatch(setTitlePreview("Introduction"));
  });

  return (
    <>
      <TextViewer textData={textData} />
    </>
  );
}

export default CourseIntroViewer;
