import { Form, Select, TreeSelect } from "antd";
import { Rule } from "rc-field-form/lib/interface";
import { ITopicSelectTreeData } from "../../types/common";
import { useState } from "react";
import { FormInstance } from "antd/es/form";

type IProps = {
  label?: string;
  name: [number, string] | string;
  rules?: Rule[];
  treeData: ITopicSelectTreeData[] | undefined;
  isLoading: boolean;
  isFetching: boolean;
  form: FormInstance;
};

const TopicTreeSelect = (props: IProps) => {
  const { treeData, name, rules, isLoading, isFetching, form, label } = props;
  const [checkedKeys, setCheckedKeys] = useState(form.getFieldValue(name));

  const onTreeCheck = (checkedKeys: any) => {
    setCheckedKeys(checkedKeys);
    form.setFieldValue(name, checkedKeys);
  };

  return treeData ? (
    <>
      <Form.Item rules={rules} name={name} label={label}>
        <TreeSelect
          treeData={treeData}
          treeDefaultExpandAll
          allowClear
          loading={isLoading || isFetching}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          value={checkedKeys}
          onChange={onTreeCheck}
        />
      </Form.Item>
    </>
  ) : (
    <>
      <Form.Item label={label} name={"parentTopicName"} rules={rules}>
        <Select />
      </Form.Item>
    </>
  );
};

export default TopicTreeSelect;
