import { Editor } from "@tinymce/tinymce-react";
import { Form, Input, Skeleton } from "antd";

import 'tinymce/tinymce';
import "tinymce/models/dom/model";
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/autoresize'
import "@wiris/mathtype-tinymce6";
import { useEffect, useState } from "react";
import { contentCss } from "../../constants/editorContentStyle";

type Props = {
  className?: string;
  name: string;
  readOnly?: boolean;
};

function TinyMce({ name, readOnly }: Props) {
  const form = Form.useFormInstance();
  const [originalData, setOriginalData] = useState("");
  const usernameValue = Form.useWatch(name, form);
  const [hide,setHide]=useState(true);
  const [loading,setLoading]=useState(true);

  const onEditorChange = (content: string) => {
    if (!content) {
      form.setFieldValue(name, originalData);
      setOriginalData("");
      return;
    }
    form.setFieldValue(name, content);
  };

  useEffect(() => {
    let formData = form.getFieldValue(name);
    if (formData?.length > 0) {
      setOriginalData(formData);
    }
  }, []);

  const OptionsWithoutReadOnly = {
    font_size_formats:
      "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt 20pt",
    font_family_formats:
      "HK Grotesk Bold=HK Grotesk-Bold; HK Grotesk Medium=HK Grotesk-Medium; Inter Bold=Inter-Bold; Inter Medium=Inter-Medium; Inter Regular=Inter-Regular; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;",
    external_plugins: {
      tiny_mce_wiris: `/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`,
    },
    plugins: [
      "print",
      "preview",
      "fullpage",
      "searchreplace",
      "autolink",
      "directionality",
      "advcode",
      "visualblocks",
      "visualchars",
      "fullscreen",
      "image",
      "link",
      "media",
      "codesample",
      "table",
      "charmap",
      "hr",
      "pagebreak",
      "nonbreaking",
      "anchor",
      "toc",
      "insertdatetime",
      "advlist",
      "lists",
      "textcolor",
      "wordcount",
      "imagetools",
      "mediaembed",
      "linkchecker",
      "contextmenu",
      "colorpicker",
      "textpattern",
      "help",
      "emoticons",
      "mathtype",
    ],
    toolbar:
      "sizeselect | fontsizeselect | formatselect | bold italic strikethrough forecolor backcolor | link image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | code | mathtype  |" +
      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
    image_advtab: true,
    file_picker_types: "image",
    automatic_uploads: true,
    htmlAllowedTags: [".*"],
    htmlAllowedAttrs: [".*"],
    extended_valid_elements: "*[.*]",
    statusbar: false,
  };

  const OptionsWithReadOnly = {
    external_plugins: {
      tiny_mce_wiris: `/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`,
    },
    plugins: "autoresize",
    autoresize_bottom_margin: 0,
    autoresize_overflow_padding: 0,
    min_height: 0,
    menubar: false,
    statusbar: false,
    toolbar: false,
    skin: false,
    content_css: false,
    content_style: contentCss,
  };
  return (
    <>
      <Form.Item name={name} hidden>
        <Input />
      </Form.Item>

      <Skeleton loading={loading} active title={false} paragraph={{rows:2}}/>

       <div  hidden={hide}>
          <Editor
              scriptLoading={{ defer: true }}
              apiKey="15cydjqvhnas6ykoihvcgnqu3faudcqcaoqj9utjicv0hpe4"
              value={usernameValue}
              onEditorChange={onEditorChange}
              disabled={readOnly ? true : false}
              init={readOnly ? OptionsWithReadOnly : OptionsWithoutReadOnly}
              onPostRender={()=>{setLoading(false);setHide(false);}}
            />
       </div>
       
    </>
  );
}

export default TinyMce;
