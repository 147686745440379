import { mainApi } from "../../../app/services/mainApiSlice";
import {
  IBlackBookCountResponse,
  IBlackBookResponse,
  IBlackBookSave,
} from "../../../types/studentPanelTypes/blackBookType";

const commonUrl: string = "/student/blackBook";

const studentBlackBookApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getBlackBookCount: builder.query<
      IBlackBookCountResponse,
      { id: string | undefined | null; courseId: string | undefined }
    >({
      query: ({ id, courseId }) => ({
        url: `${commonUrl}/get-blackBookCount/${id}?courseId=${courseId}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "BLACKBOOK" }],
    }),
    getBlackBookResource: builder.query<
      IBlackBookResponse,
      {
        idFromLocalStorage: string | null;
        resourceType: string | null;
        currentPage: number;
        pageSize: number;
        courseId: string | undefined;
      }
    >({
      query: ({
        idFromLocalStorage,
        currentPage,
        pageSize,
        resourceType,
        courseId,
      }) => ({
        url: `${commonUrl}/get-ResourcePDF/${idFromLocalStorage}?courseId=${courseId}&page=${
          currentPage - 1
        }&resourceType=${resourceType}&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "BLACKBOOK" }],
    }),
    saveBlackBook: builder.mutation<any, Partial<IBlackBookSave>>({
      query: (data: IBlackBookSave) => ({
        url: `${commonUrl}/save`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Main", id: "BLACKBOOK" }],
    }),
  }),
});

export const {
  useSaveBlackBookMutation,
  useGetBlackBookCountQuery,
  useGetBlackBookResourceQuery,
} = studentBlackBookApi;
