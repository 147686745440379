import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Tag,
  Tooltip,
  Typography,
  message,
  Checkbox,
} from "antd";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  NextButton,
  PrevButton,
  WhiteCloseButton,
} from "../../../components/styled/Button.styled";
import { useState } from "react";
import { useViewQuizQuery } from "../../../features/studentPanelFeatures/StudentQuiz/studentQuizzes";
import BlackBookPopUpContent from "../../../components/myLearning/BlackBookPopUpContent";
import { useSaveBlackBookMutation } from "../../../features/studentPanelFeatures/StudentBlackBook/blackBookApiSlice";
import {
  StudentFooter,
  StudentPanelWhiteContainer,
} from "../../../components/styled/Container.styled";
import NotesQuill from "../../../components/common/NotesQuill";
import TextViewer from "../../../components/preview/TextViewer";
import whiteBoard from "../../../assets/customIcons/whiteBoard.png";
import blackBook from "../../../assets/customIcons/blackBook.png";
import QuestionTypesConstant from "../../../utils/constants";
import secureLocalStorage from "react-secure-storage";
import { useDispatch } from "react-redux";
import {
  setButtonDisabled,
  setStudentBreadcrumb,
} from "../../../features/ui/uiSlice";

const { Title } = Typography;
function ViewAnalyticsQuiz() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { Panel } = Collapse;
  const [count, setCount] = useState(0);
  const { id: submitQuizId, courseId } = useParams();
  const {
    data,
    isLoading: isDataLoading,
    isFetching,
  } = useViewQuizQuery(submitQuizId);
  const questionLength: any = data?.data.studentAnswerDTO?.length;
  let areButtonsDisabled = data?.data.questions?.length === 0;
  let isPrevDisabled = count === 0;
  let isNextDisabled = count === questionLength - 1;
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };
  const handleDecrement = () => {
    setCount((prevCount) => prevCount - 1);
  };

  const handleClose = () => {
    navigate(`/course/${courseId}/analytics/submitQuiz`, { state: { data } });
  };

  const value = data?.data.studentAnswerDTO[count]?.studentAnswerOptions;

  const topicId = data?.data.studentAnswerDTO.map(
    (item) => item.topics[0].id
  )[0];
  const questionId: any = data?.data.studentAnswerDTO[count].questionId;

  const [saveBlackBook, { isLoading }] = useSaveBlackBookMutation();

  const dispatch = useDispatch();
  // set save button loading state
  dispatch(setButtonDisabled(isLoading));
  dispatch(
    setStudentBreadcrumb([
      `${pathname.split("/")[3]}/${pathname.split("/")[4]}`,
    ])
  );

  const handleOnClick = async () => {
    await saveBlackBook({
      studentId: idFromLocalStorage,
      questionId: questionId,
      topicId: topicId,
    })
      .unwrap()
      .then((res) => {
        Modal.success({
          content: <BlackBookPopUpContent res={res} />,
          icon: null,
          maskClosable: true,
          closable: true,
          centered: true,
          bodyStyle: {
            justifyContent: "center",
            alignItems: "center",
          },
          footer: null,
        });
      });
  };

  const handleClickNotes = () => {
    setShowNotes(!showNotes);
  };

  const handleCloseNotes = () => {
    setShowNotes(false);
  };

  const onChange = (content: string) => {
    setNotes(content);
  };

  const handleSave = async () => {
    if (notes === "") {
      message.error("Please enter something");
      return;
    } else {
      await saveBlackBook({
        studentId: idFromLocalStorage,
        questionId: questionId,
        topicId: topicId,
        note: notes,
      })
        .unwrap()
        .then((res) => {
          message.success(res);
          setShowNotes(false);
        });
    }
  };

  return (
    <>
      {data && !isDataLoading && !isFetching ? (
        <>
          <StudentPanelWhiteContainer>
            <br />
            <Row>
              <Col span={10}>
                <Title level={3}>
                  Question {count + 1}/{questionLength}
                </Title>
              </Col>
              <Col span={10}>
                <Tag>
                  {data?.data.studentAnswerDTO[count]?.questionCategory}
                </Tag>
              </Col>

              <Col span={2}>
                <Tooltip placement="top" title="Notes">
                  <Button ghost className="p-0" onClick={handleClickNotes}>
                    <img src={whiteBoard} alt="notes-icon" />
                  </Button>
                </Tooltip>
              </Col>
              <Col span={2}>
                <Tooltip placement="top" title="Bookmark">
                  <Button ghost className="p-0" onClick={handleOnClick}>
                    <img src={blackBook} alt="blackbook-icon" />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
            <br />
            {data?.data.studentAnswerDTO[count]?.questionCategory ===
            `${QuestionTypesConstant.Rc}` ? (
              <Row>
                <Col span={12}>
                  <br />
                  <TextViewer
                    textData={data.data.studentAnswerDTO[count]?.passage}
                  />
                </Col>
                <Col>
                  <Divider type="vertical" style={{ height: "38rem" }} />
                </Col>
                <Col span={11}>
                  <h3>Question</h3>
                  <TextViewer
                    textData={
                      data?.data.studentAnswerDTO[count]?.questionText || " "
                    }
                  />
                  <br />
                  <h3>Answer Options</h3>

                  {data?.data.studentAnswerDTO[count]?.answers.map(
                    (item: any) =>
                      item?.answerOptions.map((answer: any) => (
                        <>
                          <Row align={"middle"}>
                            <Col span={1}>{answer.option}</Col>
                            <Col span={1}>
                              <Checkbox
                                id={answer.value}
                                checked={value?.some(
                                  (v) => v.value === answer.value
                                )}
                              />
                            </Col>
                            <Col span={19}>
                              <TextViewer textData={answer.value || " "} />
                            </Col>
                            <>
                              <span>
                                {answer.isCorrect === true ? (
                                  <p className=" correct-ans"> Correct&nbsp;</p>
                                ) : (
                                  ""
                                )}
                              </span>

                              <>
                                {data?.data.studentAnswerDTO[count]
                                  .answerStatus === "WRONG" &&
                                value?.some((v) => v.value === answer.value) ? (
                                  <p className=" wrong-ans">Incorrect</p>
                                ) : data?.data.studentAnswerDTO[count]
                                    .answerStatus === "CORRECT" &&
                                  value?.some(
                                    (v) => v.value === answer.value
                                  ) ? (
                                  <p className=" correct-ans"> &nbsp;</p>
                                ) : data?.data.studentAnswerDTO[count]
                                    .answerStatus === "SKIPPED" ? (
                                  ""
                                ) : (
                                  ""
                                )}
                              </>
                            </>
                          </Row>
                        </>
                      ))
                  )}
                  <br />
                  <Collapse ghost expandIconPosition="end">
                    <Panel header="Explanation" key="1">
                      <TextViewer
                        textData={
                          data?.data.studentAnswerDTO[count]?.explanation || " "
                        }
                      />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {data?.data.studentAnswerDTO[count]?.questionCategory ===
            `${QuestionTypesConstant.Mcq}` ? (
              <>
                <div>
                  <br />
                  <TextViewer
                    textData={
                      data?.data.studentAnswerDTO[count]?.questionText || " "
                    }
                  />
                </div>
                <br />
                <h3>Answer Options</h3>

                {data?.data.studentAnswerDTO[count]?.answers.map((item: any) =>
                  item?.answerOptions.map((answer: any) => (
                    <Row align={"middle"}>
                      <Col span={1}>{answer.option}</Col>
                      <Col span={1}>
                        <Checkbox
                          id={answer.value}
                          checked={value?.some((v) => v.value === answer.value)}
                        />
                      </Col>
                      <Col span={17}>
                        <TextViewer textData={answer.value || " "} />
                      </Col>
                      <>
                        <span>
                          {answer.isCorrect === true ? (
                            <p className=" correct-ans"> Correct&nbsp;</p>
                          ) : (
                            ""
                          )}
                        </span>

                        <>
                          {data?.data.studentAnswerDTO[count].answerStatus ===
                            "WRONG" &&
                          value?.some((v) => v.value === answer.value) ? (
                            <p className=" wrong-ans">Incorrect</p>
                          ) : data?.data.studentAnswerDTO[count]
                              .answerStatus === "CORRECT" &&
                            value?.some((v) => v.value === answer.value) ? (
                            <p className=" correct-ans"> &nbsp;</p>
                          ) : data?.data.studentAnswerDTO[count]
                              .answerStatus === "SKIPPED" ? (
                            ""
                          ) : (
                            ""
                          )}
                        </>
                      </>
                    </Row>
                  ))
                )}
                <br />
                <Collapse ghost expandIconPosition="end">
                  <Panel header="Explanation" key="1">
                    <TextViewer
                      textData={
                        data?.data.studentAnswerDTO[count]?.explanation || " "
                      }
                    />
                  </Panel>
                </Collapse>
              </>
            ) : (
              " "
            )}
            {data?.data.studentAnswerDTO[count]?.questionCategory ===
            `${QuestionTypesConstant.Numerical}` ? (
              <>
                <div>
                  <br />
                  <TextViewer
                    textData={
                      data?.data.studentAnswerDTO[count]?.questionText || " "
                    }
                  />
                </div>
                <br />
                <Row align={"middle"} justify={"space-evenly"}>
                  <Col span={4}>
                    <h5>Type your answer here</h5>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Input
                        value={
                          data?.data?.studentAnswerDTO[count]
                            ?.studentAnswerOptions[0]?.value
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    {data?.data?.studentAnswerDTO[count]?.answerStatus ===
                      "WRONG" && <p className=" wrong-ans">Incorrect</p>}
                    {data?.data?.studentAnswerDTO[count]?.answerStatus ===
                      "CORRECT" && <p className=" correct-ans">Correct</p>}
                    {data?.data?.studentAnswerDTO[count]?.answerStatus ===
                      "SKIPPED" && <p>Skipped</p>}
                  </Col>
                </Row>
                <br />
                <Collapse ghost expandIconPosition="end">
                  <Panel header="Explanation" key="1">
                    <TextViewer
                      textData={
                        data?.data.studentAnswerDTO[count]?.explanation || " "
                      }
                    />
                  </Panel>
                </Collapse>
              </>
            ) : (
              " "
            )}
          </StudentPanelWhiteContainer>
          <StudentFooter>
            <Row>
              <Col span={12}>
                <Row justify={"start"}>
                  <PrevButton
                    onClick={handleDecrement}
                    disabled={areButtonsDisabled || isPrevDisabled}
                  >
                    Prev
                  </PrevButton>
                </Row>
              </Col>

              <Col span={12}>
                {count === questionLength - 1 ? (
                  <Row justify={"end"}>
                    <Col>
                      <WhiteCloseButton onClick={handleClose}>
                        Close
                      </WhiteCloseButton>
                    </Col>
                  </Row>
                ) : (
                  <Row justify={"end"}>
                    <Col>
                      <NextButton
                        onClick={handleIncrement}
                        disabled={areButtonsDisabled || isNextDisabled}
                      >
                        Next
                      </NextButton>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </StudentFooter>
          {showNotes && (
            <NotesQuill
              onChange={onChange}
              handleSave={handleSave}
              readOnly={false}
              handleCloseNotes={handleCloseNotes}
            ></NotesQuill>
          )}
        </>
      ) : (
        <StudentPanelWhiteContainer>
          <Spin size="large" />
        </StudentPanelWhiteContainer>
      )}
    </>
  );
}

export default ViewAnalyticsQuiz;
