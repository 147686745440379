import { mainApi } from "../../app/services/mainApiSlice";
import { IQuizResponse, IQuizByIdResponse } from "../../types/quizzes";

const commonUrl: string = "/admin/quiz";

const quizzesApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Quiz
    getAllQuiz: builder.query<
      IQuizResponse,
      {
        currentPage: number;
        pageSize: number;
        quizName: string;
        quizMode: string;
        courseName: string;
        quizStatus: string;
      }
    >({
      query: ({
        currentPage,
        pageSize,
        quizName,
        quizMode,
        courseName,
        quizStatus,
      }) => ({
        url: `${commonUrl}/get-all-quiz?courseName=${courseName || ""}&page=${
          currentPage - 1
        }&quizMode=${quizMode || ""}&quizName=${quizName || ""}&quizStatus=${
          quizStatus || ""
        }&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "QUIZZES" }],
    }),

    // getQuizById
    getQuizById: builder.query<IQuizByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-quiz/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "QUIZZES" }],
    }),
    // Update status
    updateStatusById: builder.mutation<IQuizByIdResponse, { id: number }>({
      query: (id) => ({
        url: `${commonUrl}/update-status/${id}?quizStatus=PUBLISHED`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Main", id: "QUIZZES" }],
    }),

    // deleteQuizById
    deleteQuizById: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `${commonUrl}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Main", id: "QUIZZES" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllQuizQuery,
  useGetQuizByIdQuery,
  useDeleteQuizByIdMutation,
  useUpdateStatusByIdMutation,
} = quizzesApi;
