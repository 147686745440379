import { mainApi } from "../../app/services/mainApiSlice";

import {
  IResourceResponse,
  IResourceByIdResponse,
  IResource,
} from "../../types/resources";

const commonUrl: string = "/admin/resource";

const resourcesApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    addResource: builder.mutation<{}, { formData: FormData }>({
      query: ({ formData }) => ({
        url: `${commonUrl}/save-new`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "RESOURCE" }],
    }),

    getAllResources: builder.query<
      IResourceResponse,
      {
        currentPage: number;
        pageSize: number;
        lesson: string;
        name: string;
        resourceType: string;
        section: string;
        topic: string;
      }
    >({
      query: ({
        currentPage,
        pageSize,
        lesson,
        name,
        resourceType,
        section,
        topic,
      }) => ({
        url: `${commonUrl}/get-all-resource?lesson=${lesson || ""}&name=${
          name || ""
        }&page=${currentPage - 1}&resourceType=${resourceType || ""}&section=${
          section || ""
        }&size=${pageSize}&topic=${topic || ""}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "RESOURCE" }],
    }),
    getResourceById: builder.query<IResourceByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-resource/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "RESOURCE" }],
    }),
    updateResourceById: builder.mutation<
      IResource,
      { id: number | undefined; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: `${commonUrl}/update-new/${id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "RESOURCE" }],
    }),
    deleteResourceById: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `${commonUrl}/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Main", id: "RESOURCE" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllResourcesQuery,
  useDeleteResourceByIdMutation,
  useAddResourceMutation,
  useGetResourceByIdQuery,
  useUpdateResourceByIdMutation,
} = resourcesApi;
