import { mainApi } from "../../../app/services/mainApiSlice";
import {
  ITimePunch,
  ITimePunchCourseResponse,
  ITimePunchResponse,
} from "../../../types/tutorPanelTypes/timePunch";

const commonUrl: string = "/tutor/analytics";

const timePunchApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Quiz
    getGroupList: builder.query<ITimePunchResponse, string | null>({
      query: (tutorId) => ({
        url: `${commonUrl}/groupStudent-list/${tutorId}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TIMEPUNCH" }],
    }),
    // Get course and section by id Details
    getCourseDetails: builder.query<
      ITimePunchCourseResponse,
      { id: number | null; tutorId: string | null }
    >({
      query: ({ id, tutorId }) => ({
        url: `${commonUrl}/get-course-details/${id}/${tutorId}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TIMEPUNCH" }],
    }),

    addTimePunch: builder.mutation<ITimePunch, Partial<ITimePunch>>({
      query: (data: ITimePunch) => ({
        url: `${commonUrl}/save/timePunch`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Main", id: "TIMEPUNCH" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGroupListQuery,
  useGetCourseDetailsQuery,
  useAddTimePunchMutation,
} = timePunchApi;
