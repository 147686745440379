import { Col, Row, Form } from "antd";
import { useNavigate } from "react-router-dom";
import CourseForm from "../../components/course/CourseForm";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import ContainerHeading from "../../components/styled/ContainerHeading.styled";
import FormContainer from "../../components/styled/FormContainer.styled";
import { PrimaryButton } from "../../components/styled/Button.styled";
import { useAppSelector } from "../../app/hooks";
import Breadcrumbs from "../../components/common/Breadcrumbs";

function AddCourse() {
  const navigate = useNavigate();

  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );
  const onClose = () => {
    navigate(-1);
  };

  return (
    <>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { mainForm } = forms;
            mainForm.submit();
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>Save</PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <ContainerHeading>Add Course</ContainerHeading>
          <WhiteContainer>
            <FormContainer>
              <CourseForm />
            </FormContainer>
          </WhiteContainer>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default AddCourse;
