import secureLocalStorage from "react-secure-storage";

const name = secureLocalStorage.getItem("name");
const role = secureLocalStorage.getItem("role");
const idFromLocalStorage = secureLocalStorage.getItem("id") as
  | string
  | null;
const token = secureLocalStorage.getItem("token");

export { name, role, idFromLocalStorage, token };
