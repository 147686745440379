import { Col, Input, Row, Form, notification, InputNumber } from "antd";
import type { ISection, ISectionByIdResponse } from "../../types/section";
import CourseOptions from "../selectFormOptions/CourseOptions";
import SectionTypeOptions from "../selectFormOptions/SelectTypeOptions";
import {
  useAddSectionMutation,
  useUpdateSectionByIdMutation,
} from "../../features/internalCourse/sectionICApiSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../features/ui/uiSlice";
import SelectCourseOptions from "../selectFormOptions/SelectCourseOptions";

type SectionFormProps = {
  data?: ISectionByIdResponse;
};

function SectionForm(props: SectionFormProps) {
  const { data } = props;
  const navigate = useNavigate();
  const [addSection, { isLoading: isLoadingAdd }] = useAddSectionMutation();
  const [updateSectionById, { isLoading: isLoadingEdit }] =
    useUpdateSectionByIdMutation();
  const dispatch = useDispatch();
  // set save button loading state
  dispatch(
    setButtonDisabled(isLoadingAdd === true ? isLoadingAdd : isLoadingEdit)
  );
  const onFinish = async (values: ISection) => {
    if (data) {
      await updateSectionById({
        id: data?.data.id,
        post: {
          ...values,
        },
      })
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully edited",
            placement: "bottomLeft",
          });
          navigate(-1);
        })
        .catch((error) =>
          notification.error({
            message: "Error",
            description: error.data.message,
            placement: "bottomLeft",
          })
        );
    } else {
      await addSection({
        ...values,
      })
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully added",
            placement: "bottomLeft",
          });
          navigate(-1);
        })
        .catch((error) =>
          notification.error({
            message: "Error",
            description: error.data.message,
            placement: "bottomLeft",
          })
        );
    }
  };

  const initialValues = {
    name: data?.data.name,
    duration: data?.data.duration,
    courseId: data?.data.courseId,
    sectionType: "New Section",
    courseName: data?.data.courseName,
  };
  return (
    <>
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        name="mainSectionForm"
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <SectionTypeOptions
              label="Section Type"
              name="sectionType"
              rules={[
                { required: true, message: "Please select section type" },
              ]}
            />
          </Col>
          <Col span={12}>
            <SelectCourseOptions
              label="Course"
              name="courseId"
              rules={[{ required: true, message: "Please select course" }]}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              label="Section Name"
              name="name"
              rules={[{ required: true, message: "Please enter section name" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Duration(in Hrs.)"
              name="duration"
              rules={[{ required: true, message: "Please enter duration" }]}
            >
              <InputNumber className="w-100" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default SectionForm;
