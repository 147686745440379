import { Col, Row, Form, Input, Radio, DatePicker, notification } from "antd";
import PhoneInput from "react-phone-number-input";
import { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import TextArea from "antd/es/input/TextArea";
import AssignCourse from "./AssignCourse";
import { ITutorByIdResponse } from "../../../types/tutor";
import dayjs from "dayjs";
import { useAddImageMutation } from "../../../features/tutor/tutorApiSlice";
import { TagsInput } from "react-tag-input-component";
import { GCP_URL } from "../../../constants/commonUtils";
import ProfileImageUploader from "../../../components/uploader/ProfileImageUploader";

type TutorPersonalDetailsProps = {
  data?: ITutorByIdResponse;
  setAssignCoursesId: React.Dispatch<React.SetStateAction<React.Key[]>>;
  setQualification: React.Dispatch<React.SetStateAction<string[]>>;
  qualification: string[];
  setProfilePicUpload?: React.Dispatch<React.SetStateAction<FormData>>;
};

function PersonalDetails(props: TutorPersonalDetailsProps) {
  const {
    data,
    setAssignCoursesId,
    qualification,
    setQualification,
    setProfilePicUpload,
  } = props;
  const [addImage] = useAddImageMutation();
  const [values, setValues] = useState();
  const [forQualification, setForQualification] = useState<string[]>([]);
  const [img, setImg] = useState({
    image_file: null,
    image_preview: "",
  });
  const [profilePicForm, setProfilePicForm] = useState<FormData>(
    new FormData()
  );

  const handleValue = () => {
    setValues(values);
  };

  useEffect(() => {
    const imageUrl = GCP_URL + `${data?.data?.imageUrl}`;
    setImg({
      image_preview: imageUrl,
      image_file: null,
    });
    setQualification(data?.data?.qualification || []);
  }, [data]);

  useEffect(() => {
    if (profilePicForm.has("image")) {
      addImage({ id: data?.data.id, formData: profilePicForm })
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully uploaded",
            placement: "bottomLeft",
          });
        });
    }
  }, [profilePicForm]);

  const handleValueQualification = (e: any) => {
    setForQualification(forQualification);
    setQualification(e);
  };
  return (
    <>
      <Form
        layout="vertical"
        name="personalDetailsForm"
        initialValues={{
          name: data?.data.name,
          gender: data?.data.gender,
          phoneNumber: data?.data.phoneNumber,
          email: data?.data.email,
          dateOfJoining: dayjs(data?.data.dateOfJoining),
          taxNumber: data?.data.taxNumber,
          qualification: data?.data.qualification,
          address: data?.data.address,
          image: data?.data.imageUrl,
          ...data?.data.tutorSectionDTO.reduce(
            (acc, item) => ({
              [`groupCost-${item.course[0].id}`]: item.groupCost,
              [`individualCost-${item.course[0].id}`]: item.individualCost,
              ...acc,
            }),
            {}
          ),
        }}
      >
        <Row gutter={[32, 22]}>
          <Col span={12}>
            <Row gutter={[32, 22]}>
              <Col span={24}>
                <Row align="middle">
                  <ProfileImageUploader
                    src={img.image_preview}
                    type={"file"}
                    name={"image"}
                    id={"file"}
                    setImg={setImg}
                    setProfilePicForm={
                      data ? setProfilePicForm : setProfilePicUpload
                    }
                  />
                </Row>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Tutor Name"
                  name="name"
                  rules={[{ required: true, message: "Please enter name" }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select gender",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="MALE">Male</Radio>
                    <Radio value="FEMALE">Female</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number",
                    },
                  ]}
                >
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={values}
                    onChange={handleValue}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="EmailID"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Input is not a valid email",
                    },
                    {
                      required: true,
                      message: "Please enter your email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[32, 22]}>
              <Col span={24}>
                <Form.Item
                  label="Date of Joining"
                  name="dateOfJoining"
                  rules={[
                    { required: true, message: "Please enter date of joining" },
                  ]}
                >
                  <DatePicker className="w-100" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Tax Number."
                  name="taxNumber"
                  rules={[
                    { required: true, message: "Please enter tax number" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <label>Qualification</label>
                <TagsInput
                  name="qualification"
                  value={qualification}
                  onChange={(e) => handleValueQualification(e)}
                />
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[{ required: true, message: "Please enter address" }]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Col span={24}>
          <Form.Item>
            <p>Assign courses</p>
            <AssignCourse
              courseData={data}
              setAssignCoursesId={setAssignCoursesId}
            />
          </Form.Item>
        </Col>
      </Form>
    </>
  );
}

export default PersonalDetails;
