import { useGetTutorQuizByQuizIdQuery } from "../../../features/tutorPanelFeatures/TutorQuizzes/tutorQuizApiSlice";
import { useGetTutorStudentByIdQuery } from "../../../features/tutorPanelFeatures/TutorStudent/tutorStudentsApiSlice";
import ViewAnalyticsQuizScreen from "../../../components/analytics/ViewAnalyticsQuizScreen";
import { useParams } from "react-router-dom";

function ViewAnalyticsQuizDetails() {
  const { id: quizId, id: studentId } = useParams();
  const { data } = useGetTutorQuizByQuizIdQuery(quizId);
  const { data: studentData } = useGetTutorStudentByIdQuery(studentId);

  return (
    <>
      <ViewAnalyticsQuizScreen data={data} studentData={studentData} />
    </>
  );
}

export default ViewAnalyticsQuizDetails;
