import { Col, Row, DatePicker, Select } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../../components/styled/Container.styled";
import ContainerHeading from "../../../components/styled/ContainerHeading.styled";
import { useGetStudentTopicProgressAnalyticsQuery } from "../../../features/tutorPanelFeatures/TutorAnalytics/tutorAnalyticsApiSlice";
import { useGetTutorStudentByIdQuery } from "../../../features/tutorPanelFeatures/TutorStudent/tutorStudentsApiSlice";
import dayjs, { Dayjs } from "dayjs";
import { ICourse } from "../../../types/course";
import DayProgressTabsPropsType from "../../../components/analytics/DayProgressTabs";
import { IStudentAnalytics } from "../../../types/studentPanelTypes/studentAnalytics";

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;
const { Option } = Select;

function TopicWiseProgress() {
  const dateFormat = "YYYY-MM-DD";
  const currentDate = new Date(Date.now());
  const [endDate, setEndDate] = useState(dayjs(currentDate).format(dateFormat));
  const [startDate, setStartDate] = useState(
    dayjs(currentDate.setDate(currentDate.getDate() - 7)).format(dateFormat)
  );
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);

  const { studentId } = useParams();
  const { data } = useGetTutorStudentByIdQuery(studentId);
  const [courseId, setCourseId] = useState<number>();

  const { data: studentTopicData } = useGetStudentTopicProgressAnalyticsQuery({
    courseId,
    studentId,
    endDate,
    startDate,
  });

  const handleOnChange = (e: any) => {
    setStartDate(dayjs(e[0]).format(dateFormat));
    setEndDate(dayjs(e[1]).format(dateFormat));
  };
  const filteredObjectsWithDay: IStudentAnalytics[] = [];
  studentTopicData?.data?.map((obj1) =>
    filteredObjectsWithDay.push({ ...obj1, day: dayjs(obj1.dateTime).day() })
  );
  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") >= 7;
    const tooEarly = dates[1] && dates[1].diff(current, "days") >= 7;
    return !!tooEarly || !!tooLate;
  };
  return (
    <LightGrayContainer>
      <Row justify={"space-between"} align={"middle"}>
        <Col>
          <ContainerHeading>{data?.data?.name}</ContainerHeading>
        </Col>
        <Col span={5}>
          <Select
            className="w-100"
            onChange={(e) => setCourseId(e)}
            placeholder="Select Course"
          >
            {data?.data?.assignCourse?.map((course: ICourse, index) => (
              <Option key={index} value={course?.id}>
                {course?.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <RangePicker
            defaultValue={[
              dayjs(startDate, dateFormat),
              dayjs(endDate, dateFormat),
            ]}
            onChange={(e) => handleOnChange(e)}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            format={dateFormat}
          />
        </Col>
      </Row>
      <WhiteContainer>
        <DayProgressTabsPropsType
          filteredObjectsWithDay={filteredObjectsWithDay}
        />
      </WhiteContainer>
    </LightGrayContainer>
  );
}

export default TopicWiseProgress;
