import { Button, Modal, Tooltip } from "antd";
import blackBook from "../../assets/customIcons/blackBook.png";
import { useParams } from "react-router-dom";
import { useSaveBlackBookMutation } from "../../features/studentPanelFeatures/StudentBlackBook/blackBookApiSlice";
import BlackBookPopUpContent from "./BlackBookPopUpContent";
import secureLocalStorage from "react-secure-storage";

function BookMark() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const [saveBlackBook] = useSaveBlackBookMutation();
  const { resourceId, topicId } = useParams();
  const handleOnClick = async () => {
    await saveBlackBook({
      studentId: idFromLocalStorage,
      resourceId: resourceId,
      topicId: topicId,
    })
      .unwrap()
      .then((res) => {
        Modal.success({
          content: <BlackBookPopUpContent res={res} />,
          icon: null,
          maskClosable: true,
          closable: true,
          centered: true,
          bodyStyle: {
            justifyContent: "center",
            alignItems: "center",
          },
          footer: null,
        });
      });
  };
  return (
    <>
      <Tooltip placement="top" title="Bookmark">
        <Button ghost className="p-0" onClick={handleOnClick}>
          <img src={blackBook} alt="BookMark" />
        </Button>
      </Tooltip>
    </>
  );
}

export default BookMark;
