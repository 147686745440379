import { Form, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Rule } from "rc-field-form/lib/interface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useGetCourseDetailsQuery } from "../../../features/tutorPanelFeatures/TimePunch/timePunchApiSlice";
import secureLocalStorage from "react-secure-storage";

const { Option } = Select;

type ICourseOptionsProps = {
  label: string;
  name: string | any;
  rules?: Rule[];
  form: FormInstance;
};

function SelectCourseOptions(props: ICourseOptionsProps) {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const id = useAppSelector((state: RootState) => state.ui.selectedGroup);
  const tutorId = idFromLocalStorage;
  const { data } = useGetCourseDetailsQuery({
    id,
    tutorId,
  });

  return (
    <Form.Item {...props}>
      <Select>
        {data?.data.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.courseName}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectCourseOptions;
