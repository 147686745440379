import { Col, Collapse, Row, Tag, Tooltip } from "antd";
import "react-quill/dist/quill.snow.css";
import { IQuestionBank } from "../../../types/questionBank";
import { IQuizByIdResponse } from "../../../types/quizzes";
import TextViewer from "../../../components/preview/TextViewer";
import { ITutorQuizByIdResponse } from "../../../types/tutorPanelTypes/tutorQuiz";

type QuizQuestionFormProps = {
  data?: ITutorQuizByIdResponse;
};

function ViewQuizQuestion(props: QuizQuestionFormProps) {
  const { Panel } = Collapse;
  const { data } = props;

  return (
    <Col span={24}>
      {data?.data.questions.map((item: IQuestionBank, index) => (
        <Collapse ghost expandIconPosition="end">
          <Panel
            key={index}
            header={
              <Row align={"middle"}>
                <Col>
                  <span>{index + 1}</span>
                </Col>
                <Col span={23}>
                  <TextViewer textData={item.questionText} />
                </Col>
              </Row>
            }
          >
            <Row gutter={[10, 10]}>
              <Col>
                <Tooltip title="Question Id">
                  <Tag>{item.questionNumberSeq} </Tag>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title="Course">
                  <Tag> {item.courseName} </Tag>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title="Section ">
                  <Tag> {data.data.sections[0].name}</Tag>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title="Question Type">
                  <Tag> {data?.data.topics[0].lessonName}</Tag>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title="Question Category">
                  <Tag> {item.questionCategory}</Tag>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title="Difficulty Level">
                  <Tag> {item.difficultyLevel}</Tag>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title="Topic ">
                  <Tag> {data?.data.topics[0].name}</Tag>
                </Tooltip>
              </Col>
            </Row>

            {item.questionCategory === "READING_COMPREHENSION" ? (
              <div>
                <h5>Passage</h5>
                <TextViewer textData={item.passage || ""} />
              </div>
            ) : (
              ""
            )}

            <h5>Answer Option</h5>
            {item.answers[0].answerOptions.map((answer, index) => (
              <Row key={index} className="mb-1" align={"middle"}>
                <Col span={2}>{answer.option}</Col>
                <Col span={16}>{<TextViewer textData={answer.value} />}</Col>
                <Col span={6} className="text-primary">
                  {answer.isCorrect && "Marked as Correct Answer"}
                </Col>
              </Row>
            ))}

            <h5>Explanation</h5>

            <TextViewer textData={item.explanation} />
          </Panel>
        </Collapse>
      ))}
    </Col>
  );
}

export default ViewQuizQuestion;
