import { Col, Form, notification, Row } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import { useAddStudentQuizMutation } from "../../../features/studentPanelFeatures/StudentQuiz/studentQuizzes";
import CreateCustomQuiz from "./CreateCustomQuiz";
import { useAppSelector } from "../../../app/hooks";
import { useDispatch } from "react-redux";
import {
  setButtonDisabled,
  setStudentBreadcrumb,
} from "../../../features/ui/uiSlice";

function AddQuiz() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const [addStudentQuiz, { isLoading }] = useAddStudentQuizMutation();
  const studentId = idFromLocalStorage;
  const { courseId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  dispatch(setButtonDisabled(isLoading));
  dispatch(
    setStudentBreadcrumb([
      `${pathname.split("/")[3]}/${pathname.split("/")[4]}`,
    ])
  );

  const isButtonLoading = useAppSelector((state) => state.ui.buttonDisabled);

  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { mainForm } = forms;
            mainForm.validateFields().then(() => {
              let quizData = { ...mainForm.getFieldsValue() };
              let obj = {
                ...quizData,
                studentId,
                allQuestions: quizData.usedQuestion === "allQuestion",
                unUsedQuestions: quizData.usedQuestion === "unusedQuestion",
                courses: [{ id: courseId }],
                sections: [{ id: quizData.sections }],
                lessons: [{ id: quizData.questionTypeId }],
                topics: quizData.topicIds.map((id: number) => ({ id })),
                topicId: quizData.topicIds.map((id: number) => ({ id })),
                status: true,
              };             

              addStudentQuiz(obj)
                .unwrap()
                .then(() => {
                  notification.success({
                    message: "Successfully Saved!",
                    placement: "bottomLeft",
                  });
                  navigate(-1);
                })
                .catch((error) =>
                  notification.error({
                    message: "Error!",
                    description: error.data.message,
                    placement: "bottomLeft",
                  })
                );
            });
          }
        }}
      >
        <CreateCustomQuiz />

        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col span={24}>
            <Row justify={"center"}>
              <Form name="submitForm">
                <PrimaryButton htmlType="submit" loading={isButtonLoading}>
                  Create Quiz
                </PrimaryButton>
              </Form>
            </Row>
          </Col>
        </Row>
      </Form.Provider>
    </>
  );
}

export default AddQuiz;
