import { Button, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Typography } from "antd";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type PdfFileViewerProps = {
  uri: string;
};

function PdfFileViewer(props: PdfFileViewerProps) {
  const { uri } = props;
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [width, setWidth] = useState(0);
  const { Text } = Typography;

  useEffect(() => {
    setWidth(window.innerWidth);
  });

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };
  const loader = () => {
    return <Spin tip="Loading" size="large" />;
  };
  return (
    <div>
      <Row>
        <Col span={24}>
          <Row className="pdf-page-number">
            <Text>
              Page <strong>{pageNumber || (numPages ? 1 : "--")}</strong> of{" "}
              {numPages || "--"}
            </Text>
          </Row>
        </Col>

        <Col span={24}>
          <Row className="pdf-navigation-buttons">
            <Button
              disabled={pageNumber <= 1}
              onClick={previousPage}
              type="text"
            >
              <strong> &#x1F818;</strong>
            </Button>
            <Button
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              type="text"
            >
              <strong> &#x1F81A;</strong>
            </Button>
          </Row>
        </Col>
      </Row>
      <Row className="pdf-page-border">
        <Document
          file={uri}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={loader}
        >
          <Page
            pageNumber={pageNumber}
            scale={width > 786 ? 1.1 : 0.7}
            loading={loader}
          />
        </Document>
      </Row>
    </div>
  );
}

export default PdfFileViewer;
