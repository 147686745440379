import { Col, notification } from "antd";
import { colorPrimary } from "../../constants/theme";
import dummyface from "../../assets/dummyFace.png";
import { useEffect, useState } from "react";

type ImageUploaderProps = {
  src?: string;
  name?: string;
  id?: string;
  type?: string;
  imageHandler?: any;
  setImg?: any;
  setProfilePicForm?: any;
};
const imageUploaderStyle = {
  width: "6rem",
  height: "6rem",
  borderRadius: "50%",
  backgroundColor: "white",
};
const inputFileStyle = {
  display: "none",
};
const inputFileLabelStyle = {
  fontSize: "1rem",
  color: colorPrimary,
  cursor: "pointer",
};

function ProfileImageUploader(props: ImageUploaderProps) {
  const { src, type, id, name, setImg, setProfilePicForm } = props;
  const [imageSrc, setImageSrc] = useState<string | undefined>("");
  const imageHandler = (e: any) => {
    const newData = new FormData();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    let fileType = e.target.files[0].type;

    const acceptedImagesTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (!acceptedImagesTypes.includes(fileType || "")) {
      notification.error({
        message: "Please upload image only",
        placement: "bottomLeft",
      });
      return;
    }

    newData.append("image", e.target.files[0]);
    setProfilePicForm(newData);
    setImg({
      image_preview: image_as_base64,
      image_file: image_as_files,
    });
  };

  useEffect(() => {
    if (src?.includes("undefined") || src?.includes("null")) {
      setImageSrc(dummyface);
    } else {
      setImageSrc(src);
    }
  }, [src]);

  return (
    <>
      <Col span={6}>
        <img src={imageSrc} style={imageUploaderStyle} alt="profile-pic" />
      </Col>
      <Col>
        <input
          type={type}
          id={id}
          style={inputFileStyle}
          onChange={imageHandler}
          name={name}
        />
        <label htmlFor={id} style={inputFileLabelStyle}>
          Upload Profile Picture
        </label>
      </Col>
    </>
  );
}

export default ProfileImageUploader;
