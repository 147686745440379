import { Col, Row, Spin } from "antd";
import { useGetAllDashboardDataQuery } from "../features/dashboard/dashboardApiSlice";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import DashboardCustomCard from "../components/dashboardCard/DashboardCustomCard";

// icons
import studentHatIcon from "../assets/dashboardIcons/dashboard-studentHatIcon.png";
import courseIcon from "../assets/dashboardIcons/dashboard-bookIcon.png";
import tutorIcon from "../assets/dashboardIcons/dashboard-TutorIcon.png";
import groupIcon from "../assets/dashboardIcons/dashboard-groupIcon.png";

function Dashboard() {
  const { data, refetch } = useGetAllDashboardDataQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <>
      {
        !!data ?
          <Row gutter={[8, 16]}>
            <Col>
              <Link to={"/students"}>
                <DashboardCustomCard
                  heading={"Students"}
                  subText={"New Student"}
                  cardIcon={
                    <img
                      src={studentHatIcon}
                      className="dashboard-icons-style"
                      alt="student-hat-icon"
                    />
                  }
                  newCount={data?.data?.studentCount?.newStudent}
                  totalCount={data?.data?.studentCount?.totalStudentCount}
                  internalCount={
                    data?.data?.studentCount.internalStudentCount +
                    " Internal Students"
                  }
                />
              </Link>
            </Col>

            <Col>
              <Link to={"/internal-courses"}>
                <DashboardCustomCard
                  heading={"Courses"}
                  subText={"New Course"}
                  cardIcon={
                    <img
                      src={courseIcon}
                      className="dashboard-icons-style"
                      alt="course-icon"
                    />
                  }
                  newCount={data?.data?.courseCount?.newCourse}
                  totalCount={data?.data?.courseCount?.totalCourseCount}
                  internalCount={
                    data?.data?.courseCount?.internalCourseCount +
                    " Internal Courses"
                  }
                />
              </Link>
            </Col>

            <Col>
              <Link to={"/tutors"}>
                <DashboardCustomCard
                  heading={"Tutors"}
                  subText={"New Tutor"}
                  cardIcon={
                    <img
                      src={tutorIcon}
                      className="dashboard-icons-style"
                      alt="tutor-icon"
                    />
                  }
                  newCount={data?.data?.tutorCount?.newTutor}
                  totalCount={data?.data?.tutorCount?.totalTutorCount}
                />
              </Link>
            </Col>

            <Col>
              <Link to={"/groups"}>
                <DashboardCustomCard
                  heading={"Groups"}
                  cardIcon={
                    <img
                      src={groupIcon}
                      className="dashboard-icons-style"
                      alt="group-icon"
                    />
                  }
                  totalCount={data?.data?.groupCount?.totalGroupCount}
                  individualCount={
                    data?.data?.groupCount?.individualCount + " Individual"
                  }
                  groupCount={data?.data?.groupCount?.groupCount + " Groups"}
                />
              </Link>
            </Col>
          </Row> :
          <Spin size="large" />
      }
    </>
  );
}

export default Dashboard;
