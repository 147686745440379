import { Tabs, Typography, Input, Progress, Row, Select } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { ViewAnalyticsButton } from "../../components/styled/Button.styled";
import {
  DarkGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import pageSize from "../../constants/pageSize";
import { useGetAllGroupQuery } from "../../features/group/groupApiSlice";
import { useGetAllStudentsQuery } from "../../features/student/studentsApiSlice";
import { useGetAllTutorQuery } from "../../features/tutor/tutorApiSlice";
import { ICourse } from "../../types/course";
import { IGroup } from "../../types/group";
import { ISection } from "../../types/section";
import { IStudent } from "../../types/studentType";
import { ITutor } from "../../types/tutor";
import { commonStrokeColor } from "../../constants/commonColors";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../utils/useDebouncingValue";
import GroupRowCollapsible from "./GroupRowCollapsible";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

function Analytics() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const [studentName, setStudentName] = useState("");
  const [phoneNumberTutor, setPhoneNumberTutor] = useState("");
  const [emailIdTutor, setEmailIdTutor] = useState("");
  const [course, setCourse] = useState("");
  const [typeStudent, setTypeStudent] = useState("INTERNAL");
  const [phoneNumberStudent, setPhoneNumberStudent] = useState("");
  const [studentEmailId, setStudentEmailId] = useState("");
  const [studentCount, setStudentCount] = useState("");
  const [sections, setSections] = useState("");
  const [name, setName] = useState("");
  const [assignStudent, setAssignStudent] = useState("");
  const [groupName, setGroupName] = useState("");

  // for tutor
  const { debouncedValue: tutorPhone, debouncing: phoneNumberTutorSearching } =
    useDebouncingValue(phoneNumberTutor);
  const { debouncedValue: tutorEmail, debouncing: emailIdTutorSearching } =
    useDebouncingValue(emailIdTutor);
  const { debouncedValue: tutorName, debouncing: tutorSearching } =
    useDebouncingValue(name);

  // for students
  const { debouncedValue: student, debouncing: studentSearching } =
    useDebouncingValue(studentName);
  const { debouncedValue: phoneStudent, debouncing: phoneStudentSearching } =
    useDebouncingValue(phoneNumberStudent);
  const { debouncedValue: studentEmail, debouncing: studentEmailSearching } =
    useDebouncingValue(studentEmailId);

  // for group
  const { debouncedValue: groupSearch, debouncing: groupSearching } =
    useDebouncingValue(groupName);
  const { debouncedValue: groupStudents, debouncing: studentCountSearching } =
    useDebouncingValue(studentCount);
  const { debouncedValue: groupCourse, debouncing: groupCourseSearching } =
    useDebouncingValue(course);
  const { debouncedValue: groupSection, debouncing: groupSectionSearching } =
    useDebouncingValue(sections);

  const {
    data: studentdata,
    isLoading: isStudentLoading,
    isFetching: isStudentFetching,
  } = useGetAllStudentsQuery({
    currentPage,
    pageSize,
    name: student,
    studentType: typeStudent,
    email: studentEmail,
    phoneNumber: phoneStudent,
  });

  const {
    data: tutorData,
    isLoading: isTutorLoading,
    isFetching: isTutorFetching,
  } = useGetAllTutorQuery({
    currentPage,
    pageSize,
    name: tutorName,
    phoneNumber: tutorPhone,
    email: tutorEmail,
  });

  // for group search
  const {
    data: groupData,
    isLoading: isGroupLoading,
    isFetching: isGroupFetching,
  } = useGetAllGroupQuery({
    currentPage,
    pageSize,
    course: groupCourse,
    name: groupSearch,
    section: groupSection,
    students: groupStudents,
  });

  const columnsTutor: ColumnsType<ITutor> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Tutor Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setName(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "phoneNumber",
      title: (
        <div>
          <Typography.Text>Phone Number</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setPhoneNumberTutor(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),

      dataIndex: "phoneNumber",
    },
    {
      key: "email",
      title: (
        <div>
          <Typography.Text>Email Id</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setEmailIdTutor(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),

      dataIndex: "email",
    },

    {
      key: "assignStudent",
      title: (
        <div>
          <Typography.Text>Overall Progress</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setAssignStudent(e.target.value);
            }}
            disabled
            placeholder="Search here"
          />
        </div>
      ),

      render: (_, record) => (
        <div style={{ width: 170 }}>
          <Progress
            strokeColor={commonStrokeColor}
            percent={Math.round(
              (record.tutorProgress / 60 / record.courseProgress) * 100
            )}
            size="small"
            format={() =>
              `${parseFloat((record.tutorProgress / 60).toString()).toFixed(
                0
              )}/${record.courseProgress} Hrs`
            }
          />
        </div>
      ),
      dataIndex: "assignStudent",
    },

    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewAnalyticsButton
          onClick={() => openTutorDetails(record.id)}
        ></ViewAnalyticsButton>
      ),
    },
  ];

  const openTutorDetails = (id: number) => {
    navigate(`/analytics/viewtutor/${id}`);
  };

  const columnsStudent: ColumnsType<IStudent> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Student Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setStudentName(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "phoneNumber",
      title: (
        <div>
          <Typography.Text>Phone Number</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setPhoneNumberStudent(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "phoneNumber",
    },
    {
      key: "email",
      title: (
        <div>
          <Typography.Text>Email Id</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setStudentEmailId(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "email",
    },

    {
      key: "type",
      width: 50,
      title: (
        <div>
          <Typography.Text> Student Type</Typography.Text>
          <Select value={"INTERNAL"} className="searchDropdownStyle" disabled />
        </div>
      ),
      dataIndex: "studentType",
    },
    {
      key: "active",
      width: 200,
      render: (_, record) => (
        <div>
          <Progress
            strokeColor={commonStrokeColor}
            percent={Math.round(
              (record.tutorProgress / 60 / record.courseProgress) * 100
            )}
            size="small"
            format={() =>
              `${parseFloat((record.tutorProgress / 60).toString()).toFixed(
                0
              )}/${record.courseProgress} Hrs`
            }
          />
        </div>
      ),
    },
    { width: 50 },
    {
      fixed: "right",
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewAnalyticsButton
          onClick={() => openStudentDetails(record.id)}
        ></ViewAnalyticsButton>
      ),
    },
  ];
  const openStudentDetails = (id: number) => {
    navigate(`/analytics/viewstudent/${id}`);
  };

  const columnsGroup: ColumnsType<IGroup> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Group Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setGroupName(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },

    {
      key: "studentCount",
      title: (
        <div>
          <Typography.Text>No. of Students</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setStudentCount(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),

      dataIndex: "studentCount",
    },

    {
      key: "courses",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourse(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),

      render: (courses: ICourse[]) =>
        courses.map((course: ICourse) => course.name).join(", "),

      dataIndex: "courses",
    },
    {
      key: "sections",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSections(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "sections",
      render: (sections: ISection[]) =>
        sections.map((section: ISection) => section.name).join(", "),
    },
    {
      key: "active",
      fixed: "right",
      width: 150,
      dataIndex: "Active",
      render: (_, record) => (
        <div style={{ width: 110 }}>
          <Progress
            strokeColor={commonStrokeColor}
            percent={Math.round(
              (record.tutorProgress / 60 / record.courseProgress) * 100
            )}
            size="small"
            format={() =>
              `${parseFloat((record.tutorProgress / 60).toString()).toFixed(
                0
              )}/${record.courseProgress} Hrs`
            }
          />
        </div>
      ),
    },
  ];

  const tabItems = [
    {
      key: "1",
      label: "Tutor",
      children: (
        <WhiteContainer>
          <Table<ITutor>
            loading={
              isTutorLoading ||
              emailIdTutorSearching ||
              phoneNumberTutorSearching ||
              tutorSearching ||
              isTutorFetching
            }
            rowKey="id"
            columns={columnsTutor}
            dataSource={tutorData?.data.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: tutorData?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </WhiteContainer>
      ),
    },
    {
      key: "2",
      label: "Student",
      children: (
        <WhiteContainer>
          <Table<IStudent>
            scroll={{ x: "max-content" }}
            loading={
              isStudentFetching ||
              isStudentLoading ||
              studentSearching ||
              phoneStudentSearching ||
              studentEmailSearching
            }
            rowKey="id"
            columns={columnsStudent}
            dataSource={studentdata?.data?.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: studentdata?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </WhiteContainer>
      ),
    },
    {
      key: "3",
      label: "Groups",
      children: (
        <WhiteContainer>
          <Table<IGroup>
            scroll={{ x: "max-content" }}
            loading={
              isGroupFetching ||
              isGroupLoading ||
              groupSearching ||
              studentCountSearching ||
              groupCourseSearching ||
              groupSectionSearching
            }
            rowKey="id"
            columns={columnsGroup}
            dataSource={groupData?.data?.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: groupData?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
            expandable={{
              expandedRowRender: (record) => (
                <GroupRowCollapsible data={record.students} />
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <UpOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                  <DownOutlined onClick={(e) => onExpand(record, e)} />
                ),
              expandRowByClick: true,
            }}
          />
        </WhiteContainer>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <DarkGrayContainer>
        <Tabs defaultActiveKey="1" items={tabItems} />
      </DarkGrayContainer>
    </>
  );
}

export default Analytics;
