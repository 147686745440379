import { Col, Form, notification, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../components/styled/Button.styled";
import ContainerHeading from "../../components/styled/ContainerHeading.styled";
import FormContainer from "../../components/styled/FormContainer.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import {
  useGetAdminByIdQuery,
  useUpdateAdminByIdMutation,
} from "../../features/Profile/adminProfileApiSlice";
import AdminDetails from "./AdminDetails";
import secureLocalStorage from "react-secure-storage";

function AdminProfile() {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const { data, isError, isLoading } = useGetAdminByIdQuery(idFromLocalStorage);
  const [updateAdminById] = useUpdateAdminByIdMutation();
  const navigate = useNavigate();
  const onClose = () => {
    navigate(-1);
  };

  return (
    <Form.Provider
      onFormFinish={(name, { forms }) => {
        if (name === "submitForm") {
          const { personalDetailsForm } = forms;
          personalDetailsForm.submit();

          let adminData = { ...personalDetailsForm.getFieldsValue() };

          let obj = {
            id: data?.data.id,
            ...adminData,
            status: true,
          };
          updateAdminById({ id: data?.data.id, post: obj })
            .unwrap()
            .then(() => {
              notification.success({
                message: "Successfully edited",
                placement: "bottomLeft",
              });
              navigate("/administrator-profile");
            });
        }
      }}
    >
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <Form name="submitForm">
            <PrimaryButton htmlType="submit">Save</PrimaryButton>
          </Form>
        </Col>
        <Col>
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </Col>
      </Row>
      <LightGrayContainer>
        <ContainerHeading>Administrator Profile</ContainerHeading>
        <WhiteContainer>
          <FormContainer>
            {!isError && !isLoading && data && <AdminDetails data={data} />}
          </FormContainer>
        </WhiteContainer>
      </LightGrayContainer>
    </Form.Provider>
  );
}

export default AdminProfile;
