import { Tabs } from "antd";
import { ITutorAnalyticsStudentWiseProgressResponse } from "../../types/tutorPanelTypes/tutorAnalytics";
import ChildrenTableComponent from "./ChildrenTableComponent";

type GroupWiseProgressPropsType = {
  data: ITutorAnalyticsStudentWiseProgressResponse | undefined;
};

function GroupWiseProgress({ data }: GroupWiseProgressPropsType) {
  const filteredDataGroupDTO = data?.data?.groupDTO?.map((group, index) => ({
    key: `${index}`,
    label: `${group.name}`,
    children: (
      <ChildrenTableComponent
        data={group.students}
        groupCourseName={group.courseName}
        tutorProgress={parseFloat(((group.tutorProgress / group.courseProgress) * 100).toFixed(2))}
      />
    ),
  }));

  filteredDataGroupDTO?.push({
    key: `${filteredDataGroupDTO.length + 1}`,
    label: "Individuals",
    children: <ChildrenTableComponent data={data?.data?.studentDTO} />,
  });

  return <Tabs defaultActiveKey="1" items={filteredDataGroupDTO} />;
}

export default GroupWiseProgress;
