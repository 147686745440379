import { useState, useEffect } from "react";
import { Row, Col, Dropdown, Space, MenuProps, notification } from "antd";
import { useNavigate } from "react-router-dom";
import {
  MdKeyboardArrowDown,
  MdPermIdentity,
  MdOutlineLogout,
  MdNotifications,
  MdMoreTime,
} from "react-icons/md";
import { useGetAdminByIdQuery } from "../../../features/Profile/adminProfileApiSlice";
import { useGetTutorProfileQuery } from "../../../features/tutorPanelFeatures/TutorProfile/tutorProfileSlice";
import styled from "styled-components";
import { GCP_URL } from "../../../constants/commonUtils";
import { IAdminProfileByIdResponse } from "../../../types/profileAdmin";
import { ITutorByIdResponse } from "../../../types/tutor";

import { IStudentsByIdResponse } from "../../../types/studentPanelType/profileStudent";
import { useGetStudentProfileQuery } from "../../../features/studentPanelFeatures/StudentProfile/studentProfileSlice";
import { setLoggedInUserName, setUserTypeState } from "../../../features/ui/uiSlice";
import { useAppDispatch } from "../../../app/hooks";
import { useLogOutMutation } from "../../../features/logout/logoutApiSlice";
import secureLocalStorage from "react-secure-storage";
import dummyFace from "../../../assets/dummyFace.png"

const iconSize: number = 28;
const AuthStatusContainer = styled.div`
  display: flex;
  justify-content: end;
  & > div {
    width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const AuthText = styled(Col)`
  text-align: right;
  div:first-child {
    color: #f37373;
    font-size: large;
  }
`;

const UserImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
`;

function AuthStatus() {
  const [data, setData] = useState<
    IAdminProfileByIdResponse | ITutorByIdResponse | IStudentsByIdResponse
  >();
  const id = secureLocalStorage.getItem("id")?.toString()!;
  const role = secureLocalStorage.getItem("role")!;
  const { data: adminData } = useGetAdminByIdQuery(id);
  const { data: tutorData } = useGetTutorProfileQuery(id);
  const { data: studentData } = useGetStudentProfileQuery(id);
  const [logOut] = useLogOutMutation();
  const [menuItems, setMenuItems] = useState<MenuProps["items"] | undefined>(
    []
  );

  const [profileLink, setProfileLink] = useState("");
  const [notificationLink, setNotificationLink] = useState("");



  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNotification = () => {
    navigate(`${notificationLink}`);
  };

  const handleProfile = () => {
    navigate(`${profileLink}`);
  };

  const handleTimePunch = () => {
    navigate("time-punch");
  };
  const logoutApiFunction = () => {
    logOut({ userId: id })
      .unwrap()
      .then(() => {
        notification.success({
          message: "Successfully logged out",
          placement: "bottomLeft",
        });
      })
      .then(() => {
        localStorage.clear();
      })
      .catch((error) => {
        notification.error({
          message: `${error}`,
          placement: "bottomLeft",
        });
      });
  };
  const handleAdminLogout = async () => {
    await logoutApiFunction();
    dispatch(setUserTypeState(true));
    navigate("/login");
  };
  const handleStudentLogout = async () => {
    await logoutApiFunction();
    navigate("/login");
  };

  const username = data?.data?.name;
  dispatch(setLoggedInUserName(username));

  useEffect(() => {
    if (role === "ROLE_ADMIN") {
      setData(adminData);
      setNotificationLink("/admin-notification");
      setProfileLink("/administrator-profile");
      setMenuItems([
        {
          key: "1",
          label: (
            <Space onClick={handleProfile}>
              <MdPermIdentity size={iconSize} />
              Profile
            </Space>
          ),
        },
        {
          key: "2",
          label: (
            <Space onClick={handleAdminLogout}>
              <MdOutlineLogout size={iconSize} />
              Logout
            </Space>
          ),
        },
      ]);
    } else if (role === "ROLE_TUTOR") {
      setData(tutorData);
      setNotificationLink("/tutor-notification");
      setProfileLink("tutor-profile");
      setMenuItems([
        {
          key: "1",
          label: (
            <Space onClick={handleProfile}>
              <MdPermIdentity size={iconSize} />
              Profile
            </Space>
          ),
        },
        {
          key: "2",
          label: (
            <Space onClick={handleTimePunch}>
              <MdMoreTime size={iconSize} />
              Time Punch
            </Space>
          ),
        },
        {
          key: "3",
          label: (
            <Space onClick={handleAdminLogout}>
              <MdOutlineLogout size={iconSize} />
              Logout
            </Space>
          ),
        },
      ]);
    } else if (role === "ROLE_STUDENT") {
      setData(studentData);
      setProfileLink("/student-profile");
      setNotificationLink("/student-notification");
      setMenuItems([
        {
          key: "1",
          label: (
            <Space onClick={handleProfile}>
              <MdPermIdentity size={iconSize} />
              Profile
            </Space>
          ),
        },
        {
          key: "2",
          label: (
            <Space onClick={handleStudentLogout}>
              <MdOutlineLogout size={iconSize} />
              Logout
            </Space>
          ),
        },
      ]);
    }
  }, [adminData, tutorData, studentData]);
  return (
    <AuthStatusContainer>
      <div>
        <div>
          <MdNotifications
            className="cursor-dropdown color-white"
            size={iconSize}
            onClick={handleNotification}
          />
        </div>
        <Row justify="center" align="middle" gutter={20}>
          <AuthText>
            <div className="text-white">{username}</div>
            <div className="text-primary">{`${role}`.split("_")[1]}</div>
          </AuthText>
          <Col>
            <UserImage
              src={
                data?.data?.imageUrl === null
                  ? dummyFace
                  : GCP_URL + `${data?.data?.imageUrl}`
              }
              alt="profile"
            />
          </Col>
          <Dropdown menu={{ items: menuItems }} placement="bottomLeft">
            <MdKeyboardArrowDown size={iconSize} className="color-white" />
          </Dropdown>
        </Row>
      </div>
    </AuthStatusContainer>
  );
}

export default AuthStatus;
