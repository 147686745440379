import { Col, Input, Row, Form, notification } from "antd";
import { useNavigate } from "react-router-dom";
import {
  useAddCourseMutation,
  useUpdateCourseByIdMutation,
} from "../../features/internalCourse/coursesICApiSlice";
import type { ICourse, ICourseByIdResponse } from "../../types/course";
import QuillContainer from "../styled/QuillContainer.styled";
import TinyMce from "../common/TinyMce";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../features/ui/uiSlice";

type CourseFormProps = {
  data?: ICourseByIdResponse;
};

function CourseForm(props: CourseFormProps) {
  const navigate = useNavigate();
  const { data } = props;
  const [addCourse, { isLoading: isLoadingAdd }] = useAddCourseMutation();
  const [updateCourseById, { isLoading: isLoadingEdit }] = useUpdateCourseByIdMutation();
  const dispatch = useDispatch();
  // set save button loading state
  dispatch(setButtonDisabled(isLoadingAdd === true ? isLoadingAdd : isLoadingEdit));


  const onFinish = async (values: ICourse) => {
    if (data) {
      await updateCourseById({
        id: data?.data?.id,
        post: {
          ...values,
          id: data?.data.id,
          courseStatus: "INTERNAL",
        },
      })
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully edited",
            placement: "bottomLeft",
          });
          navigate(-1);
        })
        .catch((error) =>
          notification.error({
            message: "Error!",
            description: error.data.message,
            placement: "bottomLeft",
          })
        );
    } else {
      await addCourse({
        ...values,
        duration: 0,
        courseStatus: "INTERNAL",
      })
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully added",
            placement: "bottomLeft",
          });
          navigate(-1);
        })
        .catch((error) =>
          notification.error({
            message: "Error!",
            description: error.data.message,
            placement: "bottomLeft",
          })
        );
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        initialValues={{
          name: data?.data.name,
          courseStatus: "INTERNAL",
          abbreviation: data?.data.abbreviation,
          duration: data?.data?.duration,
          assignStudents: data?.data.assignStudents,
          description: data?.data.description,
        }}
        onFinish={onFinish}
        name="mainForm"
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <Form.Item
              label="Course Name"
              name="name"
              rules={[{ required: true, message: "Please enter course name" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Duration(in Hrs.)" name="duration">
              <Input type="number" className="w-100" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Abbreviation"
              name="abbreviation"
              rules={[{ required: true, message: "Please enter abbreviation" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Assigned Students" name="assignStudents">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <p>Course Description</p>
            <QuillContainer>
              <TinyMce name="description" />
            </QuillContainer>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CourseForm;
