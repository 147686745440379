import secureLocalStorage from "react-secure-storage";
import GroupWiseProgress from "../../../components/analytics/GroupWiseProgress";
import { useGetGroupStudentListQuery } from "../../../features/tutorPanelFeatures/TutorAnalytics/tutorAnalyticsApiSlice";


function TutorGroupWiseProgress() {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const { data } = useGetGroupStudentListQuery(idFromLocalStorage);

  return (
    <>
      <GroupWiseProgress data={data} />
    </>
  );
}

export default TutorGroupWiseProgress;
