import { mainApi } from "../../app/services/mainApiSlice";
import { ITutor, ITutorByIdResponse, ITutorResponse } from "../../types/tutor";

const commonUrl: string = "/admin/teacher";

const ContentType: string = `multipart/form-data`;

const tutorApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Courses
    getAllTutor: builder.query<
      ITutorResponse,
      {
        currentPage: number;
        pageSize: number;
        assignedStudents?: string;
        email?: string;
        name?: string;
        phoneNumber?: string;
        sectionName?: string;
        course?: string;
      }
    >({
      query: ({
        currentPage,
        pageSize,
        assignedStudents,
        email,
        name,
        phoneNumber,
        sectionName,
        course,
      }) => ({
        url: `${commonUrl}/get-all-topic?assignedStudents=${
          assignedStudents || ""
        }&courseName=${course || ""}&email=${email || ""}&name=${
          name || ""
        }&page=${currentPage - 1}&phoneNumber=${
          phoneNumber || ""
        }&sectionName=${sectionName || ""}&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTOR" }],
    }),
    getTutorById: builder.query<ITutorByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-tutor/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTOR" }],
    }),

    addTutor: builder.mutation<ITutor, Partial<ITutor>>({
      query: (tutorData: ITutor) => ({
        url: `${commonUrl}/save`,
        method: "POST",

        body: tutorData,
      }),
      invalidatesTags: [{ type: "Main", id: "TUTOR" }],
    }),

    updateTutorById: builder.mutation<
      ITutor,
      { id: number | undefined; post: Partial<ITutor> }
    >({
      query: ({ id, post }) => ({
        url: `${commonUrl}/update-tutor/${id}`,
        method: "PUT",

        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "TUTOR" }],
    }),

    addImage: builder.mutation<
      FormData,
      { id: number | undefined; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: `${commonUrl}/save-image/${id}`,
        method: "POST",
        headers: {
          ContentType,
        },
        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "TUTOR" }],
    }),
    updateTutorOnlineStatusById: builder.mutation<
      void,
      { id: number | undefined; onlineStatus: boolean | undefined }
    >({
      query: ({ id, onlineStatus }) => ({
        url: `${commonUrl}/update-online-status/${id}?status=${onlineStatus}`,
        method: "GET",
      }),
      invalidatesTags: [{ type: "Main", id: "TUTOR" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllTutorQuery,
  useGetTutorByIdQuery,
  useAddTutorMutation,
  useUpdateTutorByIdMutation,
  useAddImageMutation,
  useUpdateTutorOnlineStatusByIdMutation,
} = tutorApi;
