import { Form, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Rule } from "rc-field-form/lib/interface";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useGetLessonBySectionIdQuery } from "../../../features/studentPanelFeatures/StudentQuiz/studentQuizzes";
import {
  setSelectedStudentQuestionType,
  setSelectedStudentSection,
} from "../../../features/ui/uiSlice";

const { Option } = Select;

type IQuestionTypeOptionsProps = {
  label: string;
  name: string;
  rules?: Rule[];
  form: FormInstance;
};

function SelectQuestionTypeOptions(props: IQuestionTypeOptionsProps) {
  const { form, name } = props;
  const dispatch = useAppDispatch();

  const selectedStudentSection = useAppSelector(
    (state: RootState) => state.ui.selectedStudentSection
  );

  const { data } = useGetLessonBySectionIdQuery(
    selectedStudentSection || form?.getFieldValue("sections")
  );

  useEffect(() => {
    if (selectedStudentSection) {
      form?.setFieldValue(name, "");
    }

    return () => {
      dispatch(setSelectedStudentSection(""));
    };
  }, [form, selectedStudentSection, dispatch, name]);

  return (
    <Form.Item {...props}>
      <Select onChange={(e) => dispatch(setSelectedStudentQuestionType(e))} placeholder = "Select Question Type">
        {data?.data.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectQuestionTypeOptions;
