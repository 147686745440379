import { useLocation, useNavigate } from "react-router-dom";
import { WhiteCloseButtonLarge } from "../../styled/Button.styled";

function FooterElements() {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <>
      <WhiteCloseButtonLarge onClick={handleClose}>
        Close x
      </WhiteCloseButtonLarge>
    </>
  );
}

export default FooterElements;
