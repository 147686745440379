import React, { useEffect, useState } from "react";
import { Input, Select, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import pageSize from "../../../constants/pageSize";
import { IStudentByIdResponse } from "../../../types/studentType";
import { useGetAllQuizQuery } from "../../../features/quizzes/quizApiSlice";
import { IQuizzes } from "../../../types/quizzes";
import { ViewDetailsButton } from "../../../components/styled/Button.styled";
import { ICourse } from "../../../types/course";
import { useDebouncingValue } from "../../../utils/useDebouncingValue";
import { quizModeConstant } from "../../../utils/quizSearchConstants";

type AssignQuizzesProps = {
  setAssignQuizzesId?: any;
  courseData?: any;
};

function Quiz(props: AssignQuizzesProps) {
  const { setAssignQuizzesId, courseData } = props;
  const preSelectedQuizzes = courseData?.data.quizzes.map(
    (ele: any) => ele["id"]
  );
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >([]);
  const [name, setName] = useState("");
  const [mode, setMode] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [course, setCourse] = useState("");

  const { debouncedValue: quizName, debouncing: nameSearching } =
    useDebouncingValue(name);
  const { debouncedValue: quizMode, debouncing: modeSearching } =
    useDebouncingValue(mode);
  const { debouncedValue: courseName, debouncing: courseSearching } =
    useDebouncingValue(course);

  const { data, isLoading, isFetching } = useGetAllQuizQuery({
    currentPage,
    pageSize,
    quizName,
    quizMode,
    courseName,
    quizStatus: "PUBLISHED",
  });

  useEffect(() => {
    setSelectedRowKeys(preSelectedQuizzes || []);
  }, []);

  const columns: ColumnsType<IQuizzes> = [
    {
      key: "quizName",
      title: (
        <div>
          <Typography.Text>Quiz Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setName(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "quizName",
    },
    {
      key: "quizMode",
      title: (
        <div>
          <Typography.Text>Mode</Typography.Text>
          <Select
            onChange={(value) => {
              setMode(value);
              setCurrentPage(1);
            }}
            options={quizModeConstant}
            className="searchDropdownStyle"
          />
        </div>
      ),
      dataIndex: "quizMode",
    },
    {
      key: "courses",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourse(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "courses",
      render: (courses: ICourse[]) =>
        courses.map((course: ICourse) => course.name).join(","),
    },
    {
      key: "quizStatus",
      title: (
        <div>
          <Typography.Text>Status</Typography.Text>
          <Select
            value={"PUBLISHED"}
            className="searchDropdownStyle"
            disabled
          />
        </div>
      ),
      dataIndex: "quizStatus",
    },

    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openQuizDetails(record.id)} />
      ),
    },
  ];

  const openQuizDetails = (id: number) => {
    navigate(`/quizzes/${id}`);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setAssignQuizzesId(newSelectedRowKeys.map((id) => ({ id })));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Table<IQuizzes>
        className="d-table"
        loading={
          isLoading ||
          nameSearching ||
          courseSearching ||
          modeSearching ||
          isFetching
        }
        rowSelection={{ ...rowSelection, preserveSelectedRowKeys: true }}
        rowKey="id"
        columns={columns}
        dataSource={data?.data?.content}
        pagination={{
          pageSize,
          current: currentPage,
          total: data?.data.totalElements,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
      />
    </>
  );
}

export default Quiz;
