import { Col, Row, Tabs, Form, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import AssignStudent from "./AssignStudents";
import PersonalDetails from "./PersonalDetails";
import Quiz from "../Students/AddStudents/Quiz";
import { useState, useEffect } from "react";
import { PrimaryButton } from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import FormContainer from "../../components/styled/FormContainer.styled";
import AssignResources from "./AssignResources";
import AssignTutor from "./AssignTutor";
import {
  useGetGroupByIdQuery,
  useUpdateGroupByIdMutation,
} from "../../features/group/groupApiSlice";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../features/ui/uiSlice";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import dayjs from "dayjs";

function GroupDetails() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isError, isLoading } = useGetGroupByIdQuery(id);

  const [updateGroupById, { isLoading: isLoadingEdit }] =
    useUpdateGroupByIdMutation();
  dispatch(setButtonDisabled(isLoadingEdit));
  const [assignTutorId, setAssignTutorId] = useState<React.Key[] | undefined>(
    data?.data?.tutors || []
  );
  const [assignStudentsId, setAssignStudentsId] = useState<
    React.Key[] | undefined
  >(data?.data.students || []);
  const [assignQuizzesId, setAssignQuizzesId] = useState<
    React.Key[] | undefined
  >(data?.data.quizzes || []);
  const [assignResourcesId, setAssignResourcesId] = useState<
    React.Key[] | undefined
  >(data?.data.resource || []);

  const onClose = () => {
    navigate(-1);
  };

  const isButtonLoading = useAppSelector((state) => state.ui.buttonDisabled);
  useEffect(() => {
    setAssignTutorId(data?.data.tutors);
    setAssignStudentsId(data?.data.students);
    setAssignQuizzesId(data?.data?.quizzes);
    setAssignResourcesId(data?.data?.resource);
  }, [data]);

  return (
    <>
      <Breadcrumbs
        idName={!!id ? data?.data?.name : "false"}
        isId={!!id ? true : false}
      />
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { personalDetailsForm } = forms;
            personalDetailsForm.submit();
            let personalData = { ...personalDetailsForm.getFieldsValue() };
            const { courseId, section } = personalData;

            let obj = {
              ...personalData,
              startDate: dayjs(personalData.startDate).format("YYYY-MM-DD"),
              courses: [{ id: courseId }],
              sections: section.map((id: number) => ({ id })),
              tutors: assignTutorId,
              students: assignStudentsId,
              resource: assignResourcesId,
              quizzes: assignQuizzesId,
              status: true,
            };

            updateGroupById({ id: data?.data.id, post: obj })
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully Edited!",
                  placement: "bottomLeft",
                });
                navigate(-1);
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>
                Save
              </PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Group Details" key="1">
              <WhiteContainer>
                <FormContainer>
                  {!isError && !isLoading && data && (
                    <PersonalDetails data={data} />
                  )}
                </FormContainer>
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Assigned Tutors" key="2">
              <WhiteContainer>
                {!isError && !isLoading && data && (
                  <AssignTutor
                    courseData={data}
                    setAssignTutorId={setAssignTutorId}
                  />
                )}
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Assigned Students" key="3">
              <WhiteContainer>
                {!isError && !isLoading && data && (
                  <AssignStudent
                    courseData={data}
                    setAssignStudentsId={setAssignStudentsId}
                  />
                )}
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Resources" key="4">
              <WhiteContainer>
                {!isError && !isLoading && data && (
                  <AssignResources
                    courseData={data}
                    setAssignResourcesId={setAssignResourcesId}
                  />
                )}
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Quiz" key="5">
              <WhiteContainer>
                <Quiz
                  courseData={data}
                  setAssignQuizzesId={setAssignQuizzesId}
                />
              </WhiteContainer>
            </Tabs.TabPane>
          </Tabs>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default GroupDetails;
