import { mainApi } from "../../../app/services/mainApiSlice";
import { IStudentAnalyticsResponse } from "../../../types/studentPanelTypes/studentAnalytics";

const commonUrl: string = "/student/analytics";

const studentAnalyticsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getStudentTopicAnalytics: builder.query<
      IStudentAnalyticsResponse,
      {
        idFromLocalStorage: string | null;
        endDate: string;
        startDate: string;
        courseId: string | undefined;
      }
    >({
      query: ({ idFromLocalStorage, endDate, startDate, courseId }) => ({
        url: `${commonUrl}/topic-analytic/${idFromLocalStorage}?courseId=${courseId}&endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENTANALYTICS" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetStudentTopicAnalyticsQuery } = studentAnalyticsApi;
