import { Row } from "antd";
type BlackMessageProps = {
  res: any;
};
function BlackBookPopUpContent({ res }: BlackMessageProps) {
  return (
    <>
      <Row justify={"center"}>
        <Row>
          <span>{res.message}</span>
        </Row>
      </Row>
    </>
  );
}

export default BlackBookPopUpContent;
