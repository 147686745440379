import { Col, Row, Tabs } from "antd";
import ViewQuizDetailsForm from "../../components/quizzes/ViewQuizDetailsForm";
import ViewQuizQuestion from "../../components/quizzes/ViewQuizQuestion";
import {
  useGetQuizByIdQuery,
  useUpdateStatusByIdMutation,
} from "../../features/quizzes/quizApiSlice";
import { useParams, useNavigate } from "react-router-dom";
import {
  DarkGrayContainer,
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import ContainerHeading from "../../components/styled/ContainerHeading.styled";
import FormContainer from "../../components/styled/FormContainer.styled";
import { PrimaryButton } from "../../components/styled/Button.styled";
import Breadcrumbs from "../../components/common/Breadcrumbs";

function ViewQuizDetails() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [updateStatusById] = useUpdateStatusByIdMutation();
  const { data, isError, isLoading } = useGetQuizByIdQuery(id);

  const onClose = () => {
    navigate(-1);
  };
  const publishBtn = data?.data.quizStatus === "UNPUBLISHED";

  const handlePublish = (id: any) => {
    updateStatusById(id);
    navigate(-1);
  };

  const tabItems = [
    {
      key: "1",
      label: "Quiz Details",
      children: (
        <WhiteContainer>
          <FormContainer>
            {!isError && !isLoading && data && (
              <ViewQuizDetailsForm data={data} />
            )}
          </FormContainer>
        </WhiteContainer>
      ),
    },
    {
      key: "2",
      label: "Quiz Questions",
      children: (
        <WhiteContainer>
          <ViewQuizQuestion data={data} />
        </WhiteContainer>
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs idName={!!id ? data?.data?.quizName : 'false'} isId={!!id ? true : false} />
      <Row justify="end" gutter={20} className="mb-1">
        <Col>
          {publishBtn ? (
            <PrimaryButton htmlType="button" onClick={() => handlePublish(id)}>
              Publish
            </PrimaryButton>
          ) : (
            ""
          )}
        </Col>

        <Col>
          <PrimaryButton htmlType="button" onClick={onClose}>
            Close
          </PrimaryButton>
        </Col>
      </Row>
      <Row>
        <LightGrayContainer>
          <ContainerHeading>Quiz Details</ContainerHeading>
          <DarkGrayContainer>
            <Tabs defaultActiveKey="1" items={tabItems} />
          </DarkGrayContainer>
        </LightGrayContainer>
      </Row>
    </>
  );
}

export default ViewQuizDetails;
