import { Card, Col, Row, Typography } from "antd";

type DashboardCustomCardProps = {
  heading?: string;
  subText?: string;
  cardIcon?: any;
  totalCount?: number;
  newCount?: number;
  internalCount?: string;
  individualCount?: string;
  groupCount?: string;
};
const cardBodyStyle = {
  padding: "10px",
  height: "100%",
};

function DashboardCustomCard(props: DashboardCustomCardProps) {
  const { Text } = Typography;
  const { heading, subText, cardIcon, totalCount, newCount, internalCount } =
    props;
  return (
    <>
      <Card
        className="dashboard-card-container"
        bodyStyle={cardBodyStyle}
        hoverable
      >
        <Row className="dashboard-card-row-height">
          <Col span={12}>
            <Row className="dashboard-card-row-height">
              <Row>
                <Text className="dashboard-card-heading-text-size common-text-color">
                  {heading}
                </Text>
                <Col span={24}>
                  <Text className="green-text-color">
                    {newCount}&nbsp;{subText}
                  </Text>
                </Col>
                <Col span={24}>
                  <Text
                    strong
                    className="dashboard-count-text-size common-text-color"
                  >
                    {totalCount}
                  </Text>
                </Col>
              </Row>
            </Row>
          </Col>
          <Col span={12}>
            <Row className="dashboard-card-row-height" justify={"end"}>
              <Row>
                <Col span={24}>{cardIcon}</Col>
              </Row>

              <Row>
                <Col span={24} className="separate-count">
                  {internalCount ? <span> {internalCount}</span> : <></>}
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default DashboardCustomCard;
