import { mainApiLogout } from "../../app/services/mainApiSlice";

type ILogOutResponse = {
  message: string;
};
const logOutApi = mainApiLogout.injectEndpoints({
  endpoints: (builder) => ({
    logOut: builder.mutation<ILogOutResponse, { userId: string | null }>({
      query: ({ userId }) => ({
        url: ``,
        method: "POST",
        body: { userId },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useLogOutMutation } = logOutApi;
