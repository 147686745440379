import React, { useState } from "react";
import { Input, Table, Typography, Form, InputNumber } from "antd";
import { ColumnsType } from "antd/es/table";
import { useGetAllCoursesQuery } from "../../../features/internalCourse/coursesICApiSlice";
import { ICourse } from "../../../types/course";
import pageSize from "../../../constants/pageSize";
import { ISection } from "../../../types/section";
import { ITutorByIdResponse } from "../../../types/tutor";
type AssignCoursesProps = {
  setAssignCoursesId: React.Dispatch<React.SetStateAction<React.Key[]>>;
  courseData?: ITutorByIdResponse;
};

function AssignCourses(props: AssignCoursesProps) {
  const { setAssignCoursesId, courseData } = props;
  const { data, isLoading } = useGetAllCoursesQuery({
    pageSize: 1000,
    currentPage: 1,
  });
  const [searchText, setSearchText] = useState("");
  const [section, setSection] = useState("");
  const [groupCost, setGroupCost] = useState("");
  const [individualCost, setIndividualCost] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >(
    courseData?.data.tutorSectionDTO
      ?.map((item) => item.course.map((x) => x.id))
      .flat() || []
  );

  const columns: ColumnsType<ICourse> = [
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
      filteredValue: [searchText],
      onFilter: (value: any, record) => {
        return record?.name?.toLowerCase()?.includes(value?.toLowerCase());
      },
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSection(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "section",
      render: (sections: ISection[]) =>
        sections.map((section: ISection) => section.name).join(" , "),
      filteredValue: [section],
      onFilter: (value: any, record) => {
        return record.section
          .map((section: ISection) => section.name)
          .join(" , ")
          ?.toLowerCase()
          .includes(value?.toLowerCase());
      },
    },
    {
      key: "groupCost",
      dataIndex: "groupCost",
      title: (
        <div>
          <Typography.Text>Group Cost(per Hrs.)</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setGroupCost(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [groupCost],
      onFilter: (value: any, record) => {
        return record.duration
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (_, record) => {
        return (
          <>
            <Form.Item
              name={`groupCost-${record.id}`}
              rules={[
                {
                  required: selectedRowKeys?.some((id) => id === record.id),
                  message: "Please enter value greater than equals to 1",
                },
              ]}
            >
              <InputNumber placeholder="click to add" min={1} addonAfter="€" />
            </Form.Item>
          </>
        );
      },
    },
    {
      key: "individualCost",
      dataIndex: "individualCost",
      title: (
        <div>
          <Typography.Text>Individual Cost(per Hrs.)</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setIndividualCost(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [individualCost],
      onFilter: (value: any, record) => {
        return record.duration
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (_, record) => {
        return (
          <>
            <Form.Item
              name={`individualCost-${record.id}`}
              rules={[
                {
                  required: selectedRowKeys?.some((id) => id === record.id),
                  message: "Please enter value greater than equals to 1",
                },
              ]}
            >
              <InputNumber addonAfter="€" placeholder="click to add" min={1} />
            </Form.Item>
          </>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setAssignCoursesId(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Table<ICourse>
        rowSelection={rowSelection}
        loading={isLoading}
        rowKey="id"
        columns={columns}
        dataSource={data?.data.content}
        pagination={{
          pageSize,
        }}
      />
    </>
  );
}

export default AssignCourses;
