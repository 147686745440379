import { mainApi } from "../../../app/services/mainApiSlice";
import {
  IStudentQuery,
  IStudentQueryByIdResponse,
  IStudentQueryInput,
} from "../../../types/studentPanelTypes/studentQueryType";
const commonUrl: string = "student/query";
const studentQueriesApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllQueriesByStudentId: builder.query<
      IStudentQueryByIdResponse,
      IStudentQueryInput
    >({
      query: ({id,courseId}) => ({
        url: `${commonUrl}/get-query/${id}?courseId=${courseId}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENTQUERY" }],
    }),
    saveStudentQuery: builder.mutation<IStudentQuery, Partial<IStudentQuery>>({
      query: (queryData: IStudentQuery) => ({
        url: `${commonUrl}/save`,
        method: "POST",
        body: queryData,
      }),
      invalidatesTags: [{ type: "Main", id: "STUDENTQUERY" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllQueriesByStudentIdQuery, useSaveStudentQueryMutation } =
  studentQueriesApi;
