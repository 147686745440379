import { Col, Row, Tabs, Form, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import AssignStudent from "./AssignStudents";
import PersonalDetails from "./PersonalDetails";
import Quiz from "../Students/AddStudents/Quiz";
import { useState } from "react";
import { PrimaryButton } from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import FormContainer from "../../components/styled/FormContainer.styled";
import AssignResources from "../Students/AddStudents/AssignResources";
import AssignTutor from "./AssignTutor";
import { useCreateGroupMutation } from "../../features/group/groupApiSlice";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../features/ui/uiSlice";
import dayjs from "dayjs";

function CreateGroup() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [createGroup, { isLoading }] = useCreateGroupMutation();
  const [assignTutorId, setAssignTutorId] = useState<React.Key[]>([]);
  const [assignStudentsId, setAssignStudentsId] = useState<React.Key[]>([]);
  const [assignResourcesId, setAssignResourcesId] = useState<React.Key[]>([]);
  const [assignQuizzesId, setAssignQuizzesId] = useState<React.Key[]>([]);

  const onClose = () => {
    navigate(-1);
  };
  dispatch(setButtonDisabled(isLoading));

  const isButtonLoading = useAppSelector((state) => state.ui.buttonDisabled);
  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { personalDetailsForm } = forms;
            personalDetailsForm.submit();
            let personalData = { ...personalDetailsForm.getFieldsValue() };
            const { courseId, section } = personalData;

            let obj = {
              ...personalData,
              startDate: dayjs(personalData.startDate).format("YYYY-MM-DD"),
              courses: [{ id: courseId }],
              sections: section.map((id: number) => ({ id })),
              tutors: assignTutorId,
              students: assignStudentsId,
              resource: assignResourcesId,
              quizzes: assignQuizzesId,
              status: true,
            };
            createGroup(obj)
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully Added!",
                  placement: "bottomLeft",
                });
                navigate(-1);
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>
                Save
              </PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Group Details" key="1">
              <WhiteContainer>
                <FormContainer>
                  <PersonalDetails />
                </FormContainer>
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Assigned Tutors" key="2">
              <WhiteContainer>
                <AssignTutor setAssignTutorId={setAssignTutorId} />
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Assigned Students" key="3">
              <WhiteContainer>
                <AssignStudent setAssignStudentsId={setAssignStudentsId} />
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Resources" key="4">
              <WhiteContainer>
                <AssignResources setAssignResourcesId={setAssignResourcesId} />
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Quiz" key="5">
              <WhiteContainer>
                <Quiz setAssignQuizzesId={setAssignQuizzesId} />
              </WhiteContainer>
            </Tabs.TabPane>
          </Tabs>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default CreateGroup;
