import secureLocalStorage from "react-secure-storage";
import { mainApi } from "../../app/services/mainApiSlice";
import {
  IAdminProfile,
  IAdminProfileByIdResponse,
} from "../../types/profileAdmin";

const commonUrl: string = "/admin/admin";

const ContentType: string = `multipart/form-data`;

const adminProfileApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    //Get Admin By Id
    getAdminById: builder.query<
      IAdminProfileByIdResponse,
      string | undefined | null
    >({
      query: (id) => ({
        url: `${commonUrl}/get-admin/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "ADMIN" }],
    }),
    //Update Admin by id
    updateAdminById: builder.mutation<
      IAdminProfile,
      { id: number | undefined; post: Partial<IAdminProfile>; }
    >({
      query: ({ id, post }) => ({
        url: `${commonUrl}/update-admin/${id}`,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "ADMIN" }],
    }),
    //Upload Image
    addAdminImageById: builder.mutation<
      FormData,
      { id: number | undefined; formData: FormData; }
    >({
      query: ({ id, formData }) => ({
        url: `${commonUrl}/save-image/${id}`,
        method: "POST",
        headers: {
          ContentType,
        },
        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "ADMIN" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAdminByIdQuery,
  useUpdateAdminByIdMutation,
  useAddAdminImageByIdMutation,
} = adminProfileApi;
