import { Col, Progress } from "antd";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLazyGetStudentDashboardQuery } from "../../features/studentPanelFeatures/StudentDashboard/studentDashboard";
import { setStudentCourseProgressData } from "../../features/ui/uiSlice";
import { useAppSelector } from "../../app/hooks";
import { commonStrokeColor } from "../../constants/commonColors";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";

function CourseProgress() {
  const { courseId } = useParams();
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const [getData] = useLazyGetStudentDashboardQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    getData(idFromLocalStorage).then((data: any) => {
      if (data.status === 'fulfilled') {
        dispatch(
          setStudentCourseProgressData(
            data?.data?.data?.courseMinimalDTOList?.filter((course: any) => {
              return `${course?.courseId}` === courseId;
            })[0]?.courseProgress
          )
        );
      }
    }).catch((error: any) => { console.log(error.message); });
  }, [courseId, dispatch, getData, idFromLocalStorage]);

  const courseProgressPercentage = useAppSelector(
    (state) => state.ui.studentCourseProgressData
  );

  return (
    <>
      <Col span={4}>
        <span>
          <small>Course Progress</small>
          <Progress
            percent={courseProgressPercentage}
            strokeColor={`${commonStrokeColor}`}
            trailColor="white"
            className="mb-0"
          />
        </span>
      </Col>
    </>
  );
}

export default CourseProgress;
