import styled from "styled-components";
import FooterElements from "./FooterElements";

const StudentFooter = styled.footer`
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 4rem;
`;
function StudentPanelFooter() {
  return (
    <StudentFooter>
      <FooterElements />
    </StudentFooter>
  );
}

export default StudentPanelFooter;
