import { mainApi } from "../../../app/services/mainApiSlice";
import { IStudentNotificationResponse } from "../../../types/studentPanelTypes/studentNotificationType";

const commonUrl: string = "public/notification";

const studentNotificationApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStudentNotification: builder.query<
      IStudentNotificationResponse,
      string | null
    >({
      query: (id) => ({
        url: `${commonUrl}/get-all/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENTNOTIFICATION" }],
    }),
    putStudentNotification: builder.mutation<
      IStudentNotificationResponse,
      string | null
    >({
      query: (id) => ({
        url: `${commonUrl}/update-notification-status/${id}?status=false`,
        method: "PATCH",
      }),
      invalidatesTags: [
        { type: "Main", id: "STUDENTNOTIFICATION" },
        { type: "Main", id: "TUTORNOTIFICATION" },
        { type: "Main", id: "ADMINNOTIFICATION" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllStudentNotificationQuery,usePutStudentNotificationMutation } = studentNotificationApi;
