const colorPrimary = "#f37373";
const bgColor = "#F9FAFB";
const menuItemBgColorHoverSelected = colorPrimary;
const menuItemTextColorHoverSelected = "#fff";
const menuItemFontSize = 20;

// Student Panel Sidebar MenuItems
const studentPanelSideMenuSelectedText = "#FF6E6E";
const studentPanelColorPrimary = "#EB4962";
const studentPanelBgColor = "#EEEEEE";
const sideMenuBgColor = "#F9FAFB";
const myLearningSelectedBgColor = "#FCE830";
const myLearningItemTextColor = "#222044";
const studentPanelMenuItemBgColorHoverSelected = studentPanelColorPrimary;

export {
  colorPrimary,
  bgColor,
  menuItemBgColorHoverSelected,
  menuItemTextColorHoverSelected,
  menuItemFontSize,
  studentPanelColorPrimary,
  studentPanelBgColor,
  studentPanelMenuItemBgColorHoverSelected,
  sideMenuBgColor,
  myLearningSelectedBgColor,
  myLearningItemTextColor,
  studentPanelSideMenuSelectedText,
};
