import { mainApi } from "../../app/services/mainApiSlice";
import { IQueryByIdResponse, IQueryResponse } from "../../types/queries";

const commonUrl: string = "admin/query";

const queriesApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllQueries: builder.query<
      IQueryResponse,
      { currentPage: number; pageSize: number; studentName?: string }
    >({
      query: ({ currentPage, pageSize, studentName }) => ({
        url: `${commonUrl}/get-all-query?page=${
          currentPage - 1
        }&size=${pageSize}&studentName=${studentName || ""}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "QUERY" }],
    }),
    getQueryById: builder.query<IQueryByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-query/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "QUERY" }],
    }),
    deleteQueryById: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `${commonUrl}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Main", id: "QUERY" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllQueriesQuery,
  useGetQueryByIdQuery,
  useDeleteQueryByIdMutation,
} = queriesApi;
