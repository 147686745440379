import { Col, Row, Form, Input, DatePicker } from "antd";
import dayjs from "dayjs";
import "react-phone-number-input/style.css";
import { IGroupByIdResponse } from "../../../types/group";

type PersonalDetailsProps = {
  data?: IGroupByIdResponse;
};

function ViewGroupDetails(props: PersonalDetailsProps) {
  const { data } = props;

  const [form] = Form.useForm();

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="personalDetailsForm"
        initialValues={{
          name: data?.data.name,
          startDate: dayjs(data?.data.startDate),
          studentCount: data?.data.studentCount,
          duration: data?.data.courses.map((item) => item.duration),
        }}
      >
        <Row gutter={[32, 22]}>
          <Col span={12}>
            <Row gutter={[32, 22]}>
              <Col span={24}>
                <Form.Item label="Group Name" name="name">
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Group Start Date" name="startDate">
                  <DatePicker
                    className="w-100"
                    format={"DD/MM/YYYY"}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[32, 22]}>
              <Col span={24}>
                <Form.Item label="Time Duration(in Hrs.)" name="duration">
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Number of Students" name="studentCount">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ViewGroupDetails;
