import { Col, Row, Tabs, Form, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../../components/styled/Container.styled";
import FormContainer from "../../../components/styled/FormContainer.styled";
import {
  useGetTutorGroupByIdQuery,
  useUpdateTutorGroupsByIdMutation,
} from "../../../features/tutorPanelFeatures/TutorGroups/groupsAPISlice";
import PersonalGroupDetails from "./PersonalGroupDetails";
import GroupsAssignStudents from "./GroupsAssignStudents";
import GroupsAssignQuiz from "./GroupsAssignQuiz";
import { useAppSelector } from "../../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../../features/ui/uiSlice";
import Breadcrumbs from "../../../components/common/Breadcrumbs";

function ViewGroupDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isError, isLoading } = useGetTutorGroupByIdQuery(id);
  const [updateTutorGroupById, { isLoading: isLoadingEdit }] = useUpdateTutorGroupsByIdMutation();
  const [assignQuizzesId, setAssignQuizzesId] = useState<
    React.Key[] | undefined
  >(data?.data.quizzes || []);
  const dispatch = useDispatch();
  dispatch(setButtonDisabled(isLoadingEdit));
  const onClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    setAssignQuizzesId(data?.data?.quizzes);
  }, [data]);

  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );

  return (
    <>
      <Breadcrumbs idName={!!id ? data?.data?.name : 'false'} isId={!!id ? true : false} />
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { personalDetailsForm } = forms;
            personalDetailsForm.submit();
            let personalData = { ...personalDetailsForm.getFieldsValue() };

            let obj = {
              ...personalData,
              groupId: data?.data.id,
              quizIds: assignQuizzesId?.map((ele: any) => ele["id"]),
              status: true,
            };

            updateTutorGroupById({
              post: obj,
            })
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully edited",
                  placement: "bottomLeft",
                });
                navigate(-1);
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>Save</PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Group Details" key="1">
              <WhiteContainer>
                <FormContainer>
                  {!isError && !isLoading && data && (
                    <PersonalGroupDetails data={data} />
                  )}
                </FormContainer>
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Assigned Students" key="2">
              <WhiteContainer>
                {!isError && !isLoading && data && (
                  <GroupsAssignStudents data={data} />
                )}
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Assign Quiz" key="3">
              <WhiteContainer>
                <GroupsAssignQuiz
                  data={data}
                  setAssignQuizzesId={setAssignQuizzesId}
                />
              </WhiteContainer>
            </Tabs.TabPane>
          </Tabs>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default ViewGroupDetails;
