import { Input, Table, Typography, Row, Modal, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../constants/pageSize";
import CustomCard from "../../components/countCard/CustomCard";
import {
  DeleteButton,
  ViewDetailsButton,
} from "../../components/styled/Button.styled";
import {
  useGetAllQueriesQuery,
  useDeleteQueryByIdMutation,
} from "../../features/queries/queriesApiSlice";
import { IQuery } from "../../types/queries";
import dayjs from "dayjs";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

function Queries() {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [currentPage, setCurrentPage] = useState(1);
  const [courseName, setCourseName] = useState("");
  const [queryDate, setQueryDate] = useState("");
  const [studentName, setStudentName] = useState("");
  const [deleteQueryById] = useDeleteQueryByIdMutation();

  const { debouncedValue: student, debouncing: studentSearching } =
    useDebouncingValue(studentName);

  const { data, isLoading, isFetching } = useGetAllQueriesQuery({
    currentPage,
    pageSize,
    studentName: student,
  });

  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Query?",

      onOk() {
        onDelete(id);
      },
      onCancel() {},
    });
  };
  const onDelete = (id: number) => {
    deleteQueryById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        });
      });
  };

  const columns: ColumnsType<IQuery> = [
    {
      key: "studentName",
      title: (
        <div>
          <Typography.Text>Student Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setStudentName(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "studentName",
    },
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course Enrolled</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourseName(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
            disabled
          />
        </div>
      ),
      dataIndex: "courseName",
    },
    {
      key: "queryDate",
      title: (
        <div>
          <Typography.Text>Query Date</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setQueryDate(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
            disabled
          />
        </div>
      ),
      dataIndex: "queryDate",
      render: (_, record) => (
        <>
          {dayjs(record.queryDate)
            .toISOString()
            .split("-")
            .reverse()[0]
            .split("T")[0] +
            dayjs(record.queryDate)
              .toISOString()
              .split("-")
              .reverse()
              .join("-")
              .split("Z")[1]}
        </>
      ),
    },

    {
      key: "viewDetails",
      dataIndex: "studentId",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openQueryDetails(record.studentId)}>
          View Details
        </ViewDetailsButton>
      ),
    },
    {
      key: "deleteButton",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.queryId)} />
      ),
    },
  ];

  const openQueryDetails = (id: number) => {
    navigate(`${id}`);
  };

  return (
    <div>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard sum={data?.data?.content[0]?.totalQueries} text="Queries" />
      </Row>

      <Row className="styled-container outer-styled-container">
        <Row className="styled-container inner-styled-container">
          <Table<IQuery>
            loading={isLoading || isFetching || studentSearching}
            rowKey="id"
            columns={columns}
            dataSource={data?.data?.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: data?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </Row>
      </Row>
    </div>
  );
}

export default Queries;
