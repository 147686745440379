import { Col, Form, notification, Row } from "antd";
import Add from "./Add";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../../components/styled/Container.styled";
import ContainerHeading from "../../../components/styled/ContainerHeading.styled";
import FormContainer from "../../../components/styled/FormContainer.styled";
import { useAddTimePunchMutation } from "../../../features/tutorPanelFeatures/TimePunch/timePunchApiSlice";
import dayjs from "dayjs";
import secureLocalStorage from "react-secure-storage";
import { useAppSelector } from "../../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../../features/ui/uiSlice";
import Breadcrumbs from "../../../components/common/Breadcrumbs";

function TimePunch() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const tutorId = idFromLocalStorage;

  const [addTimePunch, { isLoading }] = useAddTimePunchMutation();
  const dispatch = useDispatch();
  dispatch(setButtonDisabled(isLoading));
  const isButtonLoading = useAppSelector((state) => state.ui.buttonDisabled);

  return (
    <>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { listForm } = forms;

            listForm.submit();
            let data = { ...listForm.getFieldsValue() };

            const newData = data.timePunchDTO.map((value: any) => {
              const startTime = dayjs(value.startTime).format("HH:mm");

              const endTime = dayjs(value.endTime).format("HH:mm");

              const date = dayjs(value.date).format("YYYY-MM-DD");

              const isGroupId = value.groupStudentId.endsWith("groupId");
              const isStudentId = value.groupStudentId.endsWith("studentId");
              const id = Number(value.groupStudentId.split("-")[0]);
              return {
                ...value,
                groupId: isGroupId ? id : undefined,
                studentId: isStudentId ? id : undefined,
                tutorId,
                startTime,
                endTime,
                date,
              };
            });

            let object = { timePunchDTO: newData };
            addTimePunch(object)
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully added",
                  placement: "bottomLeft",
                });
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>
                Save
              </PrimaryButton>
            </Form>
          </Col>
        </Row>

        <LightGrayContainer>
          <ContainerHeading>Time Punch</ContainerHeading>
          <WhiteContainer>
            <FormContainer>
              <Add />
            </FormContainer>
          </WhiteContainer>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default TimePunch;
