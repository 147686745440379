import { Col, Row } from "antd";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import styled from "styled-components";
import NotificationItem from "../../components/notification/NotificationItem";
import { StudentPanelWhiteContainer } from "../../components/styled/Container.styled";
import {
  useGetAllStudentNotificationQuery,
  usePutStudentNotificationMutation,
} from "../../features/studentPanelFeatures/StudentNotification/StudentNotificationApiSlice";
import { IStudentNotification } from "../../types/studentPanelTypes/studentNotificationType";


const NotificationContainer = styled.div`
  height: 100vh;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

function StudentNotification() {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const { data } = useGetAllStudentNotificationQuery(idFromLocalStorage);

  const [putStudentNotification] = usePutStudentNotificationMutation();

  const handleNotificationClick = (id: string) => {
    putStudentNotification(id).unwrap();
  };

  return (
    <>
      <StudentPanelWhiteContainer>
        <NotificationContainer>
          <Row>
            <Col span={24}>
              {data?.data?.map((item, index) => (
                <NotificationItem
                  item={item}
                  key={index}
                  handleNotificationClick={handleNotificationClick}
                />
              ))}
            </Col>
          </Row>
        </NotificationContainer>
      </StudentPanelWhiteContainer>
    </>
  );
}

export default StudentNotification;
