import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  message,
} from "antd";
import ViewQueryDetails from "../../../components/queries/ViewQueryDetails";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import {
  useGetAllQueriesByStudentIdQuery,
  useSaveStudentQueryMutation,
} from "../../../features/studentPanelFeatures/StudentQueries/studentQueryApiSlice";

import { WhiteContainer } from "../../../components/styled/Container.styled";
import styled from "styled-components";
import { IStudentQuery } from "../../../types/studentPanelTypes/studentQueryType";
import sendArrowIcon from "../../../assets/customIcons/send-arrow.png";
import secureLocalStorage from "react-secure-storage";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { setStudentBreadcrumb } from "../../../features/ui/uiSlice";
const MessageInputContainer = styled(WhiteContainer)`
  border: none;
  position: "sticky";
  bottom: "0";
`;

const QueryMessageRowContainer = styled(StudentPanelWhiteContainer)`
  min-height: 50vh;
  height: 60vh;
  overflow-y: scroll;
`;
const SendButton = styled(Button)`
  height: 3rem;
  width: 3rem;
  padding: 1rem;
`;
function StudentQueries() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const {  courseId } = useParams();
  const { data } = useGetAllQueriesByStudentIdQuery({ id:idFromLocalStorage, courseId});
  const [saveStudentQuery, { isLoading }] = useSaveStudentQueryMutation();
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  dispatch(setStudentBreadcrumb([`${pathname.split("/")[3]}`]));

  const onFinish = async (values: IStudentQuery) => {
    await saveStudentQuery({
      ...values,
      courseId,
      studentId: idFromLocalStorage,
    }).then(() => {
      form.resetFields();
    });
  };
  return (
    <>
      <Row className="p-1">
        <Row gutter={[5, 5]}>
          <Col span={24}>
            <Typography.Title level={3}>
              {data?.data[0]?.studentName}
            </Typography.Title>
          </Col>
          <Col className="gray-text-color">{data?.data[0]?.courseName}</Col>
        </Row>
      </Row>

      {!!data ? (
        <QueryMessageRowContainer>
          {data?.data?.map((item) => (
            <>
              <ViewQueryDetails
                date={item?.queryDate}
                message={item?.queryDetail}
              />
              <Divider className="lightgray-background-color" />
            </>
          ))}
        </QueryMessageRowContainer>
      ) : (
        <QueryMessageRowContainer>
          <Spin />
        </QueryMessageRowContainer>
      )}

      <MessageInputContainer>
        <Form.Provider
          onFormFinish={(name, { forms }) => {
            if (name === "submitForm") {
              const { mainForm } = forms;
              mainForm.submit();
            }
          }}
        >
          <Row align={"middle"}>
            <Col span={22}>
              <Form onFinish={onFinish} name="mainForm" form={form}>
                <Form.Item
                  name="queryDetail"
                  rules={[{ required: true, message: "Please enter text" }]}
                >
                  <Input
                    placeholder="Write your message here..."
                    className="p-1"
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col offset={1} span={1}>
              <Form name="submitForm">
                <SendButton
                  htmlType="submit"
                  shape="circle"
                  className="dark-blue-background"
                  loading={isLoading}
                >
                  <img src={sendArrowIcon} alt="arrow-icon" />
                </SendButton>
              </Form>
            </Col>
          </Row>
        </Form.Provider>
      </MessageInputContainer>
    </>
  );
}

export default StudentQueries;
