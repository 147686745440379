import { useParams } from "react-router-dom";
import AudioFileViewer from "../../../components/preview/AudioFileViewer";
import ImageFileViewer from "../../../components/preview/ImageFileViewer";
import PdfFileViewer from "../../../components/preview/PdfFileViewer";
import PptFileViewer from "../../../components/preview/PptFileViewer";
import TextViewer from "../../../components/preview/TextViewer";
import VideoFileViewer from "../../../components/preview/VideoFileViewer";
import { GCP_URL } from "../../../constants/commonUtils";
import { useGetStudentResourceByIdQuery } from "../../../features/studentPanelFeatures/StudentMyLearning/myLearningApiSlice";

function ViewResource() {
  const { resourceId } = useParams();
  const { data } = useGetStudentResourceByIdQuery(resourceId);
  let gcsUrl = GCP_URL + `${data?.data?.path}`;

  switch (data?.data?.resourceType) {
    case "PDF":
      return <PdfFileViewer uri={gcsUrl} />;
    case "PPT":
      return <PptFileViewer uri={gcsUrl} />;
    case "MP4":
      return <VideoFileViewer uri={gcsUrl} />;
    case "TEXT":
      return <TextViewer textData={data?.data?.text} />;
    case "MP3":
      return <AudioFileViewer uri={gcsUrl} />;
    case "IMAGE":
      return <ImageFileViewer uri={gcsUrl} />;
    default:
      return <></>;
  }
}

export default ViewResource;
