import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WhiteContainer } from "../../../components/styled/Container.styled";
import { useGetPreviewByCourseIdQuery } from "../../../features/internalCourse/coursesICApiSlice";
import { useUpdateIndexMutation } from "../../../features/internalCourse/sectionICApiSlice";
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai";
import { ISectionIndex } from "../../../types/section";
import { setPreviewHeader } from "../../../features/ui/uiSlice";
import { useDispatch } from "react-redux";

function Preview(props: any) {
  const { id } = useParams();
  const { data, refetch } = useGetPreviewByCourseIdQuery(id);
  const [updateIndex] = useUpdateIndexMutation();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [display, setDisplay] = useState(false);
  const [section, setSection] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setSection(data?.data?.course?.section);
    dispatch(setPreviewHeader(data?.data?.course?.name));
  }, [data]);

  const sectionFunction = (a: any) => {
    setSection(a);
  };

  const dragStart = (e: any, position: any) => {
    e.stopPropagation();
    dragItem.current = position;
  };

  const dragEnter = (e: any, position: any) => {
    e.stopPropagation();
    dragOverItem.current = position;
  };

  const drop = (e: any) => {
    e.stopPropagation();
    const copyListItems = [...section];
    let positions = dragItem.current ? dragItem.current : 0;
    let dragOverItems = dragOverItem.current ? dragOverItem.current : 0;
    const startItem = copyListItems[positions];
    const endItem = copyListItems[dragOverItems];
    const arrayIndex = [];

    const drag: ISectionIndex = {
      id: startItem.id,
      index: dragOverItem.current ? dragOverItem.current : 0,
      indexType: "SECTION",
    };

    const drag1: ISectionIndex = {
      id: endItem.id,
      index: dragItem.current ? dragItem.current : 0,
      indexType: "SECTION",
    };

    arrayIndex.push(drag);
    arrayIndex.push(drag1);

    for (var i = 0; i < copyListItems.length; i++) {
      if (i == positions || i == dragOverItems) {
        continue;
      }

      const dragging: ISectionIndex = {
        id: copyListItems[i].id,
        index: i,
        indexType: "SECTION",
      };
      arrayIndex.push(dragging);
    }

    const dragItemContent = {
      ...copyListItems[positions],
      index: dragOverItem.current,
    };
    const dragOverItemContent = {
      ...copyListItems[dragOverItems],
      index: dragItem.current,
    };
    copyListItems[positions] = dragOverItemContent;
    copyListItems[dragOverItems] = dragItemContent;
    positions = 0;
    dragOverItems = 0;
    updateIndex({ post: arrayIndex });

    setSection(copyListItems);
  };

  const handleOnclick = (item: any) => {
    setDisplay(!display);
  };

  const navigateToLms = (sectionItem: any) => {
    navigate(`lms/${sectionItem.id}`);
    refetch();
  };
  return (
    <>
      <WhiteContainer>
        <div className="course-display">
          {data?.data?.course.name}
          <span className="colored-text"> &emsp; &emsp; &emsp; Course </span>
        </div>
        <div>
          {section &&
            section.map((item: any, index: any) => (
              <div
                className="section-display"
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={(e) => drop(e)}
                key={index}
                draggable
              >
                <a
                  onClick={() => navigateToLms(item)}
                  style={{ color: "black" }}
                >
                  {item.name}
                </a>

                <span className="colored-text">
                  {" "}
                  &emsp; &emsp; &emsp; Section{" "}
                </span>
                {display && (
                  <AiOutlineMinusSquare
                    onClick={(e) => handleOnclick(item)}
                    className="expand-plus-icon"
                  />
                )}

                {!display && (
                  <AiOutlinePlusSquare
                    onClick={(e) => handleOnclick(item)}
                    className="expand-plus-icon"
                  />
                )}
                {display && (
                  <Lesson
                    item={item}
                    section={section}
                    sectionFunction={sectionFunction}
                  />
                )}
              </div>
            ))}
        </div>
      </WhiteContainer>
    </>
  );
}

// Rendering Lesson/Question Type
function Lesson(item: any) {
  const { course } = useParams();
  const { data } = useGetPreviewByCourseIdQuery(course);
  const [lesson, setLesson] = useState(item.item.lesson);
  const [display, setDisplay] = useState(false);
  const section = item.section;
  const [updateIndex] = useUpdateIndexMutation();

  useEffect(() => {
    setLesson(item.item.lesson);
  }, [item.item.lesson]);

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e: any, position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const drop2 = (e: any, item2: any) => {
    e.stopPropagation();
    const copyListItems2 = section?.filter(
      (x: any) => x.name === item2.sectionName
    );

    const newSection = section?.filter((x: any) => x.id != item2.sectionId);

    const lessonArray = [...copyListItems2[0].lesson];
    let positions = dragItem.current ? dragItem.current : 0;
    let dragOverItems = dragOverItem.current ? dragOverItem.current : 0;

    const startItem = lessonArray[positions];
    const endItem = lessonArray[dragOverItems];
    const arrayIndex = [];

    const drag: ISectionIndex = {
      id: startItem.id,
      index: dragOverItem.current ? dragOverItem.current : 0,
      indexType: "LESSON",
    };

    const drag1: ISectionIndex = {
      id: endItem.id,
      index: dragItem.current ? dragItem.current : 0,
      indexType: "LESSON",
    };
    arrayIndex.push(drag);
    arrayIndex.push(drag1);

    for (var i = 0; i < lessonArray.length; i++) {
      if (i == positions || i == dragOverItems) {
        continue;
      }

      const dragging: ISectionIndex = {
        id: lessonArray[i].id,
        index: i,
        indexType: "LESSON",
      };
      arrayIndex.push(dragging);
    }

    const dragItemContent = {
      ...lessonArray[positions],
      index: dragOverItem.current,
    };
    const dragOverItemContent = {
      ...lessonArray[dragOverItems],
      index: dragItem.current,
    };
    lessonArray[positions] = dragOverItemContent;
    lessonArray[dragOverItems] = dragItemContent;

    const newLesson = { ...copyListItems2[0], lesson: lessonArray };

    newSection.splice(newLesson.index, 0, newLesson);

    positions = 0;
    dragOverItems = 0;
    updateIndex({ post: arrayIndex });
    item.sectionFunction(newSection);
  };

  const handleOnclick = () => {
    setDisplay(!display);
  };

  return (
    <>
      {lesson.map((item2: any, index2: any) => (
        <div
          className="lesson-display"
          onDragStart={(e) => dragStart(e, index2)}
          onDragEnter={(e) => dragEnter(e, index2)}
          onDragEnd={(e) => drop2(e, item2)}
          key={index2}
          draggable
        >
          {item2.name}
          <span className="colored-text">
            {" "}
            &emsp; &emsp; &emsp; Question Type{" "}
          </span>
          {display && (
            <AiOutlineMinusSquare
              onClick={handleOnclick}
              className="expand-plus-icon"
            />
          )}
          {!display && (
            <AiOutlinePlusSquare
              onClick={handleOnclick}
              className="expand-plus-icon"
            />
          )}

          {display && (
            <Topic
              item2={item2}
              section={section}
              sectionFunction={item.sectionFunction}
            />
          )}
        </div>
      ))}
    </>
  );
}

//Rendering Topic from lesson
function Topic(item2: any) {
  const { course } = useParams();
  const { data } = useGetPreviewByCourseIdQuery(course);
  const [topic, setTopic] = useState(item2.item2.topic);
  const section = item2.section;
  const [updateIndex] = useUpdateIndexMutation();

  useEffect(() => {
    setTopic(item2.item2.topic);
  }, [item2.item2.topic]);

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e: any, position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const drop3 = (e: any, item3: any) => {
    e.stopPropagation();
    const copyListItems3 = section?.filter(
      (x: any) => x.name === item3.sectionName
    )[0];

    const topics = copyListItems3.lesson.filter(
      (y: any) => y.id === item3.lessonId
    );

    const filteredTopic = copyListItems3?.lesson.filter(
      (y: any) => y.id != item3.lessonId
    );

    const newSection = section?.filter((x: any) => x.name != item3.sectionName);

    const topicArray = [...topics[0].topic];
    let positions = dragItem.current ? dragItem.current : 0;
    let dragOverItems = dragOverItem.current ? dragOverItem.current : 0;

    const startItem = topicArray[positions];
    const endItem = topicArray[dragOverItems];
    const arrayIndex = [];

    const drag: ISectionIndex = {
      id: startItem.id,
      index: dragOverItem.current ? dragOverItem.current : 0,
      indexType: "TOPIC",
    };
    const drag1: ISectionIndex = {
      id: endItem.id,
      index: dragItem.current ? dragItem.current : 0,
      indexType: "TOPIC",
    };

    arrayIndex.push(drag);
    arrayIndex.push(drag1);

    for (var i = 0; i < topicArray.length; i++) {
      if (i == positions || i == dragOverItems) {
        continue;
      }

      const dragging: ISectionIndex = {
        id: topicArray[i].id,
        index: i,
        indexType: "TOPIC",
      };
      arrayIndex.push(dragging);
    }

    const dragItemContent = topicArray[positions];
    topicArray[positions] = topicArray[dragOverItems];
    topicArray[dragOverItems] = dragItemContent;

    const newTopic = { ...topics[0], topic: topicArray };
    const updatedLesson = [...filteredTopic];
    updatedLesson.splice(newTopic.index, 0, newTopic);
    const newSectionObj = { ...copyListItems3, lesson: updatedLesson };
    newSection.splice(newSectionObj.index, 0, newSectionObj);

    positions = 0;
    dragOverItems = 0;
    updateIndex({ post: arrayIndex });
    item2.sectionFunction(newSection);
  };

  return (
    <>
      {topic.map((item3: any, index2: any) => (
        <div
          className="topic-display"
          onDragStart={(e) => dragStart(e, index2)}
          onDragEnter={(e) => dragEnter(e, index2)}
          onDragEnd={(e) => drop3(e, item3)}
          key={index2}
          draggable
        >
          {item3?.name}
          <span className="colored-text"> &emsp; &emsp; &emsp; Topic </span>
        </div>
      ))}
    </>
  );
}

export default Preview;
