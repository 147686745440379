import { createSlice } from "@reduxjs/toolkit";
import { IQuestionBank } from "../../types/questionBank";

export interface uiState {
  loggedInUserName: string | null;
  selectedCourse: number | null;
  selectedSection: number | null;
  selectedQuestionType: number | null;
  selectedTopic: number | null;
  selectedFilteredQuestion: IQuestionBank[] | [];
  selectedNoOfQuestion: number | null;
  titlePreview: string | null;
  previewHeader: string | null;
  arrayPreview: [] | null;
  selectedGroup: number | null;
  //student Panel
  selectedStudentSection: number | null;
  selectedStudentQuestionType: number | null;
  studentCourseProgressData: number | undefined;
  admin: boolean | null;
  token: string | null;
  buttonDisabled: boolean;
  studentBreadcrumb: [];
  courseName: string | null;
  mylearning: any;
}

const initialState: uiState = {
  buttonDisabled: false,
  loggedInUserName: null,
  selectedCourse: null,
  selectedSection: null,
  selectedQuestionType: null,
  selectedTopic: null,
  selectedFilteredQuestion: [],
  selectedNoOfQuestion: null,
  titlePreview: null,
  previewHeader: null,
  arrayPreview: null,
  selectedGroup: null,
  //student Panel
  selectedStudentSection: null,
  selectedStudentQuestionType: null,
  studentCourseProgressData: 0,
  admin: null,
  token: null,
  studentBreadcrumb: [],
  courseName: null,
  mylearning: null
};
export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setButtonDisabled: (state, { payload }) => {
      state.buttonDisabled = payload;
    },
    setLoggedInUserName: (state, { payload }) => {
      state.loggedInUserName = payload;
    },
    setSelectedCourse: (state, { payload }) => {
      state.selectedCourse = payload;
    },
    setSelectedSection: (state, { payload }) => {
      state.selectedSection = payload;
    },
    setSelectedQuestionType: (state, { payload }) => {
      state.selectedQuestionType = payload;
    },
    setSelectedTopic: (state, { payload }) => {
      state.selectedTopic = payload;
    },
    setSelectedFilteredQuestion: (state, { payload }) => {
      state.selectedFilteredQuestion = payload;
    },
    setSelectedNoOfQuestion: (state, { payload }) => {
      state.selectedNoOfQuestion = payload;
    },
    setTitlePreview: (state, { payload }) => {
      state.titlePreview = payload;
    },
    setPreviewHeader: (state, { payload }) => {
      state.previewHeader = payload;
    },
    setArrayPreview: (state, { payload }) => {
      state.arrayPreview = payload;
    },
    setSelectedGroup: (state, { payload }) => {
      state.selectedGroup = payload;
    },
    // student Panel
    setSelectedStudentSection: (state, { payload }) => {
      state.selectedStudentSection = payload;
    },
    setSelectedStudentQuestionType: (state, { payload }) => {
      state.selectedStudentQuestionType = payload;
    },
    setStudentCourseProgressData: (state, { payload }) => {
      state.studentCourseProgressData = payload;
    },
    setUserTypeState: (state, { payload }) => {
      state.admin = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setStudentBreadcrumb: (state, { payload }) => {
      state.studentBreadcrumb = payload;
    },
    setCourseName: (state, { payload }) => {
      state.courseName = payload;
    },
    setLearningState: (state, { payload }) => {
      state.mylearning = payload;
    },
  },
});

export const {
  setButtonDisabled,
  setSelectedCourse,
  setSelectedSection,
  setSelectedQuestionType,
  setSelectedTopic,
  setSelectedFilteredQuestion,
  setSelectedNoOfQuestion,
  setTitlePreview,
  setPreviewHeader,
  setArrayPreview,
  setSelectedGroup,
  setSelectedStudentSection,
  setSelectedStudentQuestionType,
  setStudentCourseProgressData,
  setLoggedInUserName,
  setUserTypeState,
  setToken,
  setStudentBreadcrumb,
  setCourseName,
  setLearningState
} = uiSlice.actions;

export default uiSlice.reducer;
