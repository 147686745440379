import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { bgColor, colorPrimary } from "./constants/theme";
import { checkBoxGreenColor, headingGreyColor } from "./constants/commonColors";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary,
              colorBgLayout: bgColor,
              fontFamily: "Inter-Regular",
              borderRadius: 3,
            },
            components: {
              Checkbox: {
                colorPrimary: checkBoxGreenColor,
                colorBgContainerDisabled: "#fff",
                colorTextDisabled: "#000",
                colorPrimaryHover: checkBoxGreenColor,
              },
              Form: {
                colorTextHeading: headingGreyColor,
              },
              Table: {
                fontWeightStrong: 300,
                colorTextHeading: headingGreyColor,
                fontSize: 12,
                paddingContentHorizontal: 3,
                paddingContentVertical: 2,
              },
            },
          }}
        >
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
