import GlobalStyles from "./components/styled/Global";
import "./App.css";
import AdminRoutes from "./routes/adminRoutes";
import StudentRoutes from "./routes/studentRoutes";


function App() {

  return (
    <>
      <GlobalStyles />
      {
        process.env.REACT_APP_IS_ADMIN === 'true' ? <AdminRoutes /> : <StudentRoutes />
      }
    </>
  );
}

export default App;
