import React, { useEffect, useState } from "react";
import { Input, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../constants/pageSize";
import { IStudent } from "../../types/studentType";
import { useGetAllStudentsQuery } from "../../features/student/studentsApiSlice";
import { IGroupByIdResponse } from "../../types/group";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

type AssignCoursesProps = {
  setAssignStudentsId?: any;
  courseData?: IGroupByIdResponse;
};

function AssignStudents(props: AssignCoursesProps) {
  const { setAssignStudentsId, courseData } = props;
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >([]);
  const [searchText, setSearchText] = useState("");
  const [group, setGroup] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [course, setCourse] = useState("");

  const preSelectedstudents = courseData?.data.students.map(
    (item: any) => item["id"]
  );

  const { debouncedValue: studentName, debouncing: studentSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: phone, debouncing: phoneSearching } =
    useDebouncingValue(phoneNumber);
  const { debouncedValue: groupName, debouncing: groupSearching } =
    useDebouncingValue(group);
  const { debouncedValue: courseName, debouncing: courseSearching } =
    useDebouncingValue(course);

  const { data, isLoading, isFetching } = useGetAllStudentsQuery({
    currentPage,
    pageSize,
    courseName,
    name: studentName,
    phoneNumber: phone,
    groupName,
  });

  useEffect(() => {
    setSelectedRowKeys(preSelectedstudents || []);
  }, []);

  const columns: ColumnsType<IStudent> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Student Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "group",
      title: (
        <div>
          <Typography.Text>Group</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setGroup(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "groupName",
    },
    {
      key: "phoneNumber ",
      title: (
        <div>
          <Typography.Text>Phone Number</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),

      dataIndex: "phoneNumber",
    },
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourse(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "courseName",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setAssignStudentsId(newSelectedRowKeys.map((id) => ({ id })));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Table<IStudent>
        rowSelection={{ ...rowSelection, preserveSelectedRowKeys: true }}
        loading={
          isLoading ||
          isFetching ||
          studentSearching ||
          phoneSearching ||
          groupSearching ||
          courseSearching
        }
        rowKey="id"
        columns={columns}
        dataSource={data?.data.content}
        pagination={{
          pageSize,
          current: currentPage,
          total: data?.data.totalElements,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
      />
    </>
  );
}

export default AssignStudents;
