import { mainApi } from "../../../app/services/mainApiSlice";

import {
  IErrorLogResponse,
  IErrorLogResponseById,
} from "../../../types/studentPanelTypes/errorLog";

const commonUrl: string = "/student/quiz";

const studentQuizApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllErrorLog: builder.query<
      IErrorLogResponse,
      {
        currentPage: number;
        pageSize: number;
        id: string | undefined | null;
        courseId: string | undefined;
      }
    >({
      query: ({ pageSize, id, courseId, currentPage }) => ({
        url: `${commonUrl}/get-all-errorLog/${id}?courseId=${courseId}&page=${
          currentPage - 1
        }&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENT" }],
    }),

    getErrorLogById: builder.query<
      IErrorLogResponseById,
      { questionId: string | undefined | null; id: string | undefined | null }
    >({
      query: ({ questionId, id }) => ({
        url: `${commonUrl}/get-errorLog/${id}?questionId=${questionId}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENT" }],
    }),

    getNotesById: builder.query<
      IErrorLogResponse,
      {
        id: string | undefined | null;
        pageSize: number;
      }
    >({
      query: ({ id, pageSize }) => ({
        url: `${commonUrl}/get-Note/${id}?page=0&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENT" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllErrorLogQuery,
  useGetErrorLogByIdQuery,
  useGetNotesByIdQuery,
} = studentQuizApi;
