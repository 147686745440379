import { Col, Input, Row, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import Category from "../../../components/selectFormOptions/Category";
import DifficultyLevel from "../../../components/selectFormOptions/DifficultyLevel";
import SelectCourseOptions from "../../../components/selectFormOptions/SelectCourseOptions";
import SelectQuestionTypeOptions from "../../../components/selectFormOptions/SelectQuestionTypeOptions";
import SelectSectionOptions from "../../../components/selectFormOptions/SelectSectionOptions";
import { IQuizByIdResponse } from "../../../types/quizzes";
import { ITutorQuizByIdResponse } from "../../../types/tutorPanelTypes/tutorQuiz";

type QuizzesFormProps = {
  data?: ITutorQuizByIdResponse;
};

function ViewQuizDetailsForm(props: QuizzesFormProps) {
  const { data } = props;

  const [form] = Form.useForm();

  const initialValues = {
    quizName: data?.data.quizName,
    quizMode: data?.data.quizMode,
    quizTime: data?.data.quizTime,
    quizQuestion: "Manual Selection",
    noOfQuestion: data?.data.numberOfQuestions,
    questionCategory: data?.data.questions[0].questionCategory,
    courseId: data?.data.courses[0].name,
    sectionId: data?.data.sections[0].name,
    lessonId: data?.data.lessons[0].name,
    difficultyLevel: data?.data.questions[0].difficultyLevel,
    topicIds: data?.data.topics.map((topic) => `${topic.name}`),
  };

  return (
    <>
      <Form
        layout="vertical"
        initialValues={initialValues}
        form={form}
        disabled
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <Form.Item label="Quiz Name" name="quizName">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Quiz Mode" name="quizMode">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Time Duration(in min)" name="quizTime">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Quiz Question" name="quizQuestion">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Number of Question" name="noOfQuestion">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Category
                  label="Question Category"
                  name="questionCategory"
                  form={form}
                />
              </Col>
              <Col span={24}>
                <DifficultyLevel
                  label="Difficulty Level"
                  name="difficultyLevel"
                  form={form}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <SelectCourseOptions label="Course" name="courseId" />
              </Col>
              <Col span={24}>
                <SelectSectionOptions
                  label="Section"
                  name="sectionId"
                  form={form}
                />
              </Col>
              <Col span={24}>
                <SelectQuestionTypeOptions
                  label="Question Type"
                  name="lessonId"
                  form={form}
                />
              </Col>
              <Col span={24}>
                <Form.Item name="topicIds" label="Topics">
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ViewQuizDetailsForm;
