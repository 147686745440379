import { Input, Select, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useState, useEffect } from "react";
import { ICourse } from "../../../types/course";
import { IQuizzes } from "../../../types/quizzes";
import { IGroupByIdResponse } from "../../../types/group";
import pageSize from "../../../constants/pageSize";
import { useGetAllTutorQuizQuery } from "../../../features/tutorPanelFeatures/TutorQuizzes/tutorQuizApiSlice";
import secureLocalStorage from "react-secure-storage";
import { useDebouncingValue } from "../../../utils/useDebouncingValue";
import { quizModeConstant } from "../../../utils/quizSearchConstants";
import { ITutorQuiz } from "../../../types/tutorPanelTypes/tutorQuiz";

type GroupsAssignQuizProps = {
  setAssignQuizzesId?: any;
  data?: IGroupByIdResponse;
};

function GroupsAssignQuiz(props: GroupsAssignQuizProps) {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const { data, setAssignQuizzesId } = props;
  const [currentPage, setCurrentPage] = useState(1);

  const preSelectedQuizzes = data?.data.quizzes.map((ele: any) => ele["id"]);
  const [name, setName] = useState("");
  const [mode, setMode] = useState("");
  const [course, setCourse] = useState("");
  const [questions, setQuestions] = useState("");

  const { debouncedValue: quizName, debouncing: quizNameSearching } =
    useDebouncingValue(name);
  const { debouncedValue: quizMode, debouncing: modeSearching } =
    useDebouncingValue(mode);
  const { debouncedValue: courseName, debouncing: courseSearching } =
    useDebouncingValue(course);

  const {
    data: quizData,
    isLoading,
    isFetching,
  } = useGetAllTutorQuizQuery({
    pageSize,
    currentPage,
    idFromLocalStorage,
    courseName,
    quizMode,
    quizName,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >([]);

  useEffect(() => {
    setSelectedRowKeys(preSelectedQuizzes || []);
  }, []);

  const columns: ColumnsType<ITutorQuiz> = [
    {
      key: "quizName",
      title: (
        <div>
          <Typography.Text>Quiz Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "quizName",
    },
    {
      key: "quizMode",
      title: (
        <div>
          <Typography.Text>Mode</Typography.Text>
          <Select
            onChange={(value) => {
              setMode(value);
            }}
            options={quizModeConstant}
            className="searchDropdownStyle"
          />
        </div>
      ),
      dataIndex: "quizMode",
    },
    {
      key: "courses",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourse(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "courses",
      render: (courses: ICourse[]) =>
        courses.map((course: ICourse) => course.name).join(","),
    },
    {
      key: "questions",
      dataIndex: "numberOfQuestions",
      title: (
        <div>
          <Typography.Text>No. of Questions</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setQuestions(e.target.value);
            }}
            placeholder="Search here"
            disabled={true}
          />
        </div>
      ),
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setAssignQuizzesId(newSelectedRowKeys.map((id) => ({ id })));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (
      (quizName !== "" || quizMode !== "" || courseName !== "") &&
      currentPage > 1
    ) {
      setCurrentPage(1);
    }
  }, [quizName, quizMode, courseName, currentPage]);

  return (
    <>
      <Table<ITutorQuiz>
        rowKey="id"
        loading={
          isLoading ||
          isFetching ||
          quizNameSearching ||
          modeSearching ||
          courseSearching
        }
        columns={columns}
        dataSource={quizData?.data?.content}
        rowSelection={{ ...rowSelection, preserveSelectedRowKeys: true }}
        pagination={{
          pageSize,
          current: currentPage,
          total: quizData?.data.totalElements,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
      />
    </>
  );
}

export default GroupsAssignQuiz;
