import { Input, Table, Typography, Row, DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../../constants/pageSize";
import CustomCard from "../../../components/countCard/CustomCard";
import { ViewDetailsButton } from "../../../components/styled/Button.styled";
import { IGroup } from "../../../types/group";
import dayjs, { Dayjs } from "dayjs";
import { useGetAllTutorGroupQuery } from "../../../features/tutorPanelFeatures/TutorGroups/groupsAPISlice";
import secureLocalStorage from "react-secure-storage";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../../utils/useDebouncingValue";

function TutorGroups() {
  const navigate = useNavigate();
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [noOfStudents, setNoOfStudents] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { debouncedValue: groupName, debouncing: nameSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: date, debouncing: dateSearching } =
    useDebouncingValue(startDate);
  const { debouncedValue: student, debouncing: studentSearching } =
    useDebouncingValue(noOfStudents);

  const { data, isLoading, isFetching } = useGetAllTutorGroupQuery({
    pageSize,
    currentPage,
    idFromLocalStorage,
    groupName,
    startDate: date,
    students: student,
  });

  const columns: ColumnsType<IGroup> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Group Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "startDate",
      title: (
        <div>
          <Typography.Text>Start Date</Typography.Text>
          <DatePicker
            onChange={(e) => {
              setStartDate(
                e !== null ? dayjs(e).format("YYYY-MM-DD 00:00:00") : ""
              );
              setCurrentPage(1);
            }}
          />
        </div>
      ),
      dataIndex: "startDate",
      render: (_, record) => (
        <>
          {dayjs(record.startDate)
            .toISOString()
            .split("-")
            .reverse()[0]
            .split("T")[0] +
            dayjs(record.startDate)
              .toISOString()
              .split("-")
              .reverse()
              .join("-")
              .split("Z")[1]}
        </>
      ),
    },
    {
      key: "studentCount",
      title: (
        <div>
          <Typography.Text>No. of Students</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setNoOfStudents(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
            disabled
          />
        </div>
      ),
      dataIndex: "studentCount",
    },
    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (text, record) => (
        <ViewDetailsButton onClick={() => openGroupDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
  ];

  const openGroupDetails = (id: number) => {
    navigate(`view/${id}`);
  };

  return (
    <div>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard sum={data?.data?.totalElements} text="Groups" />
      </Row>

      <Row className="styled-container outer-styled-container">
        <Row className="styled-container inner-styled-container">
          <Table<IGroup>
            loading={
              isLoading ||
              isFetching ||
              nameSearching ||
              dateSearching ||
              studentSearching
            }
            rowKey="id"
            columns={columns}
            dataSource={data?.data?.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: data?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </Row>
      </Row>
    </div>
  );
}

export default TutorGroups;
