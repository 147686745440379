import { Col, DatePicker, Row, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  DarkGrayContainer,
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import ContainerHeading from "../../components/styled/ContainerHeading.styled";
import pageSize from "../../constants/pageSize";
import { useGetTutorAnalyticsTimesheetQuery } from "../../features/tutorPanelFeatures/TutorAnalytics/tutorAnalyticsApiSlice";
import { useGetTutorProfileQuery } from "../../features/tutorPanelFeatures/TutorProfile/tutorProfileSlice";
import { ITutorAnalytics } from "../../types/tutorPanelTypes/tutorAnalytics";
import styled from "styled-components";

const dateFormat = "YYYY-MM-DD";
const currentDate = new Date(Date.now());

let endDate = dayjs(currentDate).format(dateFormat);
let startDate = dayjs(currentDate.setDate(currentDate.getDate() - 7)).format(
  dateFormat
);

const TimeText = styled.span`
  color: #f37373;
  font-size: 1.5rem;
  font-weight: 600;
`;

const TutorNameText = styled(ContainerHeading)`
  padding: 0;
`;

function TimeSheet() {
  const { id: idFromLocalStorage } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [startingDate, setStartingDate] = useState(startDate);
  const [endingDate, setEndingDate] = useState(endDate);

  const { data, isLoading, isFetching } = useGetTutorAnalyticsTimesheetQuery({
    idFromLocalStorage,
    startDate,
    endDate,
    pageSize,
    currentPage,
  });
  const { data: tutorData } = useGetTutorProfileQuery(idFromLocalStorage);
  const time = data?.data.totalHours;
  const hours = Math.floor(time ? time / 60 : 0);
  const minutes = Math.floor(time ? time % 60 : 0);

  const handleStartOnchange = (date: Dayjs | null) => {
    if (date) {
      const rangeDate = date.toString();
      startDate = dayjs(rangeDate).format(dateFormat);
      setStartingDate(startDate);
    }
  };

  const handleEndOnchange = (date: Dayjs | null) => {
    if (date) {
      const rangeDate = date.toString();
      endDate = dayjs(rangeDate).format(dateFormat);
      setEndingDate(endDate);
    }
  };

  const columns: ColumnsType<ITutorAnalytics> = [
    {
      key: "date",
      title: (
        <div>
          <Typography.Text>Date</Typography.Text>
        </div>
      ),
      dataIndex: "date",
    },
    {
      key: "sessionDuration",
      title: (
        <div>
          <Typography.Text>Session Duration</Typography.Text>
        </div>
      ),
      dataIndex: "sessionDuration",
      render: (_, record) => (
        <>
          {`${
            record.sessionDuration ? Math.floor(record.sessionDuration / 60) : 0
          } Hr. ${Math.floor(record.sessionDuration) % 60} Min.`}
        </>
      ),
    },
  ];

  return (
    <>
      <DarkGrayContainer>
        <LightGrayContainer>
          <Row gutter={10} className="mb-1">
            <Col span={8}>
              <TutorNameText>
                <span>{tutorData?.data?.name}</span>
              </TutorNameText>
            </Col>
            <Col span={7}>
              <Row align={"middle"}>
                <Col>Total Hours:&nbsp;&nbsp;</Col>
                <Col>
                  <TimeText>
                    {hours < 10 ? `0${hours}` : hours}:
                    {minutes < 10 ? `0${minutes}` : minutes}
                  </TimeText>
                </Col>
              </Row>
            </Col>

            <Col>
              <DatePicker
                format={dateFormat}
                onChange={handleStartOnchange}
                placeholder={"Start Date"}
                defaultValue={dayjs(startDate, dateFormat)}
              />
              <DatePicker
                format={dateFormat}
                onChange={handleEndOnchange}
                placeholder={"End Date"}
                defaultValue={dayjs(endDate, dateFormat)}
              />
            </Col>
          </Row>
          <WhiteContainer>
            <Table<ITutorAnalytics>
              loading={isLoading || isFetching}
              rowKey={"id"}
              columns={columns}
              dataSource={data?.data.tutorSessionDTO}
              pagination={{
                pageSize,
                current: currentPage,
                total: data?.data.totalElements,
                onChange(page) {
                  setCurrentPage(page);
                },
              }}
            />
          </WhiteContainer>
        </LightGrayContainer>
      </DarkGrayContainer>
    </>
  );
}

export default TimeSheet;
