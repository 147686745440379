import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  h1,h2,h3,h4{
    font-family:HK Grotesk-Medium !important;
    color:#201e43 !important;
    font-weight:500 !important;
  }
`;

export default GlobalStyles;
