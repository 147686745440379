import { FormInstance } from "antd/es/form/Form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useGetTopicByQuestionTypeIdQuery } from "../../features/internalCourse/topicICApiSlice";
import { setSelectedQuestionType } from "../../features/ui/uiSlice";
import { useEffect, useMemo } from "react";
import { ITopic } from "../../types/topic";
import { ITopicSelectTreeData } from "../../types/common";
import TopicTreeSelect from "./TopicTreeSelect";

type ITopicOptionsProps = {
  name: [number, string] | string;
  form: FormInstance;
  label?: string;
};

function SelectTopicOptions(props: ITopicOptionsProps) {
  const { form, name, label } = props;
  const dispatch = useAppDispatch();
  const selectedQuestionType = useAppSelector(
    (state: RootState) => state.ui.selectedQuestionType
  );

  const { data, isFetching, isLoading } = useGetTopicByQuestionTypeIdQuery(
    selectedQuestionType || form?.getFieldValue("lessonId")
  );

  const getOptions = (
    topicsData: ITopic[] | undefined
  ): ITopicSelectTreeData[] | undefined => {
    return topicsData?.map(({ id, name, subTopics }) => ({
      value: id,
      title: name,
      children: subTopics && subTopics.length > 0 ? getOptions(subTopics) : [],
    }));
  };

  const mappedData: ITopicSelectTreeData[] | undefined = useMemo(
    () => getOptions(data?.data),
    [data]
  );

  useEffect(() => {
    if (selectedQuestionType) {
      form?.setFieldValue(name, "");
    }
    return () => {
      dispatch(setSelectedQuestionType(""));
    };
  }, [form, selectedQuestionType, dispatch, name]);

  return (
    <TopicTreeSelect
      label={label}
      {...props}
      isFetching={isFetching}
      isLoading={isLoading}
      treeData={mappedData}
    />
  );
}

export default SelectTopicOptions;
