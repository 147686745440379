import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

type PptFileViewerProps = {
  uri: string;
};

function PptFileViewer(props: PptFileViewerProps) {
  const { uri } = props;
  return (
    <DocViewer
      documents={[{ uri, fileType: "pptx" }]}
      pluginRenderers={DocViewerRenderers}
      config={{
        header: {
          disableHeader: true,
        },
      }}
    />
  );
}

export default PptFileViewer;
