import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { sideMenuBgColor } from "../../constants/theme";
import SiderMenuItems from "./SiderMenuItems";

const { Content, Sider } = Layout;
const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  // padding: 0 1rem;
`;
function StudentPanelCourseLayout() {
  return (
    <Layout>
      <Sider width="250" style={{ backgroundColor: `${sideMenuBgColor}` }}>
        <SiderMenuItems />
      </Sider>
      <Layout style={{ background: "white" }}>
        <Content>
          <OutletContainer>
            <Outlet />
          </OutletContainer>
        </Content>
      </Layout>
    </Layout>
  );
}

export default StudentPanelCourseLayout;
