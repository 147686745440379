import { Input, Typography, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { ICourse } from "../../../types/course";
import { ISection } from "../../../types/section";
import { IStudentByIdResponse } from "../../../types/studentType";
import pageSize from "../../../constants/pageSize";

type SectionsProps = {
  data?: IStudentByIdResponse;
};
function Sections(props: SectionsProps) {
  const { data } = props;
  const [searchText, setSearchText] = useState("");
  const [duration, setDuration] = useState("");
  const [section, setSection] = useState("");

  const columns: ColumnsType<ICourse> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
      filteredValue: [searchText],
      onFilter: (value: any, record) => {
        return record.name?.toLowerCase().includes(value?.toLowerCase());
      },
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSection(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "section",
      render: (sections: ISection[]) =>
        sections.map((section: ISection) => section.name).join(", "),
      filteredValue: [section],
      onFilter: (value: any, record) => {
        return record.section
          .map((section: ISection) => section.name)
          .join(" , ")
          ?.toLowerCase()
          .includes(value?.toLowerCase());
      },
    },
    {
      key: "duration",
      title: (
        <div>
          <Typography.Text>Duration(in Hrs.)</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setDuration(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [duration],
      onFilter: (value: any, record) => {
        return record?.duration
          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      dataIndex: "duration",
    },
  ];
  return (
    <>
      <Table<ICourse>
        dataSource={data?.data?.assignCourse}
        columns={columns}
        rowKey={"id"}
        pagination={{
          pageSize,
        }}
      />
    </>
  );
}

export default Sections;
