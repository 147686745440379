import { Form } from "antd";
import TinyMce from "../common/TinyMce";

type TextViewerProps = {
  textData: string;
};

function TextViewer(props: TextViewerProps) {
  const { textData } = props;

  return (
    <>
      {textData && (
        <Form
          style={{ width: "100%" }}
          className="textViewer"
          initialValues={{ text: textData }}
          key={textData}
        >
          <TinyMce name="text" readOnly={true} />
        </Form>
      )}
    </>
  );
}

export default TextViewer;
