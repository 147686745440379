import { Form, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Rule } from "rc-field-form/lib/interface";

import { useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useAppDispatch } from "../../../app/hooks";
import { useGetAllMyLearningByCourseIdQuery } from "../../../features/studentPanelFeatures/StudentMyLearning/myLearningApiSlice";
import { setSelectedStudentSection } from "../../../features/ui/uiSlice";


const { Option } = Select;

type ISectionOptionsProps = {
  label: string;
  name: string;
  rules?: Rule[];
  form: FormInstance;
};

function SelectSectionOptions(props: ISectionOptionsProps) {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const { courseId } = useParams();
  const dispatch = useAppDispatch();
  const { data } = useGetAllMyLearningByCourseIdQuery({
    id: idFromLocalStorage,
    courseId: courseId,
  });

  return (
    <Form.Item {...props}>
      <Select onChange={(e) => dispatch(setSelectedStudentSection(e))}  placeholder = "Select Section">
        {data?.data.map((item) =>
          item.course.section.map((option) => (
            <Option key={option.id} value={option.id}>
              {option.name}
            </Option>
          ))
        )}
      </Select>
    </Form.Item>
  );
}

export default SelectSectionOptions;
