import { Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import ViewQueryDetails from "../../components/queries/ViewQueryDetails";
import { PrimaryButton } from "../../components/styled/Button.styled";
import { useGetQueryByIdQuery } from "../../features/queries/queriesApiSlice";
import QueryBody from "./QueryBody";

function ViewQueries() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, isError } = useGetQueryByIdQuery(id);
  const onClose = () => {
    navigate(-1);
  };

  return (
    <>
      <Breadcrumbs idName={!!id ? 'query' : 'false'} isId={!!id ? true : false} />
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </Col>
      </Row>
      <Row className="styled-container outer-styled-container">
        <Row gutter={[5, 5]}>
          <Col span={24}>
            <strong>{data?.data?.at(0)?.studentName}</strong>
          </Col>
          <Col>{data?.data?.at(0)?.courseName}</Col>
        </Row>

        <Row className="styled-container inner-styled-container">
          {!isError &&
            !isLoading &&
            data?.data?.map((item) => (
              <ViewQueryDetails date={item?.queryDate} message={item?.queryDetail} />
            ))}
        </Row>
      </Row>
    </>
  );
}

export default ViewQueries;
