import { Col, List, Row, Typography } from "antd";

type collapsibleProps = {
  data: any;
};

const { Text } = Typography;

const GroupRowCollapsible = ({ data }: collapsibleProps) => {
  return (
    <>
      <List
        dataSource={data}
        renderItem={(item: any, index) => (
          <List.Item key={index}>
            <Col span={6}>
              <Row justify={"start"}>
                <Text type="secondary"> {item.name}</Text>
              </Row>
            </Col>
            <Col span={6}>
              <Row justify={"start"}>
                <Text type="secondary">{item.email}</Text>
              </Row>
            </Col>
            <Col span={4}>
              <Row justify={"start"}>
                <Text type="secondary">{item.phoneNumber}</Text>
              </Row>
            </Col>
          </List.Item>
        )}
      ></List>
    </>
  );
};

export default GroupRowCollapsible;
