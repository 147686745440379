import { Checkbox, Col, Form, Row } from "antd";
import { Rule } from "rc-field-form/lib/interface";

import type { IValueLabel } from "../../types/common";

type IProps = {
  label: string;
  name: string;
  rules?: Rule[];
  data: IValueLabel[] | undefined;
};

function BorderedCheckboxes(props: IProps) {
  const { data, label, name, rules } = props;

  return (
    <Form.Item label={label}>
      <Row className="bordered-checkbox-container">
        <Form.Item name={name} rules={rules}>
          <Checkbox.Group>
            <Row>
              {data?.map((option) => (
                <Col span={24} key={option.value}>
                  <Checkbox value={option.value}>{option.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Row>
    </Form.Item>
  );
}

export default BorderedCheckboxes;
