import styled from "styled-components";
import AuthPagesHeader from "../../../components/layout/studentPanelHeader/AuthPagesHeader";
import { WhiteContainer } from "../../../components/styled/Container.styled";
import { Col, Form, Input, notification, Row, Typography } from "antd";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSignInMutation } from "../../../features/login/loginApiSlice";
import secureLocalStorage from "react-secure-storage";

const LoginPageContainer = styled(WhiteContainer)`
  border-radius: 0 0 3px 3px;
  background-color: #f9fafb;
  justify-content: space-evenly;
  align-items: center;
  height: 80vh;
  width: 95vw;
  margin: 0 auto 1rem auto;
`;

const LoginFormContainer = styled(WhiteContainer)`
  height: auto;
  min-height: 60%;
  width: 50%;
  padding: 2rem 2rem;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
`;

const { Title, Text } = Typography;
function StudentLogin() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signIn] = useSignInMutation();

  const handleLogin = () => {
    signIn({
      username: email,
      userType: "STUDENT",
      password: password,
    })
      .unwrap()
      .then((data) => {
        const { roles, id, accessToken, username } = data;

        secureLocalStorage.setItem("name", username);

        secureLocalStorage.setItem("role", roles.toString());

        secureLocalStorage.setItem("id", id.toString());

        secureLocalStorage.setItem("token", accessToken);

        if (roles.includes("ROLE_STUDENT")) {
          navigate("/");
        }
        notification.success({
          message: "Successfully logged in",
          placement: "bottomLeft",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        });
      });
  };
  return (
    <>
      <AuthPagesHeader />
      <LoginPageContainer>
        <Row justify={"center"}>
          <Title className="student-welcome-text" level={3}>
            Welcome student!
          </Title>
        </Row>

        <LoginFormContainer>
          <Form layout="vertical">
            <Row gutter={[32, 20]}>
              <Col span={24}>
                <Form.Item
                  label="Email address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Input is not a valid email",
                    },
                    {
                      required: true,
                      message: "Please enter your email",
                    },
                  ]}
                >
                  <Input
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                    color="#00000080"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Row justify="end">
                  <Link to={"/forgot-password"}>
                    <Text className="text-primary">Forgot Password?</Text>
                  </Link>
                </Row>
              </Col>

              <Col span={24}>
                <Row justify="center" className="mb-1">
                  <Form.Item>
                    <PrimaryButton htmlType="submit" onClick={handleLogin}>
                      Login
                    </PrimaryButton>
                  </Form.Item>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  {/* TODO: Need to fix later */}
                  {/* <Text>
                    Don't have an account?
                    <span className="text-primary"> Register Here</span>
                  </Text> */}
                </Row>
              </Col>
            </Row>
          </Form>
        </LoginFormContainer>
      </LoginPageContainer>
    </>
  );
}

export default StudentLogin;
