import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "../../components/layout/Layout";
import ExternalCourses from "../../pages/ExternalCourses";
//Admin Profile
import AdminProfile from "../../pages/AdminProfile/AdminProfile";

// Internal Courses
import Courses from "../../pages/InternalCourses/Courses";
import QuestionType from "../../pages/InternalCourses/QuestionType";
import Section from "../../pages/InternalCourses/Section";
import Topics from "../../pages/InternalCourses/Topics";
import AddCourse from "../../pages/InternalCourses/AddCourse";
import EditCourse from "../../pages/InternalCourses/EditCourse";
import AddSection from "../../pages/InternalCourses/AddSection";
import EditSection from "../../pages/InternalCourses/EditSection";
import AddQuestionType from "../../pages/InternalCourses/AddQuestionType";
import EditQuestionType from "../../pages/InternalCourses/EditQuestionType";
import AddTopic from "../../pages/InternalCourses/AddTopic";
import EditTopic from "../../pages/InternalCourses/EditTopic";
//Preview
import PreviewLayout from "../../components/preview/PreviewLayout";
import Preview from "../../pages/InternalCourses/Preview/Preview";
import LmsViewLayout from "../../components/preview/LmsViewLayout";
// Students
import AddStudents from "../../pages/Students/AddStudents/AddStudents";
import StudentsDetails from "../../pages/Students/ViewStudentsDetails/StudentsDetails";
import Students from "../../pages/Students/Students";
//Quizzes
import Quizzes from "../../pages/Quizzes/Quizzes";
import ViewQuizDetails from "../../pages/Quizzes/ViewQuizDetails";
import QuestionBank from "../../pages/Quizzes/QuestionBank";
import AddQuizGenerator from "../../pages/Quizzes/AddQuizGenerator";
import EditQuestionBank from "../../pages/Quizzes/EditQuestionBank";
import AddQuestionBank from "../../pages/Quizzes/AddQuestionBank";

//Resources
import Resources from "../.././pages/Resources/Resources";
import AddResources from "../../pages/Resources/AddResources";
import EditResource from "../../pages/Resources/EditResource";
// Tutor
import Tutors from "../../pages/Tutor/Tutors";
import TutorDetails from "../../pages/Tutor/TutorDetails";
import AddTutor from "../../pages/Tutor/AddTutor/AddTutor";

//Queries
import Queries from "../../pages/Queries/Queries";
import ViewQueries from "../../pages/Queries/ViewQueries";

//Group
import Groups from "../../pages/Group/Groups";
import GroupDetails from "../../pages/Group/GroupDetails";

import Analytics from "../../pages/Analytics/Analytics";
import TutorAnalytics from "../../pages/Analytics/TutorAnalytics";
import StudentAnalytics from "../../pages/Analytics/StudentAnalytics";
import CreateGroup from "../../pages/Group/CreateGroup";

//Dashboard
import Dashboard from "../../pages/Dashboard";

import AdminNotification from "../../pages/AdminNotification";

import ViewAnalyticsQuizDetails from "../../pages/tutorPages/Analytics/ViewAnalyticsQuizDetails";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";

//viewers
import ResourceFileViewer from "../../pages/InternalCourses/Preview/ResourceFileViewer";
import QuizViewer from "../../pages/InternalCourses/Preview/QuizViewer";
import CourseIntroViewer from "../../pages/InternalCourses/Preview/CourseIntroViewer";
import Login from "../../pages/login/Login";
import CreateNewPassword from "../../pages/login/CreateNewPassword";
import ForgotPassword from "../../pages/login/ForgotPassword";

const SubAdminRoutes = () => {
  return (
    <Routes>
      <>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id" element={<CreateNewPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route path="" element={<PrivateRoute />}>
            <Route index element={<Dashboard />} />
            <Route path="admin-notification" element={<AdminNotification />} />
            <Route path="administrator-profile" element={<AdminProfile />} />

            <Route path="external-courses" element={<ExternalCourses />} />
            <Route path="internal-courses">
              <Route index element={<Courses />} />
              <Route path="add" element={<AddCourse />} />
              <Route path=":id" element={<EditCourse />} />

              <Route path="section">
                <Route index element={<Section />} />
                <Route path="add" element={<AddSection />} />
                <Route path=":id" element={<EditSection />} />
              </Route>

              <Route path="question-type">
                <Route index element={<QuestionType />} />
                <Route path="add" element={<AddQuestionType />} />
                <Route path=":id" element={<EditQuestionType />} />
              </Route>

              <Route path="topics">
                <Route index element={<Topics />} />
                <Route path="add" element={<AddTopic />} />
                <Route path=":id" element={<EditTopic />} />
              </Route>
            </Route>

            <Route path="tutors">
              <Route index element={<Tutors />} />
              <Route path="add" element={<AddTutor />} />
              <Route path=":id" element={<TutorDetails />} />
            </Route>

            <Route path="students">
              <Route index element={<Students />} />
              <Route path="add" element={<AddStudents />} />
              <Route path=":id" element={<StudentsDetails />} />
            </Route>

            <Route path="groups">
              <Route index element={<Groups />} />
              <Route path="add" element={<CreateGroup />} />
              <Route path=":id" element={<GroupDetails />} />
            </Route>

            <Route path="analytics">
              <Route index element={<Analytics />} />
              <Route path="viewtutor" element={<Analytics />} />
              <Route path="viewstudent" element={<Analytics />} />
              <Route path="viewtutor/:id" element={<TutorAnalytics />} />
              <Route path="viewstudent/:id" element={<StudentAnalytics />} />
              <Route
                path="viewstudent/:id/view-quiz-details/:id"
                element={<ViewAnalyticsQuizDetails />}
              />
            </Route>

            <Route path="quizzes">
              <Route index element={<Quizzes />} />
              <Route path=":id" element={<ViewQuizDetails />} />
              <Route path="question-bank">
                <Route index element={<QuestionBank />} />
                <Route path="add" element={<AddQuestionBank />} />
                <Route path=":id" element={<EditQuestionBank />} />
              </Route>
              <Route path="quiz-generator" element={<AddQuizGenerator />} />
            </Route>

            <Route path="queries">
              <Route index element={<Queries />} />

              <Route path=":id" element={<ViewQueries />} />
            </Route>
            <Route path="queries" element={<Queries />} />

            <Route path="resources">
              <Route index element={<Resources />} />
              <Route path="add" element={<AddResources />} />
              <Route path=":id" element={<EditResource />} />
            </Route>
          </Route>
        </Route>
        <Route path="preview" element={<PreviewLayout />}>
          <Route path=":id" element={<Preview />} />
          <Route path=":courseId/lms/:sectionId" element={<LmsViewLayout />}>
            <Route
              path="topic/:topicId/resource/:resourceId"
              element={<ResourceFileViewer />}
            />
            <Route
              path="topic/:topicId/quiz/:quizId"
              element={<QuizViewer />}
            />
            <Route
              path="courseIntro/:courseIntroId"
              element={<CourseIntroViewer />}
            />
          </Route>
        </Route>
      </>
    </Routes>
  );
};

export default SubAdminRoutes;
