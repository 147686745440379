import { mainApi } from "../../../app/services/mainApiSlice";
import { IDashboardResponse } from "../../../types/dashboard";

const commonUrl: string = "/tutor/dashboard";

const tutorDashboardApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTutorDashboard: builder.query<
      IDashboardResponse,
      string | undefined | null
    >({
      query: (id) => ({
        url: `${commonUrl}/get-dashboard/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTORDASHBOARD" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllTutorDashboardQuery } = tutorDashboardApi;
