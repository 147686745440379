import { WhiteContainer } from "../../components/styled/Container.styled";
import styled from "styled-components";
import StudentDashboardCard from "../../components/dashboardCard/StudentDashboardCard";
import { Row, Typography, Col, Spin } from "antd";
import CenterContent from "../../components/styled/CenterContent.styled";
import { Link } from "react-router-dom";
import { useGetAllStudentDashboardQuery } from "../../features/studentPanelFeatures/StudentDashboard/studentDashboard";
import { ICourseMinimalDTOList } from "../../types/studentPanelTypes/studentDashboardType";
import { useAppSelector } from "../../app/hooks";
import secureLocalStorage from "react-secure-storage";
import { useDispatch } from "react-redux";
import { setCourseName } from "../../features/ui/uiSlice";

const StudentDashboardWhiteContainer = styled(WhiteContainer)`
  border-radius: 0 0 10px 10px;
  justify-content: space-around;
  margin-bottom: 1rem;
  background-color: #f9fafb;
  height: 80vh;
  overflow: hidden;
`;

const CourseCardsContainer = styled(CenterContent)`
  flex-wrap: wrap;
  overflow-y: scroll;
`;

const { Title, Text } = Typography;
function StudentDashboard() {
  const dispatch = useDispatch();
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString();
  const { data, isFetching, isLoading } =
    useGetAllStudentDashboardQuery(idFromLocalStorage);
  const userName = useAppSelector((state) => state.ui.loggedInUserName);
  return (
    <>
      {!isFetching || !isLoading ? (
        <StudentDashboardWhiteContainer>
          <Row
            justify="center"
            align={"middle"}
            style={{ textAlign: "center" }}
          >
            <Col span={24}>
              <Title className="common-text-color" level={2}>
                {`Hey there, ${userName?.split(" ")[0]}!`}
              </Title>
            </Col>
            <Col span={24}>
              <Text className="text-message-font-size gray-text-color">
                Select a course to continue.
              </Text>
            </Col>
          </Row>

          <CourseCardsContainer>
            {data?.data?.courseMinimalDTOList.map(
              (item: ICourseMinimalDTOList, index) => (
                <Link
                  to={`course/${item.courseId}`}
                  key={index}
                  onClick={() =>
                    localStorage.setItem("courseName", item.courseName)
                  }
                >
                  <StudentDashboardCard courseCardData={item} key={index} />
                </Link>
              )
            )}
          </CourseCardsContainer>
        </StudentDashboardWhiteContainer>
      ) : (
        <StudentDashboardWhiteContainer>
          <Spin />
        </StudentDashboardWhiteContainer>
      )}
    </>
  );
}

export default StudentDashboard;
