type AudioFileViewerProps = {
  uri: string;
};

function AudioFileViewer(props: AudioFileViewerProps) {
  const { uri } = props;

  return (
    <>
      <audio src={uri} controls controlsList="nodownload" />
    </>
  );
}

export default AudioFileViewer;
