import { Col, Divider, Row, Space, Tabs, Typography } from "antd";

import { IMyGroup } from "../../../types/studentPanelTypes/studentMyGroupType";
import Quizzes from "./Quizzes";
import Resource from "./Resource";
import SessionDuration from "./SessionDuration";
import computerIcon from "../../../assets/customIcons/computerIcon.png";
import calenderIcon from "../../../assets/customIcons/calendarIcon.png";
import accessTimePeachIcon from "../../../assets/customIcons/accessTimePeach.png";
import clockIcon from "../../../assets/customIcons/clockIcon.png";
import dayjs from "dayjs";

type MyGroupContentType = {
  data?: IMyGroup;
};
const { Title, Text } = Typography;
function MyGroupContent(props: MyGroupContentType) {
  const { data } = props;
  
  const day = dayjs(data?.dateOfCreation).format('DD');
  const month = dayjs(data?.dateOfCreation).format('MM');
  const year = dayjs(data?.dateOfCreation).format('YYYY');
 
  const tabItems = [
    {
      label: "Quizzes",
      key: "1",
      children: <Quizzes data={data} />,
    },
    {
      label: "Session Duration",
      key: "2",
      children: <SessionDuration data={data} />,
    },
    {
      label: "Resource",
      key: "3",
      children: <Resource data={data} />,
    },
  ];
  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={3}>{data?.name}</Title>
        </Col>
        <Col span={24}>
          <Space direction="vertical" size={"middle"}>
            <Row justify={"space-evenly"} gutter={[36, 0]}>
              <Col className="course">
                <Space direction="horizontal">
                  <span>
                    <img src={computerIcon} alt="courseIcon" />
                  </span>
                  <Text>{data?.sections[0]?.courseName}</Text>
                </Space>
              </Col>
              {data?.sections?.map((section, index) => (
                <Col className="section" key={index}>
                  <Space direction="horizontal">
                    <span>
                      <img src={accessTimePeachIcon} alt="sectionIcon" />
                    </span>
                    <Text>{section?.name}</Text>
                  </Space>
                </Col>
              ))}

              <Col className="date">
                <Space direction="horizontal">
                  <span>
                    <img src={calenderIcon} alt="calendarIcon" />
                  </span>
                  <Text>
                    {data?.dateOfCreation !== undefined
                      ? `${day}/${
                          month
                        }/${year}`
                      : "-/-/-"}
                  </Text>
                </Space>
              </Col>
              <Col className="duration">
                <Space direction="horizontal">
                  <span>
                    <img src={clockIcon} alt="clockIcon" />
                  </span>
                  <Text>{data?.totalGroupHours} Hours</Text>
                </Space>
              </Col>
            </Row>

            <Row>
              {data?.tutorNames.map((names, index) => (
                <span className="tutor-name-tag" key={index}>
                  {names}
                </span>
              ))}
            </Row>
          </Space>
        </Col>
      </Row>
      <Divider />

      <Col span={24}>
        <Tabs items={tabItems} />
      </Col>
    </>
  );
}

export default MyGroupContent;
