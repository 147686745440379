import React, { useEffect, useState } from "react";
import { Input, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../constants/smallPageSize";
import { useGetAllCoursesQuery } from "../../features/internalCourse/coursesICApiSlice";
import { ICourse } from "../../types/course";
import { WhiteContainer } from "../../components/styled/Container.styled";
import { IResourceByIdResponse } from "../../types/resources";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

type LinkCoursesProps = {
  setCourses: any;
  resourceData?: IResourceByIdResponse;
};

function LinkCourses(props: LinkCoursesProps) {
  const { setCourses, resourceData } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >([]);
  const preSelectedCourses = resourceData?.data.courses.map((ele) => ele["id"]);

  const { debouncedValue: courseName, debouncing: nameSearching } =
    useDebouncingValue(searchText);

  const { data, isLoading, isFetching } = useGetAllCoursesQuery({
    pageSize,
    currentPage,
    name: courseName,
  });

  useEffect(() => {
    setSelectedRowKeys(preSelectedCourses || []);
  }, []);

  const columns: ColumnsType<ICourse> = [
    {
      key: "name",
      title: (
        <div>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setCourses(newSelectedRowKeys.map((id) => id));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <WhiteContainer>
      <Table
        loading={isLoading || isFetching || nameSearching}
        rowKey="id"
        rowSelection={{ preserveSelectedRowKeys: true, ...rowSelection }}
        columns={columns}
        dataSource={data?.data?.content}
        pagination={{
          pageSize,
          current: currentPage,
          total: data?.data.totalElements,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
      />
    </WhiteContainer>
  );
}

export default LinkCourses;
