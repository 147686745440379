import { ButtonProps, Col, Form, Input, Row } from "antd";
import styled from "styled-components";
import { PrimaryButton } from "../../components/styled/Button.styled";
import Uploader from "../../components/uploader/Uploader";
import { DeleteFilled, PlusCircleOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { IResourceByIdResponse } from "../../types/resources";
import FileTypeIcon from "../../components/resource/FileTypeIcon";

type AddFileProps = {
  setBlockSubmit?: any;
  resourceData?: IResourceByIdResponse;
};

const AddButton = styled((props: ButtonProps) => (
  <PrimaryButton type="primary" icon={<PlusCircleOutlined />} {...props} />
))`
  position: absolute;
  top: -137px;
  right: -47px;
`;

const AddFile = (props: AddFileProps) => {
  const { setBlockSubmit, resourceData } = props;

  return resourceData ? (
    <Row justify="center" align={"middle"}>
      <Form
        name="addFileForm"
        initialValues={{ name: resourceData?.data.name }}
      >
        <Form.Item name="name" className="add-file-form-input">
          <Input disabled={true} />
        </Form.Item>
      </Form>
      <FileTypeIcon resourceType={resourceData?.data.resourceType} />
    </Row>
  ) : (
    <Form name="addFileForm" layout="vertical">
      <Form.List name="fileForm">
        {(fields, { add, remove }) => (
          <>
            <Row justify="end">
              <Col>
                <AddButton onClick={() => add()}>Add</AddButton>
              </Col>
            </Row>

            <Row gutter={[10, 10]} justify="center">
              {fields.map((field, index) => (
                <Fragment key={field.key}>
                  <Row className="upload-form-item">
                    <Row>
                      <Form.Item
                        className="add-file-form-input"
                        name={[index, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Please enter resource name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Uploader
                        setBlockSubmit={setBlockSubmit}
                        name={[index, "document"]}
                        rules={[
                          {
                            required: true,
                            message: "Please upload a resource",
                          },
                        ]}
                      />
                    </Row>
                    {index === fields.length - 1 ? (
                      <Row>
                        <DeleteFilled onClick={() => remove(field.name)} />
                      </Row>
                    ) : null}
                  </Row>
                </Fragment>
              ))}
            </Row>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default AddFile;
