import { FormInstance, Rule } from "antd/es/form";
import { IValueLabel } from "../../types/common";
import BorderedCheckboxes from "../quizzes/BorderedCheckboxes";

type IProps = {
  label: string;
  name: string;
  rules?: Rule[];
  form: FormInstance;
};

const options: IValueLabel[] = [
  {
    value: "EASY",
    label: "Easy",
  },
  {
    value: "AVERAGE",
    label: "Average",
  },
  {
    value: "HARD",
    label: "Hard",
  },
];

function DifficultyLevel(props: IProps) {
  return <BorderedCheckboxes data={options} {...props} />;
}

export default DifficultyLevel;
