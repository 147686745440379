import { mainApi } from "../../app/services/mainApiSlice";
import {
  IStudentResponse,
  IStudent,
  IStudentByIdResponse,
} from "../../types/studentType";

const commonUrl: string = "/admin/student";

type IReinviteEmailResponse = {
  data: null,
  errorMessage: string,
  message: string,
  statusCode: number
}

const studentsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStudents: builder.query<
      IStudentResponse,
      {
        currentPage: number;
        pageSize: number;
        courseName?: string;
        email?: string;
        name?: string;
        phoneNumber?: string;
        studentType?: string;
        groupName?: string;
      }
    >({
      query: ({
        currentPage,
        pageSize,
        courseName,
        email,
        name,
        phoneNumber,
        studentType,
        groupName,
      }) => ({
        url: `${commonUrl}/get-all-student?courseName=${
          courseName || ""
        }&email=${email || ""}&name=${name || ""}&groupName=${
          groupName || ""
        }&page=${currentPage - 1}&phoneNumber=${
          phoneNumber || ""
        }&size=${pageSize}&studentType=${studentType || ""}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENT" }],
    }),
    getStudentById: builder.query<IStudentByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-student/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "STUDENT" }],
    }),
    updateStudentById: builder.mutation<
      IStudent,
      { id: number | undefined; post: Partial<IStudent> }
    >({
      query: ({ id, post }) => ({
        url: `${commonUrl}/update-student/${id}`,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "STUDENT" }],
    }),
    deleteStudentById: builder.mutation<void, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Main", id: "STUDENT" }],
    }),
    addStudent: builder.mutation<IStudent, Partial<IStudent>>({
      query: (studentData: IStudent) => ({
        url: `${commonUrl}/save`,
        method: "POST",
        body: studentData,
      }),
      invalidatesTags: [{ type: "Main", id: "STUDENT" }],
    }),
    addStudentImage: builder.mutation<
      FormData,
      { id: number | undefined; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: `${commonUrl}/save-image/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "STUDENT" }],
    }),
    reinviteEmail: builder.mutation<IReinviteEmailResponse , string | undefined>({
      query: (id)=> ({
        url: `${commonUrl}/reinvite/${id}`,
        method: "POST",
      }),
      invalidatesTags: [{type: "Main", id: "STUDENT"}]
    })
  }),
  overrideExisting: false,
});

export const {
  useGetAllStudentsQuery,
  useGetStudentByIdQuery,
  useAddStudentImageMutation,
  useDeleteStudentByIdMutation,
  useUpdateStudentByIdMutation,
  useAddStudentMutation,
  useReinviteEmailMutation
} = studentsApi;
