import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import { Col, DatePicker, Row, Tabs } from "antd";
import DayProgress from "./DayProgress";
import { useGetStudentTopicAnalyticsQuery } from "../../../features/studentPanelFeatures/StudentAnalytics/studentPanelApiSlice";
import { useParams } from "react-router-dom";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { IStudentAnalytics } from "../../../types/studentPanelTypes/studentAnalytics";
import secureLocalStorage from "react-secure-storage";

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

function TopicWiseProgress() {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const dateFormat = "YYYY-MM-DD";
  const currentDate = new Date(Date.now());
  const { courseId } = useParams();
  const [endDate, setEndDate] = useState(dayjs(currentDate).format(dateFormat));
  const [startDate, setStartDate] = useState(
    dayjs(currentDate.setDate(currentDate.getDate() - 6)).format(dateFormat)
  );
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);

  const { data } = useGetStudentTopicAnalyticsQuery({
    idFromLocalStorage,
    endDate,
    startDate,
    courseId,
  });

  const handleOnChange = (e: any) => {
    setStartDate(dayjs(e[0]).format(dateFormat));
    setEndDate(dayjs(e[1]).format(dateFormat));
  };

  const filteredObjectsWithDay: IStudentAnalytics[] = [];
  data?.data?.map((obj1) =>
    filteredObjectsWithDay.push({ ...obj1, day: dayjs(obj1.dateTime).day() })
  );

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") >= 7;
    const tooEarly = dates[1] && dates[1].diff(current, "days") >= 7;
    return !!tooEarly || !!tooLate;
  };
  const tabItems = [
    {
      key: "1",
      label: "Monday",
      children: <DayProgress data={filteredObjectsWithDay} day={1} />,
    },
    {
      key: "2",
      label: "Tuesday",
      children: <DayProgress data={filteredObjectsWithDay} day={2} />,
    },
    {
      key: "3",
      label: "Wednesday",
      children: <DayProgress data={filteredObjectsWithDay} day={3} />,
    },
    {
      key: "4",
      label: "Thursday",
      children: <DayProgress data={filteredObjectsWithDay} day={4} />,
    },
    {
      key: "5",
      label: "Friday",
      children: <DayProgress data={filteredObjectsWithDay} day={5} />,
    },
    {
      key: "6",
      label: "Saturday",
      children: <DayProgress data={filteredObjectsWithDay} day={6} />,
    },
    {
      key: "7",
      label: "Sunday",
      children: <DayProgress data={filteredObjectsWithDay} day={0} />,
    },
  ];
  return (
    <>
      <StudentPanelWhiteContainer>
        <Row justify={"end"}>
          <Col span={6}>
            <RangePicker
              value={dates || value}
              onChange={(e) => handleOnChange(e)}
              disabledDate={disabledDate}
              onCalendarChange={(val) => setDates(val)}
            />
          </Col>
        </Row>

        <StudentPanelWhiteContainer>
          <Tabs defaultActiveKey="1" items={tabItems} />
        </StudentPanelWhiteContainer>
      </StudentPanelWhiteContainer>
    </>
  );
}

export default TopicWiseProgress;
