import { mainApi } from "../../app/services/mainApiSlice";
import { IQuestionBank } from "../../types/questionBank";
import {
  IQuestionFilter,
  IQuestionFilterResponse,
} from "../../types/quizDetails";

const commonUrl: string = "/admin/quiz";

const quizQuestionApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Quiz Details
    addQuiz: builder.mutation<IQuestionBank, Partial<IQuestionBank>>({
      query: (quizData: IQuestionBank) => ({
        url: `${commonUrl}/save`,
        method: "POST",
        body: quizData,
      }),
      invalidatesTags: [{ type: "Main", id: "QUIZZES" }],
    }),

    // Get filter Quiz Question
    getFilterQuestions: builder.query<IQuestionFilterResponse, IQuestionFilter>(
      {
        query: (questionData) => ({
          url: `${commonUrl}/questions-list`,
          method: "POST",
          body: questionData,
        }),
      }
    ),
  }),
  overrideExisting: false,
});

export const { useAddQuizMutation, useLazyGetFilterQuestionsQuery } =
  quizQuestionApi;
