const obj = {
  "image/png": "IMAGE",
  "image/jpeg": "IMAGE",
  "image/jpg": "IMAGE",
  "application/pdf": "PDF",
  "application/msword": "DOC",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "DOCX",
  "application/vnd.ms-powerpoint": "PPT",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "PPT",
  "video/mp4": "MP4",
  "audio/mpeg": "MP3",
};

function matchFileType(type: string) {
  return obj[type as keyof typeof obj];
}
export { matchFileType };
