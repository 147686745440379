import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import secureLocalStorage from "react-secure-storage";
import store from "../store";

// TODO: Authorization token is being used in multiple slices that needs to be removed
// This particular token will be sent in all headers unless specified in children requests
// It will remove the need to use .env file for storing token
export const mainApi = createApi({
  reducerPath: "mainApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders(headers, { getState }) {
      const jwtToken =
        (getState() as any).ui.token || secureLocalStorage.getItem("token");
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Main"],
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
});

export const mainApiLogin = createApi({
  reducerPath: "mainApiLogin",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LOGIN_URL,
  }),
  tagTypes: ["MainLogin"],
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
});

export const mainApiLogout = createApi({
  reducerPath: "mainApiLogOut",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LOGOUT_URL,
    prepareHeaders(headers, { getState }) {
      const jwtToken = (getState() as any).ui.token;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ["MainLogOut"],
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
});
