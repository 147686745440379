import { mainApi } from "../../../app/services/mainApiSlice";
import { ITutorNotificationResponse } from "../../../types/tutorPanelTypes/tutorNotificationType";

const commonUrl: string = "public/notification";

const tutorNotificationApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTutorNotification: builder.query<
      ITutorNotificationResponse,
      string | null
    >({
      query: (id) => ({
        url: `${commonUrl}/get-notification-tutor/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTORNOTIFICATION" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllTutorNotificationQuery } = tutorNotificationApi;
