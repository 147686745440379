const categoryDropdown = [
  { value: "", label: "--" },
  { value: "MCQ", label: "Mcq" },
  {
    value: "READING_COMPREHENSION",
    label: "Reading Comprehension",
  },
  {
    value: "NUMERICAL",
    label: "Student Response",
  },
];

const difficultyDropdown = [
  { value: "", label: "--" },
  { value: "EASY", label: "Easy" },
  { value: "AVERAGE", label: "Average" },
  { value: "HARD", label: "Hard" },
];

export { categoryDropdown, difficultyDropdown };
