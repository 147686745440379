import React, { useState, useEffect } from "react";
import { Input, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../constants/smallPageSize";
import { WhiteContainer } from "../../components/styled/Container.styled";
import { ITopic } from "../../types/topic";
import { useGetAllTopicsQuery } from "../../features/internalCourse/topicICApiSlice";
import { IResourceByIdResponse } from "../../types/resources";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

type LinkTopicsProps = {
  setTopics: any;
  resourceData?: IResourceByIdResponse;
};

function LinkTopics(props: LinkTopicsProps) {
  const { setTopics, resourceData } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const { debouncedValue: topicName, debouncing: nameSearching } =
    useDebouncingValue(searchText);
  const { data, isLoading, isFetching } = useGetAllTopicsQuery({
    currentPage,
    pageSize,
    name: topicName,
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >([]);
  const preSelectedTopics = resourceData?.data.topics.map((ele) => ele["id"]);

  useEffect(() => {
    setSelectedRowKeys(preSelectedTopics || []);
  }, []);

  const columns: ColumnsType<ITopic> = [
    {
      key: "name",
      title: (
        <div>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setTopics(newSelectedRowKeys.map((id) => id));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <WhiteContainer>
        <Table
          rowSelection={{ preserveSelectedRowKeys: true, ...rowSelection }}
          rowKey="id"
          columns={columns}
          dataSource={data?.data.content}
          pagination={{
            pageSize,
            current: currentPage,
            total: data?.data.totalElements,
            onChange(page) {
              setCurrentPage(page);
            },
          }}
          loading={isLoading || isFetching || nameSearching}
        />
      </WhiteContainer>
    </div>
  );
}

export default LinkTopics;
