import { Input, Table, Typography, Form } from "antd";
import { ColumnsType } from "antd/es/table";
import { ICourse } from "../../../types/course";
import pageSize from "../../../constants/pageSize";
import { ISection } from "../../../types/section";
import { ITutorByIdResponse } from "../../../types/tutor";

type AssignedCourseProps = {
  courseData?: ITutorByIdResponse;
};
function AssignCourses(props: AssignedCourseProps) {
  const { courseData } = props;

  const columns: ColumnsType<any> = [
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course Name</Typography.Text>
        </div>
      ),
      dataIndex: "course",
      render: (course: ICourse[]) =>
        course?.map((course: ICourse) => course?.name),
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
        </div>
      ),
      render: (course: ICourse[]) =>
        course
          ?.map((course: ICourse) =>
            course?.section?.map((section: ISection) => section?.name)
          )
          .join(","),
      dataIndex: "course",
    },
    {
      key: "groupCost",
      dataIndex: "groupCost",
      title: (
        <div>
          <Typography.Text>Group Cost(per Hrs.)</Typography.Text>
        </div>
      ),
      render: (_, record) => {
        return (
          <>
            <Form.Item
              name={`groupCost-${record.id}`}
              initialValue={record?.groupCost}
            >
              <Input type="number" suffix="€" disabled />
            </Form.Item>
          </>
        );
      },
    },
    {
      key: "individualCost",
      dataIndex: "individualCost",
      title: (
        <div>
          <Typography.Text>Individual Cost(per Hrs.)</Typography.Text>
        </div>
      ),
      render: (_, record) => {
        return (
          <>
            <Form.Item
              name={`individualCost-${record.id}`}
              initialValue={record?.individualCost}
            >
              <Input type="number" suffix="€" disabled />
            </Form.Item>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        pagination={{
          pageSize,
        }}
        dataSource={courseData?.data?.tutorSectionDTO}
      />
    </>
  );
}

export default AssignCourses;
