import { Col, Row, Form } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useGetMyCourseByIdQuery } from "../../../features/tutorPanelFeatures/TutorMyCourses/myCourseAPISlice";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../../components/styled/Container.styled";
import ContainerHeading from "../../../components/styled/ContainerHeading.styled";
import FormContainer from "../../../components/styled/FormContainer.styled";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import ViewMyCourseForm from "./ViewMyCourseForm";
import Breadcrumbs from "../../../components/common/Breadcrumbs";

function EditCourse() {
  const { id } = useParams();
  const { data, isError, isLoading } = useGetMyCourseByIdQuery(id);
  const navigate = useNavigate();
  const onClose = () => {
    navigate(-1);
  };
  return (
    <>
      <Breadcrumbs idName={!!id ? data?.data?.name : 'false'} isId={!!id ? true : false} />
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { mainForm } = forms;
            mainForm.submit();
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <ContainerHeading>Course</ContainerHeading>
          <WhiteContainer>
            <FormContainer>
              {!isError && !isLoading && data && (
                <ViewMyCourseForm data={data} />
              )}
            </FormContainer>
          </WhiteContainer>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default EditCourse;
