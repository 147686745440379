import { Button } from "antd";

function CloseButton(props:any) {
  return (
    <Button className="close-button" type="primary" {...props}>
      Close
    </Button>
  );
}

export default CloseButton;
