import { Col, Form, Row, ButtonProps, DatePicker, TimePicker } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Fragment, useState } from "react";
import styled from "styled-components";
import { MdDelete } from "react-icons/md";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import FormContainer from "../../../components/styled/FormContainer.styled";
import SelectCourseOptions from "../../../components/selectFormOptions/TutorPanelSelectFormOption/SelectCourseOptions";
import SelectGroupOptions from "../../../components/selectFormOptions/TutorPanelSelectFormOption/SelectGroupOption";
import SelectSectionOptions from "../../../components/selectFormOptions/TutorPanelSelectFormOption/SelectSectionOptions";
import dayjs from "dayjs";
import { RangePickerProps } from "antd/es/date-picker";

const AddButton = styled((props: ButtonProps) => (
  <PrimaryButton type="primary" icon={<PlusCircleOutlined />} {...props} />
))`
  position: absolute;
  top: -205px;
  left: -75px;
`;

const MultipleTopicForm = () => {
  const [form] = Form.useForm();
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day");
  };

  return (
    <FormContainer>
      <Form name="listForm" layout="vertical">
        <Form.List name="timePunchDTO">
          {(fields, { add, remove }) => (
            <>
              <Row justify="end" className="button-bottom-margin">
                <Col>
                  <AddButton onClick={() => add()}>Add</AddButton>
                </Col>
              </Row>

              <Row gutter={[32, 32]}>
                {fields.map((field, index) => (
                  <Fragment key={field.key}>
                    <Col span={7}>
                      <Form.Item
                        label="Date"
                        name={[index, "date"]}
                        rules={[
                          {
                            required: true,
                            message: "Please Select Date",
                          },
                        ]}
                      >
                        <DatePicker
                          className="w-100"
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                      <Form.Item>
                        <SelectGroupOptions
                          label="Groups"
                          name={[index, "groupStudentId"]}
                          rules={[
                            {
                              required: true,
                              message: "Please Select Groups",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        label="Session In"
                        name={[index, "startTime"]}
                        rules={[
                          {
                            required: true,
                            message: "Please Select Session Start Time",
                          },
                        ]}
                      >
                        <TimePicker className="w-100" format={"HH:mm"} />
                      </Form.Item>
                      <Form.Item>
                        <SelectCourseOptions
                          label="Course"
                          name={[index, "courseName"]}
                          form={form}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        label="Session Out"
                        name={[index, "endTime"]}
                        rules={[
                          {
                            required: true,
                            message: "Please Select Session End Time",
                          },
                        ]}
                      >
                        <TimePicker className="w-100" format={"HH:mm"} />
                      </Form.Item>
                      <Form.Item>
                        <SelectSectionOptions
                          label="Section"
                          name={[index, "sectionId"]}
                          form={form}
                          rules={[
                            {
                              required: true,
                              message: "Please Select Section",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      {fields.length > 1 ? (
                        <MdDelete onClick={() => remove(field.name)} />
                      ) : null}
                    </Col>
                  </Fragment>
                ))}
              </Row>
            </>
          )}
        </Form.List>
      </Form>
    </FormContainer>
  );
};

export default MultipleTopicForm;
