import { Modal } from "antd";
import DragUploader from "../../components/uploader/ExcelUploader";

type bulkUploadProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
function BulkUpload({ open, setOpen }: bulkUploadProps) {
  return (
    <>
      <Modal
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={500}
        centered
        bodyStyle={{
          margin: "2rem",
        }}
        destroyOnClose
        footer
      >
        <DragUploader setOpen={setOpen} />
      </Modal>
    </>
  );
}

export default BulkUpload;
