import { useState, useEffect } from "react";
import { Input, Table, Typography, Row, Select } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import pageSize from "../../../constants/pageSize";
import { ViewDetailsButton } from "../../../components/styled/Button.styled";
import CustomCard from "../../../components/countCard/CustomCard";

import { ITutorQuiz } from "../../../types/tutorPanelTypes/tutorQuiz";
import { useGetAllTutorQuizQuery } from "../../../features/tutorPanelFeatures/TutorQuizzes/tutorQuizApiSlice";
import secureLocalStorage from "react-secure-storage";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../../utils/useDebouncingValue";
import { quizModeConstant } from "../../../utils/quizSearchConstants";

function Quizzes() {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const [name, setName] = useState("");
  const [mode, setMode] = useState("");
  const [course, setCourse] = useState("");
  const [status, setStatus] = useState("");

  const { debouncedValue: quizName, debouncing: quizNameSearching } =
    useDebouncingValue(name);
  const { debouncedValue: quizMode, debouncing: modeSearching } =
    useDebouncingValue(mode);
  const { debouncedValue: courseName, debouncing: courseSearching } =
    useDebouncingValue(course);

  const { data, isLoading, isFetching } = useGetAllTutorQuizQuery({
    pageSize,
    currentPage,
    idFromLocalStorage,
    courseName,
    quizMode,
    quizName,
  });

  const columns: ColumnsType<ITutorQuiz> = [
    {
      key: "quizName",
      title: (
        <div>
          <Typography.Text>Quiz Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setName(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>
      ),
      dataIndex: "quizName",
    },
    {
      key: "quizMode",
      title: (
        <div>
          <Typography.Text>Mode</Typography.Text>
          <Select
            onChange={(value) => {
              setMode(value);
              setCurrentPage(1);
            }}
            options={quizModeConstant}
            className="searchDropdownStyle"
          />
        </div>
      ),
      dataIndex: "quizMode",
    },
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourse(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>
      ),
      dataIndex: "courseName",
      render: (_, record) => {
        return record.courses[0]?.name;
      },
    },
    {
      key: "quizStatus",
      title: (
        <div>
          <Typography.Text>Status</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setStatus(e.target.value);
              setCurrentPage(1);
            }}
            disabled
          />
        </div>
      ),
      dataIndex: "quizStatus",
    },

    {
      key: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openQuizDetails(record.id)} />
      ),
    },
  ];

  const openQuizDetails = (id: number) => {
    navigate(`quiz-details/${id}`);
  };

  return (
    <>
      <div>
        <Row>
          <Breadcrumbs idName={""} isId={false} />
        </Row>
        <Row justify="space-between" className="button-bottom-margin">
          <CustomCard sum={data?.data?.totalElements} text="Quiz" />
        </Row>
        <Row className="styled-container outer-styled-container">
          <Row className="styled-container inner-styled-container">
            <Table<ITutorQuiz>
              className="d-table"
              loading={
                isLoading ||
                quizNameSearching ||
                modeSearching ||
                courseSearching ||
                isFetching
              }
              rowKey="id"
              columns={columns}
              dataSource={data?.data.content}
              pagination={{
                pageSize,
                current: currentPage,
                total: data?.data.totalElements,
                onChange(page) {
                  setCurrentPage(page);
                },
              }}
            />
          </Row>
        </Row>
      </div>
    </>
  );
}

export default Quizzes;
