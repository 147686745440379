import { useState } from "react";
import {
  Input,
  Table,
  Tabs,
  Typography,
  Row,
  notification,
  Modal,
  Select,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { quizzesTabs } from "../../constants/tabs";
import { IQuizzes } from "../../types/quizzes";
import {
  useGetAllQuizQuery,
  useDeleteQuizByIdMutation,
} from "../../features/quizzes/quizApiSlice";
import pageSize from "../../constants/pageSize";
import {
  DeleteButton,
  ViewDetailsButton,
} from "../../components/styled/Button.styled";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../utils/useDebouncingValue";
import {
  quizModeConstant,
  quizStatusConstant,
} from "../../utils/quizSearchConstants";

function Quizzes() {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteQuizById] = useDeleteQuizByIdMutation();

  const [name, setName] = useState("");
  const [mode, setMode] = useState("");
  const [course, setCourse] = useState("");
  const [status, setStatus] = useState("");

  const { debouncedValue: quizName, debouncing: nameSearching } =
    useDebouncingValue(name);
  const { debouncedValue: quizMode, debouncing: modeSearching } =
    useDebouncingValue(mode);
  const { debouncedValue: courseName, debouncing: courseSearching } =
    useDebouncingValue(course);
  const { debouncedValue: quizStatus, debouncing: statusSearching } =
    useDebouncingValue(status);

  const { data, isLoading, isFetching } = useGetAllQuizQuery({
    currentPage,
    pageSize,
    quizName,
    quizMode,
    courseName,
    quizStatus,
  });

  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Quiz?",

      onOk() {
        onDelete(id);
      },
      onCancel() {},
    });
  };
  const columns: ColumnsType<IQuizzes> = [
    {
      key: "quizName",
      title: (
        <div>
          <Typography.Text>Quiz Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setName(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>
      ),
      dataIndex: "quizName",
    },
    {
      key: "quizMode",
      title: (
        <div>
          <Typography.Text>Mode</Typography.Text>
          <Select
            onChange={(value) => {
              setMode(value);
              setCurrentPage(1);
            }}
            options={quizModeConstant}
            className="searchDropdownStyle"
          />
        </div>
      ),
      dataIndex: "quizMode",
    },
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourse(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>
      ),
      dataIndex: "courseName",
      render: (_, record) => {
        return record.courses[0]?.name;
      },
    },
    {
      key: "quizStatus",
      title: (
        <div>
          <Typography.Text>Status</Typography.Text>
          <Select
            onChange={(value) => {
              setStatus(value);
              setCurrentPage(1);
            }}
            options={quizStatusConstant}
            className="searchDropdownStyle"
          />
        </div>
      ),
      dataIndex: "quizStatus",
    },

    {
      key: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openQuizDetails(record.id)} />
      ),
    },
    {
      key: "deleteButton",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];
  const onDelete = (id: number) => {
    deleteQuizById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        });
      });
  };
  const openQuizDetails = (id: number) => {
    navigate(`${id}`);
  };

  const tabChange = (key: string) => {
    navigate(`../quizzes/${quizzesTabs[Number(key) - 1]}`);
  };

  return (
    <>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row className="styled-container outer-styled-container">
        <Tabs defaultActiveKey="1" onChange={tabChange}>
          <Tabs.TabPane tab="Quizzes" key="1">
            <Row className="styled-container inner-styled-container">
              <Table<IQuizzes>
                loading={
                  isLoading ||
                  isFetching ||
                  nameSearching ||
                  modeSearching ||
                  courseSearching ||
                  statusSearching
                }
                rowKey="id"
                columns={columns}
                dataSource={data?.data?.content}
                pagination={{
                  pageSize,
                  current: currentPage,
                  total: data?.data.totalElements,
                  onChange(page) {
                    setCurrentPage(page);
                  },
                }}
              />
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Question Bank" key="2"></Tabs.TabPane>
          <Tabs.TabPane tab="Quiz Generator" key="3"></Tabs.TabPane>
        </Tabs>
      </Row>
    </>
  );
}

export default Quizzes;
