import { Col, Row, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import {
  DarkGrayContainer,
  LightGrayContainer,
  WhiteContainer,
} from "../../../components/styled/Container.styled";
import ContainerHeading from "../../../components/styled/ContainerHeading.styled";
import FormContainer from "../../../components/styled/FormContainer.styled";
import { useGetQuizByTutorIdQuery } from "../../../features/tutorPanelFeatures/TutorQuizzes/tutorQuizApiSlice";

import ViewQuizDetailsForm from "./ViewQuizDetailsForm";
import ViewQuizQuestion from "./ViewQuizQuestion";

function TutorQuizView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isError, isLoading } = useGetQuizByTutorIdQuery(id);
  const onClose = () => {
    navigate(-1);
  };

  const tabItems = [
    {
      key: "1",
      label: "Quiz Details",
      children: (
        <WhiteContainer>
          <FormContainer>
            {!isError && !isLoading && data && (
              <ViewQuizDetailsForm data={data} />
            )}
          </FormContainer>
        </WhiteContainer>
      ),
    },
    {
      key: "2",
      label: "Quiz Questions",
      children: (
        <WhiteContainer>
          <ViewQuizQuestion data={data} />
        </WhiteContainer>
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs idName={!!id ? data?.data?.quizName : 'false'} isId={!!id ? true : false} />
      <Row justify="end" gutter={20} className="mb-1">
        <Col>
          <PrimaryButton htmlType="button" onClick={onClose}>
            Close
          </PrimaryButton>
        </Col>
      </Row>
      <Row>
        <LightGrayContainer>
          <ContainerHeading>Quiz Details</ContainerHeading>
          <DarkGrayContainer>
            <Tabs defaultActiveKey="1" items={tabItems} />
          </DarkGrayContainer>
        </LightGrayContainer>
      </Row>
    </>
  );
}

export default TutorQuizView;
