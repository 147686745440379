import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";

import { useLocation, useParams } from "react-router-dom";
import BlackBookPopUpContent from "../../../components/myLearning/BlackBookPopUpContent";
import { useSaveBlackBookMutation } from "../../../features/studentPanelFeatures/StudentBlackBook/blackBookApiSlice";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import StudentPanelFooter from "../../../components/layout/studentPanelFooter/StudentPanelFooter";
import { useEffect, useState } from "react";
import NotesQuill from "../../../components/common/NotesQuill";
import { useGetStudentQuestionByIdQuery } from "../../../features/studentPanelFeatures/StudentQuiz/studentQuizzes";
import TextViewer from "../../../components/preview/TextViewer";
import whiteBoard from "../../../assets/customIcons/whiteBoard.png";
import blackBook from "../../../assets/customIcons/blackBook.png";
import QuestionTypesConstant from "../../../utils/constants";
import secureLocalStorage from "react-secure-storage";
import { setStudentBreadcrumb } from "../../../features/ui/uiSlice";
import { useDispatch } from "react-redux";
const { Panel } = Collapse;

function ViewQuestions() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const { questionId } = useParams();
  const [showNotes, setShowNotes] = useState(false);
  const { data, isLoading, isFetching } = useGetStudentQuestionByIdQuery({
    idFromLocalStorage,
    questionId,
  });
  const [notes, setNotes] = useState("");
  const [textNote, setTextNote]=useState("");

  useEffect(()=>{
    if (data && data.data) {
      setTextNote(data?.data.note || "");
    }
  },[data]);

  const topicId = data?.data?.topics[0]?.id;
  const value = data?.data.studentAnswerOption;

  const [saveBlackBook] = useSaveBlackBookMutation();

  const handleOnClick = async () => {
    await saveBlackBook({
      studentId: idFromLocalStorage,
      questionId: questionId,
      topicId: topicId,
    })
      .unwrap()
      .then((res) => {
        Modal.success({
          content: <BlackBookPopUpContent res={res} />,
          icon: null,
          maskClosable: true,
          closable: true,
          centered: true,
          bodyStyle: {
            justifyContent: "center",
            alignItems: "center",
          },
          footer: null,
        });
      });
  };

  const handleClickNotes = () => {
    setShowNotes(!showNotes);
  };

  const handleCloseNotes = () => {
    setShowNotes(false);
  };

  const onChange = (content: string) => {
    setNotes(content);
  };

  const handleSave = async () => {
    if (notes === "") {
      message.error("Please enter something");
      return;
    } else {
      await saveBlackBook({
        studentId: idFromLocalStorage,
        questionId: questionId,
        topicId: topicId,
        note: notes,
      })
        .unwrap()
        .then((res) => {
          message.success(res.message);
          setShowNotes(false);
          if(res.data) setTextNote(notes);
        });
    }
  };

  dispatch(
    setStudentBreadcrumb([
      `${pathname.split("/")[3]}/${pathname.split("/")[4]}`,
    ])
  );

  return (
    <>
      {data && !isLoading && !isFetching ? (
        <StudentPanelWhiteContainer>
          <Row>
            <Col span={10} />
            <Col span={10}>
              <Tag className="p-1">{data?.data.questionCategory}</Tag>
            </Col>
            <Col span={2}>
              <Tooltip title="Notes">
                <Button ghost className="p-0" onClick={handleClickNotes}>
                  <img src={whiteBoard} alt="notes-icon" />
                </Button>
              </Tooltip>
            </Col>
            <Col span={2}>
              <Tooltip title="Blackbook">
                <Button ghost className="p-0" onClick={handleOnClick}>
                  <img src={blackBook} alt="blackbook-icon" />
                </Button>
              </Tooltip>
            </Col>
          </Row>

          {data?.data?.questionCategory === `${QuestionTypesConstant.Mcq}` ? (
            <>
              <div>
                <Typography.Title level={4} className="ml-1">
                  Question
                </Typography.Title>
                <TextViewer textData={data?.data?.questionText || " "} />
              </div>
              <div className="ml-1">
                <Typography.Title level={4}>Answer Options</Typography.Title>

                {data?.data.answers.map((item) =>
                  item?.answerOptions.map((answer) => (
                    <Row align={"middle"}>
                      <Col span={2}>{answer.option}</Col>
                      <Col>
                        <Checkbox
                          id={answer.value}
                          checked={value?.some((v) => v.value === answer.value)}
                        />
                      </Col>
                      <Col span={17}>
                        <TextViewer textData={answer.value || " "} />
                      </Col>
                      <>
                        <span>
                          {answer.isCorrect === true ? (
                            <p className=" correct-ans"> Correct&nbsp;</p>
                          ) : (
                            ""
                          )}
                        </span>

                        <>
                          {data?.data.answerStatus === "WRONG" &&
                          value?.some((v) => v.value === answer.value) ? (
                            <p className=" wrong-ans">Incorrect</p>
                          ) : data?.data.answerStatus === "CORRECT" &&
                            value?.some((v) => v.value === answer.value) ? (
                            <p className=" correct-ans"> &nbsp;</p>
                          ) : data?.data.answerStatus === "SKIPPED" ? (
                            ""
                          ) : (
                            ""
                          )}
                        </>
                      </>
                    </Row>
                  ))
                )}
              </div>

              <br />
              <Collapse ghost expandIconPosition="end">
                <Panel header="Explanation" key="1">
                  <TextViewer textData={data?.data.explanation || " "} />
                </Panel>
              </Collapse>
            </>
          ) : (
            ""
          )}
          {data?.data?.questionCategory ===
          `${QuestionTypesConstant.Numerical}` ? (
            <>
              <div>
                <Typography.Title level={4} className="ml-1">
                  Question
                </Typography.Title>
                <TextViewer textData={data?.data.questionText || " "} />
              </div>

              <Row align={"middle"} justify={"space-evenly"} className="ml-1">
                <Col span={24}>
                  <Typography.Title level={5}>
                    Type your answer here
                  </Typography.Title>
                </Col>
                <Col span={6}>
                  <Form.Item>
                    <Input value={data?.data?.studentAnswerOption[0]?.value} />
                  </Form.Item>
                </Col>
                <Col>
                  {data?.data?.answerStatus === "WRONG" && (
                    <p className=" wrong-ans">Incorrect</p>
                  )}
                  {data?.data?.answerStatus === "CORRECT" && (
                    <p className=" correct-ans">Correct</p>
                  )}
                  {data?.data?.answerStatus === "SKIPPED" && <p>Skipped</p>}
                </Col>
              </Row>
              <br />
              <Collapse ghost expandIconPosition="end">
                <Panel header="Explanation" key="1">
                  <TextViewer textData={data?.data.explanation || " "} />
                </Panel>
              </Collapse>
            </>
          ) : (
            ""
          )}
          {data?.data?.questionCategory === `${QuestionTypesConstant.Rc}` ? (
            <>
              <Row>
                <Col span={12}>
                  <TextViewer textData={data.data.passage} />
                </Col>
                <Col>
                  <Divider type="vertical" style={{ height: "38rem" }} />
                </Col>
                <Col span={11}>
                  <Typography.Title level={4} className="ml-1">
                    Question
                  </Typography.Title>
                  <TextViewer textData={data?.data.questionText || " "} />
                  <div className="ml-1">
                    <Typography.Title level={4}>
                      Answer Options
                    </Typography.Title>

                    {data?.data.answers.map((item) =>
                      item?.answerOptions.map((answer) => (
                        <Row align={"middle"}>
                          <Col span={2}>{answer.option}</Col>
                          <Col>
                            <Checkbox
                              id={answer.value}
                              checked={value?.some((v) => v.value === answer.value)}
                            />
                          </Col>
                          <Col span={15}>
                            <TextViewer textData={answer.value || " "} />
                          </Col>
                          <>
                            <span>
                              {answer.isCorrect === true ? (
                                <p className=" correct-ans"> Correct&nbsp;</p>
                              ) : (
                                ""
                              )}
                            </span>

                            <>
                              {data?.data.answerStatus === "WRONG" &&
                              value?.some((v) => v.value === answer.value) ? (
                                <p className=" wrong-ans">Incorrect</p>
                              ) : data?.data.answerStatus === "CORRECT" &&
                                value?.some((v) => v.value === answer.value) ? (
                                <p className=" correct-ans"> &nbsp;</p>
                              ) : data?.data.answerStatus === "SKIPPED" ? (
                                ""
                              ) : (
                                ""
                              )}
                            </>
                          </>
                        </Row>
                      ))
                    )}
                  </div>

                  <br />
                  <Collapse ghost expandIconPosition="end" className="ml-1">
                    <Panel header="Explanation" key="1">
                      <TextViewer textData={data?.data.explanation || " "} />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </StudentPanelWhiteContainer>
      ) : (
        <StudentPanelWhiteContainer>
          <Spin />
        </StudentPanelWhiteContainer>
      )}

      <StudentPanelFooter />
      {showNotes && (
        <NotesQuill
          onChange={onChange}
          handleSave={handleSave}
          readOnly={false}
          handleCloseNotes={handleCloseNotes}
          notes={textNote}
        ></NotesQuill>
      )}
    </>
  );
}

export default ViewQuestions;
