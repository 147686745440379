import styled from "styled-components";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import ViewResource from "./ViewResource";
import { WhiteCloseButtonLarge } from "../../../components/styled/Button.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { setStudentBreadcrumb } from "../../../features/ui/uiSlice";

const ViewResourceContainer = styled(StudentPanelWhiteContainer)`
  justify-content: center;
  margin: auto;
`;

const CloseButtonContainer = styled.div`
  margin-top: 4rem;
  display: flex;
`;

function MyGroupResource() {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(-1);
  };

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  dispatch(
    setStudentBreadcrumb([
      `${pathname.split("/")[3]}/${pathname.split("/")[4]}`,
    ])
  );

  return (
    <>
      <ViewResourceContainer>
        <div className="view-resource-container">
          <ViewResource />
        </div>
        <CloseButtonContainer>
          <WhiteCloseButtonLarge onClick={handleOnClick}>
            Close x
          </WhiteCloseButtonLarge>
        </CloseButtonContainer>
      </ViewResourceContainer>
    </>
  );
}

export default MyGroupResource;
