import { FormInstance, Rule } from "antd/es/form";
import { IValueLabel } from "../../types/common";
import { QuestionCategory } from "../../types/questionBank";
import BorderedCheckboxes from "../quizzes/BorderedCheckboxes";

type IProps = {
  label: string;
  name: string;
  rules?: Rule[];
  form: FormInstance;
};

const options: IValueLabel[] = [
  {
    value: QuestionCategory.MCQ,
    label: "Multiple Choice Questions",
  },
  {
    value: QuestionCategory.RC,
    label: "Reading Comprehension",
  },
  {
    value: QuestionCategory.SR,
    label: "Student Response",
  },
];

function Category(props: IProps) {
  return <BorderedCheckboxes data={options} {...props} />;
}

export default Category;
