import { Col, Row, Form, Input, DatePicker } from "antd";
import dayjs from "dayjs";
import "react-phone-number-input/style.css";
import SelectCourseOptions from "../../components/selectFormOptions/SelectCourseOptions";
import { IGroupByIdResponse } from "../../types/group";

import SectionCheckbox from "./SectionCheckbox";
type PersonalDetailsProps = {
  data?: IGroupByIdResponse;
};

function GroupDetails(props: PersonalDetailsProps) {
  const { data } = props;

  const [form] = Form.useForm();

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="personalDetailsForm"
        initialValues={{
          name: data?.data.name,
          startDate: dayjs(data?.data.startDate),
          courseId: data?.data.courses[0].id,
          section: data?.data?.sections.map((item) => `${item.id}`),
        }}
      >
        <Row gutter={[32, 22]}>
          <Col span={12}>
            <Row gutter={[32, 22]}>
              <Col span={24}>
                <Form.Item
                  label="Group Name"
                  name="name"
                  rules={[{ required: true, message: "Please enter name" }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Group Start Date"
                  name="startDate"
                  rules={[
                    { required: true, message: "Please enter date of joining" },
                  ]}
                >
                  <DatePicker className="w-100" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[32, 22]}>
              <Col span={24}>
                <SelectCourseOptions
                  label="Select Course"
                  name="courseId"
                  rules={[{ required: true, message: "Please select course" }]}
                />
              </Col>

              <Col span={24}>
                <SectionCheckbox
                  label="Section"
                  name="section"
                  dependency="courseId"
                  rules={[{ required: true, message: "Please select section" }]}
                  form={form}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default GroupDetails;
