import { mainApi } from "../../app/services/mainApiSlice";
import {
  ISection,
  ISectionByCourseIdResponse,
  ISectionByIdResponse,
  ISectionResponse,
  ISectionIndex,
} from "../../types/section";

const commonUrl: string = "/admin/section";

const sectionICApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSections: builder.query<
      ISectionResponse,
      {
        currentPage: number;
        pageSize: number;
        sectionName?: string;
        course?: string;
        duration?: string;
      }
    >({
      query: ({ currentPage, pageSize, sectionName, course, duration }) => ({
        url: `${commonUrl}/get-all-section?sectionName=${
          sectionName || ""
        }&course=${course || ""}&duration=${duration || ""}&page=${
          currentPage - 1
        }&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "SECTIONIC" }],
    }),

    getSectionById: builder.query<ISectionByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-section/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "SECTIONIC" }],
    }),

    // Add Section
    addSection: builder.mutation<ISection, Partial<ISection>>({
      query: (SectionData: ISection) => ({
        url: `${commonUrl}/save`,
        method: "POST",
        body: SectionData,
      }),
      invalidatesTags: [
        { type: "Main", id: "SECTIONIC" },
        { type: "Main", id: "COURSESIC" },
      ],
    }),

    // Delete Section By ID
    deleteSectionById: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `${commonUrl}/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "Main", id: "SECTIONIC" },
        { type: "Main", id: "COURSESIC" },
      ],
    }),

    // Update Section By ID
    updateSectionById: builder.mutation<
      ISection,
      { id: number | undefined; post: Partial<ISection> }
    >({
      query: ({ id, post }) => ({
        url: `${commonUrl}/update-section/${id}`,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: [
        { type: "Main", id: "SECTIONIC" },
        { type: "Main", id: "COURSESIC" },
      ],
    }),

    //update index
    updateIndex: builder.mutation<
      ISectionIndex,
      { post: Partial<ISectionIndex>[] }
    >({
      query: ({ post }) => ({
        url: `${commonUrl}/update-index`,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "SECTIONIC" }],
    }),

    // Get Section By Course ID
    getSectionByCourseId: builder.query<
      ISectionByCourseIdResponse,
      number | null
    >({
      query: (id) => ({
        url: `${commonUrl}/section-list/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "SECTIONIC" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllSectionsQuery,
  useAddSectionMutation,
  useGetSectionByIdQuery,
  useUpdateIndexMutation,
  useGetSectionByCourseIdQuery,
  useLazyGetSectionByCourseIdQuery,
  useUpdateSectionByIdMutation,
  useDeleteSectionByIdMutation,
} = sectionICApi;
