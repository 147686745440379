import { Button, Layout, Row, Tooltip } from "antd";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import styled from "styled-components";
import CourseProgress from "./CourseProgress";
import CommonHeader from "./studentPanelHeader/CommonHeader";
import StudentBreadcrumbs from "../common/StudentBreadcrumbs";
import { HomeFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  // padding: 0 1rem 0rem 1rem;
`;

const SubHeaderBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dfecef;
  height: 3rem;
  padding: 0rem 1.5rem 0rem 1.5rem;
`;

const HomeButtonGhost = styled(Button)`
  border: none;
  padding: 0;
  font-size: 1.2rem;
`;
const { Content } = Layout;

function StudentPanelLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const navigationArray = location.pathname.split("/");

  const setLink = () => {
    if (navigationArray.includes("start-learning")) {
      navigate(`/course/${courseId}/my-learning`);
    } else if (
      navigationArray.length === 4 &&
      !navigationArray.includes("start-learning")
    ) {
      navigate("/");
    } else {
      navigate(`/course/${courseId}/${navigationArray[3]}`);
    }
  };

  return (
    <Layout
      style={{ minHeight: "100%" }}
      className="lightgray-background-color"
    >
      <>
        {!location.pathname.split("/").includes("take-quiz") ? (
          <CommonHeader />
        ) : (
          <></>
        )}
        {!location.pathname.split("/").includes("course") ||
        location.pathname.split("/").includes("take-quiz") ? (
          <></>
        ) : (
          <SubHeaderBar>
            <Row align={"middle"}>
              <Row>
                <Tooltip title="Go to home">
                  <HomeButtonGhost ghost onClick={setLink}>
                    <HomeFilled />
                  </HomeButtonGhost>
                </Tooltip>
              </Row>

              <Row className="p-1">
                <StudentBreadcrumbs />
              </Row>
            </Row>
            {!courseId ? <></> : <CourseProgress />}
          </SubHeaderBar>
        )}

        <Layout className="inner-styled-container">
          <Content>
            <OutletContainer>
              <Outlet />
            </OutletContainer>
          </Content>
        </Layout>
      </>
    </Layout>
  );
}

export default StudentPanelLayout;
