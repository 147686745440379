import { Col, Input, Row, Form } from "antd";
import type { IQuizByIdResponse } from "../../types/quizzes";
import TopicCheckbox from "../quizDetails/TopicCheckbox";
import Category from "../selectFormOptions/Category";
import DifficultyLevel from "../selectFormOptions/DifficultyLevel";
import SelectCourseOptions from "../selectFormOptions/SelectCourseOptions";
import SelectQuestionTypeOptions from "../selectFormOptions/SelectQuestionTypeOptions";
import SelectSectionOptions from "../selectFormOptions/SelectSectionOptions";

type QuizzesFormProps = {
  data?: IQuizByIdResponse;
};

function ViewQuizDetailsForm(props: QuizzesFormProps) {
  const { data } = props;
  const [form] = Form.useForm();

  const initialValues = {
    quizName: data?.data.quizName,
    quizMode: data?.data.quizMode,
    quizTime: data?.data.quizTime,
    quizQuestion: "Manual Selection",
    noOfQuestion: data?.data.numberOfQuestions,
    questionCategory: data?.data.questions[0].questionCategory,
    courseId: data?.data.courses[0].id,
    sectionId: data?.data.sections[0].id,
    lessonId: data?.data.topics[0].lessonId,
    difficultyLevel: data?.data.questions[0].difficultyLevel,
    topicIds: data?.data.topics.map((topic) => topic.id),
    courseName: data?.data.courses[0].name,
    sectionName: data?.data.sections[0].name,
    lessonName: data?.data.topics[0].lessonName,
  };

  return (
    <>
      <Form
        layout="vertical"
        initialValues={initialValues}
        form={form}
        disabled
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <Form.Item label="Quiz Name" name="quizName">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Quiz Mode" name="quizMode">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Time Duration(in min)" name="quizTime">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Quiz Question" name="quizQuestion">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Number of Question" name="noOfQuestion">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Category
                  label="Question Category"
                  name="questionCategory"
                  form={form}
                />
              </Col>
              <Col span={24}>
                <DifficultyLevel
                  label="Difficulty Level"
                  name="difficultyLevel"
                  form={form}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <SelectCourseOptions label="Course" name="courseId" />
              </Col>
              <Col span={24}>
                <SelectSectionOptions
                  label="Section"
                  name="sectionId"
                  form={form}
                />
              </Col>
              <Col span={24}>
                <SelectQuestionTypeOptions
                  label="Question Type"
                  name="lessonId"
                  form={form}
                />
              </Col>
              <Col span={24}>
                <TopicCheckbox
                  name="topicIds"
                  label="Topics"
                  dependency="lessonId"
                  form={form}
                  disabled={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ViewQuizDetailsForm;
