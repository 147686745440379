const internalCoursesTabs: string[] = [
  "/internal-courses",
  "/internal-courses/section",
  "/internal-courses/question-type",
  "/internal-courses/topics",
];

const resourceTabs: string[] = [
  "resources/add-file",
  "resources/add-text1"
];

const resourceSubTabs: string[]= [
  "courses",
  "section",
  "question-type",
  "topic"
];


const quizzesTabs: string[] = ["", "question-bank", "quiz-generator"];

export { internalCoursesTabs, quizzesTabs, resourceTabs, resourceSubTabs };
