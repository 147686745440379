import secureLocalStorage from "react-secure-storage";
import { mainApi } from "../../../app/services/mainApiSlice";
import { IStudentsByIdResponse } from "../../../types/studentPanelType/profileStudent";

const commonUrl: string = "/student/student";
const studentProfileApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // get tutor profile
    getStudentProfile: builder.query<
      IStudentsByIdResponse,
      string | undefined | null
    >({
      query: (id) => ({
        url: `${commonUrl}/get-student/${id}`,
        method: "GET",

      }),
      providesTags: [{ type: "Main", id: "STUDENT" }],
    }),

    addStudentImage: builder.mutation<
      FormData,
      { id: number | undefined; formData: FormData; }
    >({
      query: ({ id, formData }) => ({
        url: `${commonUrl}/save-image/${id}`,
        method: "POST",

        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "STUDENT" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetStudentProfileQuery, useAddStudentImageMutation } =
  studentProfileApi;
