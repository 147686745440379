const resourceFormatDropdown = [
  { value: "", label: "--" },
  { value: "DOC", label: "Doc" },
  { value: "IMAGE", label: "Image" },
  {
    value: "MP3",
    label: "Mp3",
  },
  {
    value: "MP4",
    label: "Mp4",
  },
  {
    value: "PDF",
    label: "Pdf",
  },
  {
    value: "PPT",
    label: "Ppt",
  },
  {
    value: "TEXT",
    label: "Text",
  },
];

export { resourceFormatDropdown };
