import { mainApi } from "../../../app/services/mainApiSlice";
import { IResourceByIdResponse } from "../../../types/resources";
import {
  IMyLearningResponse,
  ISaveProgress,
  ITopicCountResponse,
} from "../../../types/studentPanelTypes/studentMyLearningType";

const commonUrl: string = "/student/course";

const studentMyLearningApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    saveMyLearningProgress: builder.mutation<
      ISaveProgress,
      Partial<ISaveProgress>
    >({
      query: (data: any) => ({
        url: `${commonUrl}/save`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Main", id: "MYLEARNING" }],
    }),
    getAllMyLearningByCourseId: builder.query<
      IMyLearningResponse,
      { id: string | null; courseId: string | undefined }
    >({
      query: ({ id, courseId }) => ({
        url: `${commonUrl}/get-preview/${id}?courseId=${courseId}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "MYLEARNING" }],
    }),
    getStudentResourceById: builder.query<
      IResourceByIdResponse,
      string | undefined
    >({
      query: (id) => ({
        url: `${commonUrl}/get-resource/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "MYLEARNING" }],
    }),
    getStudentTopicCount: builder.query<
      ITopicCountResponse,
      { studentId: string | null; courseId: string | undefined }
    >({
      query: ({ studentId, courseId }) => ({
        url: `${commonUrl}/get-topicCount/${studentId}?courseId=${courseId}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "MYLEARNING" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllMyLearningByCourseIdQuery,
  useGetStudentResourceByIdQuery,
  useSaveMyLearningProgressMutation,
  useGetStudentTopicCountQuery,
} = studentMyLearningApi;
