import { Input, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../constants/pageSize";
import { ITutor } from "../../types/tutor";
import { useGetAllTutorQuery } from "../../features/tutor/tutorApiSlice";
import { IGroupByIdResponse } from "../../types/group";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

type AssignResourcesProps = {
  courseData?: IGroupByIdResponse;
  setAssignTutorId?: any;
};
function AssignTutor(props: AssignResourcesProps) {
  const [currentPage, setCurrentPage] = useState(1);

  const { courseData, setAssignTutorId } = props;

  const [section, setSection] = useState("");
  const [name, setName] = useState("");
  const [course, setCourse] = useState("");

  const { debouncedValue: sectionName, debouncing: sectionSearching } =
    useDebouncingValue(section);
  const { debouncedValue: tutorName, debouncing: tutorSearching } =
    useDebouncingValue(name);
  const { debouncedValue: courseName, debouncing: courseSearching } =
    useDebouncingValue(course);

  const { data, isLoading, isFetching } = useGetAllTutorQuery({
    currentPage,
    pageSize,
    sectionName,
    name: tutorName,
    course: courseName,
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >([]);

  const preSelectedTutor = courseData?.data.tutors.map(
    (item: any) => item["id"]
  );

  useEffect(() => {
    setSelectedRowKeys(preSelectedTutor || []);
  }, []);

  const columns: ColumnsType<ITutor> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Tutor Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setName(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "course",
      dataIndex: "tutorSectionDTO",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourse(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      render: (_, record) => {
        return record?.tutorSectionDTO
          .map((item) => item.course.map((course) => course.name))
          .join(", ");
      },
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSection(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      render: (_, record) => {
        return record.tutorSectionDTO
          .map((item: any) => item.sectionName)
          .join(", ");
      },
      dataIndex: "tutorSectionDTO",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setAssignTutorId(newSelectedRowKeys.map((id) => ({ id })));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Table<ITutor>
      rowSelection={{ ...rowSelection, preserveSelectedRowKeys: true }}
      loading={
        isLoading ||
        isFetching ||
        courseSearching ||
        sectionSearching ||
        tutorSearching
      }
      rowKey="id"
      columns={columns}
      dataSource={data?.data.content}
      pagination={{
        pageSize,
        current: currentPage,
        total: data?.data.totalElements,
        onChange(page) {
          setCurrentPage(page);
        },
      }}
    />
  );
}

export default AssignTutor;
