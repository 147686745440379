import { ColumnsType } from "antd/es/table";
import { Input, Table, Typography, Row } from "antd";
import { useState, useEffect } from "react";
import { ViewAnalyticsButton } from "../../../components/styled/Button.styled";
import { useNavigate } from "react-router-dom";
import pageSize from "../../../constants/pageSize";
import { IStudent } from "../../../types/studentType";
import { useGetAllStudentsByTutorIdQuery } from "../../../features/tutorPanelFeatures/TutorStudent/tutorStudentsApiSlice";
import secureLocalStorage from "react-secure-storage";
import { useDebouncingValue } from "../../../utils/useDebouncingValue";

function StudentAnalytics() {
  const navigate = useNavigate();
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const [searchText, setSearchText] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [course, setCourse] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { debouncedValue: studentName, debouncing: nameSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: phone, debouncing: phoneSearching } =
    useDebouncingValue(phoneNumber);
  const { debouncedValue: email, debouncing: emailSearching } =
    useDebouncingValue(emailId);
  const { debouncedValue: courseName, debouncing: courseSearching } =
    useDebouncingValue(course);

  const { data, isLoading,isFetching } = useGetAllStudentsByTutorIdQuery({
    currentPage,
    pageSize,
    idFromLocalStorage,
    courseName,
    emailId: email,
    phoneNumber: phone,
    studentName,
  });

  const columns: ColumnsType<IStudent> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Student Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "phoneNumber",
      title: (
        <div>
          <Typography.Text>Phone Number</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "phoneNumber",
    },
    {
      key: "email",
      title: (
        <div>
          <Typography.Text>Email Id</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setEmailId(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "email",
    },

    {
      key: "Course",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourse(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "courseName",
    },
    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewAnalyticsButton onClick={() => openStudentDetails(record.id)} />
      ),
    },
  ];
  const openStudentDetails = (id: number) => {
    navigate(`weekly-progress/${id}`);
  };

  return (
    <>
      <Row className="styled-container inner-styled-container">
        <Table<IStudent>
          columns={columns}
          rowKey={"id"}
          loading={
            isLoading ||
            nameSearching ||
            phoneSearching ||
            emailSearching ||
            courseSearching ||
            isFetching
          }
          dataSource={data?.data?.content}
          pagination={{
            pageSize,
            current: currentPage,
            total: data?.data.totalElements,
            onChange(page) {
              setCurrentPage(page);
            },
          }}
        />
      </Row>
    </>
  );
}

export default StudentAnalytics;
