import { Col, Form, Row } from "antd";
import { useNavigate } from "react-router-dom";
import SectionForm from "../../components/section/SectionForm";
import { PrimaryButton } from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import ContainerHeading from "../../components/styled/ContainerHeading.styled";
import FormContainer from "../../components/styled/FormContainer.styled";
import { useAppSelector } from "../../app/hooks";

function AddSection() {
  const navigate = useNavigate();

  const onClose = () => {
    navigate(-1);
  };
  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );
  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { mainSectionForm } = forms;
            mainSectionForm.submit();
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>Save</PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <ContainerHeading>Add Section</ContainerHeading>
          <WhiteContainer>
            <FormContainer>
              <SectionForm />
            </FormContainer>
          </WhiteContainer>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default AddSection;
