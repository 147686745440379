import { Input, Select, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../constants/pageSize";
import { IResource } from "../../types/resources";
import { useGetAllResourcesQuery } from "../../features/resources/resourcesApiSlice";
import { ITopic } from "../../types/topic";
import { IQuestionType } from "../../types/questionType";
import { ISection } from "../../types/section";
import { IStudentByIdResponse } from "../../types/studentType";
import { IGroupByIdResponse } from "../../types/group";
import { useDebouncingValue } from "../../utils/useDebouncingValue";
import { resourceFormatDropdown } from "../../utils/resourceFormatConstants";

type AssignResourcesProps = {
  setAssignResourcesId?: any;
  courseData?: IGroupByIdResponse;
};

function AssignResources(props: AssignResourcesProps) {
  const { setAssignResourcesId, courseData } = props;

  const [searchText, setSearchText] = useState("");
  const [topic, setTopic] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [section, setSection] = useState("");
  const [format, setFormat] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { debouncedValue: resourceName, debouncing: resourceSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: topicName, debouncing: topicSearching } =
    useDebouncingValue(topic);
  const {
    debouncedValue: questionTypeName,
    debouncing: questionTypeSearching,
  } = useDebouncingValue(questionType);
  const { debouncedValue: sectionName, debouncing: sectionSearching } =
    useDebouncingValue(section);
  const { debouncedValue: formatName, debouncing: formatSearching } =
    useDebouncingValue(format);

  const { data, isLoading, isFetching } = useGetAllResourcesQuery({
    currentPage,
    pageSize,
    lesson: questionTypeName,
    name: resourceName,
    resourceType: formatName,
    section: sectionName,
    topic: topicName,
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >([]);
  const preSelectedResources = courseData?.data.resource.map(
    (ele) => ele["id"]
  );

  useEffect(() => {
    setSelectedRowKeys(preSelectedResources || []);
  }, []);

  const columns: ColumnsType<IResource> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Resource Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },

    {
      key: "topics",
      title: (
        <div>
          <Typography.Text>Topic</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setTopic(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "topics",
      render: (topics: ITopic[]) =>
        topics.map((topic: ITopic) => topic?.name).join(", "),
    },
    {
      key: "questionType",
      title: (
        <div>
          <Typography.Text>Question Type</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setQuestionType(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      render: (lessons: IQuestionType[]) =>
        lessons.map((lesson: IQuestionType) => lesson?.name).join(", "),
      dataIndex: "lessons",
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSection(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      render: (sections: ISection[]) =>
        sections.map((section: ISection) => section?.name).join(", "),
      dataIndex: "sections",
    },
    {
      key: "format",
      title: (
        <div>
          <Typography.Text>Format</Typography.Text>
          <Select
            onChange={(value) => {
              setFormat(value);
              setCurrentPage(1);
            }}
            options={resourceFormatDropdown}
            className="searchDropdownStyle"
          />
        </div>
      ),
      dataIndex: "resourceType",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setAssignResourcesId(
      newSelectedRowKeys.map((id) => ({
        id,
      }))
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Table
      rowSelection={{ ...rowSelection, preserveSelectedRowKeys: true }}
      loading={
        isLoading ||
        isFetching ||
        resourceSearching ||
        topicSearching ||
        questionTypeSearching ||
        sectionSearching ||
        formatSearching
      }
      rowKey="id"
      columns={columns}
      dataSource={data?.data.content}
      pagination={{
        pageSize,
        current: currentPage,
        total: data?.data.totalElements,
        onChange(page) {
          setCurrentPage(page);
        },
      }}
    />
  );
}

export default AssignResources;
