import { Col, Form, Row } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuestionBankForm from "../../components/questionBank/QuestionBankForm";
import { PrimaryButton } from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import ContainerHeading from "../../components/styled/ContainerHeading.styled";
import FormContainer from "../../components/styled/FormContainer.styled";
import { useGetQuestionByIdQuery } from "../../features/quizzes/questionApiSlice";
import { FormInstance } from "rc-field-form/lib/interface";
import { useAppSelector } from "../../app/hooks";
import Breadcrumbs from "../../components/common/Breadcrumbs";

function EditQuestionBank() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [forms, setForms] = useState<Record<string, FormInstance>>();
  const { data, isLoading, isError } = useGetQuestionByIdQuery(id);

  const onClose = () => {
    navigate(-1);
  };
  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );

  return (
    <>
      <Breadcrumbs idName={!!id ? data?.data?.courseName.toLowerCase() : 'false'} isId={!!id ? true : false} />
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { mainForm } = forms;
            setForms(forms);
            mainForm.submit();
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>Save</PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <ContainerHeading>Question Details</ContainerHeading>
          <WhiteContainer>
            <FormContainer>
              {!isLoading && !isError && data && (
                <QuestionBankForm data={data?.data} forms={forms} />
              )}
            </FormContainer>
          </WhiteContainer>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default EditQuestionBank;
