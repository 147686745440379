import { Input, Row, Col, Form, notification, InputNumber } from "antd";
import type { IQuestionFilter, IQuizDetails } from "../../types/quizDetails";
import TopicCheckbox from "./TopicCheckbox";
import SelectCourseOptions from "../selectFormOptions/SelectCourseOptions";
import SelectSectionOptions from "../selectFormOptions/SelectSectionOptions";
import SelectQuestionTypeOptions from "../selectFormOptions/SelectQuestionTypeOptions";
import DifficultyLevel from "../selectFormOptions/DifficultyLevel";
import Category from "../selectFormOptions/Category";
import { useLazyGetFilterQuestionsQuery } from "../../features/quizzes/quizDetailsApiSlice";
import { useAppDispatch } from "../../app/hooks";
import {
  setSelectedFilteredQuestion,
  setSelectedNoOfQuestion,
} from "../../features/ui/uiSlice";
import { RedGhostButton } from "../styled/Button.styled";

type QuizDetailsFormProps = {
  data?: IQuizDetails;
  setActiveKey?: any;
};

function QuizDetailsForm({ setActiveKey }: QuizDetailsFormProps) {
  const [form] = Form.useForm();
  const [getFilterQuestions, { isFetching, isLoading }] =
    useLazyGetFilterQuestionsQuery();
  const dispatch = useAppDispatch();

  const filterQuestion = () => {
    const { difficultyLevel, questionCategory, topicIds, numberOfQuestions } =
      form.getFieldsValue();
    dispatch(setSelectedNoOfQuestion(Number(numberOfQuestions)));

    let obj = {
      difficultyLevel,
      questionCategory,
      topicId: topicIds.map((id: number) => ({ id })),
    } as IQuestionFilter;

    getFilterQuestions(obj)
      .unwrap()
      .then((data) => {
        dispatch(setSelectedFilteredQuestion(data?.data));
        setActiveKey("2");
      })
      .catch((error) =>
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        })
      );
  };

  const validateAndProceed = () => {
    form.validateFields().then(() => {
      filterQuestion();
    });
  };

  return (
    <>
      <Form
        form={form}
        name="mainForm"
        layout="vertical"
        initialValues={{
          quizMode: "TIME_BASED",
          quizQuestion: "Manual Selection",
        }}
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <SelectCourseOptions
                  label="Course"
                  name="courseId"
                  rules={[{ required: true, message: "Please select course" }]}
                />
              </Col>

              <Col span={24}>
                <SelectSectionOptions
                  label="Section"
                  name="sectionId"
                  rules={[{ required: true, message: "Please select section" }]}
                  form={form}
                />
              </Col>

              <Col span={24}>
                <SelectQuestionTypeOptions
                  label="Question Type"
                  name="questionTypeId"
                  rules={[
                    { required: true, message: "Please select question type" },
                  ]}
                  form={form}
                />
              </Col>

              <Col span={24}>
                <TopicCheckbox
                  form={form}
                  label="Topics"
                  name="topicIds"
                  rules={[{ required: true, message: "Please select topics" }]}
                />
              </Col>

              <Col span={24}>
                <Category
                  label="Question Category"
                  name="questionCategory"
                  rules={[
                    {
                      required: true,
                      message: "Please select question category",
                    },
                  ]}
                  form={form}
                />
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <DifficultyLevel
                  label="Difficulty Level"
                  name="difficultyLevel"
                  rules={[
                    {
                      required: true,
                      message: "Please select difficulty level",
                    },
                  ]}
                  form={form}
                />
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Quiz Name"
                  name="quizName"
                  rules={[
                    { required: true, message: "Please enter quiz name" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Quiz Mode"
                  name="quizMode"
                  rules={[
                    { required: true, message: "Please enter quiz mode" },
                  ]}
                >
                  <Input defaultValue={"TIME_BASED"} disabled />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Time Duration (in min.)"
                  name="quizTime"
                  rules={[
                    { required: true, message: "Please enter time duration" },
                  ]}
                >
                  <InputNumber
                    className="w-100"
                    controls={false}
                    min={1}
                    precision={0}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Quiz Questions"
                  name="quizQuestion"
                  rules={[
                    { required: true, message: "Please enter quiz questions" },
                  ]}
                >
                  <Input defaultValue={"Manual selection"} disabled />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Number of Question"
                  name="numberOfQuestions"
                  rules={[
                    {
                      required: true,
                      message: "Please enter number of question",
                    },
                  ]}
                >
                  <InputNumber
                    className="w-100"
                    controls={false}
                    min={1}
                    precision={0}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Row justify="end" gutter={20} className="mt-2">
        <Col>
          <RedGhostButton
            onClick={validateAndProceed}
            loading={isFetching || isLoading}
          >
            Proceed to Add Questions
          </RedGhostButton>
        </Col>
      </Row>
    </>
  );
}

export default QuizDetailsForm;
