import AuthStatus from "./AuthStatus";
import styled from "styled-components";

const HeaderContainer = styled.header`
  padding: 1rem 1rem 1rem 1rem;
  background-color: #201e43;
`;

function Header() {
  return (
    <HeaderContainer>
      <AuthStatus />
    </HeaderContainer>
  );
}

export default Header;
