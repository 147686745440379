import { mainApi } from "../../../app/services/mainApiSlice";
import {
  IGroupResponse,
  IGroupByIdResponse,
  IGroup,
} from "../../../types/group";

const commonUrl: string = "/tutor/group";

const tutorGroupApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTutorGroup: builder.query<
      IGroupResponse,
      {
        currentPage: number;
        pageSize: number;
        idFromLocalStorage: string | null;
        groupName: string;
        startDate: string;
        students: string;
      }
    >({
      query: ({
        currentPage,
        pageSize,
        idFromLocalStorage,
        groupName,
        startDate,
        students,
      }) => ({
        url: `${commonUrl}/tutor-groups/${idFromLocalStorage}?name=${
          groupName || ""
        }&page=${currentPage - 1}&size=${pageSize}&startDate=${
          startDate || ""
        }`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "GROUP" }],
    }),
    getTutorGroupById: builder.query<IGroupByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-group/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "GROUP" }],
    }),
    updateTutorGroupsById: builder.mutation<
      IGroup,
      {
        post: Partial<IGroup>;
      }
    >({
      query: ({ post }) => ({
        url: `${commonUrl}/edit-quiz`,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "GROUP" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllTutorGroupQuery,
  useGetTutorGroupByIdQuery,
  useUpdateTutorGroupsByIdMutation,
} = tutorGroupApi;
