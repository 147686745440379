import React, { useState } from "react";
import { Input, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../../constants/pageSize";
import { IStudent } from "../../../types/studentType";

import { ITutorByIdResponse } from "../../../types/tutor";

type AssignStudentProps = {
  data?: ITutorByIdResponse;
  setAssignStudentsId?: any;
  studentData?: any;
};

function AssignStudents(props: AssignStudentProps) {
  const { data } = props;
  const [searchText, setSearchText] = useState("");
  const [group, setGroup] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [course, setCourse] = useState("");

  const columns: ColumnsType<IStudent> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Student Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
      filteredValue: [searchText],
      onFilter: (value: any, record) => {
        return record?.name?.toLowerCase()?.includes(value?.toLowerCase());
      },
    },
    {
      key: "groupName",
      title: (
        <div>
          <Typography.Text>Group</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setGroup(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [group],

      dataIndex: "groupName",
    },
    {
      key: "phoneNumber ",
      title: (
        <div>
          <Typography.Text>Phone Number</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [phoneNumber],
      onFilter: (value: any, record) => {
        return record.phoneNumber
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      dataIndex: "phoneNumber",
    },
    {
      key: "course",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourse(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [course],

      onFilter: (value: any, record) => {
        return record?.courseName
          ?.toString()
          ?.toLowerCase()
          .includes(value?.toLowerCase());
      },
      dataIndex: "courseName",
    },
  ];

  return (
    <>
      <Table<IStudent>
        rowKey="id"
        columns={columns}
        dataSource={data?.data?.students}
        pagination={{
          pageSize,
        }}
      />
    </>
  );
}

export default AssignStudents;
