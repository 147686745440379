import { Col, Row, Tabs, Form } from "antd";
import type { ITopicByIdResponse } from "../../types/topic";
import SelectCourseOptions from "../selectFormOptions/SelectCourseOptions";
import SelectSectionOptions from "../selectFormOptions/SelectSectionOptions";
import SelectQuestionTypeOptions from "../selectFormOptions/SelectQuestionTypeOptions";
import { DarkGrayContainer } from "../styled/Container.styled";
import MultipleTopicForm from "./MultipleTopicForm";

type TopicFormProps = {
  data?: ITopicByIdResponse;
  topicName?: string;
};

function TopicForm(props: TopicFormProps) {
  const [form] = Form.useForm();

  const tabItems = [
    {
      key: "1",
      label: "Existing Topics",
      children: "",
      disabled: true,
    },
    {
      key: "2",
      label: "New Topic",
      children: <MultipleTopicForm form={form} />,
    },
  ];

  return (
    <>
      <Form form={form} layout="vertical" name="mainForm">
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <SelectCourseOptions
              label="Section Course"
              name="courseId"
              rules={[{ required: true, message: "Please Select Course" }]}
            />
          </Col>
          <Col span={12}>
            <SelectSectionOptions
              form={form}
              rules={[{ required: true, message: "Please Select Section" }]}
              label="Select Section"
              name="sectionId"
            />
          </Col>
          <Col span={12}>
            <SelectQuestionTypeOptions
              form={form}
              rules={[
                { required: true, message: "Please Select Question Type" },
              ]}
              label="Select Question Type"
              name="lessonId"
            />
          </Col>
        </Row>
      </Form>
      <Row>
        <Col span={24}>
          <DarkGrayContainer className="mt-2">
            <Tabs defaultActiveKey="2" items={tabItems} />
          </DarkGrayContainer>
        </Col>
      </Row>
    </>
  );
}

export default TopicForm;
