import { Col, Row } from "antd";
import styled from "styled-components";
import NotificationItem from "../components/notification/NotificationItem";
import { CloseButton } from "../components/styled/Button.styled";
import { StudentPanelWhiteContainer } from "../components/styled/Container.styled";
import { usePutStudentNotificationMutation } from "../features/studentPanelFeatures/StudentNotification/StudentNotificationApiSlice";
import { useGetAllAdminNotificationQuery } from "../features/AdminNotification/AdminNotificationApiSlice";
import { idFromLocalStorage } from "../utils/localStorageEncrypt";
import { useNavigate } from "react-router-dom";

const NotificationContainer = styled.div`
  height: 100vh;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

function AdminNotification() {
  const navigate = useNavigate();
  const { data } = useGetAllAdminNotificationQuery(idFromLocalStorage);

  const [putStudentNotification] = usePutStudentNotificationMutation();

  const handleNotificationClick = (id: string) => {
    putStudentNotification(id).unwrap();
  };
  const handleClose = () => {
    navigate("/");
  };

  return (
    <>
      <StudentPanelWhiteContainer>
        <NotificationContainer>
          <Row>
            <Col span={24}>
              {data?.data?.map((item, index) => (
                <NotificationItem
                  item={item}
                  key={index}
                  handleNotificationClick={handleNotificationClick}
                />
              ))}
            </Col>
            <CloseButton onClick={handleClose}></CloseButton>
          </Row>
        </NotificationContainer>
      </StudentPanelWhiteContainer>
    </>
  );
}

export default AdminNotification;
