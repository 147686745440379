import { useState } from "react";
import { Input, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../../constants/pageSize";
import { IStudent } from "../../../types/studentType";
import { IGroupByIdResponse } from "../../../types/group";

type AssignCoursesProps = {
  setAssignStudentsId?: any;
  data?: IGroupByIdResponse;
};

function AssignStudents(props: AssignCoursesProps) {
  const [searchText, setSearchText] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { data } = props;

  const columns: ColumnsType<IStudent> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Student Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
      filteredValue: [searchText],
      onFilter: (value: any, record) => {
        return record?.name?.toLowerCase()?.includes(value?.toLowerCase());
      },
    },
    {
      key: "phoneNumber ",
      title: (
        <div>
          <Typography.Text>Phone Number</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [phoneNumber],
      onFilter: (value: any, record) => {
        return record.phoneNumber
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      dataIndex: "phoneNumber",
    },
    {
      key: "email",
      title: (
        <div>
          <Typography.Text>Email</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [email],
      onFilter: (value: any, record) => {
        return record.email
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      dataIndex: "email",
    },
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "courseName",
      filteredValue: [searchText],
      onFilter: (value: any, record) => {
        return record?.name?.toLowerCase()?.includes(value?.toLowerCase());
      },
    },
  ];

  return (
    <>
      <Table<IStudent>
        rowKey="id"
        columns={columns}
        dataSource={data?.data.students}
        pagination={{
          pageSize,
          current: currentPage,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
      />
    </>
  );
}

export default AssignStudents;
