import { useState } from "react";
import { Input, Table, Typography, Row } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { ICourse } from "../../../types/course";
import CustomCard from "../../../components/countCard/CustomCard";
import pageSize from "../../../constants/pageSize";
import { ViewDetailsButton } from "../../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../../components/styled/Container.styled";
import { useGetAllMyCoursesQuery } from "../../../features/tutorPanelFeatures/TutorMyCourses/myCourseAPISlice";
import secureLocalStorage from "react-secure-storage";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../../utils/useDebouncingValue";

function MyCourses() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [section, setSection] = useState("");
  const [duration, setDuration] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [assignStudents, setAssignStudents] = useState("");
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;

  const { debouncedValue: courseName, debouncing: nameSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: sectionName, debouncing: sectionSearching } =
    useDebouncingValue(section);
  const { debouncedValue: courseDuration, debouncing: durationSearching } =
    useDebouncingValue(duration);
  const { debouncedValue: assigned, debouncing: studentSearching } =
    useDebouncingValue(assignStudents);

  const { data, isLoading, isFetching } = useGetAllMyCoursesQuery({
    pageSize,
    currentPage,
    idFromLocalStorage,
    course: courseName,
    sectionName,
    duration: courseDuration,
    assignedStudents: assigned,
  });

  const columns: ColumnsType<ICourse> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSection(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "section",
      render: (_, record) => {
        const section = record?.section.map((item) => item.name).join(", ");
        return section;
      },
    },
    {
      key: "duration",
      title: (
        <div>
          <Typography.Text>Duration(in Hrs.)</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setDuration(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "duration",
    },
    {
      key: "assignStudents",
      title: (
        <div>
          <Typography.Text>Assigned Students</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setAssignStudents(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "assignStudents",
    },
    {
      key: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openCourseDetails(record.id)} />
      ),
    },
  ];

  const openCourseDetails = (id: number) => {
    navigate(`view/${id}`);
  };

  return (
    <>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard sum={data?.data?.totalElements} text="My Courses" />
      </Row>
      <LightGrayContainer>
        <WhiteContainer>
          <Table<ICourse>
            loading={
              isLoading ||
              isFetching ||
              nameSearching ||
              sectionSearching ||
              durationSearching ||
              studentSearching
            }
            rowKey="id"
            columns={columns}
            dataSource={data?.data.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: data?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </WhiteContainer>
      </LightGrayContainer>
    </>
  );
}

export default MyCourses;
