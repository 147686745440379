import { Input, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { useGetAllTutorQuery } from "../../../features/tutor/tutorApiSlice";
import { ITutor } from "../../../types/tutor";
import pageSize from "../../../constants/pageSize";
import { IStudentByIdResponse } from "../../../types/studentType";
import { useDebouncingValue } from "../../../utils/useDebouncingValue";

type AssignTutorsProps = {
  setAssignTutorsId?: any;
  courseData?: IStudentByIdResponse;
};
function AssignTutors(props: AssignTutorsProps) {
  const { setAssignTutorsId, courseData } = props;
  const [currentPage, setCurrentPage] = useState(1);

  const [searchText, setSearchText] = useState("");
  const [section, setSection] = useState("");
  const [course, setCourse] = useState("");

  const { debouncedValue: sectionName, debouncing: sectionSearching } =
    useDebouncingValue(section);
  const { debouncedValue: tutorName, debouncing: tutorSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: courseName, debouncing: courseSearching } =
    useDebouncingValue(course);

  const { data, isLoading, isFetching } = useGetAllTutorQuery({
    currentPage,
    pageSize,
    sectionName,
    name: tutorName,
    course: courseName,
  });

  const preSelectedTutors = courseData?.data.assignTutor.map(
    (ele) => ele["id"]
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >([]);

  useEffect(() => {
    setSelectedRowKeys(preSelectedTutors || []);
  }, []);

  const columns: ColumnsType<ITutor> = [
    {
      key: "tutorName",
      title: (
        <div>
          <Typography.Text>Tutor Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSection(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      render: (_, record) => {
        return record.tutorSectionDTO
          .map((item: any) => item.sectionName)
          .join(", ");
      },
      dataIndex: "tutorSectionDTO",
    },
    {
      key: "course",
      title: (
        <div>
          <Typography.Text>Course Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourse(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      render: (tutorSectionDTO) =>
        tutorSectionDTO
          .map((tutorSection: any) =>
            tutorSection.course.map((course: any) => course.name)
          )
          .join(" , "),
      dataIndex: "tutorSectionDTO",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setAssignTutorsId(
      newSelectedRowKeys.map((id) => ({
        id,
      }))
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Table<ITutor>
      rowSelection={{ ...rowSelection, preserveSelectedRowKeys: true }}
      loading={
        isLoading ||
        isFetching ||
        tutorSearching ||
        sectionSearching ||
        courseSearching
      }
      rowKey="id"
      columns={columns}
      dataSource={data?.data.content}
      pagination={{
        pageSize,
        current: currentPage,
        total: data?.data.totalElements,
        onChange(page) {
          setCurrentPage(page);
        },
      }}
    />
  );
}

export default AssignTutors;
