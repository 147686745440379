import {
  LightGrayContainer,
  WhiteContainer,
} from "../../../components/styled/Container.styled";
import FormContainer from "../../../components/styled/FormContainer.styled";
import ContainerHeading from "../../../components/styled/ContainerHeading.styled";
import { Col, Form, Input, notification, Radio, Row } from "antd";
import PhoneInput from "react-phone-number-input";
import AssignedCourses from "./AssignedCourse";
import { useState, useEffect } from "react";
import {
  useAddTutorProfileImageMutation,
  useGetTutorProfileQuery,
  useUpdateTutorProfileByIdMutation,
} from "../../../features/tutorPanelFeatures/TutorProfile/tutorProfileSlice";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import { GCP_URL } from "../../../constants/commonUtils";
import secureLocalStorage from "react-secure-storage";
import { useAppSelector } from "../../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../../features/ui/uiSlice";
import ProfileImageUploader from "../../../components/uploader/ProfileImageUploader";


function TutorProfile() {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const { data, isLoading, isError } =
    useGetTutorProfileQuery(idFromLocalStorage);
  const [addTutorProfileImage] = useAddTutorProfileImageMutation();
  const [updateTutorProfileById, { isLoading: isLoadingEdit }] = useUpdateTutorProfileByIdMutation();
  const [values, setValues] = useState();

  const dispatch = useDispatch();
  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );

  dispatch(setButtonDisabled(isLoadingEdit));

  const [profilePicForm, setProfilePicForm] = useState<FormData>(
    new FormData()
  );

  const handleValue = () => {
    setValues(values);
  };
  const [img, setImg] = useState({
    image_file: null,
    image_preview: "",
  });

  useEffect(() => {
    const imageUrl = GCP_URL + `${data?.data?.imageUrl}`;
    setImg({
      image_preview: imageUrl,
      image_file: null,
    });
  }, [data]);

  useEffect(() => {
    if (profilePicForm.has("image")) {
      addTutorProfileImage({ id: data?.data.id, formData: profilePicForm })
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully uploaded",
            placement: "bottomLeft",
          });
        })
        .catch(() => {
          notification.error({
            message: "Error occured",
            placement: "bottomLeft",
          });
        });
    }
  }, [profilePicForm]);

  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { personalDetailsForm } = forms;
            personalDetailsForm.submit();
            let personalData = { ...personalDetailsForm?.getFieldsValue() };
            let obj = {
              ...personalData,
              imageUrl: data?.data?.imageUrl,
              tutorSectionDTO: data?.data?.tutorSectionDTO,
              status: true,
            };
            updateTutorProfileById({ id: data?.data?.id, post: obj })
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully edited",
                  placement: "bottomLeft",
                });
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>Save</PrimaryButton>
            </Form>
          </Col>
        </Row>

        <LightGrayContainer>
          <ContainerHeading>Tutor Profile</ContainerHeading>
          {!isLoading && !isError && data && (
            <WhiteContainer>
              <FormContainer>
                <Form
                  layout="vertical"
                  name="personalDetailsForm"
                  initialValues={{
                    name: data?.data?.name,
                    role: "Tutor",
                    gender: data?.data?.gender,
                    phoneNumber: data?.data?.phoneNumber,
                    email: data?.data?.email,
                  }}
                >
                  <Row gutter={[32, 22]}>
                    <Col span={12}>
                      <Row gutter={[32, 22]}>
                        <Col span={24}>
                          <Row align="middle">
                            <ProfileImageUploader
                              src={img.image_preview}
                              type={"file"}
                              name={"image"}
                              id={"file"}
                              setImg={setImg}
                              setProfilePicForm={setProfilePicForm}
                            />
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Form.Item label="Tutor Name" name="name">
                            <Input disabled={true} />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[
                              {
                                required: true,
                                message: "Please select gender",
                              },
                            ]}
                          >
                            <Radio.Group disabled={true}>
                              <Radio value="MALE">Male</Radio>
                              <Radio value="FEMALE">Female</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            name="phoneNumber"
                            label="Phone Number"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your phone number",
                              },
                            ]}
                          >
                            <PhoneInput
                              placeholder="Enter phone number"
                              value={values}
                              onChange={handleValue}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item label="EmailID" name="email">
                            <Input disabled={true} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={12}>
                      <Row gutter={[32, 22]}>
                        <Col span={24}>
                          <Form.Item label="Role" name="role">
                            <Input disabled={true} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Col span={24}>
                    <Form.Item>
                      <p>Assigned courses</p>
                      {!isLoading && !isError && data && (
                        <AssignedCourses courseData={data} />
                      )}
                    </Form.Item>
                  </Col>
                </Form>
              </FormContainer>
            </WhiteContainer>
          )}
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default TutorProfile;
