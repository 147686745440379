import styled from "styled-components";
import { useState } from "react";
import { Button, Form, Input, Card, notification, Typography, Row } from "antd";
import AuthPagesHeader from "../../components/layout/studentPanelHeader/AuthPagesHeader";
import { AuthPagesContainer } from "../../components/styled/Container.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../../features/forgotPassword/forgotPasswordApiSlice";

const NewPasswordCard = styled(Card)`
  height: 70vh;
  max-height: 400px;
  width: 40vw;
  display: flex;
  text-align: center;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
`;
const cardBodyStyle = {
  width: "100%",
};
function CreateNewPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = location.pathname.split("/")[2];
  const [resetPassword] = useResetPasswordMutation();
  const [showForm, setShowForm] = useState(true);

  const handleFormSubmit = async (val: {
    confirmPassword: string;
    newPassword: string;
    token: string;
  }) => {
    if (val.newPassword === val.confirmPassword) {
      resetPassword({ confirmPassword: val.confirmPassword, token: token })
        .unwrap()
        .then(() => {
          setShowForm(false);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        });
    } else {
      notification.error({
        message: "Passwords don’t match.",
        placement: "bottomLeft",
      });
    }
  };
  return (
    <>
      <AuthPagesHeader />
      <AuthPagesContainer>
        {showForm ? (
          <NewPasswordCard bodyStyle={cardBodyStyle}>
            <Typography.Title level={3} className="mt-0">
              Create New Password
            </Typography.Title>

            <Form
              initialValues={{ remember: true }}
              layout="vertical"
              onFinish={(values) => handleFormSubmit(values)}
            >
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  { 
                    required: true, 
                    message: "Please enter password" 
                  },
                  {
                      pattern: /^(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,32}$/,
                      message:
                          'Minimum 8 characters, one uppercase letter and one special character',
                  }
                ]}
                className="p-1"
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  { 
                    required: true, 
                    message: "Please confirm password" 
                  },
                  {
                      pattern: /^(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,32}$/,
                      message:
                          'Minimum 8 characters, one uppercase letter and one special character',
                  }
                ]}
                className="p-1"
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
              <Row justify={"center"} className="mt-2">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Create password
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </NewPasswordCard>
        ) : (
          <NewPasswordCard bodyStyle={cardBodyStyle}>
            <Typography.Title level={3} className="mt-0">
              Password updated successfully
            </Typography.Title>
            <Typography.Text>
              You will be redirected to login screen.
            </Typography.Text>
          </NewPasswordCard>
        )}
      </AuthPagesContainer>
    </>
  );
}

export default CreateNewPassword;
