import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../styled/Button.styled";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 90px;
  background-color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: white;
  height: max-content;
  padding-left: 15px;
  border-radius: 10px;
  width: 97.5%;

  .course-section-info {
    display: flex;
    position: relative;
    gap: 10px;
    height: 70px;
    align-items: center;
    justify-content: space-between;
  }
  .left-section {
    height: max-content;
    display: flex;
    gap: 10px;
  }
  .btn-container {
    position: absolute;
    right: 40px;
    top: 10px;
  }
  .section-info {
    display: flex;
    gap: 10px;
    height: 35px;
    align-items: center;
  }
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #b5b5b5;
  }
`;

function PreviewCustomHeader(props: any) {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="course-section-info">
        <div className="left-section">
          <p>{props.headerText}</p>
        </div>
        <div className="btn-container">
          <PrimaryButton onClick={() => navigate("/internal-courses")}>
            Close
          </PrimaryButton>
        </div>
      </div>
    </Container>
  );
}

export default PreviewCustomHeader;
