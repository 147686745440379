import { ITopic } from "./topic";

type IQuestionBank = {
  id: number;
  answers: IAnswer[];
  questionNumberSeq: string;
  questionText: string;
  questionCategory: string;
  difficultyLevel: string;
  courseName: string;
  explanation: string;
  courseId: number;
  lessonId: number;
  sectionId: number;
  status: boolean;
  topics: ITopic[];
  rcQuestionId: number;
  passage: string;
  questions: Omit<IQuestionBank, "questions">[];
  lessonName: string;
  sectionName: string;
};

type IAnswer = {
  id?: number;
  answerOptions: IAnswerOptions;
};

type IAnswerOption = {
  isCorrect: boolean;
  option: string;
  value: string;
  id?: number;
};

type IStudentAnswerOption = {
  isCorrect: boolean;
  option: string;
  value: string;
  id?: number;
  correctAnswer: string;
};

type IAnswerOptions = IAnswerOption[];

type IQuestionBankResponse = {
  data: {
    content: IQuestionBank[];
    totalElements: number;
  };
};

type IQuestionByIdResponse = {
  data: IQuestionBank;
};

enum QuestionCategory {
  RC = "READING_COMPREHENSION",
  MCQ = "MCQ",
  SR = "NUMERICAL",
}

export type {
  IQuestionBank,
  IQuestionBankResponse,
  IAnswerOptions,
  IAnswerOption,
  IQuestionByIdResponse,
  IStudentAnswerOption,
};

export { QuestionCategory };
