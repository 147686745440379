import { Tabs } from "antd";
import { IStudentAnalytics } from "../../types/studentPanelTypes/studentAnalytics";
import DayProgress from "./DayProgress";

type DayProgressTabsPropsType = {
  filteredObjectsWithDay: IStudentAnalytics[];
};

function DayProgressTabs({ filteredObjectsWithDay }: DayProgressTabsPropsType) {
  const tabItems = [
    {
      key: "1",
      label: "Monday",
      children: <DayProgress data={filteredObjectsWithDay} day={1} />,
    },
    {
      key: "2",
      label: "Tuesday",
      children: <DayProgress data={filteredObjectsWithDay} day={2} />,
    },
    {
      key: "3",
      label: "Wednesday",
      children: <DayProgress data={filteredObjectsWithDay} day={3} />,
    },
    {
      key: "4",
      label: "Thursday",
      children: <DayProgress data={filteredObjectsWithDay} day={4} />,
    },
    {
      key: "5",
      label: "Friday",
      children: <DayProgress data={filteredObjectsWithDay} day={5} />,
    },
    {
      key: "6",
      label: "Saturday",
      children: <DayProgress data={filteredObjectsWithDay} day={6} />,
    },
    {
      key: "7",
      label: "Sunday",
      children: <DayProgress data={filteredObjectsWithDay} day={0} />,
    },
  ];
  return (
    <>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </>
  );
}

export default DayProgressTabs;
