import { mainApi } from "../../app/services/mainApiSlice";
import {
  ICourseResponse,
  ICourse,
  ICourseByIdResponse,
  ICourseListResponse,
} from "../../types/course";

const commonUrl: string = "/admin/course";

const coursesICApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Courses
    getAllCourses: builder.query<
      ICourseResponse,
      {
        currentPage: number;
        pageSize: number;
        name?: string;
        duration?: string;
        assignedStudents?: string;
        sectionName?: string;
      }
    >({
      query: ({
        currentPage,
        pageSize,
        name,
        duration,
        assignedStudents,
        sectionName,
      }) => ({
        url: `${commonUrl}/get-all-course?page=${
          currentPage - 1
        }&size=${pageSize}&name=${name || ""}&duration=${
          duration || ""
        }&assignedStudents=${assignedStudents || ""}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "COURSESIC" }],
    }),
    // Get All Courses List
    getAllCoursesList: builder.query<ICourseListResponse, void>({
      query: () => ({
        url: `${commonUrl}/course-list`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "COURSESIC" }],
    }),
    // Add Course
    addCourse: builder.mutation<ICourse, Partial<ICourse>>({
      query: (courseData: ICourse) => ({
        url: `${commonUrl}/save`,
        method: "POST",
        body: courseData,
      }),
      invalidatesTags: [{ type: "Main", id: "COURSESIC" }],
    }),

    // Get Course By ID
    getCourseById: builder.query<ICourseByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-course/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "COURSESIC" }],
    }),

    // Delete Course By ID
    deleteCourseById: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `${commonUrl}/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Main", id: "COURSESIC" }],
    }),

    // Update Course By ID
    updateCourseById: builder.mutation<
      ICourse,
      { id: number | undefined; post: Partial<ICourse> }
    >({
      query: ({ id, post }) => ({
        url: `${commonUrl}/update-course/${id}`,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "COURSESIC" }],
    }),

    //Get Preview by Course ID
    getPreviewByCourseId: builder.query<any, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-preview/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "COURSESIC" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllCoursesQuery,
  useAddCourseMutation,
  useUpdateCourseByIdMutation,
  useGetCourseByIdQuery,
  useGetPreviewByCourseIdQuery,
  useDeleteCourseByIdMutation,
  useGetAllCoursesListQuery,
} = coursesICApi;
