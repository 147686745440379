import { Input, Table, Typography, Row, Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { IStudent } from "../../types/studentType";
import { ColumnsType } from "antd/es/table";
import { useGetAllStudentsQuery } from "../../features/student/studentsApiSlice";
import pageSize from "../../constants/pageSize";
import CustomCard from "../../components/countCard/CustomCard";
import {
  PrimaryButton,
  ViewDetailsButton,
} from "../../components/styled/Button.styled";
import { ICourse } from "../../types/course";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

function Students() {
  const { Text } = Typography;
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const [searchText, setSearchText] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [course, setCourse] = useState("");
  const [type, setType] = useState("INTERNAL");

  const { debouncedValue: studentName, debouncing: studentSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: phone, debouncing: phoneSearching } =
    useDebouncingValue(phoneNumber);
  const { debouncedValue: email, debouncing: emailSearching } =
    useDebouncingValue(emailId);
  const { debouncedValue: courseName, debouncing: courseSearching } =
    useDebouncingValue(course);

  const { data, isLoading, isFetching } = useGetAllStudentsQuery({
    currentPage,
    pageSize,
    courseName,
    email,
    name: studentName,
    phoneNumber: phone,
    studentType: type,
  });

  const columns: ColumnsType<IStudent> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Student Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "phoneNumber",
      title: (
        <div>
          <Typography.Text>Phone Number</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),

      dataIndex: "phoneNumber",
    },
    {
      key: "email",
      title: (
        <div>
          <Typography.Text>Email Id</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setEmailId(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),

      dataIndex: "email",
    },
    {
      key: "course",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourse(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),

      render: (assignCourse: ICourse[]) =>
        assignCourse.map((course: ICourse) => course.name).join(", "),

      dataIndex: "assignCourse",
    },
    {
      key: "type",
      title: (
        <div>
          <Typography.Text>Type</Typography.Text>
          <Select value={"INTERNAL"} className="searchDropdownStyle" disabled />
        </div>
      ),

      dataIndex: "studentType",
    },
    {
      key: "groupTag",
      dataIndex: "groupName",
      width: "15%",
      ellipsis: true,
      render: (_, record) => (
        <div>
          {record.groupName ? (
            <Text code ellipsis={true}>
              {record.groupName}
            </Text>
          ) : (
            <Text code ellipsis={true}>
              Individual
            </Text>
          )}
        </div>
      ),
    },
    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openStudentDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
  ];

  const openStudentDetails = (id: number) => {
    navigate(`${id}`);
  };

  return (
    <div>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard
          sum={data?.data?.content[0]?.totalStudent}
          text="Students"
        />
        <Link to="add">
          <PrimaryButton>Add Student</PrimaryButton>
        </Link>
      </Row>

      <Row className="styled-container outer-styled-container">
        <Row className="styled-container inner-styled-container">
          <Table<IStudent>
            loading={
              isLoading ||
              isFetching ||
              studentSearching ||
              emailSearching ||
              phoneSearching ||
              courseSearching
            }
            rowKey="id"
            columns={columns}
            dataSource={data?.data?.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: data?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </Row>
      </Row>
    </div>
  );
}

export default Students;
