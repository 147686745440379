import { Col, Collapse, Progress, Row, Tree, Typography } from "antd";
import { commonStrokeColor } from "../../constants/commonColors";
import { IStudentLesson } from "../../types/studentPanelTypes/studentAnalytics";

const { Panel } = Collapse;
const { Text } = Typography;

type ProgressItemsProps = {
  item2: IStudentLesson;
};

function ProgressItems({ item2 }: ProgressItemsProps) {
  const treeData = item2.studentTopicDTO?.map((topicData) => {
    return {
      title: (
        <>
          {topicData.topicName}
          <Progress
            percent={topicData?.topicProgress}
            strokeColor={commonStrokeColor}
          />
        </>
      ),
      key: topicData.topicId,
    };
  });

  return (
    <>
      <Collapse ghost expandIconPosition={"end"}>
        <Panel
          key="1"
          header={
            <>
              <Row style={{ justifyContent: "space-between" }}>
                <Col>{item2?.lessonName}</Col>
                <Col>
                  <Text code>{item2?.sectionName}</Text>
                </Col>
                <Col>
                  <Text code>{item2?.courseName}</Text>
                </Col>
                <Col span={6}>
                  <Progress
                    percent={item2?.lessonProgress}
                    strokeColor={commonStrokeColor}
                  />
                </Col>
              </Row>
            </>
          }
        >
          <Tree treeData={treeData} showLine />
        </Panel>
      </Collapse>
    </>
  );
}

export default ProgressItems;
