import styled from "styled-components";

const QuillContainer = styled.div`
  height: 100%;
  & > div > div:last-child {
    min-height: 250px;
  }
  .ql-container {
    min-height: 250px;
  }
  .ql-editor {
    min-height: 250px;
  }
`;

export default QuillContainer;
