import { Col, Row, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import { DarkGrayContainer } from "../../../components/styled/Container.styled";
import QuizProgress from "./QuizProgress";
import TopicWiseProgress from "./TopicWiseProgress";

function WeeklyProgress() {
  const navigate = useNavigate();
  const tabItems = [
    {
      key: "1",
      label: "Topic Wise Progress",
      children: <TopicWiseProgress />,
    },
    {
      key: "2",
      label: "Quiz Progress",
      children: <QuizProgress />,
    },
  ];

  const onClose = () => {
    navigate(-1);
  };
  return (
    <>
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </Col>
      </Row>
      <DarkGrayContainer>
        <Tabs defaultActiveKey="1" items={tabItems} />
      </DarkGrayContainer>
    </>
  );
}

export default WeeklyProgress;
