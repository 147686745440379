import {
  Checkbox,
  Col,
  Form,
  Row,
  Tag,
  Statistic,
  Divider,
  Input,
  Typography,
  Spin,
} from "antd";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  NextButton,
  PrimaryButton,
} from "../../../components/styled/Button.styled";
import {
  StudentFooter,
  StudentPanelWhiteContainer,
} from "../../../components/styled/Container.styled";
import {
  useGetStudentQuizByIdQuery,
  useSubmitQuizMutation,
} from "../../../features/studentPanelFeatures/StudentQuiz/studentQuizzes";
import { ReactComponent as BrandLogo } from "../../../assets/pluslogo.svg";
import { ImStopwatch } from "react-icons/im";
import TextViewer from "../../../components/preview/TextViewer";
import secureLocalStorage from "react-secure-storage";
import ModalSubmitQuiz from "../../../components/submitQuiz/ModalSubmitQuiz";
import QuestionTypesConstant from "../../../utils/constants";

const { Countdown } = Statistic;
const { Title } = Typography;
const HeaderContainer = styled.header`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
  background-color: #201e43;
  align-items: center;
`;
const SubHeaderBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ff6e6e;
  height: 4rem;
  padding: 0rem 1rem 0rem 1rem;
  color: white;
`;
function TakeQuiz() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id, courseId } = useParams();
  const { pathname } = useLocation();
  const { data, isLoading, isFetching } = useGetStudentQuizByIdQuery({id, studentId:idFromLocalStorage});
  const [count, setCount] = useState(0);
  const [studentAnswerOptions, setStudentAnswerOptions] = useState<any>([]);
  const [studentAnswerDTO, setStudentAnswerDTO] = useState<any>([]);
  const [totalQuizTime, setTotalQuizTime] = useState<string | number>(0);
  const [timeTaken, setTimeTaken] = useState(0);
  const [timeTakenFormatted, setTimeTakenFormatted] = useState<string>("");
  const [submitRedirectUrl, setSubmitRedirectUrl] = useState(
    `/course/${courseId}/quizzes/submitQuiz`
  );
  const [numericalResponse, setNumericalResponse] = useState("");

  const questionLength: any = data?.data.questions?.length;

  let areButtonsDisabled = data?.data.questions?.length === 0;

  let isNextDisabled = count === questionLength - 1;

  let quizTime = (data?.data?.quizTime || 0) * 60;
  const [openModal, setOpenModal] = useState(false);

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
    let studentAnswerDTOObj;
    if (
      data?.data.questions[count]?.questionCategory ===
        `${QuestionTypesConstant.Numerical}` &&
      numericalResponse !== ""
    ) {
      studentAnswerDTOObj = {
        questionId: data?.data.questions[count].id,
        studentAnswerOptions: [
          {
            value: `${numericalResponse}`,
            studentAnswerMarked: true,
          },
        ],
      };
      setNumericalResponse("");
    } else if (
      data?.data.questions[count]?.questionCategory ===
        `${QuestionTypesConstant.Numerical}` &&
      numericalResponse === ""
    ) {
      studentAnswerDTOObj = {
        questionId: data?.data.questions[count].id,
        studentAnswerOptions: [],
      };
    } else {
      studentAnswerDTOObj = {
        questionId: data?.data.questions[count].id,
        studentAnswerOptions,
      };
    }
    setStudentAnswerDTO([...studentAnswerDTO, studentAnswerDTOObj]);
    setStudentAnswerOptions([]);
  };

  const [submitQuiz] = useSubmitQuizMutation();

  const handleAnswer = (value: any, option: string) => {
    let studentAnswerOptionsObj = { value, option, studentAnswerMarked: true };
    if (
      !studentAnswerOptions.some(
        (item: any) => item.value === studentAnswerOptionsObj.value
      )
    ) {
      setStudentAnswerOptions([
        ...studentAnswerOptions,
        studentAnswerOptionsObj,
      ]);
    } else {
      let index = studentAnswerOptions.findIndex(
        (x: any) => x.value === studentAnswerOptionsObj.value
      );
      studentAnswerOptions.splice(index, 1);
    }
  };

  const onFinish = () => {
    navigate(`${submitRedirectUrl}`);
  };

  const saveTime = () => {
    let timeOfQuiz = quizTime - timeTaken / 1000;

    let dateObj = new Date(timeOfQuiz * 1000);
    let hours = dateObj.getUTCHours();
    let minutes = dateObj.getUTCMinutes();
    let seconds = dateObj.getSeconds();

    let timeString =
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0");

    setTimeTakenFormatted(timeString);
  };

  useEffect(() => {
    if (data?.data?.quizTime !== null) {
      setTotalQuizTime(
        new Date().getTime() + (data?.data?.quizTime || 0) * 1000 * 60
      );
    }
  }, [data]);

  useEffect(() => {
    if (pathname.split("/").includes("start-learning")) {
      setSubmitRedirectUrl(`/course/${courseId}/start-learning/submitQuiz`);
    }
  }, [pathname]);

  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { mainForm } = forms;
            mainForm.submit();
            let quizData = { ...mainForm.getFieldsValue() };
            let payloadObj = {
              ...quizData,
              quizId: data?.data.id,
              quizTime: data?.data.quizTime,
              timeTaken: timeTakenFormatted,
              studentAnswerDTO,
              studentId: idFromLocalStorage,
              totalQuestions: data?.data.questions.length,
              studentQuizStatus: true,
            };

            const confirmSubmit = () => {
              submitQuiz(payloadObj)
                .unwrap()
                .then((data) => {
                  setOpenModal(false);
                  navigate(`${submitRedirectUrl}`, { state: { data } });
                });
            };
            confirmSubmit();
          }
        }}
      >
        <HeaderContainer>
          <BrandLogo style={{ padding: "1rem", cursor: "pointer" }} />
        </HeaderContainer>

        <SubHeaderBar>
          <Row align={"middle"}>
            <Row>
              <Col>{data?.data.quizName}</Col>
            </Row>
          </Row>
          {data?.data?.quizTime !== null ? (
            <Row>
              <Col>
                <ImStopwatch size={30} />
              </Col>
              <Col>
                <Countdown
                  value={totalQuizTime}
                  format={"HH:mm:ss"}
                  onChange={(e: any) => setTimeTaken(e)}
                  onFinish={onFinish}
                  valueStyle={{ color: "white" }}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}

          <Col span={4}>
            <span>
              <Form name="submitForm">
                {count >= questionLength - 1 ? (
                  <PrimaryButton
                    htmlType="submit"
                    style={{ backgroundColor: "#c3dd7a" }}
                    onClick={() => {
                      setOpenModal(true);
                      handleIncrement();
                      saveTime();
                    }}
                  >
                    SUBMIT
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    title="Button will be enabled at last question"
                    disabled
                  >
                    SUBMIT
                  </PrimaryButton>
                )}
              </Form>
            </span>
          </Col>
        </SubHeaderBar>

        {data && !isLoading && !isFetching ? (
          <Form form={form} layout="vertical" name="mainForm">
            <StudentPanelWhiteContainer>
              <Row>
                <Col span={10}>
                  {count + 1 > data?.data?.numberOfQuestions ? (
                    ""
                  ) : (
                    <Title level={3}>
                      Question {count + 1}/{data.data.numberOfQuestions}
                    </Title>
                  )}
                </Col>
                <Col span={10}>
                  <Tag>{data?.data.questions[count]?.questionCategory}</Tag>
                </Col>
              </Row>

              {data?.data.questions[count]?.questionCategory ===
              `${QuestionTypesConstant.Rc}` ? (
                <Row>
                  <Col span={11}>
                    <br />

                    <TextViewer
                      textData={data?.data.questions[count]?.passage || " "}
                    />
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "38rem" }} />
                  </Col>
                  <Col span={11}>
                    <h3>Question</h3>

                    <TextViewer
                      textData={
                        data?.data.questions[count]?.questionText || " "
                      }
                    />
                    <br />
                    <h3>Answer Options</h3>

                    {data?.data.questions[count]?.answers.map((item) =>
                      item?.answerOptions?.map((answer) => (
                        <Row align={"middle"}>
                          <Col span={1}>{answer.option}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                key={`${answer.value}+${answer.id}`}
                                onClick={() =>
                                  handleAnswer(answer.value, answer.option)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={19}>
                            <TextViewer textData={answer.value || " "} />
                          </Col>
                        </Row>
                      ))
                    )}
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {data?.data.questions[count]?.questionCategory ===
              `${QuestionTypesConstant.Mcq}` ? (
                <>
                  <Col>
                    <TextViewer
                      textData={
                        data?.data.questions[count]?.questionText || " "
                      }
                    />
                  </Col>

                  <div>
                    <h5>Answer Options</h5>
                    {data?.data.questions[count]?.answers.map((item) =>
                      item?.answerOptions?.map((answer) => (
                        <Row align={"middle"}>
                          <Col span={1}>{answer.option}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                key={`${answer.value}+${answer.id}`}
                                onClick={() =>
                                  handleAnswer(answer.value, answer.option)
                                }
                              ></Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={19}>
                            <TextViewer textData={answer.value || " "} />
                          </Col>
                        </Row>
                      ))
                    )}
                  </div>

                  <br />
                </>
              ) : (
                ""
              )}
              {data?.data.questions[count]?.questionCategory ===
              `${QuestionTypesConstant.Numerical}` ? (
                <>
                  <Col>
                    <TextViewer
                      textData={
                        data?.data.questions[count]?.questionText || " "
                      }
                    />
                  </Col>
                  <br />
                  <Row align={"middle"}>
                    <Col span={4}>
                      <h5>Type your answer here</h5>
                    </Col>
                    <Col span={6}>
                      <Form.Item>
                        <Input
                          value={numericalResponse}
                          onChange={(e) => setNumericalResponse(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <br />
                </>
              ) : (
                ""
              )}
            </StudentPanelWhiteContainer>
            <StudentFooter>
              <Row>
                <Col span={12}>
                  <Row justify={"start"}></Row>
                </Col>

                <Col span={12}>
                  <Row justify={"end"}>
                    <NextButton
                      onClick={handleIncrement}
                      disabled={areButtonsDisabled || isNextDisabled}
                    >
                      Next
                    </NextButton>
                  </Row>
                </Col>
              </Row>
            </StudentFooter>
          </Form>
        ) : (
          <StudentPanelWhiteContainer>
            <Spin size="large" />
          </StudentPanelWhiteContainer>
        )}
      </Form.Provider>
      <ModalSubmitQuiz openModal={openModal} />
    </>
  );
}

export default TakeQuiz;
