import { mainApi } from "../../../app/services/mainApiSlice";
import { IMyGroupResponse } from "../../../types/studentPanelTypes/studentMyGroupType";

const commonUrl: string = "student/group";

const studentMyGroupApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllMyGroupByStudentId: builder.query<IMyGroupResponse, {courseId:string|undefined , id:string|null}>({
      query: ({courseId,id}) => ({
        url: `${commonUrl}/${id}?courseId=${courseId}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "MYGROUP" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllMyGroupByStudentIdQuery } = studentMyGroupApi;
