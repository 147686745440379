import {
  MdAssessment,
  MdAssignmentInd,
  MdAutoStories,
  MdBatchPrediction,
  MdCalculate,
  MdDashboard,
  MdFace,
  MdFileCopy,
  MdHelp,
  MdOutlineMenu,
  MdSupervisedUserCircle,
  MdMoreTime,
} from "react-icons/md";
import type { MenuProps } from "antd";

// New Menu Icons
import notesIcon from "../../assets/menuIcons/notesIcon.png";
import bookIcon from "../../assets/menuIcons/bookIcon.png";
import crossIcon from "../../assets/menuIcons/crossIcon.png";
import graphIcon from "../../assets/menuIcons/graphIcon.png";
import groupsIcon from "../../assets/menuIcons/groupsIcon.png";
import questionIcon from "../../assets/menuIcons/questionIcon.png";
import quizzesIcon from "../../assets/menuIcons/quizzesIcon.png";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

export const hamburgerIcon: MenuItem[] = [getItem("", "1", <MdOutlineMenu />)];

const adminItems: MenuItem[] = [
  getItem("Dashboard", "", <MdDashboard />),
  getItem("External Courses", "external-courses", <MdBatchPrediction />),
  getItem("Internal Courses", "internal-courses", <MdAutoStories />),
  getItem("Tutors", "tutors", <MdAssignmentInd />),
  getItem("Students", "students", <MdFace />),
  getItem("Groups", "groups", <MdSupervisedUserCircle />),
  getItem("Analytics", "analytics", <MdAssessment />),
  getItem("Quizzes", "quizzes", <MdCalculate />),
  getItem("Queries", "queries", <MdHelp />),
  getItem("Resources", "resources", <MdFileCopy />),
];

const tutorItems: MenuItem[] = [
  getItem("Dashboard", "", <MdDashboard />),
  getItem("My Courses", "my-courses", <MdAutoStories />),
  getItem("Time Punch", "time-punch", <MdMoreTime />),
  getItem("Students", "students", <MdFace />),
  getItem("Groups", "groups", <MdSupervisedUserCircle />),
  getItem("Analytics", "analytics", <MdAssessment />),
  getItem("Quizzes", "quizzes", <MdCalculate />),
];

const studentItems: MenuItem[] = [
  getItem(
    "My Learning",
    "my-learning",
    <img
      src={notesIcon}
      className="side-menu-icon-style"
      alt="my-learning-icon"
    />
  ),
  getItem(
    "My Group",
    "my-group",
    <img
      src={groupsIcon}
      className="side-menu-icon-style"
      alt="my-group-icon"
    />
  ),
  getItem(
    "Quizzes",
    "quizzes",
    <img
      src={quizzesIcon}
      className="side-menu-icon-style"
      alt="quizzes-icon"
    />
  ),
  getItem(
    "Error Log",
    "error-log",
    <img
      src={crossIcon}
      className="side-menu-icon-style"
      alt="error-log-icon"
    />
  ),
  getItem(
    "Analytics",
    "analytics",
    <img
      src={graphIcon}
      className="side-menu-icon-style"
      alt="analytics-icon"
    />
  ),
  getItem(
    "Blackbook",
    "blackbook",
    <img src={bookIcon} className="side-menu-icon-style" alt="blackbook-icon" />
  ),
  getItem(
    "Query",
    "query",
    <img src={questionIcon} className="side-menu-icon-style" alt="query-icon" />
  ),
];

export { adminItems, tutorItems, studentItems };
