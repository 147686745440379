import { Col, Form, Input, Radio, Row } from "antd";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import QuillContainer from "../../../components/styled/QuillContainer.styled";
import { GCP_URL } from "../../../constants/commonUtils";
import { IStudentByIdResponse } from "../../../types/studentType";
import TinyMce from "../../../components/common/TinyMce";

type PersonalDetailsProps = {
  data?: IStudentByIdResponse;
};

const profileImageStyle = {
  width: "6rem",
  height: "6rem",
  borderRadius: "50%",
};

function PersonalDetails(props: PersonalDetailsProps) {
  const { data } = props;
  const [values, setValues] = useState();
  const handleValue = () => {
    setValues(values);
  };

  return (
    <>
      <Form
        layout="vertical"
        initialValues={{
          name: data?.data.name,
          gender: data?.data.gender,
          phoneNumber: data?.data.phoneNumber,
          email: data?.data.email,
          studentType: data?.data.studentType,
          note: data?.data.note,
        }}
        disabled={true}
        name="personalDetailsForm"
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <img
                  src={GCP_URL + data?.data?.imageUrl}
                  style={profileImageStyle}
                  alt="profileImage"
                />
              </Col>

              <Col span={24}>
                <Form.Item label="Student Name" name="name">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="gender" label="Gender">
                  <Radio.Group>
                    <Radio value="MALE">Male</Radio>
                    <Radio value="FEMALE">Female</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="phoneNumber" label="Phone Number">
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={values}
                    onChange={handleValue}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="EmailID" name="email">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <p>Notes</p>
            <QuillContainer>
              <TinyMce name="note" />
            </QuillContainer>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default PersonalDetails;
