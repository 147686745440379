import styled from "styled-components";

const ContainerHeading = styled.div`
  padding: 0 0 1rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #f37373;
`;

export default ContainerHeading;
