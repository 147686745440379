import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Row,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import excelIcon from "../../assets/customIcons/2993694_brand_brands_excel_logo_logos_icon.svg";
import { useState } from "react";
import { RcFile } from "antd/es/upload";
import "../../css/excelUploader.css";
import { PrimaryButton } from "../styled/Button.styled";
import { useQuestionBulkUploadMutation } from "../../features/quizzes/questionApiSlice";

type ExcelUploaderProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
function ExcelUploader({ setOpen }: ExcelUploaderProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [questionBulkUpload] = useQuestionBulkUploadMutation();


  const handleUpload = () => {
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append("file", file as RcFile);
    });
    setUploading(true);
    questionBulkUpload({ formData })
      .unwrap()
      .then(({fieldErrorMessageMap,status}) => {
        if(status==='FAILED' && Object.keys(fieldErrorMessageMap).length===0){
          message.error("Failed")
          setFileList([]);
        }
        else if(status==='FAILED'&& Object.keys(fieldErrorMessageMap).length>0){
            Object.entries(fieldErrorMessageMap).forEach(([key,value])=>message.error(`${key}:${value}`))
            setFileList([]);
        }
       else{
        setFileList([]);
        message.success("Successfully uploaded");
        setOpen(false);
       }
      })
      .catch((error) => {
        message.error(error);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  
  return (
    <>
      <Row justify={"center"} className="mb-1">
        <img src={excelIcon} className="excel-upload-icon" alt="excel-icon" />
      </Row>

      <Row justify={"center"} className="excelUploader">
        <Col span={24}>
          <Row justify={"center"}>
            <Upload {...props}>
              <Button
                icon={<UploadOutlined />}
                className={`select-file-button ${
                  fileList.length > 0 ? "button-display-none" : ""
                }`}
              >
                Select File
              </Button>
            </Upload>
          </Row>
        </Col>
        <Divider style={{ borderColor: "#878787" }} className="mb-0" />
        <PrimaryButton
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          className="upload-button"
        >
          {uploading ? "Uploading" : "Start Upload"}
        </PrimaryButton>
      </Row>
    </>
  );
}

export default ExcelUploader;
