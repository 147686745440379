import { Form, Input } from "antd";
import QuillContainer from "../../components/styled/QuillContainer.styled";
import { IResourceByIdResponse } from "../../types/resources";
import TinyMce from "../../components/common/TinyMce";

type AddTextProps = {
  resourceData?: IResourceByIdResponse;
};

function AddText(props: AddTextProps) {
  const { resourceData } = props;

  return (
    <Form
      name="addTextForm"
      initialValues={{
        name: resourceData?.data.name,
        text: resourceData?.data.text,
      }}
    >
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: "Please enter name",
          },
        ]}
      >
        <Input placeholder="Add Title Here..." className="mb-1" />
      </Form.Item>

      <QuillContainer>
        <TinyMce name="text" />
      </QuillContainer>
    </Form>
  );
}

export default AddText;
