import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useLocation, useParams } from "react-router-dom";
import TextViewer from "../../../components/preview/TextViewer";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import { useGetStudentQuestionByIdQuery } from "../../../features/studentPanelFeatures/StudentQuiz/studentQuizzes";
import { useState } from "react";
import notesIcon from "../../../assets/customIcons/whiteBoard.png";
import NotesQuill from "../../../components/common/NotesQuill";
import StudentPanelFooter from "../../../components/layout/studentPanelFooter/StudentPanelFooter";
import QuestionTypesConstant from "../../../utils/constants";
import secureLocalStorage from "react-secure-storage";
import { setStudentBreadcrumb } from "../../../features/ui/uiSlice";
import { useDispatch } from "react-redux";

const { Panel } = Collapse;

function ViewBlackbookQuestion() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const { questionId } = useParams();
  const { data, isLoading } = useGetStudentQuestionByIdQuery({
    idFromLocalStorage,
    questionId,
  });
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const value = data?.data?.studentAnswerOption;
  const handleClickNotes = () => {
    setShowNotes(!showNotes);
  };

  const handleCloseNotes = () => {
    setShowNotes(false);
  };
  const onChange = (content: string) => {
    setNotes(content);
  };

  dispatch(
    setStudentBreadcrumb([
      `${pathname.split("/")[3]}/${pathname.split("/")[4]}`,
    ])
  );

  return (
    <>
      {!isLoading ? (
        <>
          {!!value ? (
            <>
              <StudentPanelWhiteContainer>
                <Row>
                  <Col span={21} className="blackbook-meta-tags-container">
                    <Tag className="p-1">{data?.data?.questionCategory}</Tag>
                    <Tag className="p-1">{data?.data?.courseName}</Tag>
                    <Tag className="p-1">{data?.data?.sectionName}</Tag>
                    <Tag className="p-1">{data?.data?.lessonName}</Tag>
                  </Col>
                  <Col span={2}>
                    <Row justify={"end"}>
                      <Tooltip title="Notes">
                        <Button
                          ghost
                          className="p-0"
                          onClick={handleClickNotes}
                        >
                          <img src={notesIcon} alt="notes" />
                        </Button>
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>

                {data?.data?.questionCategory ===
                `${QuestionTypesConstant.Mcq}` ? (
                  <>
                    <Typography.Title level={4} className="ml-1">
                      Question
                    </Typography.Title>
                    <TextViewer textData={data?.data?.questionText || " "} />
                    <div className="ml-1">
                      <Typography.Title level={4}>
                        Answer Options
                      </Typography.Title>

                      {data?.data.answers.map((item) =>
                        item?.answerOptions.map((answer) => (
                          <Row align={"middle"}>
                            <Col span={2}>{answer.option}</Col>
                            <Col>
                              <Checkbox
                                id={answer.value}
                                checked={value?.some((v) => v.value === answer.value)}
                              />
                            </Col>
                            <Col span={17}>
                              <TextViewer textData={answer.value || " "} />
                            </Col>
                            <>
                              <span>
                                {answer.isCorrect === true ? (
                                  <p className=" correct-ans"> Correct&nbsp;</p>
                                ) : (
                                  ""
                                )}
                              </span>

                              <>
                                {data?.data.answerStatus === "WRONG" &&
                                value?.some((v) => v.value === answer.value) ? (
                                  <p className=" wrong-ans">Incorrect</p>
                                ) : data?.data.answerStatus === "CORRECT" &&
                                  value?.some(
                                    (v) => v.value === answer.value
                                  ) ? (
                                  <p className=" correct-ans"> &nbsp;</p>
                                ) : data?.data.answerStatus === "SKIPPED" ? (
                                  ""
                                ) : (
                                  ""
                                )}
                              </>
                            </>
                          </Row>
                        ))
                      )}
                    </div>

                    <br />
                    <Collapse ghost expandIconPosition="end">
                      <Panel header="Explanation" key="1">
                        <TextViewer textData={data?.data.explanation || " "} />
                      </Panel>
                    </Collapse>
                  </>
                ) : (
                  ""
                )}
                {data?.data?.questionCategory ===
                `${QuestionTypesConstant.Numerical}` ? (
                  <>
                    <Typography.Title level={4} className="ml-1">
                      Question
                    </Typography.Title>
                    <TextViewer textData={data?.data.questionText || " "} />

                    <Row align={"middle"} justify={"space-evenly"}>
                      <Col span={24}>
                        <Typography.Title level={5}>
                          Type your answer here
                        </Typography.Title>
                      </Col>
                      <Col span={6}>
                        <Form.Item>
                          <Input
                            value={data?.data?.studentAnswerOption[0]?.value}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        {data?.data?.answerStatus === "WRONG" && (
                          <p className=" wrong-ans">Incorrect</p>
                        )}
                        {data?.data?.answerStatus === "CORRECT" && (
                          <p className=" correct-ans">Correct</p>
                        )}
                        {data?.data?.answerStatus === "SKIPPED" && (
                          <p>Skipped</p>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Collapse ghost expandIconPosition="end">
                      <Panel header="Explanation" key="1">
                        <TextViewer textData={data?.data.explanation || " "} />
                      </Panel>
                    </Collapse>
                  </>
                ) : (
                  ""
                )}
                {data?.data?.questionCategory ===
                `${QuestionTypesConstant.Rc}` ? (
                  <>
                    <Row>
                      <Col span={12}>
                        <br />
                        <TextViewer textData={data.data.passage} />
                      </Col>
                      <Col>
                        <Divider type="vertical" style={{ height: "38rem" }} />
                      </Col>
                      <Col span={11}>
                        <Typography.Title level={4} className="ml-1">
                          Question
                        </Typography.Title>
                        <TextViewer textData={data?.data.questionText || " "} />
                        <div className="ml-1">
                          <Typography.Title level={4}>
                            Answer Options
                          </Typography.Title>

                          {data?.data.answers.map((item) =>
                            item?.answerOptions.map((answer) => (
                              <Row align={"middle"}>
                                <Col span={2}>{answer.option}</Col>
                                <Col>
                                  <Checkbox
                                    id={answer.value}
                                    checked={value?.some((v) => v.value === answer.value)}
                                  />
                                </Col>
                                <Col span={17}>
                                  <TextViewer textData={answer.value || " "} />
                                </Col>
                                <>
                                  <span>
                                    {answer.isCorrect === true ? (
                                      <p className=" correct-ans">
                                        {" "}
                                        Correct&nbsp;
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </span>

                                  <>
                                    {data?.data.answerStatus === "WRONG" &&
                                    value?.some(
                                      (v) => v.value === answer.value
                                    ) ? (
                                      <p className=" wrong-ans">Incorrect</p>
                                    ) : data?.data.answerStatus === "CORRECT" &&
                                      value?.some(
                                        (v) => v.value === answer.value
                                      ) ? (
                                      <p className=" correct-ans"> &nbsp;</p>
                                    ) : data?.data.answerStatus ===
                                      "SKIPPED" ? (
                                      ""
                                    ) : (
                                      ""
                                    )}
                                  </>
                                </>
                              </Row>
                            ))
                          )}
                        </div>

                        <br />
                        <Collapse
                          ghost
                          expandIconPosition="end"
                          className="ml-1"
                        >
                          <Panel header="Explanation" key="1">
                            <TextViewer
                              textData={data?.data.explanation || " "}
                            />
                          </Panel>
                        </Collapse>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </StudentPanelWhiteContainer>
              <StudentPanelFooter />
              {showNotes && (
                <NotesQuill
                  onChange={onChange}
                  notes={data?.data?.note}
                  readOnly={true}
                  handleCloseNotes={handleCloseNotes}
                ></NotesQuill>
              )}
            </>
          ) : (
            <div style={{ paddingTop: "5%" }}>
              <Alert
                message="data inconsistent, check with database administrator"
                type="warning"
              />
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            paddingTop: "5%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  );
}

export default ViewBlackbookQuestion;
