import { mainApi } from "../../../app/services/mainApiSlice";
import { IStudentDashboardResponse } from "../../../types/studentPanelTypes/studentDashboardType";

const commonUrl: string = "/student";

const studentDashboardApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStudentDashboard: builder.query<
      IStudentDashboardResponse,
      string | undefined | null
    >({
      query: (id) => ({
        url: `${commonUrl}/dashboard/${id}`,
        method: "GET",
        keepUnusedDataFor: 1,
      }),
      providesTags: [{ type: "Main", id: "STUDENTDASHBOARD" }],
      keepUnusedDataFor: 1,
    }),
    getStudentDashboard: builder.query<
      IStudentDashboardResponse,
      string | undefined | null
    >({
      query: (id) => ({
        url: `${commonUrl}/dashboard/${id}`,
        method: "GET",
        keepUnusedDataFor: 1
      }),
      providesTags: [{ type: "Main", id: "STUDENTDASHBOARD" }],
      keepUnusedDataFor: 1
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllStudentDashboardQuery, useLazyGetStudentDashboardQuery } = studentDashboardApi;
