const lightGreyColor = "#F6F6F6";
const commonStrokeColor = "#c3dd7a";
const wrongStrokeColor = "#FF6E6E";
const skippedStrokeColor = "#E5E5E5";
const checkBoxGreenColor = "#C3DD7A";
const headingGreyColor = "#878787";
export {
  lightGreyColor,
  commonStrokeColor,
  wrongStrokeColor,
  skippedStrokeColor,
  checkBoxGreenColor,
  headingGreyColor,
};
