import styled from "styled-components";
import { Button } from "antd";
import { ButtonProps } from "antd";
import CenterContent from "./CenterContent.styled";
import { DeleteFilled } from "@ant-design/icons";
import { CheckCircleOutlined, CheckOutlined } from "@ant-design/icons";

const PrimaryButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props} />
))`
  min-width: 120px;
  height: 3rem;
  font-size: 20px;
  background: #ff6e6e;
  border-radius: 3px;
`;
const ViewSolutionButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props} />
))`
  min-width: 120px;
  height: 3rem;
  font-size: 20px;
  color: #201e43;
  background: #ffffff;
  border: 2px solid #201e43;
  border-radius: 3px;
`;

const QuizSolutionButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props} />
))`
  font-size: 14px;
  color: #ff6e6e;
  background: #ffffff;
  border: 2px solid #ff6e6e;
  border-radius: 3px;
`;
const SecondaryButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props} />
))`
  font-size: 14px;
  border-radius: 3px;
`;

const PrimaryViewButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props} />
))`
  min-width: 120px;
  background: #ff6e6e;
  border-radius: 3px;
`;
const CloseButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props}>
    Close
  </Button>
))`
  min-width: 120px;
  height: 2.5rem;
  font-size: 20px;
  background: #ff6e6e;
  margin-top: 14%;
  border-radius: 3px;
`;

const WhiteCloseButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props} />
))`
  min-width: 100px;
  margin: 0 auto;
  border-radius: 3px;
  background: #ff6e6e;
  height: 3rem;
  color: white;
`;
const WhiteCloseButtonLarge = styled(WhiteCloseButton)`
  width: 100%;
`;
const SuccessButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props} icon={<CheckOutlined />} />
))`
  min-width: 120px;
  height: 3rem;
  background: #c3dd7a;
  border-radius: 3px;
`;

const FaliureButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props} icon={<CheckOutlined />} />
))`
  min-width: 120px;
  height: 3rem;
  background: #ff6e6e;
  border-radius: 3px;
`;

const ViewDetailsButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props}>
    View Details
  </Button>
))`
  font-size: 14px;
  background: #ff6e6e;
  border-radius: 3px;
`;
const ViewQuestionButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props}>
    View Question
  </Button>
))`
  font-size: 14px;
  background: #ff6e6e;
  border-radius: 3px;
`;
const ViewAnalyticsButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props}>
    View Analytics
  </Button>
))`
  font-size: 14px;
  border-radius: 3px;
  background: #ff6e6e;
`;

const DeleteButton = styled((props: ButtonProps) => (
  <CenterContent>
    <Button {...props}>
      <DeleteFilled />
    </Button>
  </CenterContent>
))`
  color: #a3a3a3;
  border: none;
  border-radius: 3px;
  box-shadow: none;
`;

type ICorrectAnswerButtonProps = ButtonProps & {
  isCorrect: boolean;
};

const CorrectAnswerButton = styled(
  ({ isCorrect, ...props }: ICorrectAnswerButtonProps) => (
    <Button
      type={isCorrect ? "primary" : "default"}
      htmlType="button"
      icon={isCorrect ? <CheckCircleOutlined /> : null}
      {...props}
    >
      {isCorrect ? "Correct Answer" : "Mark as Correct Answer"}
    </Button>
  )
)`
  min-width: 140px;
  height: 3rem;
  font-size: 1rem;
  color: ${({ isCorrect }) => (isCorrect ? "#ffffff" : "#FF6E6E")};
  border-color: ${({ isCorrect }) => (isCorrect ? "#ffffff" : "#FF6E6E")};
  background-color: ${({ isCorrect }) => (isCorrect ? "#FF6E6E" : "#ffffff")};
`;

const RedGhostButton = styled((props: ButtonProps) => (
  <Button htmlType="button" type="ghost" {...props} />
))`
  color: #ff6e6e;
`;
const PrevButton = styled((props: ButtonProps) => <Button {...props} />)`
  width: 6rem;
  height: 3rem;
  border-radius: 3px;
  background: #ff6e6e;
  color: white;
  border-radius: 3px;
`;

const NextButton = styled((props: ButtonProps) => <Button {...props} />)`
  width: 6rem;
  height: 3rem;
  border-radius: 3px;
  background: #ff6e6e;
  color: white;
  border-radius: 3px;
`;
export {
  DeleteButton,
  PrimaryButton,
  ViewDetailsButton,
  CorrectAnswerButton,
  RedGhostButton,
  ViewAnalyticsButton,
  PrevButton,
  NextButton,
  ViewQuestionButton,
  CloseButton,
  SuccessButton,
  FaliureButton,
  SecondaryButton,
  PrimaryViewButton,
  WhiteCloseButton,
  ViewSolutionButton,
  QuizSolutionButton,
  WhiteCloseButtonLarge,
};
