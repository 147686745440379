import { mainApi } from "../../app/services/mainApiSlice";
import {
  IQuestionBankResponse,
  IQuestionBank,
  IQuestionByIdResponse,
} from "../../types/questionBank";

const commonUrl: string = "/admin/question";

type IBulkUploadResponse = {
  status: string;
  fieldErrorMessageMap: {};
  error: null;
};

const questionApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Quiz Question
    getAllQuestions: builder.query<
      IQuestionBankResponse,
      {
        currentPage: number;
        pageSize: number;
        difficulty: string;
        qId: string;
        category: string;
        course: string;
        section: string;
        lesson: string;
        questionText: string;
        passageText: string;
      }
    >({
      query: ({
        currentPage,
        pageSize,
        difficulty,
        qId,
        category,
        course,
        section,
        lesson,
        questionText,
        passageText,
      }) => ({
        url: `${commonUrl}/get-all-question?course=${
          course || ""
        }&difficultyLevel=${difficulty || ""}&id=${qId || ""}&lesson=${
          lesson || ""
        }&page=${currentPage - 1}&passage=${
          passageText || ""
        }&questionCategory=${category || ""}&questionText=${
          questionText || ""
        }&section=${section || ""}&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "QUESTION" }],
    }),

    // Delete
    deleteQuestionById: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `${commonUrl}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Main", id: "QUESTION" }],
    }),

    // Add Question
    addQuestion: builder.mutation<void, Partial<IQuestionBank>>({
      query: (data) => ({
        url: `${commonUrl}/save-question`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Main", id: "QUESTION" }],
    }),

    // Add Question RC
    addQuestionRC: builder.mutation<void, Partial<IQuestionBank>>({
      query: (data) => ({
        url: `${commonUrl}/save-rc-question`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Main", id: "QUESTION" }],
    }),

    // Get Question By Id
    getQuestionById: builder.query<IQuestionByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-question/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "QUESTION" }],
    }),

    // Update Question
    updateQuestionById: builder.mutation<
      IQuestionByIdResponse,
      { id: number | undefined; data: Partial<IQuestionBank> }
    >({
      query: ({ id, data }) => ({
        url: `${commonUrl}/update-question/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [{ type: "Main", id: "QUESTION" }],
    }),
    updateRCQuestionById: builder.mutation<
      IQuestionByIdResponse,
      { id: number | undefined; data: Partial<IQuestionBank> }
    >({
      query: ({ id, data }) => ({
        url: `${commonUrl}/update-rc-question/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [{ type: "Main", id: "QUESTION" }],
    }),
    deleteAnswerOptionById: builder.mutation<void, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/answerOption/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Main", id: "QUESTION" }],
    }),
    questionBulkUpload: builder.mutation<
      IBulkUploadResponse,
      { formData: FormData }
    >({
      query: ({ formData }) => ({
        url: `${commonUrl}/upload-question`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "QUESTION" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllQuestionsQuery,
  useDeleteQuestionByIdMutation,
  useAddQuestionMutation,
  useAddQuestionRCMutation,
  useGetQuestionByIdQuery,
  useUpdateQuestionByIdMutation,
  useUpdateRCQuestionByIdMutation,
  useDeleteAnswerOptionByIdMutation,
  useQuestionBulkUploadMutation,
} = questionApi;
