import {
  menuItemBgColorHoverSelected,
  menuItemFontSize,
  menuItemTextColorHoverSelected,
  studentPanelMenuItemBgColorHoverSelected,
  sideMenuBgColor,
  studentPanelSideMenuSelectedText,
} from "../../constants/theme";
import type { MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigProvider, Menu } from "antd";
import { MdOutlineMenu } from "react-icons/md";
import { adminItems, studentItems, tutorItems } from "./Items";
import secureLocalStorage from "react-secure-storage";

type IProps = {
  toggleSiderCollapse?: () => void;
};

type MenuItem = Required<MenuProps>["items"][number];

const SiderMenuItems = ({ toggleSiderCollapse }: IProps) => {
  const { pathname } = useLocation();
  const role = secureLocalStorage.getItem("role");

  let selection;
  if (role === "ROLE_STUDENT") {
    selection = pathname.split("/")[3];
  } else {
    selection = pathname.split("/")[1];
  }

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }

  const hamburgerIcon: MenuItem[] = [getItem("", "1", <MdOutlineMenu />)];

  const navigate = useNavigate();
  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  const isAdmin = role === "ROLE_ADMIN";
  const isStudent = role === "ROLE_STUDENT";

  return !isStudent ? (
    <ConfigProvider
      theme={{
        token: {
          fontSize: menuItemFontSize,
        },
        components: {
          Menu: {
            colorItemTextHover: menuItemTextColorHoverSelected,
            colorItemTextSelected: menuItemTextColorHoverSelected,
            colorItemBgHover: menuItemBgColorHoverSelected,
            colorItemBgSelected: menuItemBgColorHoverSelected,
          },
        },
      }}
    >
      <Menu
        onClick={toggleSiderCollapse}
        mode="inline"
        items={hamburgerIcon}
        selectable={false}
      />

      <Menu
        onClick={onClick}
        defaultSelectedKeys={["/"]}
        mode="inline"
        items={isAdmin ? adminItems : tutorItems}
        selectedKeys={[selection]}
      />
    </ConfigProvider>
  ) : (
    <ConfigProvider
      theme={{
        token: {
          fontSize: menuItemFontSize,
          fontFamily: "HK Grotesk-Medium",
          borderRadius: 3,
        },
        components: {
          Menu: {
            radiusItem: 3,
            fontFamily: "HK Grotesk-Medium",
            colorItemTextHover: "black",
            colorItemTextSelected: studentPanelSideMenuSelectedText,
            colorItemBgHover: "white",
            colorItemBgSelected: "white",
          },
        },
      }}
    >
      <Menu
        onClick={onClick}
        defaultSelectedKeys={["/"]}
        mode="inline"
        items={studentItems}
        selectedKeys={[selection]}
        style={{ backgroundColor: `${sideMenuBgColor}` }}
      />
    </ConfigProvider>
  );
};

export default SiderMenuItems;
