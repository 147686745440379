import { Col, Row, Spin } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import QuizProgressTabs from "../../../components/analytics/QuizProgressTabs";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../../components/styled/Container.styled";
import ContainerHeading from "../../../components/styled/ContainerHeading.styled";
import { useGetTutorStudentQuizProgressQuery } from "../../../features/tutorPanelFeatures/TutorQuizzes/tutorQuizApiSlice";
import { useGetTutorStudentByIdQuery } from "../../../features/tutorPanelFeatures/TutorStudent/tutorStudentsApiSlice";
import pageSize from "../../../constants/pageSize";

function QuizProgress() {
  const { studentId } = useParams();
  const { data } = useGetTutorStudentByIdQuery(studentId);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: quizProgressData,
    isLoading,
    isFetching,
  } = useGetTutorStudentQuizProgressQuery({
    currentPage,
    pageSize,
    studentId,
  });
  return (
    <LightGrayContainer>
      <Row>
        <Col>
          <ContainerHeading>{data?.data?.name}</ContainerHeading>
        </Col>
      </Row>
      {data && !isLoading && !isFetching ? (
        <WhiteContainer>
          <QuizProgressTabs
            currentPage={currentPage}
            data={quizProgressData}
            setCurrentPage={setCurrentPage}
          />
        </WhiteContainer>
      ) : (
        <WhiteContainer>
          <Spin />
        </WhiteContainer>
      )}
    </LightGrayContainer>
  );
}

export default QuizProgress;
