import { mainApi } from "../../app/services/mainApiSlice";
import { IAdminNotificationResponse } from "../../types/notification";

const commonUrl: string = "public/notification";

const adminNotification = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAdminNotification: builder.query<
      IAdminNotificationResponse,
      string | null
    >({
      query: (id) => ({
        url: `${commonUrl}/get-notification-admin/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "ADMINNOTIFICATION" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllAdminNotificationQuery } = adminNotification;
