import { useParams } from "react-router-dom";
import GroupWiseProgress from "../../components/analytics/GroupWiseProgress";
import { useGetGroupStudentListQuery } from "../../features/tutorPanelFeatures/TutorAnalytics/tutorAnalyticsApiSlice";
import { Spin } from "antd";

function TutorGroupWiseProgress() {
  const { id: idFromLocalStorage } = useParams();
  const { data, isLoading, isFetching } =
    useGetGroupStudentListQuery(idFromLocalStorage);

  return (
    <>
      {data && !isLoading && !isFetching ? (
        <GroupWiseProgress data={data} />
      ) : (
        <Spin />
      )}
    </>
  );
}

export default TutorGroupWiseProgress;
