import { Select, Form } from "antd";
import { Rule } from "rc-field-form/lib/interface";
type IOptions = {
  value: string;
  label: string;
};

const options: IOptions[] = [
  {
    value: "New Section",
    label: "New Section",
  },
];

type ISectionTypeOptionsProps = {
  label: string;
  name: string;
  rules?: Rule[];
};

const { Option } = Select;

const SectionTypeOptions = (props: ISectionTypeOptionsProps) => {
  return (
    <Form.Item {...props}>
      <Select>
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SectionTypeOptions;
