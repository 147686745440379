import React, { useState, useEffect } from "react";
import { Input, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { WhiteContainer } from "../../components/styled/Container.styled";
import pageSize from "../../constants/smallPageSize";
import { useGetAllQuestionTypesQuery } from "../../features/internalCourse/questionTypeICApiSlice";
import { IQuestionType } from "../../types/questionType";
import { IResourceByIdResponse } from "../../types/resources";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

type LinkQuestionTypeProps = {
  setQuestionTypes: any;
  resourceData?: IResourceByIdResponse;
};

function LinkQuestionType(props: LinkQuestionTypeProps) {
  const { setQuestionTypes, resourceData } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const {
    debouncedValue: questionTypeName,
    debouncing: questionTypeSearching,
  } = useDebouncingValue(searchText);

  const { data, isLoading, isFetching } = useGetAllQuestionTypesQuery({
    currentPage,
    pageSize,
    name: questionTypeName,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >([]);
  const preSelectedSelectedQuestionTypes = resourceData?.data.lessons.map(
    (ele) => ele["id"]
  );

  useEffect(() => {
    setSelectedRowKeys(preSelectedSelectedQuestionTypes || []);
  }, []);

  const columns: ColumnsType<IQuestionType> = [
    {
      key: "name",
      title: (
        <div>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
      filteredValue: [searchText],
      onFilter: (value: any, record) => {
        return record?.name?.toLowerCase()?.includes(value?.toLowerCase());
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setQuestionTypes(newSelectedRowKeys.map((id) => id));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <WhiteContainer>
        <Table
          rowKey="id"
          rowSelection={{ preserveSelectedRowKeys: true, ...rowSelection }}
          columns={columns}
          dataSource={data?.data.content}
          loading={isLoading || isFetching || questionTypeSearching}
          pagination={{
            pageSize,
            current: currentPage,
            total: data?.data.totalElements,
            onChange(page) {
              setCurrentPage(page);
            },
          }}
        />
      </WhiteContainer>
    </div>
  );
}

export default LinkQuestionType;
