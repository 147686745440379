import { mainApi } from "../../app/services/mainApiSlice";
import { IGroupResponse, IGroupByIdResponse, IGroup } from "../../types/group";

const commonUrl: string = "/admin/group";

const groupApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllGroup: builder.query<
      IGroupResponse,
      {
        currentPage: number;
        pageSize: number;
        course?: string;
        name?: string;
        section?: string;
        startDate?: string;
        students?: string;
      }
    >({
      query: ({
        currentPage,
        pageSize,
        course,
        name,
        section,
        startDate,
        students,
      }) => ({
        url: `${commonUrl}/get-all-group?course=${course || ""}&name=${
          name || ""
        }&page=${currentPage - 1}&section=${
          section || ""
        }&size=${pageSize}&startDate=${startDate || ""}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "GROUP" }],
    }),
    getGroupById: builder.query<IGroupByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-group/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "GROUP" }],
    }),
    updateGroupById: builder.mutation<
      IGroup,
      { id: number | undefined; post: Partial<IGroup> }
    >({
      query: ({ id, post }) => ({
        url: `${commonUrl}/update-group/${id}`,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "GROUP" }],
    }),
    createGroup: builder.mutation<IGroup, Partial<IGroup>>({
      query: (groupData: IGroup) => ({
        url: `${commonUrl}/save`,
        method: "POST",
        body: groupData,
      }),
      invalidatesTags: [{ type: "Main", id: "GROUP" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllGroupQuery,
  useGetGroupByIdQuery,
  useCreateGroupMutation,
  useUpdateGroupByIdMutation,
} = groupApi;
