import { HiPresentationChartLine, HiDocumentText } from "react-icons/hi";
import { BsImages, BsFillFileEarmarkTextFill } from "react-icons/bs";
import { MdPictureAsPdf } from "react-icons/md";
import { GrMultimedia } from "react-icons/gr";
import { FaFileAudio } from "react-icons/fa";

type fileTypeProps = {
  resourceType?: string;
};
const FileTypeIconStyle = {
  fontSize: "1rem",
  marginLeft: "1rem",
  marginRight: "1rem",
};

function FileTypeIcon(props: fileTypeProps): JSX.Element {
  const { resourceType } = props;

  if (resourceType === "PPT") {
    return <HiPresentationChartLine style={FileTypeIconStyle} />;
  } else if (resourceType === "IMAGE") {
    return <BsImages style={FileTypeIconStyle} />;
  } else if (resourceType === "PDF") {
    return <MdPictureAsPdf style={FileTypeIconStyle} />;
  } else if (resourceType === "DOC") {
    return <HiDocumentText style={FileTypeIconStyle} />;
  } else if (resourceType === "MP3") {
    return <FaFileAudio style={FileTypeIconStyle} />;
  } else if (resourceType === "MP4") {
    return <GrMultimedia style={FileTypeIconStyle} />;
  } else if (resourceType === "TEXT") {
    return <BsFillFileEarmarkTextFill style={FileTypeIconStyle} />;
  } else {
    return <></>;
  }
}

export default FileTypeIcon;
