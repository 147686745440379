import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

type ImageFileViewerProps = {
  uri: string;
};

function ImageFileViewer(props: ImageFileViewerProps) {
  const { uri } = props;
  const fileType = uri.split(".")[3];
  return (
    <div>
      <DocViewer
        documents={[
          {
            uri,
            fileType,
          },
        ]}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            disableHeader: true,
          },
        }}
      />
    </div>
  );
}

export default ImageFileViewer;
