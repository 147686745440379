import { Col, Form, Input, Row, ButtonProps } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/es/form/Form";
import { PrimaryButton } from "../styled/Button.styled";
import { WhiteContainer } from "../styled/Container.styled";
import FormContainer from "../styled/FormContainer.styled";
import { Fragment } from "react";
import styled from "styled-components";
import SelectTopicOptions from "../selectFormOptions/SelectTopicOptions";
import { MdDelete, MdLink } from "react-icons/md";

const AddButton = styled((props: ButtonProps) => (
  <PrimaryButton type="primary" icon={<PlusCircleOutlined />} {...props} />
))`
  position: absolute;
  top: -130px;
  left: -75px;
`;
type MultipleTopicFormProps = {
  form: FormInstance;
};
const MultipleTopicForm = (props: MultipleTopicFormProps) => {
  const { form } = props;
  return (
    <WhiteContainer>
      <FormContainer>
        <Form name="listForm" layout="vertical" form={form}>
          <Form.List name="topics">
            {(fields, { add, remove }) => (
              <>
                <Row justify="end" className="button-bottom-margin">
                  <Col>
                    <AddButton onClick={() => add()}>Add</AddButton>
                  </Col>
                </Row>

                <Row gutter={[12, 32]}>
                  {fields.map((field, index) => (
                    <Fragment key={field.key}>
                      <Col span={23}>
                        <Row align={"middle"}>
                          <Col span={12}>
                            <Form.Item
                              name={[index, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please fill details here",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col>
                            <MdLink />
                          </Col>

                          <Col span={11}>
                            <Form.Item>
                              <SelectTopicOptions
                                name={[index, "parentTopicId"]}
                                form={form}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={1}>
                        {fields.length > 1 ? (
                          <MdDelete onClick={() => remove(field.name)} />
                        ) : null}
                      </Col>
                    </Fragment>
                  ))}
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      </FormContainer>
    </WhiteContainer>
  );
};

export default MultipleTopicForm;
