import { mainApi } from "../../app/services/mainApiSlice";
import {
  ITopicResponse,
  ITopic,
  ITopicByIdResponse,
  IAddTopic,
  ITopicsByQuestionTypeIdResponse,
} from "../../types/topic";

const commonUrl: string = "/admin/module";

const topicICApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Topics
    getAllTopics: builder.query<
      ITopicResponse,
      {
        currentPage: number;
        pageSize: number;
        name?: string;
        courseName?: string;
        lessonName?: string;
        sectionName?: string;
      }
    >({
      query: ({
        currentPage,
        pageSize,
        courseName,
        lessonName,
        name,
        sectionName,
      }) => ({
        url: `${commonUrl}/get-all-topic?courseName=${
          courseName || ""
        }&lessonName=${lessonName || ""}&name=${name || ""}&page=${
          currentPage - 1
        }&sectionName=${sectionName || ""}&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TOPICIC" }],
    }),

    // Get Topic By Id
    getTopicsById: builder.query<ITopicByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-topic/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TOPICIC" }],
    }),

    // Get Section By Course ID
    getTopicByQuestionTypeId: builder.query<
      ITopicsByQuestionTypeIdResponse,
      number | null
    >({
      query: (id) => ({
        url: `${commonUrl}/topic-list/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TOPICIC" }],
    }),

    // add Topics
    addTopic: builder.mutation<IAddTopic, any>({
      query: (topicData: IAddTopic) => ({
        url: `${commonUrl}/save`,
        method: "POST",
        body: topicData,
      }),
      invalidatesTags: [{ type: "Main", id: "TOPICIC" }],
    }),

    //update topic by id
    updateTopicById: builder.mutation<
      ITopic,
      { id: number | undefined; post: Partial<ITopic> }
    >({
      query: ({ id, post }) => ({
        url: `${commonUrl}/update-topic/${id}`,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "TOPICIC" }],
    }),

    //DEtete Topic by id
    deleteTopicById: builder.mutation<void, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Main", id: "TOPICIC" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllTopicsQuery,
  useGetTopicsByIdQuery,
  useAddTopicMutation,
  useUpdateTopicByIdMutation,
  useDeleteTopicByIdMutation,
  useGetTopicByQuestionTypeIdQuery,
} = topicICApi;
