import {
  Input,
  Table,
  Typography,
  Row,
  notification,
  Modal,
  Select,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../constants/pageSize";
import {
  PrimaryButton,
  DeleteButton,
  ViewDetailsButton,
} from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import CustomCard from "../../components/countCard/CustomCard";
import { IResource } from "../../types/resources";
import {
  useDeleteResourceByIdMutation,
  useGetAllResourcesQuery,
} from "../../features/resources/resourcesApiSlice";
import { ITopic } from "../../types/topic";
import { IQuestionType } from "../../types/questionType";
import { ISection } from "../../types/section";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../utils/useDebouncingValue";
import { resourceFormatDropdown } from "../../utils/resourceFormatConstants";

function Resources() {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [searchText, setSearchText] = useState("");
  const [topic, setTopic] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [section, setSection] = useState("");
  const [format, setFormat] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteResourceById] = useDeleteResourceByIdMutation();

  const { debouncedValue: resourceName, debouncing: resourceSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: topicName, debouncing: topicSearching } =
    useDebouncingValue(topic);
  const {
    debouncedValue: questionTypeName,
    debouncing: questionTypeSearching,
  } = useDebouncingValue(questionType);
  const { debouncedValue: sectionName, debouncing: sectionSearching } =
    useDebouncingValue(section);
  const { debouncedValue: formatName, debouncing: formatSearching } =
    useDebouncingValue(format);

  const { data, isLoading, isFetching } = useGetAllResourcesQuery({
    currentPage,
    pageSize,
    lesson: questionTypeName,
    name: resourceName,
    resourceType: formatName,
    section: sectionName,
    topic: topicName,
  });

  const showConfirm = (id: number) => {
    confirm({
      title: "Do you want to delete resource?",

      onOk() {
        onDelete(id);
      },
      onCancel() {},
    });
  };
  const columns: ColumnsType<IResource> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Resource Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },

    {
      key: "topics",
      title: (
        <div>
          <Typography.Text>Topic</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setTopic(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "topics",
      render: (topics: ITopic[]) =>
        topics.map((topic: ITopic) => topic?.name).join(", "),
    },
    {
      key: "questionType",
      title: (
        <div>
          <Typography.Text>Question Type</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setQuestionType(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      render: (lessons: IQuestionType[]) =>
        lessons.map((lesson: IQuestionType) => lesson?.name).join(", "),
      dataIndex: "lessons",
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSection(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      render: (sections: ISection[]) =>
        sections.map((section: ISection) => section?.name).join(", "),
      dataIndex: "sections",
    },
    {
      key: "format",
      title: (
        <div>
          <Typography.Text>Format</Typography.Text>
          <Select
            onChange={(value) => {
              setFormat(value);
              setCurrentPage(1);
            }}
            options={resourceFormatDropdown}
            className="searchDropdownStyle"
          />
        </div>
      ),
      dataIndex: "resourceType",
    },
    {
      key: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openResourceDetails(record.id)} />
      ),
    },
    {
      key: "deleteButton",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];

  const openResourceDetails = (id: number) => {
    navigate(`${id}`);
  };

  const onDelete = (id: number) => {
    deleteResourceById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully deleted",
          placement: "bottomLeft",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        });
      });
  };

  return (
    <>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard
          sum={data?.data?.content[0]?.totalResources}
          text="Resources"
        />
        <Link to="add">
          <PrimaryButton>Add Resource</PrimaryButton>
        </Link>
      </Row>

      <LightGrayContainer>
        <WhiteContainer>
          <Table
            loading={
              isLoading ||
              isFetching ||
              resourceSearching ||
              topicSearching ||
              questionTypeSearching ||
              sectionSearching ||
              formatSearching
            }
            columns={columns}
            dataSource={data?.data.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: data?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </WhiteContainer>
      </LightGrayContainer>
    </>
  );
}

export default Resources;
