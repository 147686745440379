import { Col, Divider, Row, Spin, Typography } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import TextViewer from "../../../components/preview/TextViewer";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import {
  useGetAllMyLearningByCourseIdQuery,
  useGetStudentTopicCountQuery,
} from "../../../features/studentPanelFeatures/StudentMyLearning/myLearningApiSlice";

import clockIcon from "../../../assets/customIcons/clockIcon.png";
import secureLocalStorage from "react-secure-storage";
import { useDispatch } from "react-redux";
import { setStudentBreadcrumb } from "../../../features/ui/uiSlice";
import { useAppSelector } from "../../../app/hooks";

const { Title } = Typography;
const IntroductionContentContainer = styled.div`
  height: 40vh;
  overflow-y: scroll;
`;
function MyLearningIntroduction() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const { data } = useGetAllMyLearningByCourseIdQuery({
    id: idFromLocalStorage,
    courseId: courseId,
  });

  const { data: countData } = useGetStudentTopicCountQuery({
    studentId: idFromLocalStorage,
    courseId,
  });
  const handleOnClick = () => {
    navigate(`/course/${courseId}/start-learning/courseIntro/${courseId}`);
  };

  dispatch(setStudentBreadcrumb([`${pathname.split("/")[3]}`]));
  return (
    <>
      {data && countData ? (
        <StudentPanelWhiteContainer>
          <Row justify={"space-around"}>
            <Row
              justify="center"
              align="middle"
              className="mylearning-count-row"
            >
              <Col>
                <Title
                  level={1}
                  className="m-0   common-text-color hk-grotesk-medium-font"
                >
                  {countData?.data?.totalTopics}&nbsp;
                </Title>
              </Col>

              <Col className="count-text-font-size ">
                <span>Total Topics</span>
              </Col>
            </Row>
            <Row
              justify="center"
              align="middle"
              className="mylearning-count-row"
            >
              <Col>
                <Title
                  level={1}
                  className="m-0  common-text-color hk-grotesk-medium-font"
                >
                  {countData?.data?.topicsCompleted}&nbsp;
                </Title>
              </Col>

              <Col className="count-text-font-size">
                <span>Topics Completed</span>
              </Col>
            </Row>
            <Row
              justify="center"
              align="middle"
              className="mylearning-count-row"
            >
              <Col>
                <Title
                  level={1}
                  className="m-0 common-text-color hk-grotesk-medium-font"
                >
                  {countData?.data?.topicsPending}&nbsp;
                </Title>
              </Col>

              <Col className="count-text-font-size">
                <span>Topics Pending</span>
              </Col>
            </Row>
          </Row>

          <Divider />

          <Row align="middle">
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col span={12}>
                  <Title level={3} className="m-0 ">
                    {`${data?.data[0]?.course?.name}`} - Introduction
                  </Title>
                </Col>
                <Col span={7}>
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col span={9}>
                          <Row
                            style={{ justifyContent: "space-between" }}
                            align={"middle"}
                          >
                            <Col>
                              <img src={clockIcon} alt="clock-icon" />
                            </Col>
                            <Col>
                              <span>
                                {`${data?.data[0]?.course?.duration}`} hours
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <IntroductionContentContainer>
            <Row>
              <TextViewer textData={data?.data[0]?.course?.description || ""} />
            </Row>
          </IntroductionContentContainer>

          <Row justify={"start"} className="mt-2 ">
            <PrimaryButton onClick={handleOnClick}>
              Start Learning
            </PrimaryButton>
          </Row>
        </StudentPanelWhiteContainer>
      ) : (
        <StudentPanelWhiteContainer>
          <Spin />
        </StudentPanelWhiteContainer>
      )}
    </>
  );
}

export default MyLearningIntroduction;
