const quizModeConstant = [
  { value: "", label: "--" },
  { value: "PRACTICE_BASED", label: "Practice Based" },
  { value: "TIME_BASED", label: "Time Based" },
];

const quizStatusConstant = [
  { value: "", label: "--" },
  { value: "PUBLISHED", label: "Published" },
  { value: "UNPUBLISHED", label: "Unpublished" },
];
export { quizModeConstant, quizStatusConstant };
