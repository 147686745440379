import styled from "styled-components";
import AuthStatus from "../header/AuthStatus";
import { ReactComponent as BrandLogo } from "../../../assets/pluslogo.svg";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
const HeaderContainer = styled.header`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
  background-color: #201e43;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

function CommonHeader() {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate("/");
  };
  return (
    <HeaderContainer>
      <Tooltip title="Go to dashboard">
        <BrandLogo
          style={{ padding: "1rem", cursor: "pointer" }}
          onClick={handleOnClick}
        />
      </Tooltip>

      <AuthStatus />
    </HeaderContainer>
  );
}

export default CommonHeader;
