import React, { useEffect, useState } from "react";
import { Input, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useGetAllCoursesQuery } from "../../../features/internalCourse/coursesICApiSlice";
import { ICourse } from "../../../types/course";
import pageSize from "../../../constants/pageSize";
import { IStudentByIdResponse } from "../../../types/studentType";
import { ISection } from "../../../types/section";
import { useDebouncingValue } from "../../../utils/useDebouncingValue";

type AssignCoursesProps = {
  setAssignCoursesId?: any;
  courseData?: IStudentByIdResponse;
};

function AssignCourses(props: AssignCoursesProps) {
  const { setAssignCoursesId, courseData } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [section, setSection] = useState("");
  const [duration, setDuration] = useState("");

  const { debouncedValue: courseName, debouncing: nameSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: courseDuration, debouncing: durationSearching } =
    useDebouncingValue(duration);
  const { debouncedValue: sectionName, debouncing: sectionSearching } =
    useDebouncingValue(section);

  const { data, isLoading, isFetching } = useGetAllCoursesQuery({
    pageSize,
    currentPage,
    name: courseName,
    duration: courseDuration,
    sectionName,
  });

  const preSelectedCourses = courseData?.data.assignCourse.map(
    (ele) => ele["id"]
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >([]);

  useEffect(() => {
    setSelectedRowKeys(preSelectedCourses || []);
  }, []);

  const columns: ColumnsType<ICourse> = [
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSection(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "section",
      render: (sections: ISection[]) =>
        sections.map((section: ISection) => section.name).join(" , "),
    },
    {
      key: "duration",
      title: (
        <div>
          <Typography.Text>Duration(in Hrs.)</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setDuration(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "duration",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setAssignCoursesId(newSelectedRowKeys.map((id) => ({ id })));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Table<ICourse>
        rowSelection={{ preserveSelectedRowKeys: true, ...rowSelection }}
        loading={
          isLoading ||
          nameSearching ||
          durationSearching ||
          isFetching ||
          sectionSearching
        }
        rowKey="id"
        columns={columns}
        dataSource={data?.data.content}
        pagination={{
          pageSize,
          current: currentPage,
          total: data?.data.totalElements,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
      />
    </>
  );
}

export default AssignCourses;
