import { Tabs, Row, Col, DatePicker, Collapse, Tree } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "../../components/styled/Button.styled";
import { LightGrayContainer } from "../../components/styled/Container.styled";

import type { DataNode, TreeProps } from "antd/es/tree";
import QuizProgress from "./QuizProgress";
import TopicWiseProgress from "./TopicWiseProgres";
import Breadcrumbs from "../../components/common/Breadcrumbs";
type ExpandIconPosition = "end";

function StudentAnalytics() {
  const [currentTab, setCurrentTab] = useState<string>();
  const { id } = useParams();
  const navigate = useNavigate();
  const onClose = () => {
    navigate(-1);
  };

  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("end");

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => { };
  const treeData: DataNode[] = [
    {
      title: "parent 1-0",
      key: "0-0-0",
      children: [
        {
          title: "Number Properties",
          key: "0-0-0-0",
        },
        {
          title: "Prime Number",
          key: "0-0-0-1",
        },
        {
          title: "Poitive,Negatives",
          key: "0-0-0-2",
        },
        {
          title: "Even,Odd",
          key: "0-0-0-3",
        },
        {
          title: "Consecutive Integer",
          key: "0-0-0-4",
        },
      ],
    },
  ];

  const tabItems = [
    {
      key: "1",
      label: "Topic Progress",
      children: <TopicWiseProgress />,
    },
    {
      key: "2",
      label: "Quiz progress",
      children: <QuizProgress />,
    },
  ];
  return (
    <>
      <Breadcrumbs idName={!id ? 'false' : currentTab === '2' ? 'quiz progress' : 'topic progress'} isId={!!id ? true : false} />
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </Col>
      </Row>

      <LightGrayContainer>
        <Tabs defaultActiveKey="1" items={tabItems} onTabClick={(tab) => setCurrentTab(tab)} />
      </LightGrayContainer>
    </>
  );
}

export default StudentAnalytics;
