import { Row, Tabs, Form, Col, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { quizzesTabs } from "../../constants/tabs";
import SubQuizGenerator from "./SubQuizGenerator";
import { useState } from "react";
import { useAddQuizMutation } from "../../features/quizzes/quizDetailsApiSlice";
import { PrimaryButton } from "../../components/styled/Button.styled";
import {
  DarkGrayContainer,
  LightGrayContainer,
} from "../../components/styled/Container.styled";
import { RootState } from "../../app/store";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../features/ui/uiSlice";
import Breadcrumbs from "../../components/common/Breadcrumbs";

function AddQuizGenerator() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabChange = (key: string) => {
    navigate(`../${quizzesTabs[Number(key) - 1]}`);
  };
  const [quizStatus, setQuizStatus] = useState("UNPUBLISHED");
  const onClose = () => {
    navigate(-1);
  };

  const [questionId, setQuestionId] = useState<React.Key[]>([]);
  const [disable, setDisable] = useState(true);
  let questions = questionId.map((id) => ({ id }));
  const [addQuiz, { isLoading }] = useAddQuizMutation();
  const selectedNoOfQuestion = useAppSelector(
    (state: RootState) => state.ui.selectedNoOfQuestion
  );
  dispatch(setButtonDisabled(isLoading));
  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );
  const tabItems = [
    {
      label: "Quizzes",
      key: "1",
      children: "",
    },
    {
      label: "Question Bank",
      key: "2",
      children: "",
    },
    {
      label: "Quiz Generator",
      key: "3",
      children: (
        <DarkGrayContainer>
          <SubQuizGenerator
            setQuestionId={setQuestionId}
            setDisable={setDisable}
          />
        </DarkGrayContainer>
      ),
    },
  ];
  const handlePublish = () => {
    setQuizStatus("PUBLISHED");
  };

  return (
    <>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { mainForm } = forms;
            mainForm.submit();
            let quizDetailData = { ...mainForm.getFieldsValue() };

            let obj = {
              ...quizDetailData,
              courses: [{ id: quizDetailData.courseId }],
              sections: [{ id: quizDetailData.sectionId }],
              lessons: [{ id: quizDetailData.questionTypeId }],
              topics: quizDetailData.topicIds.map((id: number) => ({ id })),
              questions,
              quizStatus: quizStatus,
              status: true,
            };

            if (selectedNoOfQuestion !== questions.length) {
              notification.error({
                message: `Please select ${selectedNoOfQuestion} question(s)`,
                description: `You have selected ${questions.length} question(s)`,
                placement: "bottomLeft",
              });
            } else {
              addQuiz(obj)
                .unwrap()
                .then(() => {
                  notification.success({
                    message: "Successfully Saved!",
                    placement: "bottomLeft",
                  });
                  navigate(-1);
                })
                .catch((error) =>
                  notification.error({
                    message: "Error!",
                    description: error.data.message,
                    placement: "bottomLeft",
                  })
                );
            }
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Form name="submitForm">
            <Col>
              <PrimaryButton
                htmlType="submit"
                onClick={handlePublish}
                disabled={disable}
              >
                Publish
              </PrimaryButton>
            </Col>
          </Form>
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" disabled={disable} loading={isButtonLoading}>
                Save
              </PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <Tabs defaultActiveKey="3" onChange={tabChange} items={tabItems} />
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default AddQuizGenerator;
