import styled from "styled-components";
import { ReactComponent as BrandLogo } from "../../../assets/pluslogo.svg";
const HeaderContainer = styled.header`
  height: 5rem;
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #201e43;
`;
const brandLogoStyle = {
  padding: "0.5rem",
};
function AuthPagesHeader() {
  return (
    <HeaderContainer>
      <BrandLogo style={brandLogoStyle} />
    </HeaderContainer>
  );
}

export default AuthPagesHeader;
