import { Col, Row, Form } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import CourseForm from "../../components/course/CourseForm";
import { useGetCourseByIdQuery } from "../../features/internalCourse/coursesICApiSlice";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import ContainerHeading from "../../components/styled/ContainerHeading.styled";
import FormContainer from "../../components/styled/FormContainer.styled";
import { PrimaryButton } from "../../components/styled/Button.styled";
import { useAppSelector } from "../../app/hooks";
import Breadcrumbs from "../../components/common/Breadcrumbs";

function EditCourse() {
  const { id } = useParams();
  const { data, isError, isLoading } = useGetCourseByIdQuery(id);
  const navigate = useNavigate();
  const onClose = () => {
    navigate(-1);
  };
  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );

  return (
    <>
      <Breadcrumbs idName={!!id ? data?.data?.name : 'false'} isId={!!id ? true : false} />
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { mainForm } = forms;
            mainForm.submit();
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>Save</PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <ContainerHeading>Course</ContainerHeading>
          <WhiteContainer>
            <FormContainer>
              {!isError && !isLoading && data && <CourseForm data={data} />}
            </FormContainer>
          </WhiteContainer>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default EditCourse;
