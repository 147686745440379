import { Col, Row, Form, Tabs, notification } from "antd";
import DifficultyLevel from "./DifficultyLevel";
import Category from "./Category";
import QuillContainer from "../styled/QuillContainer.styled";
import SelectCourseOptions from "../selectFormOptions/SelectCourseOptions";
import SelectSectionOptions from "../selectFormOptions/SelectSectionOptions";
import SelectQuestionTypeOptions from "../selectFormOptions/SelectQuestionTypeOptions";
import {
  DarkGrayContainer,
  LightGrayContainer,
  WhiteContainer,
} from "../styled/Container.styled";
import AnswerTabs from "./AnswerTabs";
import TopicCheckbox from "../quizDetails/TopicCheckbox";
import { useEffect, useState } from "react";
import {
  useAddQuestionMutation,
  useAddQuestionRCMutation,
  useDeleteQuestionByIdMutation,
  useUpdateQuestionByIdMutation,
  useUpdateRCQuestionByIdMutation,
} from "../../features/quizzes/questionApiSlice";
import {
  IAnswerOption,
  IAnswerOptions,
  IQuestionBank,
  QuestionCategory,
} from "../../types/questionBank";
import { useNavigate } from "react-router-dom";
import { Tab } from "rc-tabs/lib/interface";
import getStringFromHTML from "../../utils/getStringFromHTML";
import { FormInstance } from "rc-field-form/lib/interface";
import { PrimaryButton } from "../styled/Button.styled";
import AnswerTabsSR from "./AnswerTabsSR";
import TinyMce from "../common/TinyMce";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../features/ui/uiSlice";

type IQuestionBankFormProps = {
  data?: IQuestionBank;
  forms?: Record<string, FormInstance>;
};

function QuestionBankForm({ data, forms }: IQuestionBankFormProps) {
  const navigate = useNavigate();
  const [questionsActiveKey, setQuestionsActiveKey] = useState("1");
  const [form] = Form.useForm();

  const category = Form.useWatch("questionCategory", form);
  const dispatch = useDispatch();

  const [addQuestion, { isLoading: isLoadingAdd }] = useAddQuestionMutation();
  const [addQuestionRC, { isLoading: isLoadingAddRC }] =
    useAddQuestionRCMutation();
  const [updateQuestionById, { isLoading: isLoadingUpdate }] =
    useUpdateQuestionByIdMutation();
  const [updateRCQuestionById, { isLoading: isLoadingUpdateRC }] =
    useUpdateRCQuestionByIdMutation();
  const [deleteQuestionById] = useDeleteQuestionByIdMutation();
  const [selectedTopicsKeys, setSelectedTopicKeys] = useState([]);

  useEffect(() => {
    if (
      isLoadingAdd ||
      isLoadingAddRC ||
      isLoadingUpdate ||
      isLoadingUpdateRC
    ) {
      dispatch(setButtonDisabled(true));
    } else {
      dispatch(setButtonDisabled(false));
    }
  }, [
    isLoadingAdd,
    isLoadingAddRC,
    isLoadingUpdate,
    isLoadingUpdateRC,
    dispatch,
  ]);

  const tabItemsSR: Tab[] = [
    {
      key: "1",
      label: "Question",
      children: (
        <WhiteContainer>
          <QuillContainer>
            <TinyMce name="question" />
          </QuillContainer>
        </WhiteContainer>
      ),
      forceRender: true,
    },
    {
      key: "2",
      label: "Answer",
      children: (
        <WhiteContainer>
          <QuillContainer>
            <AnswerTabsSR data={data} />
          </QuillContainer>
        </WhiteContainer>
      ),
      forceRender: true,
    },
    {
      key: "3",
      label: "Explanation",
      children: (
        <WhiteContainer>
          <QuillContainer>
            <TinyMce name="explanation" />
          </QuillContainer>
        </WhiteContainer>
      ),
      forceRender: true,
    },
  ];

  const getTabItemsMCQ = (question?: Partial<IQuestionBank>): Tab[] => [
    {
      key: "1",
      label: "Question",
      children: (
        <WhiteContainer>
          <QuillContainer>
            <TinyMce name="question" />
          </QuillContainer>
        </WhiteContainer>
      ),
      forceRender: true,
    },
    {
      key: "2",
      label: "Answer",
      children: (
        <WhiteContainer>
          <QuillContainer>
            <AnswerTabs
              data={
                data?.questionCategory === QuestionCategory.RC
                  ? (question as IQuestionBank)
                  : data
              }
            />
          </QuillContainer>
        </WhiteContainer>
      ),
      forceRender: true,
    },
    {
      key: "3",
      label: "Explanation",
      children: (
        <WhiteContainer>
          <QuillContainer>
            <TinyMce name="explanation" />
          </QuillContainer>
        </WhiteContainer>
      ),
      forceRender: true,
    },
  ];

  const getAnswerKeysWithValues = (data: IAnswerOptions) => {
    const sortedAnswerOptions = ([...data] as Required<IAnswerOption>[]).sort(
      (a, b) => a.id - b.id
    );
    return sortedAnswerOptions?.reduce(
      (acc, answer, index) => ({
        [`answer${index + 1}`]: answer.value,
        [`isCorrect${index + 1}`]: answer.isCorrect,
        ...acc,
      }),
      {}
    );
  };

  const initialTabItemsRC: Tab[] = [
    {
      label: "Passage",
      key: "1",
      children: (
        <Form name="passageForm">
          <WhiteContainer>
            <QuillContainer>
              <TinyMce name="passage" />
            </QuillContainer>
          </WhiteContainer>
        </Form>
      ),
      forceRender: true,
      closable: false,
    },
    {
      label: "Question 1",
      key: "2",
      children: (
        <Form name="questionForm1">
          <WhiteContainer>
            <DarkGrayContainer>
              <Tabs defaultActiveKey="1" items={getTabItemsMCQ()} />
            </DarkGrayContainer>
          </WhiteContainer>
        </Form>
      ),
      forceRender: true,
      closable: false,
    },
  ];

  const getInitialItemRC = (data?: IQuestionBank): Tab[] => {
    if (data && data.questionCategory !== QuestionCategory.RC) {
      return [];
    }

    if (!data && category === QuestionCategory.RC) {
      return initialTabItemsRC;
    }

    const newArray = data ? [...data.questions] : [];
    const final = newArray.map((question, index) => ({
      label: `Question ${index + 1}`,
      key: `${index + 2}`,
      id: question ? `${question.id}` : undefined,
      children: (
        <Form
          name={`questionForm${index + 1}`}
          initialValues={{
            question: question.questionText,
            explanation: question.explanation,
            ...getAnswerKeysWithValues(question?.answers[0]?.answerOptions),
          }}
        >
          <WhiteContainer>
            <DarkGrayContainer>
              <Tabs defaultActiveKey="1" items={getTabItemsMCQ(question)} />
            </DarkGrayContainer>
          </WhiteContainer>
        </Form>
      ),
      forceRender: true,
      closable: data?.questions.length === index + 1 ? true : false,
    }));

    const newest = [
      {
        label: "Passage",
        key: "1",
        children: (
          <Form name="passageForm" initialValues={{ passage: data?.passage }}>
            <WhiteContainer>
              <QuillContainer>
                <TinyMce name="passage" />
              </QuillContainer>
            </WhiteContainer>
          </Form>
        ),
        forceRender: true,
        closable: false,
      },
      ...final,
    ];

    return newest as unknown as Tab[];
  };

  const [tabItemsRC, setTabItemsRC] = useState(getInitialItemRC(data));

  const onEditQuestionTabs = (targetKey: any, action: "add" | "remove") => {
    if (action === "add") {
      if (tabItemsRC.length >= 10) {
        return;
      }
      addQuestionTab();
    } else {
      removeQuestionTab(targetKey);
    }
  };

  const addQuestionTab = () => {
    const newTabIndexValue = tabItemsRC.length;
    setTabItemsRC((items) =>
      items
        .map((item) => ({ ...item, closable: false }))
        .concat({
          label: `Question ${newTabIndexValue}`,
          key: `${newTabIndexValue + 1}`,
          children: (
            <Form name={`questionForm${newTabIndexValue}`}>
              <WhiteContainer>
                <DarkGrayContainer>
                  <Tabs defaultActiveKey="1" items={getTabItemsMCQ()} />
                </DarkGrayContainer>
              </WhiteContainer>
            </Form>
          ),
          forceRender: true,
          closable: true,
        })
    );
    setQuestionsActiveKey((newTabIndexValue + 1).toString());
  };

  const removeQuestionTab = (targetKey: string) => {
    // Passage is one of the tabs in RC, that's why we cannot delete second tab
    if (Number(targetKey) > 2) {
      const itemToRemove = tabItemsRC.find((item) => item.key === targetKey);
      if (itemToRemove) {
        deleteQuestionById(Number(itemToRemove.id));
      }
      setTabItemsRC((items) =>
        items
          .filter((item) => item.key !== targetKey)
          .map((item) => ({
            ...item,
            closable:
              Number(item.key) === Number(targetKey) - 1
                ? Number(item.key) !== 2
                : false,
          }))
      );
    }
    // Set previous tab as the active one
    setQuestionsActiveKey((Number(targetKey) - 1).toString());
  };

  const onFinishSR = (mainFormValues: any) => {
    const questionData = forms?.questionFormSR.getFieldsValue();

    const values = { ...mainFormValues, ...questionData };

    // Adding Validation for Question, Answers and Explanation Tabs
    if (!validationForQuAnExTabsSuccess(values)) {
      return;
    }

    // Making Answer Options object
    const answerOptions = getAnswerOptions(values, true);

    // Making Final format which the API accepts
    let finalFormat: Partial<IQuestionBank> = {
      answers: [{ id: data?.answers![0].id || undefined, answerOptions }],
      courseId: values.courseId,
      difficultyLevel: values.difficultyLevel,
      lessonId: values.lessonId,
      questionText: values.question,
      explanation: values.explanation,
      questionCategory: values.questionCategory,
      sectionId: values.sectionId,
      status: true,
      topics: values.topicIds.map((id: string) => ({ id })),
    };

    if (data) {
      updateQuestionById({ id: data.id, data: finalFormat })
        .then(() => {
          notification.success({
            message: "Successfully updated",
            placement: "bottomLeft",
          });
          navigate(-1);
        })
        .catch((error) =>
          notification.error({
            message: "Error!",
            description: error.data.message,
            placement: "bottomLeft",
          })
        );
    } else {
      addQuestion(finalFormat)
        .then(() => {
          notification.success({
            message: "Successfully added",
            placement: "bottomLeft",
          });
          navigate(-1);
        })
        .catch((error) =>
          notification.error({
            message: "Error!",
            description: error.data.message,
            placement: "bottomLeft",
          })
        );
    }
  };

  const onFinishRC = (values: any) => {
    if (!forms) {
      return;
    }

    // Error for Passage Tab
    const passageData = forms.passageForm.getFieldsValue().passage;
    if (!passageData) {
      return notification.error({
        message: "Please add value in passage tab",
        placement: "bottomLeft",
      });
    }

    // Error if any of the Questions Tab is empty fully
    let isError = false;
    const questionsData = [...Array(tabItemsRC.length - 1)].map(
      (question, index) => {
        const formObject = forms[`questionForm${index + 1}`].getFieldsValue();
        if (!Object.keys(formObject).some((x) => formObject[x])) {
          isError = true;
          notification.error({
            message: `Please add all values in question tab ${index + 1}`,
            placement: "bottomLeft",
          });
        }
        return formObject;
      }
    );
    if (isError) {
      return;
    }

    // Adding Validation for Question, Answers and Explanation Tabs
    const isError2 = !questionsData.every((values, index) => {
      return validationForQuAnExTabsSuccess(values, index + 1);
    });
    if (isError2) {
      return;
    }

    // Checking if no correct answer is present
    const isError3 = !questionsData.every((values, index) =>
      isCorrectAnswerPresent(values, index + 1)
    );
    if (isError3) {
      return;
    }

    // Making common format to put into every question which the API accepts
    const commonApiFormat: Partial<IQuestionBank> = {
      courseId: values.courseId,
      sectionId: values.sectionId,
      lessonId: values.lessonId,
      topics: values.topicIds.map((id: string) => ({ id })),
      difficultyLevel: values.difficultyLevel,
      questionCategory: values.questionCategory,
    };

    const finalFormat = {
      ...commonApiFormat,
      passage: passageData,
      id: data?.id,
      questions: questionsData.map((questionData, index) => {
        return {
          ...commonApiFormat,
          id: data?.questions![index]?.id,
          questionText: questionData.question,
          explanation: questionData.explanation,
          answers: [
            {
              id: data?.questions![index]?.answers![0]?.id || undefined,
              answerOptions: getAnswerOptions(
                questionData,
                false,
                data?.questions![index]?.answers![0]?.answerOptions
              ),
              explanation: questionData.explanation,
            },
          ],
        };
      }),
    } as IQuestionBank;

    if (data) {
      updateRCQuestionById({ id: data.id, data: finalFormat })
        .then(() => {
          notification.success({
            message: "Successfully updated",
            placement: "bottomLeft",
          });
          navigate(-1);
        })
        .catch((error) =>
          notification.error({
            message: "Error",
            description: error.data.message,
            placement: "bottomLeft",
          })
        );
    } else {
      addQuestionRC(finalFormat)
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully added",
            placement: "bottomLeft",
          });
          navigate(-1);
        })
        .catch((error) =>
          notification.error({
            message: "Error",
            description: error.data.message,
            placement: "bottomLeft",
          })
        );
    }
  };

  const onFinishMCQ = (mainFormValues: any) => {
    const questionData = forms?.questionFormMCQ.getFieldsValue();

    const values = { ...mainFormValues, ...questionData };

    // Adding Validation for Question, Answers and Explanation Tabs
    if (!validationForQuAnExTabsSuccess(values)) {
      return;
    }

    // Checking if any answer isn't marked correct
    if (!isCorrectAnswerPresent(values)) {
      return;
    }

    // Making Answer Options object
    const answerOptions = getAnswerOptions(values);

    // Making Final format which the API accepts
    let finalFormat: Partial<IQuestionBank> = {
      answers: [{ id: data?.answers![0].id || undefined, answerOptions }],
      courseId: values.courseId,
      difficultyLevel: values.difficultyLevel,
      lessonId: values.lessonId,
      questionText: values.question,
      explanation: values.explanation,
      questionCategory: values.questionCategory,
      sectionId: values.sectionId,
      status: true,
      topics: values.topicIds.map((id: string) => ({ id })),
    };

    if (data) {
      updateQuestionById({ id: data.id, data: finalFormat })
        .then(() => {
          notification.success({
            message: "Successfully Updated!",
            placement: "bottomLeft",
          });
          navigate(-1);
        })
        .catch((error) =>
          notification.error({
            message: "Error!",
            description: error.data.message,
            placement: "bottomLeft",
          })
        );
    } else {
      addQuestion(finalFormat)
        .then(() => {
          notification.success({
            message: "Successfully added",
            placement: "bottomLeft",
          });
          navigate(-1);
        })
        .catch((error) =>
          notification.error({
            message: "Error",
            description: error.data.message,
            placement: "bottomLeft",
          })
        );
    }
  };

  // Adding Validation for Question, Answers and Explanation Tabs
  const validationForQuAnExTabsSuccess = (
    values: any,
    questionTab?: number
  ): boolean => {
    return Object.keys(values).every((key) => {
      if (
        key === "question" ||
        key === "explanation" ||
        key.startsWith("answer")
      ) {
        const keyToShow: string = key.startsWith("question")
          ? "Question"
          : key.startsWith("explanation")
          ? "Explanation"
          : key.startsWith("answer")
          ? "Answer"
          : "";

        const answerTabName: string = key.startsWith("answer")
          ? String.fromCharCode(Number(key.slice(-1)) + 64)
          : "";

        if (!values[key] || !getStringFromHTML(values[key]).trim().length) {
          notification.error({
            message: "Error",
            description: questionTab
              ? `Please add value to ${keyToShow} tab ${answerTabName} in question tab ${questionTab}`
              : `Please add value to ${keyToShow} tab ${answerTabName}`,
            placement: "bottomLeft",
          });
          return false;
        }
      }
      return true;
    });
  };

  // Checking if no correct answer present
  const isCorrectAnswerPresent = (
    values: any,
    questionTab?: number
  ): boolean => {
    const isCorrectAnswer = !!Object.keys(values)
      .filter((key) => key.startsWith("isCorrect"))
      .find((key) => values[key]);
    if (!isCorrectAnswer) {
      notification.error({
        message: "Error",
        description: questionTab
          ? `Please mark at least one correct answer for question ${questionTab}`
          : "Please mark at least one correct answer",
        placement: "bottomLeft",
      });
    }
    return isCorrectAnswer;
  };

  // Get Answer Options Array
  const getAnswerOptions = (
    values: any,
    sr?: boolean,
    dataRC?: IAnswerOptions
  ): IAnswerOptions => {
    let answerOptions: IAnswerOptions = [];
    Object.keys(values).forEach((key) => {
      let option = key.startsWith("answer")
        ? String.fromCharCode(Number(key.slice(-1)) + 64)
        : "";
      if (key.startsWith("answer")) {
        answerOptions.push({
          isCorrect:
            (values[`isCorrect${key.slice(-1)}`] ? true : false) ||
            (sr ? true : false),
          option,
          value: values[key],
        });
      }
    });
    if (dataRC) {
      const sortedAnswerOptions = (
        [...dataRC] as Required<IAnswerOption>[]
      ).sort((a, b) => a.id - b.id);
      return answerOptions.map((option, index) => ({
        ...option,
        // Giving `id` if the answer is being edited
        id: sortedAnswerOptions[index]?.id || undefined,
      }));
    } else {
      const sortedAnswerOptions = (
        [...answerOptions] as Required<IAnswerOption>[]
      ).sort((a, b) => a.id - b.id);
      return answerOptions.map((option, index) => ({
        ...option,
        id: sortedAnswerOptions[index]?.id || undefined,
      }));
    }
  };

  const initialValuesMainForm = {
    courseId: data?.courseId,
    courseName: data?.courseName,
    sectionId: data?.sectionId,
    sectionName: data?.sectionName,
    lessonId: data?.lessonId,
    lessonName: data?.lessonName,
    questionCategory: data?.questionCategory,
    difficultyLevel: data?.difficultyLevel,
    topicIds: data?.topics.map((topic) => topic.id),
  };

  const initialValuesMCQForm =
    data && data.answers && data?.answers?.length > 0
      ? {
          question: data?.questionText,
          ...getAnswerKeysWithValues(data?.answers[0].answerOptions),
          explanation: data?.explanation,
        }
      : {
          question: "",
        };

  return (
    <>
      <Form
        layout="vertical"
        name="mainForm"
        form={form}
        onFinish={
          category === QuestionCategory.SR
            ? onFinishSR
            : category === QuestionCategory.RC
            ? onFinishRC
            : onFinishMCQ
        }
        initialValues={initialValuesMainForm}
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <SelectCourseOptions
                  label="Course"
                  name="courseId"
                  rules={[{ required: true, message: "Please select course" }]}
                />
              </Col>

              <Col span={24}>
                <SelectSectionOptions
                  label="Section"
                  name="sectionId"
                  rules={[{ required: true, message: "Please select section" }]}
                  form={form}
                />
              </Col>

              <Col span={24}>
                <SelectQuestionTypeOptions
                  label="Question Type"
                  name="lessonId"
                  rules={[
                    { required: true, message: "Please select question type" },
                  ]}
                  form={form}
                />
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <TopicCheckbox
                  name="topicIds"
                  label="Topics"
                  form={form}
                  rules={[{ required: true, message: "Please select a topic" }]}
                  dependency="lessonId"
                />
              </Col>

              <Col span={24}>
                <Category />
              </Col>

              <Col span={24}>
                <DifficultyLevel />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      {category === QuestionCategory.SR ? (
        <Form name="questionFormSR" initialValues={initialValuesMCQForm}>
          <LightGrayContainer className="mt-2">
            <Tabs defaultActiveKey="1" items={tabItemsSR} />
          </LightGrayContainer>
        </Form>
      ) : category === QuestionCategory.RC ? (
        <div>
          <LightGrayContainer className="mt-2">
            <Tabs
              type="editable-card"
              activeKey={questionsActiveKey}
              onChange={(key) => setQuestionsActiveKey(key)}
              onEdit={onEditQuestionTabs}
              items={tabItemsRC}
              tabBarExtraContent={
                <PrimaryButton htmlType="button" onClick={addQuestionTab}>
                  Add a Question
                </PrimaryButton>
              }
            />
          </LightGrayContainer>
        </div>
      ) : category === QuestionCategory.MCQ ? (
        <Form name="questionFormMCQ" initialValues={initialValuesMCQForm}>
          <LightGrayContainer className="mt-2">
            <Tabs defaultActiveKey="1" items={[...getTabItemsMCQ()]} />
          </LightGrayContainer>
        </Form>
      ) : null}
    </>
  );
}

export default QuestionBankForm;
