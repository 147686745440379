import { Col, Row } from "antd";
import styled from "styled-components";
import CloseButton from "../../components/buttons/CloseButton";

import NotificationItem from "../../components/notification/NotificationItem";
import { StudentPanelWhiteContainer } from "../../components/styled/Container.styled";
import { useNavigate } from "react-router-dom";
import {
  useGetAllTutorNotificationQuery
} from "../../features/tutorPanelFeatures/TutorNotification/TutorNotificationApiSlice";

import {
  usePutStudentNotificationMutation,
} from "../../features/studentPanelFeatures/StudentNotification/StudentNotificationApiSlice";
import secureLocalStorage from "react-secure-storage";

const NotificationContainer = styled.div`
  height: 100vh;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

function TutorNotification() {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const navigate = useNavigate();
  const { data } = useGetAllTutorNotificationQuery(idFromLocalStorage);
  const [putStudentNotification] = usePutStudentNotificationMutation();

  const handleNotificationClick = (id: string) => {
    putStudentNotification(id).unwrap();
  };

  const handleClose = () => {
    navigate("/");
  };

  return (
    <>
      <StudentPanelWhiteContainer>
        <NotificationContainer>
          <Row>
            <Col span={24}>
              {data?.data?.map((item, index) => (
                <NotificationItem
                  item={item}
                  key={index}
                  handleNotificationClick={handleNotificationClick}
                />
              ))}
            </Col>
            <CloseButton onClick={handleClose}></CloseButton>
          </Row>
        </NotificationContainer>
      </StudentPanelWhiteContainer>
    </>
  );
}

export default TutorNotification;
