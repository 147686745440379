import { Col, Row, Form, Input, Radio, Select, notification } from "antd";
import { IStudentByIdResponse } from "../../../types/studentType";
import PhoneInput from "react-phone-number-input";
import { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import QuillContainer from "../../../components/styled/QuillContainer.styled";
import { useAddStudentImageMutation } from "../../../features/student/studentsApiSlice";
import { GCP_URL } from "../../../constants/commonUtils";
import ProfileImageUploader from "../../../components/uploader/ProfileImageUploader";
import TinyMce from "../../../components/common/TinyMce";

type PersonalDetailsProps = {
  data?: IStudentByIdResponse;
  setProfilePicUpload?: React.Dispatch<React.SetStateAction<FormData>>;
};
const { Option } = Select;

function PersonalDetails(props: PersonalDetailsProps) {
  const { data, setProfilePicUpload } = props;

  const [values, setValues] = useState();
  const handleValue = () => {
    setValues(values);
  };
  const [addStudentImage] = useAddStudentImageMutation();
  const [img, setImg] = useState({
    image_file: null,
    image_preview: "",
  });
  const [profilePicForm, setProfilePicForm] = useState<FormData>(
    new FormData()
  );
  useEffect(() => {
    const imageUrl = GCP_URL + `${data?.data?.imageUrl}`;
    setImg({
      image_preview: imageUrl,
      image_file: null,
    });
  }, [data]);

  useEffect(() => {
    if (profilePicForm.has("image")) {
      addStudentImage({ id: data?.data.id, formData: profilePicForm })
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully uploaded",
            placement: "bottomLeft",
          });
        })
        .catch(() => {
          notification.error({
            message: "Error occured",
            placement: "bottomLeft",
          });
        });
    }
  }, [profilePicForm]);

  return (
    <>
      <Form
        layout="vertical"
        name="personalDetailsForm"
        initialValues={{
          name: data?.data.name,
          gender: data?.data.gender,
          phoneNumber: data?.data.phoneNumber,
          email: data?.data.email,
          studentType: data?.data.studentType,
          note: data?.data.note,
        }}
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <Row align="middle">
                  <ProfileImageUploader
                    src={img.image_preview}
                    type={"file"}
                    name={"image"}
                    id={"file"}
                    setImg={setImg}
                    setProfilePicForm={
                      data ? setProfilePicForm : setProfilePicUpload
                    }
                  />
                </Row>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Student Name"
                  name="name"
                  rules={[{ required: true, message: "Please enter name" }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select gender",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="MALE">Male</Radio>
                    <Radio value="FEMALE">Female</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number",
                    },
                  ]}
                >
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={values}
                    onChange={handleValue}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="EmailID"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Input is not a valid email",
                    },
                    {
                      required: true,
                      message: "Please enter your email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Student Type"
                  name="studentType"
                  rules={[
                    {
                      required: true,
                      message: "Please select student type",
                    },
                  ]}
                >
                  <Select>
                    <Option value="INTERNAL">INTERNAL</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <p>Notes</p>
            <QuillContainer>
              <TinyMce name="note" />
            </QuillContainer>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default PersonalDetails;
