import { Col, Row, Tabs, Form, notification } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { PrimaryButton } from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import FormContainer from "../../components/styled/FormContainer.styled";
import {
  useGetTutorByIdQuery,
  useUpdateTutorByIdMutation,
} from "../../features/tutor/tutorApiSlice";
import AssignStudents from "./AddTutor/AssignStudents";
import PersonalDetails from "./AddTutor/PersonalDetails";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../features/ui/uiSlice";

function TutorDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isError, isLoading } = useGetTutorByIdQuery(id);
  const [updateTutorById,{isLoading:isLoadingEdit}] = useUpdateTutorByIdMutation();
  const navigate = useNavigate();
  dispatch(setButtonDisabled(isLoadingEdit));
  const [assignCoursesId, setAssignCoursesId] = useState<React.Key[]>(
    data?.data.tutorSectionDTO
      ?.map((item) => item.course.map((x) => x.id))
      .flat() || []
  );
  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );
  const [qualification, setQualification] = useState<string[]>(
    data?.data?.qualification || []
  );

  const onClose = () => {
    navigate(-1);
  };

  return (
    <>
      <Breadcrumbs idName={!!id ? data?.data?.name : 'false'} isId={!!id ? true : false} />
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { personalDetailsForm } = forms;
            personalDetailsForm.submit();
            let personalData = { ...personalDetailsForm.getFieldsValue() };

            let obj = {
              ...personalData,
              password: "string",
              qualification: qualification,
              imageUrl: data?.data?.imageUrl,
              tutorSectionDTO: assignCoursesId.map((course) => ({
                course: [{ id: course }],
                groupCost: personalData[`groupCost-${course}`],
                individualCost: personalData[`individualCost-${course}`],
              })),
              status: true,
            };
            updateTutorById({ id: data?.data.id, post: obj })
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully Edited!",
                  placement: "bottomLeft",
                });
                navigate(-1);
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>Save</PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Personal Details" key="1">
              <WhiteContainer>
                <FormContainer>
                  {!isError && !isLoading && data && (
                    <PersonalDetails
                      data={data}
                      qualification={qualification}
                      setQualification={setQualification}
                      setAssignCoursesId={setAssignCoursesId}
                    />
                  )}
                </FormContainer>
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Assigned Students" key="2">
              <WhiteContainer>
                {!isError && !isLoading && data && (
                  <AssignStudents data={data} />
                )}
              </WhiteContainer>
            </Tabs.TabPane>
          </Tabs>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default TutorDetails;
