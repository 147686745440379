import { mainApiLogin } from "../../app/services/mainApiSlice";
import { ILogin, ILoginResponse } from "../../types/login";

const loginApi = mainApiLogin.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<ILoginResponse, ILogin>({
      query: (loginData) => ({
        url: ``,
        method: "POST",
        body: loginData,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useSignInMutation } = loginApi;
