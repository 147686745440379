import { useState, useEffect } from "react";
import { Input, Table, Tabs, Typography, Row, notification, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link, useNavigate } from "react-router-dom";
import { internalCoursesTabs } from "../../constants/tabs";
import { ICourse } from "../../types/course";
import {
  useGetAllCoursesQuery,
  useDeleteCourseByIdMutation,
} from "../../features/internalCourse/coursesICApiSlice";
import pageSize from "../../constants/pageSize";
import {
  PrimaryButton,
  DeleteButton,
  ViewDetailsButton,
} from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import styled from "styled-components";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

function Courses() {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [currentPage, setCurrentPage] = useState(1);

  const [searchText, setSearchText] = useState("");
  const [duration, setDuration] = useState("");
  const [assignStudents, setAssignStudents] = useState("");

  const [deleteCourseById] = useDeleteCourseByIdMutation();

  const { debouncedValue: courseName, debouncing: nameSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: courseDuration, debouncing: durationSearching } =
    useDebouncingValue(duration);
  const { debouncedValue: assigned, debouncing: studentSearching } =
    useDebouncingValue(assignStudents);

  const { data, isLoading, isFetching } = useGetAllCoursesQuery({
    currentPage,
    pageSize,
    name: courseName,
    duration: courseDuration,
    assignedStudents: assigned,
  });

  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Course?",
      onOk() {
        onDelete(id);
      },
      onCancel() {},
    });
  };

  const columns: ColumnsType<ICourse> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Course Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "duration",
      title: (
        <div>
          <Typography.Text>Duration(in Hrs.)</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setDuration(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "duration",
    },
    {
      key: "assignStudents",
      title: (
        <div>
          <Typography.Text>Assigned Students</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setAssignStudents(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "assignStudents",
    },
    {
      key: "preview",
      render: (_, record) => (
        <Preview
          onClick={() => openPreview(record)}
          style={{ cursor: "pointer" }}
        >
          Preview
        </Preview>
      ),
    },
    {
      key: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openCourseDetails(record.id)} />
      ),
    },
    {
      key: "deleteButton",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];

  const openPreview = (course: any) => {
    navigate(`/preview/${course.id}`);
  };

  const openCourseDetails = (id: number) => {
    navigate(`${id}`);
  };

  const tabChange = (key: string) => {
    navigate(`../${internalCoursesTabs[Number(key) - 1]}`);
  };

  const tabItems = [
    {
      label: "Courses",
      key: "1",
      children: (
        <WhiteContainer>
          <Table<ICourse>
            rowKey="id"
            loading={
              isLoading ||
              nameSearching ||
              durationSearching ||
              studentSearching ||
              isFetching
            }
            columns={columns}
            dataSource={data?.data.content}
            // TODO: Implement Pagination like this for every paginatied table required. Right now the requests are being made for 1000 entries and page 0. That is not okay. We need to change that.
            pagination={{
              pageSize,
              current: currentPage,
              total: data?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </WhiteContainer>
      ),
    },
    {
      label: "Section",
      key: "2",
      children: "",
    },
    {
      label: "Question Type",
      key: "3",
      children: "",
    },
    {
      label: "Topics",
      key: "4",
      children: "",
    },
  ];

  const onDelete = (id: number) => {
    deleteCourseById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        });
      });
  };

  return (
    <>
      <Row className="flex-start">
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="end" className="button-bottom-margin">
        <Link to="add">
          <PrimaryButton>Add Course</PrimaryButton>
        </Link>
      </Row>

      <LightGrayContainer>
        <Tabs defaultActiveKey="1" onChange={tabChange} items={tabItems} />
      </LightGrayContainer>
    </>
  );
}

const Preview = styled.div`
  color: dodgerblue;
`;

export default Courses;
