import secureLocalStorage from "react-secure-storage";
import { mainApi } from "../../app/services/mainApiSlice";
import { IDashboard, IDashboardResponse } from "../../types/dashboard";

const commonUrl: string = "/admin/dashboard";

const dashboardApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDashboardData: builder.query<IDashboardResponse, void>({
      query: () => ({
        url: `${commonUrl}/get-dashboard`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "DASHBOARD" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllDashboardDataQuery } = dashboardApi;
