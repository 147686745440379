import { Col, Input, Row, Form, notification } from "antd";
import { useNavigate } from "react-router-dom";
import {
  useAddQuestionTypeMutation,
  useUpdateQuestionTypeByIdMutation,
} from "../../features/internalCourse/questionTypeICApiSlice";
import type {
  IQuestionType,
  IQuestionTypeByIdResponse,
} from "../../types/questionType";
import QuestionTypeOptions from "../selectFormOptions/QuestionTypeOptions";
import SelectCourseOptions from "../selectFormOptions/SelectCourseOptions";
import SelectSectionOptions from "../selectFormOptions/SelectSectionOptions";
import { setButtonDisabled } from "../../features/ui/uiSlice";
import { useDispatch } from "react-redux";

type QuestionTypeProps = {
  data?: IQuestionTypeByIdResponse;
};

function QuestionTypeForm(props: QuestionTypeProps) {
  const navigate = useNavigate();
  const { data } = props;
  const [addQuestionType, { isLoading: isLoadingAdd }] =
    useAddQuestionTypeMutation();
  const [updateQuestionTypeById, { isLoading: isLoadingEdit }] =
    useUpdateQuestionTypeByIdMutation();
  const dispatch = useDispatch();
  // set save button loading state
  dispatch(
    setButtonDisabled(isLoadingAdd === true ? isLoadingAdd : isLoadingEdit)
  );

  const onFinish = async (values: IQuestionType) => {
    if (data) {
      await updateQuestionTypeById({
        id: data?.data.id,
        post: {
          ...values,
          id: data?.data.id,
        },
      })
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully edited",
            placement: "bottomLeft",
          });
          navigate(-1);
        })
        .catch((error) =>
          notification.error({
            message: "Error",
            description: error.data.message,
            placement: "bottomLeft",
          })
        );
    } else {
      await addQuestionType({
        ...values,
      })
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully added",
            placement: "bottomLeft",
          });
          navigate(-1);
        })
        .catch((error) =>
          notification.error({
            message: "Error",
            description: error.data.message,
            placement: "bottomLeft",
          })
        );
    }
  };

  const [form] = Form.useForm();

  const initialValues = {
    name: data?.data.name,
    abbreviation: data?.data.abbreviation,
    sectionId: data?.data.sectionId,
    courseId: data?.data.courseId,
    courseName: data?.data.courseName,
    sectionName: data?.data.sectionName,
    questionType: "New Question Type",
  };
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        name="mainForm"
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <QuestionTypeOptions
              label="Question Type"
              name="questionType"
              rules={[
                { required: true, message: "Please select question type" },
              ]}
            />
          </Col>

          <Col span={12}>
            <SelectCourseOptions
              label="Select Course"
              name="courseId"
              rules={[{ required: true, message: "Please select course" }]}
            />
          </Col>

          <Col span={12}>
            <SelectSectionOptions
              label="Select Section"
              name="sectionId"
              rules={[{ required: true, message: "Please select section" }]}
              form={form}
            />
          </Col>

          <Col span={12}>
            <Form.Item
              label="Question Type"
              name="name"
              rules={[
                { required: true, message: "Please enter question type" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Abbreviation"
              name="abbreviation"
              rules={[{ required: true, message: "Please enter abbreviation" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default QuestionTypeForm;
