import { Col, Row, Spin, Typography } from "antd";
import { useLocation, useParams } from "react-router-dom";
import {
  QuizSolutionButton,
  SecondaryButton,
} from "../../../components/styled/Button.styled";
import { useGetQuizByIdQuery } from "../../../features/quizzes/quizApiSlice";
import { setTitlePreview } from "../../../features/ui/uiSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGetStudentQuizByIdQuery } from "../../../features/studentPanelFeatures/StudentQuiz/studentQuizzes";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { ROLE_ADMIN, ROLE_STUDENT } from "../../../constants/roles";

const quizViewerStyle = {
  height: "100%",
  width: "100%",
};

function QuizViewer() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  const navigate = useNavigate();
  const { quizId, courseId } = useParams();

  const role = secureLocalStorage.getItem("role");
  const isStudent = role === ROLE_STUDENT;
  const isAdmin = role === ROLE_ADMIN;

  const { data, isFetching, isLoading } = useGetQuizByIdQuery(quizId, {
    skip: !isAdmin,
  });

  const {
    data: quizData,
    isFetching: isQuizFetching,
    isLoading: isQuizLoading,
  } = useGetStudentQuizByIdQuery({id:quizId,studentId:idFromLocalStorage}, {
    skip: !isStudent,
  });

  let dataSource = data ? data?.data : quizData?.data;

  const { Text, Title } = Typography;

  const takeQuiz = (id: number | undefined) => {
    navigate(`/course/${courseId}/start-learning/take-quiz/${id}`);
  };

  const viewQuiz = (id: number) => {
    navigate(`/course/${courseId}/start-learning/view-quiz/${id}`);
  };

  useEffect(() => {
    dispatch(setTitlePreview(dataSource?.quizName));
  });

  return (
    <div style={quizViewerStyle}>
      {!isLoading && !isFetching && !isQuizFetching && !isQuizLoading ? (
        <Row justify={"space-between"} align={"middle"}>
          <Col>
            <Row>
              <Title level={5}>Quiz - {dataSource?.quizName}</Title>
            </Row>
            <Row>
              <Text type="secondary">{dataSource?.quizTime} minute</Text>
            </Row>
          </Col>
          {!pathArray.includes("preview") && (
            <Col>
              {quizData?.data?.submitQuizId ? (
                <QuizSolutionButton
                  onClick={() => viewQuiz(quizData?.data?.submitQuizId)}
                >
                  View Quiz
                </QuizSolutionButton>
              ) : (
                <SecondaryButton onClick={() => takeQuiz(quizData?.data?.id)}>
                  Take Quiz
                </SecondaryButton>
              )}
            </Col>
          )}
        </Row>
      ) : (
        <Row justify={"center"}>
          <Spin />
        </Row>
      )}
    </div>
  );
}

export default QuizViewer;
