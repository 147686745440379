import { Tabs, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import { LightGrayContainer } from "../../../components/styled/Container.styled";
import StudentAnalytics from "./StudentAnalytics";
import TimeSheet from "./TimeSheet";

function TutorsAnalytics() {
  const navigate = useNavigate();
  const onClose = () => {
    navigate(-1);
  };

  const tabItems = [
    {
      key: "1",
      label: "Student Analytics",
      children: <StudentAnalytics />,
    },
    {
      key: "2",
      label: "Tutor Analytics",
      children: <TimeSheet />,
    },
  ];

  return (
    <>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </Col>
      </Row>

      <LightGrayContainer>
        <Tabs items={tabItems} />
      </LightGrayContainer>
    </>
  );
}

export default TutorsAnalytics;
