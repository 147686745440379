import { Rule } from "rc-field-form/lib/interface";
import BorderedCheckboxes from "../../components/quizzes/BorderedCheckboxes";
import { useMemo } from "react";
import type { IValueLabel } from "../../types/common";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { FormInstance } from "antd/es/form";
import { useGetSectionByCourseIdQuery } from "../../features/internalCourse/sectionICApiSlice";

type IProps = {
  label: string;
  name: string;
  rules?: Rule[];
  form: FormInstance;
  dependency?: "courseId";
};


function SectionCheckbox(props: IProps) {
  const { form, dependency = "courseId" } = props;

  const selectedCourse = useAppSelector(
    (state: RootState) => state.ui.selectedCourse
  );

  const { data } = useGetSectionByCourseIdQuery(
    selectedCourse || form?.getFieldValue(dependency)
  );

  const mappedData: IValueLabel[] | undefined = useMemo(
    () =>
      data?.data.map((option) => ({
        value: `${option.id}`,
        label: option.name,
      })),
    [data]
  );

  return <BorderedCheckboxes data={mappedData} {...props} />;
}

export default SectionCheckbox;
