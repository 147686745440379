import { mainApi } from "../../../app/services/mainApiSlice";
import {
  IStudentQuizByIdResponse,
  IStudentQuizProgressTypeResponse,
} from "../../../types/studentPanelTypes/studentQuizProgressType";
import {
  ITutorQuizByIdResponse,
  ITutorQuizResponse,
} from "../../../types/tutorPanelTypes/tutorQuiz";

const commonUrl: string = "/tutor/quiz";

const tutorQuizApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Quiz
    getAllTutorQuiz: builder.query<
      ITutorQuizResponse,
      {
        currentPage: number;
        pageSize: number;
        idFromLocalStorage: string | null;
        quizName?: string;
        quizMode?: string;
        courseName?: string;
      }
    >({
      query: ({
        currentPage,
        pageSize,
        idFromLocalStorage,
        courseName,
        quizMode,
        quizName,
      }) => ({
        url: `${commonUrl}/tutor-quizzes/${idFromLocalStorage}?courseName=${
          courseName || ""
        }&page=${currentPage - 1}&quizMode=${quizMode || ""}&quizName=${
          quizName || ""
        }&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTORQUIZ" }],
    }),
    getQuizByTutorId: builder.query<ITutorQuizByIdResponse, string | undefined>(
      {
        query: (id) => ({
          url: `${commonUrl}/get-quiz/${id}`,
          method: "GET",
        }),
        providesTags: [{ type: "Main", id: "TUTORQUIZ" }],
      }
    ),
    getTutorStudentQuizProgress: builder.query<
      IStudentQuizProgressTypeResponse,
      { currentPage: number; pageSize: number; studentId: string | undefined }
    >({
      query: ({ currentPage, pageSize, studentId }) => ({
        url: `${commonUrl}/get-all-viewQuiz/${studentId}?page=${
          currentPage - 1
        }&size=${pageSize}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTORQUIZPROGRESS" }],
    }),
    getTutorQuizByQuizId: builder.query<
      IStudentQuizByIdResponse,
      string | undefined
    >({
      query: (id) => ({
        url: `${commonUrl}/viewQuiz/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "TUTORQUIZ" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllTutorQuizQuery,
  useGetQuizByTutorIdQuery,
  useGetTutorStudentQuizProgressQuery,
  useGetTutorQuizByQuizIdQuery,
} = tutorQuizApi;
