import { Input, Table, Typography, Row, DatePicker } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../constants/pageSize";
import CustomCard from "../../components/countCard/CustomCard";
import {
  PrimaryButton,
  ViewDetailsButton,
} from "../../components/styled/Button.styled";
import { useGetAllGroupQuery } from "../../features/group/groupApiSlice";
import { IGroup } from "../../types/group";
import dayjs from "dayjs";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

function Groups() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [noOfStudents, setNoOfStudents] = useState("");
  const [course, setCourse] = useState("");
  const [noOfTutors, setNoOfTutors] = useState("");

  const { debouncedValue: name, debouncing: nameSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: startDateSearch, debouncing: startDateSearching } =
    useDebouncingValue(startDate);
  const { debouncedValue: courseName, debouncing: courseNameSearching } =
    useDebouncingValue(course);

  const { data, isLoading, isFetching } = useGetAllGroupQuery({
    currentPage,
    pageSize,
    name,
    startDate: startDateSearch,
    course: courseName,
  });

  const columns: ColumnsType<IGroup> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Group Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "startDate",
      title: (
        <div>
          <Typography.Text>Start Date</Typography.Text>
          <DatePicker
            onChange={(e) => {
              setStartDate(
                e !== null ? dayjs(e).format("YYYY-MM-DD 00:00:00") : ""
              );
              setCurrentPage(1);
            }}
            allowClear
          />
        </div>
      ),

      dataIndex: "startDate",
      render: (_, record) => (
        <>
          {dayjs(record.startDate)
            .toISOString()
            .split("-")
            .reverse()[0]
            .split("T")[0] +
            dayjs(record.startDate)
              .toISOString()
              .split("-")
              .reverse()
              .join("-")
              .split("Z")[1]}
        </>
      ),
    },
    {
      key: "studentCount",
      title: (
        <div>
          <Typography.Text>No. of Students</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setNoOfStudents(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
            disabled
          />
        </div>
      ),

      dataIndex: "studentCount",
    },
    {
      key: "tutorCount",
      title: (
        <div>
          <Typography.Text>No. of Tutors</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setNoOfTutors(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
            disabled
          />
        </div>
      ),

      dataIndex: "tutorCount",
    },
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCourse(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),

      dataIndex: "courseName",
    },

    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (text, record) => (
        <ViewDetailsButton onClick={() => openGroupDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
  ];

  const openGroupDetails = (id: number) => {
    navigate(`${id}`);
  };

  useEffect(() => {
    if (
      (name !== "" || startDateSearch !== "" || courseName !== "") &&
      currentPage > 1
    ) {
      setCurrentPage(1);
    }
  }, [name, startDateSearch, courseName, currentPage]);

  return (
    <div>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard sum={data?.data?.content[0]?.totalGroup} text="Groups" />
        <Link to="add">
          <PrimaryButton>Create Group</PrimaryButton>
        </Link>
      </Row>

      <Row className="styled-container outer-styled-container">
        <Row className="styled-container inner-styled-container">
          <Table<IGroup>
            loading={
              isLoading ||
              isFetching ||
              nameSearching ||
              startDateSearching ||
              courseNameSearching
            }
            rowKey="id"
            columns={columns}
            dataSource={data?.data?.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: data?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </Row>
      </Row>
    </div>
  );
}

export default Groups;
