import { Input, Table, Typography, Row, Switch } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../constants/pageSize";
import CustomCard from "../../components/countCard/CustomCard";
import {
  PrimaryButton,
  ViewDetailsButton,
} from "../../components/styled/Button.styled";
import {
  useGetAllTutorQuery,
  useUpdateTutorOnlineStatusByIdMutation,
} from "../../features/tutor/tutorApiSlice";
import { ITutor } from "../../types/tutor";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

function Tutor() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const [updateTutorOnlineStatusById] =
    useUpdateTutorOnlineStatusByIdMutation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [section, setSection] = useState("");
  const [name, setName] = useState("");
  const [assignStudents, setAssignStudents] = useState("");

  const { debouncedValue: phone, debouncing: phoneSearching } =
    useDebouncingValue(phoneNumber);
  const { debouncedValue: email, debouncing: emailSearching } =
    useDebouncingValue(emailId);
  const { debouncedValue: sectionName, debouncing: sectionSearching } =
    useDebouncingValue(section);
  const { debouncedValue: tutorName, debouncing: tutorSearching } =
    useDebouncingValue(name);
  const { debouncedValue: students, debouncing: studentSearching } =
    useDebouncingValue(assignStudents);

  const { data, isLoading, isFetching } = useGetAllTutorQuery({
    currentPage,
    pageSize,
    assignedStudents: students,
    email,
    name: tutorName,
    phoneNumber: phone,
    sectionName,
  });

  const toggleSwitch = (id: number, onlineStatus: boolean) => {
    updateTutorOnlineStatusById({ id, onlineStatus });
  };

  const columns: ColumnsType<ITutor> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Tutor Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setName(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "phoneNumber",
      title: (
        <div>
          <Typography.Text>Phone Number</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "phoneNumber",
    },
    {
      key: "email",
      title: (
        <div>
          <Typography.Text>Email Id</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setEmailId(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),

      dataIndex: "email",
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSection(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      render: (_, record) => {
        return record.tutorSectionDTO
          .map((item: any) => item.sectionName)
          .join(", ");
      },
      dataIndex: "tutorSectionDTO",
    },
    {
      key: "assignStudents",
      title: (
        <div>
          <Typography.Text>Assigned Students</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setAssignStudents(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),

      dataIndex: "assignStudents",
    },
    {
      key: "active",
      dataIndex: "status",
      render: (_, record) => {
        return (
          <>
            <span>{record.status === true ? "Active" : "Inactive"}</span>
            <span>
              <Switch
                key={record.id}
                onChange={(event) => toggleSwitch(record.id, event)}
                checked={record.status}
              ></Switch>
            </span>
          </>
        );
      },
    },

    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openTutorDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
  ];

  const openTutorDetails = (id: number) => {
    navigate(`${id}`);
  };

  return (
    <div>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard sum={data?.data?.content[0]?.totalTutor} text="Tutor" />
        <Link to="add">
          <PrimaryButton>Add Tutor</PrimaryButton>
        </Link>
      </Row>

      <Row className="styled-container outer-styled-container">
        <Row className="styled-container inner-styled-container">
          <Table<ITutor>
            loading={
              isLoading ||
              isFetching ||
              phoneSearching ||
              emailSearching ||
              sectionSearching ||
              tutorSearching ||
              studentSearching
            }
            rowKey="id"
            columns={columns}
            dataSource={data?.data.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: data?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </Row>
      </Row>
    </div>
  );
}

export default Tutor;
