import { Col, Row, Spin } from "antd";

import {
  MdAutoStories,
  MdSchool,
  MdSupervisedUserCircle,
} from "react-icons/md";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import DashboardCustomCard from "../../components/dashboardCard/DashboardCustomCard";
import { colorPrimary } from "../../constants/theme";
import { useGetAllTutorDashboardQuery } from "../../features/tutorPanelFeatures/TutorDashboard/tutorDashboard";


function TutorDashboard() {
  const size = 60;
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString();
  const { data } = useGetAllTutorDashboardQuery(idFromLocalStorage);

  return (
    <>
      {
        !!data ? <Row gutter={[8, 16]}>
          <Col>
            <Link to={"/students"}>
              <DashboardCustomCard
                heading={"Students"}
                subText={"New Student"}
                cardIcon={<MdSchool size={size} color={colorPrimary} />}
                // newCount={data?.data?.studentCount?.newStudent}
                totalCount={data?.data?.studentCount?.totalStudentCount}
              />
            </Link>
          </Col>

          <Col>
            <Link to={"/my-courses"}>
              <DashboardCustomCard
                heading={"Courses"}
                subText={"New Course"}
                // newCount={data?.data?.courseCount?.newCourse}
                totalCount={data?.data?.courseCount?.totalCourseCount}
                cardIcon={<MdAutoStories size={size} color={colorPrimary} />}
              />
            </Link>
          </Col>

          <Col>
            <Link to={"/groups"}>
              <DashboardCustomCard
                heading={"Groups"}
                totalCount={data?.data?.groupCount?.totalGroupCount}
                cardIcon={
                  <MdSupervisedUserCircle size={size} color={colorPrimary} />
                }
              />
            </Link>
          </Col>
        </Row> :
          <Spin size="large" />
      }
    </>
  );
}

export default TutorDashboard;
