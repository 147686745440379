import { useEffect, useState } from "react";
import { Input, Table, Typography, Row, Tabs, notification, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import { internalCoursesTabs } from "../../constants/tabs";
import { Link, useNavigate } from "react-router-dom";
import type { IQuestionType } from "../../types/questionType";
import {
  useGetAllQuestionTypesQuery,
  useDeleteQuestionTypeByIdMutation,
} from "../../features/internalCourse/questionTypeICApiSlice";
import pageSize from "../../constants/pageSize";
import {
  DeleteButton,
  PrimaryButton,
  ViewDetailsButton,
} from "../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../components/styled/Container.styled";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

function QuestionType() {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [currentPage, setCurrentPage] = useState(1);

  const [deleteQuestionTypeById] = useDeleteQuestionTypeByIdMutation();
  const [searchText, setSearchText] = useState("");
  const [section, setsection] = useState("");
  const [course, setcourse] = useState("");

  const {
    debouncedValue: questionTypeName,
    debouncing: questionTypeSearching,
  } = useDebouncingValue(searchText);
  const { debouncedValue: sectionName, debouncing: sectionSearching } =
    useDebouncingValue(section);
  const { debouncedValue: courseName, debouncing: courseSearching } =
    useDebouncingValue(course);

  const { data, isLoading, isFetching } = useGetAllQuestionTypesQuery({
    currentPage,
    pageSize,
    name: questionTypeName,
    courseName,
    sectionName,
  });

  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Question Type?",

      onOk() {
        onDelete(id);
      },
      onCancel() {},
    });
  };
  const columns: ColumnsType<IQuestionType> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Question Type</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "sectionName",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setsection(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "sectionName",
    },
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setcourse(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "courseName",
    },
    {
      key: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openQuestionTypeDetails(record.id)} />
      ),
    },
    {
      key: "deleteButton",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];

  const openQuestionTypeDetails = (id: number) => {
    navigate(`${id}`);
  };

  const tabChange = (key: string) => {
    navigate(`../${internalCoursesTabs[Number(key) - 1]}`);
  };

  const tabItems = [
    {
      label: "Courses",
      key: "1",
      children: "",
    },
    {
      label: "Section",
      key: "2",
      children: "",
    },
    {
      label: "Question Type",
      key: "3",
      children: (
        <WhiteContainer>
          <Table<IQuestionType>
            rowKey={"id"}
            loading={
              isLoading ||
              questionTypeSearching ||
              courseSearching ||
              sectionSearching ||
              isFetching
            }
            columns={columns}
            dataSource={data?.data.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: data?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </WhiteContainer>
      ),
    },
    {
      label: "Topics",
      key: "4",
      children: "",
    },
  ];

  const onDelete = (id: number) => {
    deleteQuestionTypeById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        });
      });
  };

  return (
    <>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="end" style={{ marginBottom: "1rem" }}>
        <Link to="add">
          <PrimaryButton>Add Question Type</PrimaryButton>
        </Link>
      </Row>

      <LightGrayContainer>
        <Tabs defaultActiveKey="3" onChange={tabChange} items={tabItems} />
      </LightGrayContainer>
    </>
  );
}

export default QuestionType;
