import { VerticalAlignTopOutlined } from "@ant-design/icons";
import { Upload, Form, Alert, Button } from "antd";
import type { UploadProps } from "antd";
import { useState } from "react";
import { UploadChangeParam } from "antd/es/upload";
import { Rule } from "rc-field-form/lib/interface";
const acceptFileTypesArray = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "video/mp4",
  "audio/mpeg",
];

type IFileUpload = {
  setBlockSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  name: [number, string] | string;
  rules?: Rule[];
};

const FileUpload = (props: IFileUpload) => {
  const { setBlockSubmit } = props;
  const [beforeUploadError, setBeforeUploadError] = useState<string>("");

  const uploadProps: UploadProps = {
    name: "file",
    maxCount: 1,
    accept:
      "image/jpeg,image/jpg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document,video/mp4,audio/mpeg",
    beforeUpload: () => false,
    onChange: (file: UploadChangeParam) => {
      // Check if file was removed
      if (file.file.status === "removed") {
        setBlockSubmit(true);
        return setBeforeUploadError("Please add file");
      }
      // Check for file size
      const fileSizeGreaterThanThousand: boolean =
        file?.file && file.file.size
          ? file.file.size / 1024 / 1024 > 1000
          : false;
      if (fileSizeGreaterThanThousand) {
        setBlockSubmit(true);
        return setBeforeUploadError("Please upload file upto 100Mb");
      }
      // Check for file type
      if (!acceptFileTypesArray.includes(file.file.type || "")) {
        setBlockSubmit(true);
        return setBeforeUploadError(
          "Accepted file formats: .jpg,.jpeg,.png,.pdf,.pptx,.mp4,.mp3"
        );
      }
      setBlockSubmit(false);
      setBeforeUploadError("");
    },
  };

  return (
    <>
      {
        <Form.Item {...props}>
          <Upload {...uploadProps}>
            <Button icon={<VerticalAlignTopOutlined />}></Button>
          </Upload>
        </Form.Item>
      }

      {!!beforeUploadError && (
        <Alert type="error" message={beforeUploadError} />
      )}
    </>
  );
};

export default FileUpload;
