
import secureLocalStorage from "react-secure-storage";
import { useAppSelector } from "../app/hooks";
import SubAdminRoutes from "./admin/subAdminRoutes";
import TutorRoutes from "./admin/subTutorRoutes";


const AdminRoutes = () => {

	const isAdmin = useAppSelector((state) => state.ui.admin) ?? secureLocalStorage.getItem("admin");

	return (
		<>
			{isAdmin ? <SubAdminRoutes /> : <TutorRoutes />}
		</>
	);
};


export default AdminRoutes;
