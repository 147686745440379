import { Card, Col, Row, Typography } from "antd";
import {
  lightGreyColor,
  commonStrokeColor,
} from "../../constants/commonColors";
import { IAdminNotification } from "../../types/notification";
import { IStudentNotification } from "../../types/studentPanelTypes/studentNotificationType";
import { ITutorNotification } from "../../types/tutorPanelTypes/tutorNotificationType";

type NotificationItemProps = {
  item: IStudentNotification | ITutorNotification | IAdminNotification;
  handleNotificationClick: any;
};
const { Title } = Typography;

function NotificationItem({
  item,
  handleNotificationClick,
}: NotificationItemProps) {
  return (
    <>
      <Card
        onClick={() => handleNotificationClick(item.id)}
        className="mb-1"
        style={{
          backgroundColor: `${
            item.status ? commonStrokeColor : lightGreyColor
          }`,
        }}
      >
        <Row justify={"space-between"}>
          <Col>
            <Title level={5} className="m-0">
              <span>{item?.title}</span>
            </Title>
          </Col>
          <Col>
            <span>{item?.date}</span>
          </Col>
        </Row>
        <Row>
          <span>{item?.description}</span>
        </Row>
      </Card>
    </>
  );
}

export default NotificationItem;
