import React, { useEffect, useState } from "react";
import { Input, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { WhiteContainer } from "../../components/styled/Container.styled";
import { ISection } from "../../types/section";
import { useGetAllSectionsQuery } from "../../features/internalCourse/sectionICApiSlice";
import pageSize from "../../constants/smallPageSize";
import { IResourceByIdResponse } from "../../types/resources";
import { useDebouncingValue } from "../../utils/useDebouncingValue";

type LinkSectionProps = {
  setSections: any;
  resourceData?: IResourceByIdResponse;
};

function LinkSection(props: LinkSectionProps) {
  const { setSections, resourceData } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const { debouncedValue: sectionName, debouncing: sectionSearching } =
    useDebouncingValue(searchText);
  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >([]);
  const { data, isLoading, isFetching } = useGetAllSectionsQuery({
    currentPage,
    pageSize,
    sectionName,
  });
  const preSelectedSections = resourceData?.data.sections.map(
    (ele) => ele["id"]
  );

  useEffect(() => {
    setSelectedRowKeys(preSelectedSections || []);
  }, []);

  const columns: ColumnsType<ISection> = [
    {
      key: "name",
      title: (
        <div>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSections(newSelectedRowKeys.map((id) => id));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <WhiteContainer>
        <Table
          rowSelection={{ preserveSelectedRowKeys: true, ...rowSelection }}
          rowKey="id"
          columns={columns}
          dataSource={data?.data.content}
          pagination={{
            pageSize,
            current: currentPage,
            total: data?.data.totalElements,
            onChange(page) {
              setCurrentPage(page);
            },
          }}
          loading={isLoading || isFetching || sectionSearching}
        />
      </WhiteContainer>
    </div>
  );
}

export default LinkSection;
