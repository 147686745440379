import { useState } from "react";
import { Form, Input, Tabs } from "antd";
import QuillContainer from "../styled/QuillContainer.styled";
import { IAnswerOptions, IQuestionBank } from "../../types/questionBank";
import { Tab } from "rc-tabs/lib/interface";
import { useDeleteAnswerOptionByIdMutation } from "../../features/quizzes/questionApiSlice";

type IAnswerTabsSRProps = {
  data?: IQuestionBank;
};

const getInitialItems = (array: IAnswerOptions): Tab[] => {
  const num = array.length;
  return array.map((item, index) => ({
    label: String.fromCharCode(index + 1 + 64),
    key: `${index + 1}`,
    id: item ? `${item.id}` : undefined,
    children: (
      <QuillContainer>
        <Form.Item name={`answer${index + 1}`}>
          <Input />
        </Form.Item>
      </QuillContainer>
    ),
    closable: num > 1 && num === index + 1 ? true : false,
    forceRender: true,
  }));
};

const AnswerTabsSR = ({ data }: IAnswerTabsSRProps) => {
  const [deleteAnswerOptionById] = useDeleteAnswerOptionByIdMutation();
  const [activeKey, setActiveKey] = useState("1");
  const initialItems = getInitialItems(
    data?.answers[0].answerOptions || [...Array(1)]
  );

  const [items, setItems] = useState(initialItems);

  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const add = () => {
    const newTabIndexValue = items.length + 1;
    const newActiveKey = `${newTabIndexValue}`;

    setItems((items) =>
      items
        .map((item) => ({ ...item, closable: false }))
        .concat({
          label: String.fromCharCode(newTabIndexValue + 64),
          children: (
            <QuillContainer>
              <Form.Item name={`answer${newTabIndexValue}`}>
                <Input />
              </Form.Item>
            </QuillContainer>
          ),
          key: newActiveKey,
          closable: true,
          forceRender: true,
        })
    );
    setActiveKey(newActiveKey);
  };

  const remove = (targetKey: string) => {
    let allItems = [...items];
    let itemToRemove = allItems.find((item) => item.key === targetKey);
    let itemIndexToRemove = itemToRemove ? allItems.indexOf(itemToRemove) : -5;
    if (itemToRemove?.id) {
      deleteAnswerOptionById(itemToRemove.id);
    }
    if (itemIndexToRemove > -1) {
      allItems.splice(itemIndexToRemove, 1);
      const finalItems = allItems.map((item, index) => {
        if (index <= 3) {
          return item;
        }
        if (index >= itemIndexToRemove) {
          return { ...item, key: `${Number(item.key) - 1}` };
        }
        return item;
      });
      const lastItemIndex = finalItems.length - 1;
      finalItems[lastItemIndex] = {
        ...finalItems[lastItemIndex],
        closable: lastItemIndex >= 1 ? true : false,
      };
      setItems(finalItems);
    }
    setActiveKey(`${items.length - 1}`);
  };

  const onEdit = (targetKey: any, action: "add" | "remove") => {
    if (action === "add") {
      if (items.length >= 10) {
        return;
      }
      add();
    } else {
      remove(targetKey);
    }
  };

  return (
    <Tabs
      type="editable-card"
      onChange={onChange}
      activeKey={activeKey}
      onEdit={onEdit}
      items={items}
    />
  );
};

export default AnswerTabsSR;
