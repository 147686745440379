import { Outlet } from "react-router-dom";
import { Layout, Row } from "antd";
import React from "react";
import Header from "../layout/header/Header";
import Breadcrumbs from "../common/Breadcrumbs";
import styled from "styled-components";
import PreviewCustomHeader from "../PreviewHeader/PreviewCustomHeader";
import { useAppSelector } from "../../app/hooks";

const { Content } = Layout;

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1rem 2rem 1rem;
`;

const App: React.FC = () => {
  const headerText = useAppSelector((state) => state.ui.previewHeader);
  return (
    <Layout style={{ minHeight: "100%" }}>
      <Layout>
        <Header />
        <Breadcrumbs />
        <Row justify={"center"}>
          <PreviewCustomHeader headerText={headerText} />
        </Row>

        <Content>
          <OutletContainer>
            <Outlet />
          </OutletContainer>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
