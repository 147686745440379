import { Col, Row, Tabs, Form, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import AssignCourses from "../AddStudents/AssignCourses";
import PersonalDetails from "../AddStudents/PersonalDetails";
import Quiz from "../AddStudents/Quiz";
import {
  useGetStudentByIdQuery,
  useUpdateStudentByIdMutation,
  useReinviteEmailMutation
} from "../../../features/student/studentsApiSlice";
import { useState, useEffect } from "react";
import { PrimaryButton } from "../../../components/styled/Button.styled";
import {
  LightGrayContainer,
  WhiteContainer,
} from "../../../components/styled/Container.styled";
import FormContainer from "../../../components/styled/FormContainer.styled";
import AssignResources from "../AddStudents/AssignResources";
import AssignTutors from "../AddStudents/AssignTutors";
import { useAppSelector } from "../../../app/hooks";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../../features/ui/uiSlice";
import Breadcrumbs from "../../../components/common/Breadcrumbs";

function StudentsDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isError, isLoading } = useGetStudentByIdQuery(id);
  const [updateStudentById, { isLoading: isLoadingEdit }] = useUpdateStudentByIdMutation();
  const [ reinviteEmail, { isLoading: isLoadingEmail } ] = useReinviteEmailMutation();
  const [assignCoursesId, setAssignCoursesId] = useState<
    React.Key[] | undefined
  >(data?.data?.assignCourse || []);
  const [assignTutorsId, setAssignTutorsId] = useState<React.Key[] | undefined>(
    data?.data?.assignTutor || []
  );
  const [assignQuizzesId, setAssignQuizzesId] = useState<
    React.Key[] | undefined
  >(data?.data.quizzes || []);
  const [assignResourcesId, setAssignResourcesId] = useState<
    React.Key[] | undefined
  >(data?.data.resources || []);

  const isButtonLoading = useAppSelector(
    (state) => state.ui.buttonDisabled
  );
  const onClose = () => {
    navigate(-1);
  };
  dispatch(setButtonDisabled(isLoadingEdit));

  useEffect(() => {
    setAssignCoursesId(data?.data?.assignCourse);
    setAssignQuizzesId(data?.data?.quizzes);
    setAssignResourcesId(data?.data?.resources);
    setAssignTutorsId(data?.data?.assignTutor);
  }, [data]);
 
  const handelReinvite = () => {
    // reinviteEmail(id)
    reinviteEmail(id)
      .unwrap()
      .then((response) => {
        if (response.statusCode == 200) {
          notification.success({
            message: response.message,
            placement: "bottomLeft",
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: `Reinvite Failed`,
          placement: "bottomLeft",
        });
      });
  };


  return (
    <>
      <Breadcrumbs idName={!!id ? data?.data?.name : 'false'} isId={!!id ? true : false} />
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { personalDetailsForm } = forms;
            personalDetailsForm.submit();

            let personalData = { ...personalDetailsForm.getFieldsValue() };

            let obj = {
              id: data?.data.id,
              ...personalData,
              assignCourse: assignCoursesId,
              imageUrl: data?.data?.imageUrl,
              password: "string",
              assignTutor: assignTutorsId,
              quizzes: assignQuizzesId,
              resources: assignResourcesId,
              status: true,
            };
            updateStudentById({ id: data?.data.id, post: obj })
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully Edited!",
                  placement: "bottomLeft",
                });
                navigate(-1);
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <PrimaryButton disabled={ isLoadingEmail ? true: false} onClick={handelReinvite} >Re-Invite</PrimaryButton>
          </Col>
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" loading={isButtonLoading}>Save</PrimaryButton>
            </Form>
          </Col>
          <Col>
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          </Col>
        </Row>

        <LightGrayContainer>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Personal Details" key="1">
              <WhiteContainer>
                <FormContainer>
                  {!isError && !isLoading && data && (
                    <PersonalDetails data={data} />
                  )}
                </FormContainer>
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Assigned Courses" key="2">
              <WhiteContainer>
                {!isError && !isLoading && data && (
                  <AssignCourses
                    courseData={data}
                    setAssignCoursesId={setAssignCoursesId}
                  />
                )}
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Assigned Tutors" key="3">
              <WhiteContainer>
                {!isError && !isLoading && data && (
                  <AssignTutors
                    courseData={data}
                    setAssignTutorsId={setAssignTutorsId}
                  />
                )}
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Resources" key="4">
              <WhiteContainer>
                {!isError && !isLoading && data && (
                  <AssignResources
                    courseData={data}
                    setAssignResourcesId={setAssignResourcesId}
                  />
                )}
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Quiz" key="5">
              <WhiteContainer>
                <Quiz
                  courseData={data}
                  setAssignQuizzesId={setAssignQuizzesId}
                />
              </WhiteContainer>
            </Tabs.TabPane>
          </Tabs>
        </LightGrayContainer>
      </Form.Provider>
    </>
  );
}

export default StudentsDetails;
