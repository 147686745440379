import { Card, Col, Row } from "antd";
import dayjs from "dayjs";

type QueryBodyProps = {
  date: string;
  message: string;
};

const outerQueryContainer = {
  width: "100%",
  justifyContent: "center",
};

const innerColumnsContainer = {
  padding: "0",
  margin: "1rem",
};

const cardsContainer = {
  backgroundColor: "#EEEEEE",
  padding: "1rem",
  width: "fit-content",
  margin: "auto",
};

function ViewQueryDetails(props: QueryBodyProps) {
  const { date, message } = props;
  return (
    <>
      <Row style={outerQueryContainer}>
        <Col style={innerColumnsContainer}>
          <Card
            size="small"
            style={cardsContainer}
            bodyStyle={{ padding: "0" }}
          >
            <Row align={"middle"} justify={"center"}>
              {dayjs(date).toISOString().split("-").reverse()[0].split("T")[0] +
                dayjs(date)
                  .toISOString()
                  .split("-")
                  .reverse()
                  .join("-")
                  .split("Z")[1]}
            </Row>
          </Card>
        </Col>
        <Col span={23} style={innerColumnsContainer}>
          <Card style={{ border: "none" }} bodyStyle={{ padding: "0.5rem" }}>
            <Row
              align={"middle"}
              justify={"center"}
              style={{ overflowWrap: "anywhere" }}
            >
              {message}
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ViewQueryDetails;
