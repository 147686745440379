import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const BreadcrumbContainer = styled.div`
  padding: 1rem 1rem;
`;

const Breadcrumbs = ({ idName, isId }: any) => {
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter((item) => item);

  return (
    <BreadcrumbContainer>
      {(process.env.REACT_APP_IS_ADMIN === "true"
        ? pathnames
        : pathnames.slice(2)
      ).map((name, index) => {
        const routeTo =
          process.env.REACT_APP_IS_ADMIN === "true"
            ? `${pathnames.slice(0, index + 1).join("/")}`
            : `${pathnames.slice(3, index + 1).join("/")}`;

        const isLast =
          process.env.REACT_APP_IS_ADMIN === "true"
            ? index === pathnames.length - 1
            : index === pathnames.slice(2).length - 1;
        const isLastSecond = index === pathnames.length - 2;
        const isSecond = index === 1;

        return isLast ? (
          <>{isId ? idName : name}</>
        ) : (
          <span>
            <Link
              to={`/${routeTo}`}
              style={{ color: "black", textDecorationLine: "underline" }}
            >
              {name.concat(">")}
            </Link>
          </span>
        );
      })}
    </BreadcrumbContainer>
  );
};

export default Breadcrumbs;
