import { WhiteContainer } from "../../../components/styled/Container.styled";
import FormContainer from "../../../components/styled/FormContainer.styled";
import { Col, Form, Input, notification, Radio, Row } from "antd";
import PhoneInput from "react-phone-number-input";
import AssignedCourses from "./AssignCourse";
import { useState, useEffect } from "react";
import { GCP_URL } from "../../../constants/commonUtils";

import {
  useAddStudentImageMutation,
  useGetStudentProfileQuery,
} from "../../../features/studentPanelFeatures/StudentProfile/studentProfileSlice";
import ProfileImageUploader from "../../../components/uploader/ProfileImageUploader";
import secureLocalStorage from "react-secure-storage";

function TutorProfile() {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const { data, isLoading, isError } =
    useGetStudentProfileQuery(idFromLocalStorage);

  const [addStudentImage] = useAddStudentImageMutation();

  const [values, setValues] = useState();
  const [profilePicForm, setProfilePicForm] = useState<FormData>(
    new FormData()
  );

  const handleValue = () => {
    setValues(values);
  };
  const [img, setImg] = useState({
    image_file: null,
    image_preview: "",
  });

  useEffect(() => {
    const imageUrl = GCP_URL + `${data?.data?.imageUrl}`;
    setImg({
      image_preview: imageUrl,
      image_file: null,
    });
  }, [data]);

  useEffect(() => {
    if (profilePicForm.has("image")) {
      addStudentImage({ id: data?.data.id, formData: profilePicForm })
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully uploaded",
            placement: "bottomLeft",
          });
        })
        .catch(() => {
          notification.error({
            message: "Error occured",
            placement: "bottomLeft",
          });
        });
    }
  }, [profilePicForm]);

  return (
    <>
      {!isLoading && !isError && data && (
        <WhiteContainer>
          <FormContainer>
            <Form
              layout="vertical"
              name="personalDetailsForm"
              initialValues={{
                name: data?.data?.name,
                role: "Student",
                gender: data?.data?.gender,
                phoneNumber: data?.data?.phoneNumber,
                email: data?.data?.email,
              }}
            >
              <Row gutter={[32, 22]}>
                <Col span={12}>
                  <Row gutter={[32, 22]}>
                    <Col span={24}>
                      <Row align="middle">
                        <ProfileImageUploader
                          src={img.image_preview}
                          type={"file"}
                          name={"image"}
                          id={"file"}
                          setImg={setImg}
                          setProfilePicForm={setProfilePicForm}
                        />
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Form.Item label="Name" name="name">
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item name="gender" label="Gender">
                        <Radio.Group disabled={true}>
                          <Radio value="MALE">Male</Radio>
                          <Radio value="FEMALE">Female</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="phoneNumber"
                        label="Phone Number"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your Phone Number",
                          },
                        ]}
                      >
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={values}
                          onChange={handleValue}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row gutter={[32, 22]}>
                    <Col span={24}>
                      <Form.Item label="Email" name="email">
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Role" name="role">
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Col span={24}>
                <Form.Item>
                  <p>Assigned courses</p>
                  {!isLoading && !isError && data && (
                    <AssignedCourses studentData={data} />
                  )}
                </Form.Item>
              </Col>
            </Form>
          </FormContainer>
        </WhiteContainer>
      )}
    </>
  );
}

export default TutorProfile;
