import { Input, Typography, Table } from "antd";
import React, { useState } from "react";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../constants/pageSize";
import type {
  IQuestionBank,
  IQuestionBankResponse,
} from "../../types/questionBank";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import getStringFromHTML from "../../utils/getStringFromHTML";

type QuizQuestionProps = {
  setQuestionId?: any;
  setDisable?: any;
  questionData?: IQuestionBankResponse;
};

function QuizQuestionForm(props: QuizQuestionProps) {
  const { setQuestionId, setDisable } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [id, setId] = useState("");
  const [question, setQuestion] = useState("");
  const [category, setCategory] = useState("");
  const [passage, setPassage] = useState("");

  const selectedFilteredQuestion = useAppSelector(
    (state: RootState) => state.ui.selectedFilteredQuestion
  );

  const columns: ColumnsType<IQuestionBank> = [
    {
      key: "questionNumberSeq",
      width: "20%",
      title: (
        <div>
          <Typography.Text>Q.ID</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setId(e.target.value);
            }}
          />
        </div>
      ),
      dataIndex: "questionNumberSeq",
      filteredValue: [id],
      onFilter: (value: any, record) => {
        return record.questionNumberSeq
          ?.toLowerCase()
          .includes(value?.toLowerCase());
      },
    },
    {
      key: "question",
      dataIndex: "question",

      title: (
        <div>
          <Typography.Text>Question</Typography.Text>
          <div>
            <Input.Search
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
            />
          </div>
        </div>
      ),
      filteredValue: [question],
      onFilter: (value: any, record) => {
        return record.questionText
          ?.toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (text, record) => {
        return getStringFromHTML(record.questionText);
      },
    },
    {
      key: "questionCategory",
      dataIndex: "questionCategory",
      width: "20%",
      title: (
        <div>
          <Typography.Text>Category</Typography.Text>

          <Input.Search
            onChange={(e) => {
              setCategory(e.target.value);
            }}
          />
        </div>
      ),
      filteredValue: [category],
      onFilter: (value: any, record) => {
        return record.questionCategory
          ?.toLowerCase()
          .includes(value?.toLowerCase());
      },
    },
    {
      key: "explanation",
      dataIndex: "passage",
      ellipsis: true,
      width: "35%",
      title: (
        <div>
          <Typography.Text>Passage</Typography.Text>
          <div>
            <Input.Search
              onChange={(e) => {
                setPassage(e.target.value);
              }}
            />
          </div>
        </div>
      ),
      filteredValue: [passage],
      onFilter: (value: any, record) => {
        return record?.passage
          ? record.passage
              .toString()
              .toLowerCase()
              .includes(value?.toLowerCase())
          : true;
      },

      render: (_, record) => {
        return getStringFromHTML(record?.passage);
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setQuestionId(newSelectedRowKeys);
    setDisable(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Table<IQuestionBank>
      rowKey="id"
      rowSelection={rowSelection}
      columns={columns}
      dataSource={selectedFilteredQuestion}
      pagination={{
        pageSize,
      }}
    />
  );
}

export default QuizQuestionForm;
