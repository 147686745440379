import { ColumnsType } from "antd/es/table";
import { Input, Table, Typography, Row, Select } from "antd";
import { useState, useEffect } from "react";
import { ViewDetailsButton } from "../../../components/styled/Button.styled";
import { useNavigate } from "react-router-dom";
import { useGetAllStudentsByTutorIdQuery } from "../../../features/tutorPanelFeatures/TutorStudent/tutorStudentsApiSlice";
import pageSize from "../../../constants/pageSize";
import { IStudent } from "../../../types/studentType";
import CustomCard from "../../../components/countCard/CustomCard";
import secureLocalStorage from "react-secure-storage";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import { useDebouncingValue } from "../../../utils/useDebouncingValue";

function TutorStudents() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const { Text } = Typography;
  const [searchText, setSearchText] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [status, setStatus] = useState("INTERNAL");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const { debouncedValue: studentName, debouncing: nameSearching } =
    useDebouncingValue(searchText);
  const { debouncedValue: phone, debouncing: phoneSearching } =
    useDebouncingValue(phoneNumber);
  const { debouncedValue: email, debouncing: emailSearching } =
    useDebouncingValue(emailId);
  const { debouncedValue: studentStatus, debouncing: studentStatusSearching } =
    useDebouncingValue(status);

  const { data, isLoading, isFetching } = useGetAllStudentsByTutorIdQuery({
    pageSize,
    currentPage,
    idFromLocalStorage,
    emailId: email,
    phoneNumber: phone,
    studentName,
    studentType: studentStatus,
  });

  const columns: ColumnsType<IStudent> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Student Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "phoneNumber",
      title: (
        <div>
          <Typography.Text>Phone Number</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "phoneNumber",
    },
    {
      key: "email",
      title: (
        <div>
          <Typography.Text>Email Id</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setEmailId(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      dataIndex: "email",
    },

    {
      key: "type",
      title: (
        <div>
          <Typography.Text>Status</Typography.Text>
          <Typography.Text>Type</Typography.Text>
          <Select value={"INTERNAL"} className="searchDropdownStyle" disabled />
        </div>
      ),
      dataIndex: "studentType",
    },
    {
      key: "groupTag",
      dataIndex: "groupName",
      width: "15%",
      ellipsis: true,
      render: (_, record) => (
        <div>
          {record.groupName ? (
            <Text code ellipsis={true}>
              {record.groupName}
            </Text>
          ) : (
            <Text code ellipsis={true}>
              Individual
            </Text>
          )}
        </div>
      ),
    },
    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openStudentDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
  ];
  const openStudentDetails = (id: number) => {
    navigate(`view/${id}`);
  };

  return (
    <>
      <Row>
        <Breadcrumbs idName={""} isId={false} />
      </Row>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard sum={data?.data?.totalElements} text="Students" />
      </Row>
      <Row className="styled-container outer-styled-container">
        <Row className="styled-container inner-styled-container">
          <Table<IStudent>
            loading={
              isLoading ||
              isFetching ||
              nameSearching ||
              phoneSearching ||
              emailSearching
            }
            columns={columns}
            rowKey={"id"}
            dataSource={data?.data?.content}
            pagination={{
              pageSize,
              current: currentPage,
              total: data?.data.totalElements,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
          />
        </Row>
      </Row>
    </>
  );
}

export default TutorStudents;
